import './styles/hintbox.css';

/** Renders a Hintbox compo.
 * @param {Object} props The props object.
 * @param {boolean} [props.empty] If true, compo will have a colored border instead of a colored
 * fill.
 * @param {boolean} [props.fullWidth] If true, compo will be rendered at full width and maxWidth will be ignored.
 * @param {*} [props.icon] An icon to be displayed at message's left side.
 * @param {number} [props.maxWidth] A max width for the compo (in pixels). Must be greater than zero.
 * @param {string} props.message The message to be displayed. If undefined or empty, 'Hintbox' will
 * be the default.
 * @param {boolean} [props.rounded] If true, border radius will be rounded.
 * @param {'error'|'warning'|'complete'|'glass'} [props.type] The render type. Set to undefined to
 * render the purple one.
 */
const Hintbox = props => {
  const getClassName = () => {
    let className = 'hintbox';

    if (props.empty) className += ' empty';
    if (props.rounded) className += ' rounded';
    if (props.type) className += ` ${props.type}`;

    return className;
  }

  /** @returns {React.CSSProperties|undefined} */
  const getStyle = () => {
    if (props.fullWidth) return { width: '100%' }
    else if (props.maxWidth > 0) return { maxWidth: `${props.maxWidth}px` };
  }

  return (<div className={getClassName()} style={getStyle()}>
    {props.icon && <img src={props.icon} alt="" />}
    <p>{props.message || 'Hintbox'}</p>
  </div>);
}

export default Hintbox;