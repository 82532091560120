import Generic from "./Generic";

/** PassObject typedef
 * @typedef {Object} PassObject
 * @property {boolean} de_bidd Allow bidding deletion.
 * @property {boolean} de_cont Allow contract deletion.
 * @property {boolean} de_esta Allow estate deletion.
 * @property {boolean} in_bidd Allow bidding creation.
 * @property {boolean} in_cont Allow contract creation.
 * @property {boolean} in_esta Allow estate creation.
 * @property {boolean} up_agre Allow agreement update.
 * @property {boolean} up_esta Allow estate update.
 * @property {boolean} up_pict Allow user picture update.
 * @property {boolean} up_sinf ALlow user info update.
 */

/** The User's Pass class definition */
class Pass extends Generic {
  /** Delete bidding */
  static get DE_BIDD() { return 'de_bidd' }
  /** Delete contract */
  static get DE_CONT() { return 'de_cont' }
  /** Delete estate */
  static get DE_ESTA() { return 'de_esta' }
  /** Insert bidding */
  static get IN_BIDD() { return 'in_bidd' }
  /** Insert contract */
  static get IN_CONT() { return 'in_cont' }
  /** Insert estate */
  static get IN_ESTA() { return 'in_esta' }
  /** Update agreement */
  static get UP_AGRE() { return 'up_agre' }
  /** Update estate */
  static get UP_ESTA() { return 'up_esta' }
  /** Update user self picture */
  static get UP_PICT() { return 'up_pict' }
  /** Update user self info */
  static get UP_SINF() { return 'up_sinf' }

  /** @param {Pass|PassObject} [object] */
  constructor(object) {
    super(object);

    const {
      de_bidd,
      de_cont,
      de_esta,
      in_bidd,
      in_cont,
      in_esta,
      up_agre,
      up_esta,
      up_pict,
      up_sinf
    } = object?.pass || object || {};

    /** @type {PassObject} */
    this.pass = {
      de_bidd: Boolean(de_bidd),
      de_cont: Boolean(de_cont),
      de_esta: Boolean(de_esta),
      in_bidd: Boolean(in_bidd),
      in_cont: Boolean(in_cont),
      in_esta: Boolean(in_esta),
      up_agre: Boolean(up_agre),
      up_esta: Boolean(up_esta),
      up_pict: Boolean(up_pict),
      up_sinf: Boolean(up_sinf)
    }

    Object.freeze();
  }

  /** Obtains the pass object, containing all passes. */
  getAllPasses() {
    return this.pass;
  }

  /** Obtains the specified pass.
   * @param {string} attr Pass name (defined by class globals)
   * @returns {boolean}
   */
  getPass(attr) {
    return this.pass[`${attr}`];
  }

  /** Assings new value to specified pass.
   * @param {string} attr Pass position (defined by class globals) 
   * @param {boolean} val New value for the pass.
   */
  setPass(attr, val) {
    if (this.pass[`${attr}`] !== undefined)
      this.pass[`${attr}`] = Boolean(val);
  }
}

export default Pass;