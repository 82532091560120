import './styles/prop-display.css';

/** Renders a PropDisplay compo.
 * @param {Object} props The props object.
 * @param {string} [props.className] Class name for the compo.
 * @param {boolean} [props.disabled] If true, disables the component.
 * @param {string} props.header A header for the compo. 'PropDisplay Compo' by default.
 * @param {string} [props.id] An id for the compo.
 * @param {boolean} [props.largeFont] If true, compo renders with larger font.
 * @param {React.MouseEventHandler<Element>} [props.onClick] An onClick event.
 * @param {string} props.property The property value to be displayed.
 * @param {string} [props.propertyClass] Class name for the property value.
 * @param {boolean} [props.waiting] If true, compo renders in wait state.
 */
const PropDisplay = props => {
  const getCompoClass = () => {
    let className = 'prop-display';

    if (props.className) className += ` ${props.className}`;
    if (props.largeFont) className += ' large-font';
    if (props.waiting) className += ' waiting';

    return className;
  }

  const getPropertyClass = () => {
    let className = 'value';

    if (props.propertyClass) className += ` ${props.propertyClass}`;

    return className;
  }

  return (
    <div disabled={props.disabled} onClick={props.onClick} className={getCompoClass()} id={props.id}>
      {!props.waiting && <p className="header highlight">{props.header || 'PropDisplay Compo'}</p>}
      {!props.waiting && <p className={getPropertyClass()}>{props.property}</p>}
      {props.waiting && <div className="wrapper" />}
    </div>
  );
}

export default PropDisplay;