import './styles/starbar.css'
import { StarBarIcon } from '../assets/images';

/** Renders a Starbar compo.
 * @param {Object} props The props object.
 * @param {number} props.stars How many filled stars should be rendered. Accepts 0 up to 5 stars
 * (can be decimal).
 */
const Starbar = props => {
  const getStarsWidth = () => {
    if (!props.stars || Number(props.stars) <= 0) return 0;

    const stars = Number(props.stars);

    return ((stars >= 5 && 5) || (stars <= 0 && 0) || stars) * 20;
  }

  return (
    <div className="star-bar">
      <div className='fill-bar' >
        <div className="bar" style={{ 'width': `${getStarsWidth()}%` }} />
      </div>
      <img src={StarBarIcon} alt="star_bar" />
    </div>

  );
}

export default Starbar;