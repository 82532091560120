import './styles/mainpage.css';
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { globalContext } from "./context/GlobalContext";
import Global from "./objects/Global";
import User from "./objects/User";
// Icons an images.
import { KevinImg, MapIcon } from './assets/images/index';

const MainPage = () => {
  const { currSession, setSearchMethod } = useContext(globalContext);
  const navigate = useNavigate();

  // Checks user data when page has been loaded completely
  useEffect(() => {
    // Showing search bar.
    setSearchMethod({
      action: () => navigate(Global.PATH_SEARCH),
      type: 'button',
      icon: MapIcon,
      placeholder: 'Buscar propiedades'
    });

    if (currSession.sessionStatus === User.SESSION_ACT)
      navigate(Global.PATH_HOME, { replace: true });
  }, [currSession, navigate, setSearchMethod]);

  return (
    <div className="container main-page">
      <div className="img">
        <img src={KevinImg} alt="kevin-pet" />
      </div>
      <div className="text">
        <h1>¡Regístrate ahora y aprovecha el máximo potencial de <span className="title highlight">Racchome</span>!</h1>
        <br /><br />
        <h2>
          O bien, utiliza la barra de navegación para encontrar tu próxima
          casa, depa, local, oficina y mucho más...
        </h2>
      </div>
    </div>
  );
}

export default MainPage;