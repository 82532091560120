import '../styles/bank-card-editor.css';
import { useContext, useEffect, useRef, useState } from "react";
import { globalContext } from '../../context/GlobalContext';
import * as Icons from '../../assets/images';
import BankCard from '../../objects/BankCard';
import Button from '../Button';
import DAOServ from '../../objects/DAOServ';
import ErrHandler from '../../objects/ErrHandler';
import Global from '../../objects/Global';
import Hintbox from '../Hintbox';
import Inputbar from '../Inputbar';
import UIRender from '../../objects/UIRender';
import User from '../../objects/User';
import LoadingBlock from '../LoadingBlock';

/** CardPlaceholder typedef.
 * @typedef {Object} CardPlaceholderObject
 * @property {string} [cardNumber]
 * @property {string} [cvc]
 * @property {{ month?: string, year?: string }} exp
 */

/** Renders a BankCardEditor compo.
 * @param {Object} props The props object.
 * @param {BankCard} [props.bankCard] A bank card to modify. If undefined, compo will try
 * to fetch current session's bank card. To create a new card, set this attribute to a new instance
 * of BankCard.
 * @param {string} [props.id] A custom id for the compo.
 * @param {boolean} [props.ignoreCardCVC] If true or ignoreCardExp is true, CVC won't be asked.
 * @param {boolean} [props.ignoreCardExp] If true, expiration won't be asked.
 * @param {() => void} props.onHide A callback function that will be called when compo is hidden.
 * @param {(bC: BankCard) => void} props.onResolve When compo resolves, this function will trigger,
 * given to args the card created or modified (the card number won't be sensored).
 */
const BankCardEditor = props => {
  // *** useContext ***
  const { currSession, pushMessageHint, timezoneOffset } = useContext(globalContext);
  // *** useRef ***
  const auxExpHolder = useRef({ month: '', year: '' });
  const cardCVCInputRef = useRef(/** @type {HTMLInputElement} */(undefined));
  const cardExpMothInputRef = useRef(/** @type {HTMLInputElement} */(undefined));
  const cardExpYearInputRef = useRef(/** @type {HTMLInputElement} */(undefined));
  const cardNumberInputRef = useRef(/** @type {HTMLInputElement} */(undefined));
  const compoId = useRef(props.id || 'bank-card-editor');
  const popup = useRef(/** @type {HTMLDivElement} */(undefined));
  const previousBankCard = useRef(/** @type {BankCard} */(undefined));
  // *** useState ***
  const [bankCard, setBankCard] = useState(props.bankCard);
  const [cardPlaceholder, setCardPlaceholder] = useState(/** @type {CardPlaceholderObject} */({ exp: {} }));
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [disableUI, setDisableUI] = useState(false);
  const [today, setToday] = useState(/** @type {number} */(undefined));

  /** @param {string} [input] */
  const cardCVCChangeHandler = input => {
    bankCard.setCVC(input);

    cardPlaceholder.cvc = input;
    setCardPlaceholder({ ...cardPlaceholder });
    requestEnableSubmit();
  }

  /** @param {string} [input] */
  const cardExpMonthChangeHandler = input => {
    auxExpHolder.current.month = input;
    let { month, year } = auxExpHolder.current;
    month = month?.length === 1 ? `0${month}` : month;

    bankCard.setExp(isExpSetted() ? Date.parse(`${year}-${month}-01`) : undefined);
    cardPlaceholder.exp.month = input;
    setCardPlaceholder({ ...cardPlaceholder });
    requestEnableSubmit();
  }

  /** @param {string} [input] */
  const cardExpYearChangeHandler = input => {
    auxExpHolder.current.year = input;
    const { month, year } = auxExpHolder.current;

    bankCard.setExp(isExpSetted() ? Date.parse(`${year}-${month}-01`) : undefined);
    cardPlaceholder.exp.year = input;
    setCardPlaceholder({ ...cardPlaceholder });
    requestEnableSubmit();
  }

  /** @param {string} [input] */
  const cardNumberChangeHandler = input => {
    bankCard.setCardNumber(input);
    cardPlaceholder.cardNumber = input;
    setCardPlaceholder({ ...cardPlaceholder });
    requestEnableSubmit();
  }

  const createOrUpadeBankCardActionHandler = async () => {
    setDisableUI(true);

    try {
      let key = '', nonce = 0;

      // Getting key.
      const auxUserData = {
        creation_date: currSession.creationDate,
        iduser: currSession.id,
        username: currSession.username
      }

      do {
        key = Global.md5(auxUserData, nonce);
        nonce++;
      } while (key.substring(0, 3) !== '000');

      const cardCVC = !props.ignoreCardCVC ? Global.encrypt(`${bankCard.getCVC()}`, key) : undefined;
      const cardExp = !props.ignoreCardExp ? Global.encrypt(`${bankCard.getExp()}`, key) : undefined;
      const cardNumber = Global.encrypt(bankCard.getCardNumber(), key);
      const payload = { cardCVC, cardExp, cardNumber };

      await DAOServ.post('create_or_update_bank_card', { payload, tst: currSession.tst }, 'JSON');

      pushMessageHint({
        message: previousBankCard.current ? 'Tarjeta actualizada' : 'Tarjeta registrada',
        type: 'complete'
      });
      props.onResolve(bankCard);
      UIRender.hideElement(popup.current);
    } catch (err) {
      pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' });
      setDisableUI(false);
    }
  }

  /** @type {React.AnimationEventHandler} */
  const dispose = e => {
    if (e.target === popup.current && UIRender.isHidden(popup.current))
      props.onHide();
  }

  const getCardLogo = () => {
    const type = getCardTypeClass();

    switch (type) {
      case ' mastercard': return Icons.MasterCardLogo;
      case ' visa': return Icons.VisaLogo;
      default: return undefined;
    }
  }

  const getCardTypeClass = () => {
    const type = bankCard.getCardNumberType();

    if (type === 4) return ' visa';
    else if (type === 5) return ' mastercard';
    else return '';
  }

  const isExpSetted = () => {
    const { month, year } = auxExpHolder.current;

    return Boolean(month && year) && year.length === 4;
  }

  const isExpValid = () => {
    const { month, year } = auxExpHolder.current;

    return isExpSetted() && Date.parse(`${year}-${month}-01`) > today;
  }

  const renderCardNumber = () => {
    if (!cardPlaceholder.cardNumber) return '1234 1234 1234 1234';

    let result = '';

    for (let i = 0; i < cardPlaceholder.cardNumber.length; i++) {
      result += cardPlaceholder.cardNumber.charAt(i);

      if (i === 3 || i === 7 || i === 11) result += ' ';
    }

    return result;
  }

  const renderCardExp = () => {
    const { month, year } = cardPlaceholder.exp;

    return `${month || 'MM'}/${year?.substring(2, 4) || 'YY'}`
  }

  const requestEnableSubmit = () => {
    const sameAsPrevious = previousBankCard.current?.getCVC() === bankCard.getCVC()
      && previousBankCard.current?.getCardNumber() === bankCard.getCardNumber()
      && previousBankCard.current?.getExp() === bankCard.getExp()
    const cardNumberValid = BankCard.cardNumberValid(bankCard.getCardNumber());
    const cvcValid = props.ignoreCardCVC
      || (bankCard.getCVC() >= 100 && bankCard.getCVC() <= 9999);
    const expValid = props.ignoreCardExp
      || (bankCard.getExp() > today)

    setDisableSubmit(sameAsPrevious || !(cardNumberValid && cvcValid && expValid));
  }

  // Page behavior
  useEffect(() => {
    const id = compoId.current;
    const parent = popup.current?.parentNode;
    const options = { footer: true, navbar: true };

    UIRender.disableGlobalScroll(id);
    UIRender.disableSiblings(popup.current, options);

    return () => {
      // Enable childs.
      UIRender.enableChilds(parent, options, id);
      // Enable global scroll.
      UIRender.enableGlobalScroll(id);
    }
  }, []);

  // Fetch data.
  useEffect(() => {
    const fetchBankCard = async () => {
      await DAOServ.post('get_bank_card', { tst: currSession.tst }, 'JSON')
        .then(data => {
          let key = '', nonce = 0;
          const auxUserData = {
            creation_date: currSession.creationDate,
            iduser: currSession.id,
            username: currSession.username
          }

          do {
            key = Global.md5(auxUserData, nonce);
            nonce++;
          } while (key.substring(0, 3) !== '000');

          const cN = Global.decrypt(data['cardNumber'], key);
          const cC = Number(Global.decrypt(data['cvc'], key));
          const cE = Number(Global.decrypt(data['exp'], key));
          const auxDate = new Date(cE - timezoneOffset);
          let auxMonth = `${auxDate.getUTCMonth() + 1}`;
          let auxYear = `${auxDate.getUTCFullYear()}`;

          if (auxMonth.length === 1) auxMonth = `0${auxMonth}`;

          auxExpHolder.current.month = auxMonth;
          auxExpHolder.current.year = auxYear;
          setCardPlaceholder({
            cardNumber: cN,
            cvc: `${cC}`,
            exp: { month: auxMonth, year: auxYear }
          });
          setBankCard(new BankCard({ cardNumber: cN, cvc: cC, exp: cE }));
          previousBankCard.current = new BankCard({ cardNumber: cN, cvc: cC, exp: cE });
        }).catch(err => {
          if (ErrHandler.getCode(err) === ErrHandler.CODES.NOT_FOUND) { // Current session has no bank card.
            setBankCard(new BankCard());
          } else { // Another error.
            pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' });
            UIRender.hideElement(popup.current);
          }
        });
    }

    const fetchToday = async () => {
      try {
        setToday(await DAOServ.getCurrentDay());
      } catch (err) {
        UIRender.hideElement(popup.current);
        pushMessageHint({ message: 'La sesión actual está inactiva o expirada.', type: 'error' });
        return;
      }

      if (bankCard === undefined) fetchBankCard();
      else previousBankCard.current = new BankCard(bankCard);
    }

    if (bankCard === undefined && currSession.sessionStatus !== User.STATUS_ACT) {
      UIRender.hideElement(popup.current);
      pushMessageHint({ message: 'La sesión actual está inactiva o expirada.', type: 'error' });
    } else if (today === undefined) fetchToday();
  }, [bankCard, currSession, timezoneOffset, today, pushMessageHint]);

  return (
    <div className="bank-card-editor popup-wrapper" id={compoId.current} ref={popup} onAnimationEnd={dispose}>
      <div className="popup">
        <div className="popup-content">
          <div className="top-bar">
            <h3 className='highlight'>Editor de tarjetas</h3>
            <Button disabled={bankCard === undefined || disableUI || today === undefined}
              borderless
              empty
              icon={Icons.CloseIcon}
              onClick={() => UIRender.hideElement(popup.current)}
              reduced
              rounded
              typeRender='error' />
          </div>
          <div className="content">
            <div className="bank-card-container">
              {(!Boolean(bankCard) || !Boolean(today)) && <div className="bank-card wait">
                <span className="wait-curtain" />
              </div>}
              {Boolean(bankCard) && Boolean(today) && <div className={"bank-card"} >
                {Boolean(getCardTypeClass()) && <span className={`background${getCardTypeClass()}`} />}
                {Boolean(getCardTypeClass()) && <img
                  src={getCardLogo()}
                  alt="card-logo"
                  className="card-logo" />}
                <img src={Icons.LogoImg} alt="page-logo" className="page-logo-icon" />
                <span className="logo" />
                <span className="card-number">{renderCardNumber()}</span>
                {!Boolean(props.ignoreCardCVC) && <span className="card-code">
                  CVC: {cardPlaceholder.cvc || '123'}
                </span>}
                {!Boolean(props.ignoreCardExp) && <span className="card-exp">
                  EXP: {renderCardExp()}
                </span>}
              </div>}
            </div>
            {!Boolean(bankCard) && <div className="form-container"><LoadingBlock noBackground /></div>}
            {Boolean(bankCard) && <div className="form-container">
              <div className="flex-box m3">
                <Inputbar defaultValue={cardPlaceholder.cardNumber}
                  disabled={disableUI || today === undefined}
                  filters={[{ regExp: Global.REGEXP_FILTER_INTEGER }]}
                  inputMode='numeric'
                  isValid={input => input?.length < 16 || BankCard.cardNumberValid(input)}
                  maxLength={16}
                  onChange={cardNumberChangeHandler}
                  onEnter={() => UIRender.setFocus(cardExpMothInputRef.current)}
                  placeholder={{ default: 'Número del plástico', onIsValidFail: 'Plástico inválido' }}
                  reference={cardNumberInputRef}
                  requestFocus
                  required
                  stopPropagation />
              </div>
              {!props.ignoreCardExp && <div className="flex-box">
                {!props.ignoreCardExp && <div className="flex-box m3">
                  <div className="child m3">
                    <Inputbar defaultValue={cardPlaceholder.exp.month}
                      disabled={disableUI || today === undefined}
                      inputMode='numeric'
                      filters={[{ regExp: Global.REGEXP_FILTER_INTEGER }, { regExp: /^0+/, replace: '0' }]}
                      isValid={input => input && Number(input) > 0 && Number(input) <= 12}
                      onBlur={input => input?.length === 1 ? `0${input}` : input}
                      onChange={cardExpMonthChangeHandler}
                      onEnter={() => UIRender.setFocus(cardExpYearInputRef.current)}
                      maxLength={2}
                      placeholder={{ default: 'MM', onIsValidFail: 'Mes inválido' }}
                      reference={cardExpMothInputRef}
                      required
                      stopPropagation
                      textCenter />
                  </div>
                  <div className="child auto-width"><h3 className="overset">/</h3></div>
                  <div className="child m3">
                    <Inputbar defaultValue={cardPlaceholder.exp.year}
                      disabled={disableUI || today === undefined}
                      inputMode='numeric'
                      filters={[{ regExp: Global.REGEXP_FILTER_INTEGER }, { regExp: /^0+/ }]}
                      isValid={input => input?.length === 4}
                      onChange={cardExpYearChangeHandler}
                      onEnter={() => UIRender.setFocus(cardCVCInputRef.current)}
                      maxLength={4}
                      placeholder={{ default: 'AAAA', onIsValidFail: 'Año inválido' }}
                      required
                      reference={cardExpYearInputRef}
                      stopPropagation
                      textCenter />
                  </div>
                </div>}
                {!props.ignoreCardExp && !props.ignoreCardCVC && <div className="child auto-width m3">
                  <Inputbar defaultValue={bankCard.getCVC()}
                    disabled={disableUI || today === undefined}
                    filters={[{ regExp: Global.REGEXP_FILTER_INTEGER }]}
                    maxLength={BankCard.CVC_MAX_LENGTH}
                    onChange={cardCVCChangeHandler}
                    placeholder={{ default: 'CVC', onIsValidFail: 'CVC inválido' }}
                    reference={cardCVCInputRef}
                    required
                    stopPropagation
                    textCenter
                    width={90} />
                </div>}
              </div>}
              {isExpSetted() && !isExpValid() && <Hintbox icon={Icons.WarningIcon}
                message='La fecha es inválida o ya ha pasado. No puedes utilizar esta fecha.'
                type='warning' />}
              {(!isExpSetted() || isExpValid()) && <Button disabled={disableSubmit}
                fullWidth
                icon={!previousBankCard.current ? Icons.AddIcon : Icons.SaveIcon}
                isWaiting={disableUI || bankCard === undefined || today === undefined}
                onClick={createOrUpadeBankCardActionHandler}
                onWaitValue={!props.bankCard && !bankCard ? 'Cargando...' : 'Guardando...'}
                stopPropagation
                value={previousBankCard.current ? 'Guardar' : 'Registrar'} />}
            </div>}
          </div>
          <Hintbox icon={Icons.InfoIcon} message='Los datos de tu tarjeta están cifrados.' type='complete' />
        </div>
      </div>
    </div>
  );
}

export default BankCardEditor;