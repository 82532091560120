import ErrHandler from "./ErrHandler";
import Generic from "./Generic";
import GenericFile from "./GenericFile";

class FileChooser extends Generic {
  /** @param {FileChooser} [object] */
  constructor(object) {
    super(object);

    const { type, extensions } = object || {};

    this.type = type;
    this.extensions = Array.isArray(extensions) ? Array.from(extensions) : [];
  }

  /** Reads a blob as data URL.
   * @param {Blob} blob 
   * @returns {Promise<string>} The data url.
   */
  static readAsDataURL = blob => {
    return new Promise((resolve, reject) => {
      if (blob instanceof Blob) {
        const fR = new FileReader();

        fR.onerror = () => {
          fR.abort();
          reject("Couldn't resolve blob reading");
        }

        fR.onload = read => {
          resolve(read.target.result);
        }

        fR.readAsDataURL(blob);
      } else return reject('Blob file is not an instance of Blob class');
    });
  }

  /**
   * Checks if the given extension exists in the supported extensions.
   * @param {string} ext An extension to test
   */
  isExtensionValid(ext) {
    return this.getSupportedExtensions().length === 0 || this.getSupportedExtensions().includes(ext);
  }

  /**
   * Checks if the given type is a valid type.
   * @param {string} type
   */
  isTypeValid(type) {
    return !this.type || this.type === type;
  }

  /**
   * Obtains the extensions array admited by this File Chooser instance.
   * @returns {String[]} Extensions array.
   */
  getSupportedExtensions() {
    return this.extensions;
  }

  /**
   * Obtains the extensions array including a dot at the start of every extension.
   * @returns {String[]} A new array of extensions, including a dot at the start of every extension.
   */
  getDottedExtensions() {
    return this.extensions.map(ext => ext[0] !== '.' ? '.'.concat(ext) : ext);
  }

  /**
   * Assigns a string array, beign the suported file extensions.
   * @param {String[]} extensions 
   */
  setSupportedExtensions(extensions) {
    this.extensions = Array.isArray(extensions) ? extensions : this.extensions;
  }

  /**
   * Obtains the supported type.
   * @returns {string}
   */
  getSupportedType() {
    return this.type;
  }

  /**
   * Assigns a supported type.
   * @param {string} type 
   */
  setType(type) {
    this.type = type;
  }

  /**
   * Opens file chooser.
   * @param {string} type Supported type.
   * @param {String[]} extensions Supported extensions.
   * @returns {Promise<GenericFile>}
   */
  open(type, extensions) {
    this.setType(type);
    this.setSupportedExtensions(extensions);

    return new Promise((resolve, reject) => {
      let input = document.createElement('input');
      input.type = 'file';
      input.accept = this.getDottedExtensions().toString();

      input.addEventListener('cancel', () => {
        reject(ErrHandler.getError(ErrHandler.CODES.USER_ABORT, 'File chooser closed by user'));
      });

      input.addEventListener('change', e => {
        // Getting selected file.
        const fetchFile = e.target.files[0];
        const fileType = fetchFile.type.split('/')[0];
        const fileExt = fetchFile.type.split('/')[1];
        const extV = this.isExtensionValid(fileExt);
        const typeV = this.isTypeValid(fileType);

        if (extV && typeV) { // Is file valid?
          const fR = new FileReader();
          const gF = new GenericFile({ name: fetchFile.name, size: fetchFile.size });

          fR.onload = file => {
            gF.setURLData(file.target.result);
            resolve(gF);
          }

          fR.readAsDataURL(fetchFile);
        } else {
          const message = `File ${!typeV ? `type '${fileType}'` : `extension '${fileExt}'`}`
            .concat(`${!typeV && !extV ? ` and extension '${fileExt}' are` : ' is'}`)
            .concat(' not valid')
          reject(ErrHandler.getError(ErrHandler.CODES.PARAM_INVALID, message));
        }
      });

      input.click(); // Opening file chooser.
    });
  }
}

export default FileChooser;