import './styles/license-management.css';
import { useContext, useEffect, useState } from 'react';
import { globalContext } from './context/GlobalContext';
import { useNavigate } from 'react-router-dom';
import Button from './components/Button';
import Dialog from './components/popups/Dialog';
import ErrHandler from './objects/ErrHandler';
import Global from './objects/Global';
import Hintbox from './components/Hintbox';
import LicenseCard from './components/LicenseCard';
import License from './objects/License';
import PropDisplay from './components/PropDisplay';
import DAOServ from './objects/DAOServ';
import UIRender from './objects/UIRender';
import User from './objects/User';
// Icons.
import * as Icons from './assets/images';
import PayRecords from './popups/PayRecords';

const LicenseManagement = () => {
  // *** useContext ***
  const { currSession, pushMessageHint, setSearchMethod, timezoneOffset } = useContext(globalContext);
  // *** useNavigate ***
  const navigate = useNavigate();
  // *** useState ***
  const [licenses, setLicenses] = useState(/** @type {License[]} */(undefined));
  const [currentPopup, setCurrentPopup] = useState(/** @type {undefined|1|2|3} */(undefined));
  const [subscription, setSubscription] = useState( /** @type {import('./objects/Subscription').default} */(undefined));

  const dialogActionHandler = async () => {
    return await DAOServ.post('toggle_subscription_status', { tst: currSession.tst }, 'JSON');
  }

  const dialogOnResolveHandler = () => {
    subscription.setStatus(!Boolean(subscription.getStatus()));

    const message = !subscription.getStatus()
      ? 'La suscripción fue cancelada'
      : 'La suscripción fue activada';

    pushMessageHint({ message, type: 'complete' });
  }

  const getDialogMessage = () => {
    const expiration = 'La suscripción se mantendrá activa hasta el '
      + Global.parseDateUTC(subscription.getNextPayment() + timezoneOffset) + '. ';
    const newSub = 'Para adquirir una nueva suscripción, deberás esperar a que la suscripción actual caduque. ';

    return `El estado de la suscripción pasará a estar cancelada. ${expiration}`
      + ' Toma en cuenta que, para cancelar una suscripción, no debe haber subastas que'
      + ' finalizen en la fecha de corte o después (si aplica), ni más de un contrato'
      + ' que finalize en la fecha de corte o después, y tampoco debes tener más de una'
      + ' propiedad activa. Cuando llegue la fecha de corte, no tendrás acceso a la'
      + ' información de subastas que hayas realizado (si aplica). Las subcuentas asociadas'
      + ` (si las hay) no serán eliminadas, pero ya no podrán acceder a Racchome. ${newSub}`
      + ' ¿Quieres continuar?';
  }

  const getSubscriptionExpiration = () => {
    const className = subscription?.getLicense().getClassName();

    return className === 'lmted' || className === 'betas'
      ? 'N/A'
      : Global.parseDateUTC(subscription?.getNextPayment(), timezoneOffset)
  }

  const getSubscriptionStatus = () => {
    const className = subscription?.getLicense().getClassName();

    return className === 'betas' || subscription?.getStatus()
      ? 'Activo'
      : 'Cancelado'
  }

  useEffect(() => {
    setSearchMethod(false);
    UIRender.scrollTo();
  }, [setSearchMethod]);

  useEffect(() => {
    const getCurrentLicenses = async () => {
      const fetchLicenses = [];
      /** @type {Object[]} */
      const data = await DAOServ.get('get_current_licenses');

      data.forEach(rawLic => {
        const auxLic = new License();
        const keys = Object.keys(rawLic).filter(key => key === 'bidding' || key.includes('max_'));
        // Lic init.
        auxLic.setClassName(rawLic['class_name']);
        auxLic.setDiscount(Number(rawLic['discount']));
        auxLic.setDescription(rawLic['dscr']);
        auxLic.setId(rawLic['idlicense']);
        auxLic.setPrice(Number(rawLic['price']));
        auxLic.setTitle(rawLic['title']);
        keys.forEach(k => auxLic.getFeatures()[k] = rawLic[k])

        fetchLicenses.push(auxLic);
      });

      setLicenses(fetchLicenses);
    }

    const getUserSubscription = async () => {
      // Fetch current subscription.
      setSubscription(await DAOServ.fetchSubscription(currSession.tst, true));
      // Fetch current licenses.
      getCurrentLicenses();
    }

    if (currSession.sessionStatus) {
      if (currSession.sessionStatus === User.SESSION_ACT) {
        // Fetch user subscription info.
        getUserSubscription();
      } else {
        if (currSession.sessionStatus === User.SESSION_EXP) { // Session expired.
          pushMessageHint({
            message: ErrHandler.CODES.TST_FAIL,
            type: 'error'
          });
        }

        navigate('/', { replace: true });
      }
    }
  }, [currSession, navigate, pushMessageHint]);

  return (
    <div className="container license-management">
      <div className="box borderless current">
        <h2 className="highlight">Tu suscripción.</h2>
        {subscription?.getLicense().getClassName() === 'betas' && <Hintbox
          icon={Icons.InfoIcon}
          message='Eres parte del programa BETA. Te avisaremos cuando exista fecha de expiración.' />}
        <div>
          {<LicenseCard btnClassName='glass'
            license={subscription?.getLicense()} />}
        </div>
        <div className="flex-box m3 wrap">
          {(subscription === undefined || subscription.getLicense().getClassName() !== 'lmted') &&
            <div className="child m3 jc-left">
              <PropDisplay
                header='Estado'
                id='sub-status'
                property={getSubscriptionStatus()}
                waiting={!subscription} />
            </div>
          }
          {(subscription === undefined || subscription.getLicense().getClassName() !== 'lmted') &&
            <div className="child jc-left">
              <PropDisplay
                header={!subscription?.getStatus() || subscription.getLicense().getClassName() === 'betas'
                  ? 'Expiración'
                  : 'Próximo pago'}
                id='sub-exp'
                property={getSubscriptionExpiration()}
                waiting={!subscription} />
            </div>
          }
        </div>
        {subscription !== undefined && subscription?.getLicense().getClassName() !== 'lmted' &&
          <div className="flex-box wrap">
            <div className="child m3">
              <Button disabled={!subscription.getStatus() || subscription.getLicense().getClassName() === 'betas'}
                empty
                fullWidth
                icon={Icons.CloseIcon}
                onClick={() => setCurrentPopup(1)}
                typeRender={subscription.getStatus() ? 'error' : ''}
                value='Cancelar suscripción' />
            </div>
            <div className="child m3">
              <Button disabled={subscription.getLicense().getClassName() === 'betas'}
                empty
                fullWidth
                icon={Icons.PayIcon}
                onClick={() => setCurrentPopup(2)}
                value='Gestionar método de pago' />
            </div>
          </div>
        }
        {subscription !== undefined && subscription.getLicense().getClassName() !== 'lmted' &&
          <div className="flex-box m3">
            <Button
              empty
              fullWidth
              icon={Icons.RecordIcon}
              onClick={() => setCurrentPopup(3)}
              value='Historial de pagos' />
          </div>
        }
      </div>
      <div className="box borderless current">
        <h2 className="highlight">Planes disponibles</h2>
        <Hintbox message={'No necesitas de una suscripción para ser arrendatario de una o varias propiedades'
          + ', ni para ofertar en subastas.'
        } icon={Icons.WarningIcon}
          type='warning' />
        <h4 className="overset subtitle">Planes generales</h4>
        {licenses !== undefined && licenses.map(license => {
          const className = license.getClassName();

          if (className !== 'cust2' && !className.includes('htlr')) return <LicenseCard
            key={license.getClassName()}
            license={license}
            showGetButton={license.getClassName() !== subscription?.getLicense().getClassName()} />
          else return undefined;
        })}
        <h4 className="overset subtitle">Planes para hoteles</h4>
        {licenses !== undefined && licenses.map(license => {
          const className = license.getClassName();

          if (className === 'cust2' || className.includes('htlr')) return <LicenseCard
            key={license.getClassName()}
            license={license}
            showGetButton={license.getClassName() !== subscription?.getLicense().getClassName()} />
          else return undefined;
        })}
      </div>
      {/* Cancel subscription */}
      {currentPopup === 1 && <Dialog action={dialogActionHandler}
        confirmBtn={{ icon: Icons.BanIcon, onWaitValue: 'Cancelando...', type: 'error', value: 'Cancelar' }}
        message={getDialogMessage()}
        onHide={() => setCurrentPopup()}
        onReject={err => err && pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' })}
        onResolve={dialogOnResolveHandler}
        rejectBtn={{ value: 'Regresar' }}
        renderButtonsEmpty
        renderButtonsRounded
        renderButtonsSwitched />}
      {/* Payment records */}
      {currentPopup === 3 && <PayRecords
        onHide={() => setCurrentPopup()} />}
    </div>
  );
}

export default LicenseManagement;