import '../styles/create-estate.css';
import { useContext, useEffect, useRef, useState } from 'react';
import { globalContext } from '../context/GlobalContext';
import { ArrowIcon, CloseIcon } from '../assets/images';
import { Step1, Step2, Step3, Step4, Step5, Step6, Step7 } from './CEComponents';
import Button from '../components/Button';
import Dialog from '../components/popups/Dialog';
import Global from '../objects/Global';
import Estate from '../objects/Estate';
import DAOServ from '../objects/DAOServ';
import UIRender from '../objects/UIRender';
import ErrHandler from '../objects/ErrHandler';
import LocSelector from '../components/popups/LocSelector';
import Address from '../objects/Address';

/** Props object typedef.
 * @typedef {Object} CreateEstatePropsObject
 * @property {Estate} [complex] The complex estate (subdivisions).
 * @property {import('../objects/Persona').default} contactInfo The default contact info object.
 * @property {boolean} [enableBidding] If true, bidding creation will be enabled.
 * @property {() => void} onHide The callback function that will be called when compo is hidden.
 * @property {() => void} onResolve The callback function that will be called when estate has been created
 * sucsessfully.
 * @property {import('../Signup').PrefixObject[]} prefixes The phone prefixes array for contact info
 * management.
 * @property {number} today The current day.
 */

/** ShowLocObject typedef
 * @typedef {Object} ShowLocObject
 * @property {string} defaultValue Default value for compo.
 * @property {() => void} onResolve A callback function that will be activated when
 * on resolve.
 */

/** Renders a CreateEstate popup compo.
 * @param {CreateEstatePropsObject} props The props object
 */
const CreateEstate = props => {
  // *** useContext ***
  const { currSession, pushMessageHint, timezoneOffset } = useContext(globalContext);
  // *** useRef ***
  const compoId = useRef("create-estate");
  const displayStep = useRef({ current: 1, last: 7 });
  const estate = useRef(new Estate({ location: new Address(props.complex?.getLocation()) }));
  const popup = useRef(/** @type {HTMLDivElement} */(undefined));
  const ignoredSteps = useRef([4]); // Ignored steps array.
  // *** useState ***
  const [step, setStep] = useState(0); // Current step
  const [showLocSel, setShowLocSel] = useState(/** @type {ShowLocObject} */(undefined)); // Loc selector.
  const [showDiscardDialog, setShowDiscardDialog] = useState(false);
  const [enableNextBtn, setEnableNextBtn] = useState(false); // Enable next button.
  const [disableUI, setDisableUI] = useState(false); // Disable ui.

  /** Adds an step to the ignored steps array.
   * @param {number} step 
   */
  const addIgnoredStep = step => {
    if (!ignoredSteps.current.includes(step)) ignoredSteps.current.push(step);
  }

  /** Obtains the title of current step. */
  const getStepName = () => {
    switch (step) {
      case 0: return 'Carátula';
      case 1: return 'Tipo y promoción';
      case 2: return estate.current.getSellMethod() === Estate.ON_LEASE ? 'Contrato' : 'Precio';
      case 3: return 'Detalles y características';
      case 4: return 'Servicios incluidos';
      case 5: return 'Contacto';
      case 6: return 'Revisión';
      default: return 'Paso no asignado';

    }
  }

  /** @param {boolean} goNext If true, proceeds to next step. If false, returns one step. */
  const nextPrevBtnHandleOnClick = goNext => {
    const content = popup.current.children[0]?.children[2];

    if (content?.localName === 'div' && content?.className.includes('popup-content')) {
      setEnableNextBtn(false);
      content.setAttribute(goNext ? 'go-next' : 'go-previous', '');
    }
  }

  /** @type {React.AnimationEventHandler}  */
  const popupHandleOnEndAnimation = e => {
    if (e.target === popup.current && UIRender.isHidden(e.target)) {
      // On window close.
      props.onHide();
    } else if (e.target.hasAttribute('go-next') || e.target.hasAttribute('go-previous')) {
      // On step change.
      stepHandleOnChange(e.target);
    }
  }

  /** On dispose function handler */
  const popupHandleOnDispose = () => {
    UIRender.hideElement(popup.current);
  }

  /** On submit function handler. */
  const createEstateHandleOnSubmit = async () => {
    if (disableUI) return;

    // *** Copy and Payload.
    let auxEstate = new Estate(estate.current); // Estate copy.
    const files = auxEstate.getImages().map(img => img.toFile()); // Getting images in file format.
    const payload = new FormData(); // payload.

    // *** Estate dates parsing.
    auxEstate.setBuildDate(auxEstate.getBuildDate() - timezoneOffset)

    if (auxEstate.getBidding().getStatus())
      auxEstate.getBidding().setEndDate(auxEstate.getBidding().getEndDate() - timezoneOffset)

    // *** Purge.
    Estate.purge(auxEstate); // Purge estate.
    auxEstate.setImages(); // Purge Generic Files from aux estate.

    if (auxEstate.getTotalSpaces() === undefined)
      auxEstate.setTotalSpaces(1);

    auxEstate = Global.purgeObject(auxEstate); // Purge undefined and null properties.

    // *** Payload append.
    payload.append('tst', currSession.tst); // Current user's tst.
    payload.append('estate', JSON.stringify(auxEstate)); // Estate object.

    if (props.complex) payload.append('idComplex', props.complex.getId());

    // *** Image files append.
    files.forEach(file => payload.append('files', file));

    setDisableUI(true); // Disable UI.

    // DAOServ request.
    await DAOServ.post('create_estate', payload, 'MULTI')
      .then(() => {
        const active = auxEstate.status === Estate.STATUS_ACT;

        popupHandleOnDispose(); // Dispose popup.

        if (props.onResolve) props.onResolve(); // On resolve function trigger.

        // Show message hint.
        pushMessageHint({
          message: `La propiedad ha sido creada${active ? ' y publicada' : ''}`,
          type: 'complete'
        });
      }).catch(err => {
        setDisableUI(false); // Enable UI.
        pushMessageHint({
          message: ErrHandler.parseError(err),
          type: 'error'
        });
      });
  }

  /** Removes an step from the ignored steps array
   * @param {number} step 
   */
  const removeIgnoredStep = step => {
    const index = ignoredSteps.current.findIndex(igStp => igStp === step);
    if (index !== -1) ignoredSteps.current.splice(index, 1);
  }

  /** @type {(e: HTMLDivElement) => void} */
  const stepHandleOnChange = divElm => {
    const getNextStep = () => {
      const sense = getSense();
      let ret = -1;
      let i = step + sense;

      while (ret === -1 && i >= 0 && i <= 6) {
        if (!ignoredSteps.current.includes(i)) ret = i;
        i += sense;
      }

      return ret;
    }

    const getSense = () => divElm.hasAttribute('go-next') ? 1 : -1;

    if (divElm.classList.contains('popup-content')) {
      if (divElm.hasAttribute('go-next') || divElm.hasAttribute('go-previous')) {
        setStep(getNextStep());
        displayStep.current.current = displayStep.current.current + getSense();
      }
    }
  }

  useEffect(() => {
    const id = compoId.current
    const options = { footer: true, navbar: true };
    const parent = popup.current?.parentNode;

    UIRender.disableGlobalScroll(id)
    UIRender.disableSiblings(popup.current, options); // Disable siblings.

    return () => {
      UIRender.enableGlobalScroll(id);
      UIRender.enableChilds(parent, options, id);
    }
  }, [])

  return (
    <div id={compoId.current}
      className="create-estate popup-wrapper"
      onAnimationEnd={popupHandleOnEndAnimation}
      ref={popup}>
      <div className="popup" id='create-estate-container' disabled={disableUI}>
        {/* popup top bar */}
        <div className="top-bar">
          <h2 className="highlight">Registra una propiedad.</h2>
          <Button onClick={() => setShowDiscardDialog(true)}
            id='close-window'
            className='error reduced rounded empty borderless'
            icon={CloseIcon} />
        </div>
        {/* popup middle bar */}
        <div className="sub-bar">
          <Button onClick={() => nextPrevBtnHandleOnClick(false)}
            empty
            rounded
            reduced
            rotation={180}
            icon={ArrowIcon}
            value='Anterior'
            disabled={step === 0} />
          <div className="info">
            <h6 className="highlight">{`${getStepName()}`}</h6>
            <h6 className="overset">Paso {displayStep.current.current} de {displayStep.current.last - ignoredSteps.current.length}</h6>
          </div>
          <Button onClick={() => nextPrevBtnHandleOnClick(true)}
            rounded
            empty
            icon={ArrowIcon}
            reduced
            value='Siguiente'
            disabled={!enableNextBtn} />
        </div>
        {/* popup content elements by step */}
        {/* Step 1: cover */}
        {step === 0 && <Step1
          estate={estate.current}
          setEnableNextBtn={setEnableNextBtn} />}
        {/* Step 2: type and method */}
        {step === 1 && <Step2 addIgnoredStep={addIgnoredStep}
          complex={props.complex}
          enableBidding={props.enableBidding}
          estate={estate.current}
          removeIgnoredStep={removeIgnoredStep}
          setEnableNextBtn={setEnableNextBtn}
          today={props.today} />}
        {/* Step 3: price */}
        {step === 2 && <Step3
          estate={estate.current}
          setEnableNextBtn={setEnableNextBtn} />}
        {/* Step 4: Details and properties */}
        {step === 3 && <Step4 addIgnoredStep={addIgnoredStep}
          complex={props.complex}
          estate={estate.current}
          removeIgnoredStep={removeIgnoredStep}
          setEnableNextBtn={setEnableNextBtn}
          today={props.today}
          toggleLocSel={setShowLocSel} />}
        {/* Step 5: Included services */}
        {step === 4 && <Step5
          estate={estate.current}
          setEnableNextStep={setEnableNextBtn} />}
        {/* Step 6: Contact info */}
        {step === 5 && <Step6 contactInfo={props.contactInfo}
          estate={estate.current}
          prefixes={props.prefixes}
          setEnableNextBtn={setEnableNextBtn} />}
        {/* Step 7: Revision */}
        {step === 6 && <Step7
          complex={props.complex}
          estate={estate.current}
          isWaiting={disableUI}
          onSubmit={createEstateHandleOnSubmit} />}
        {step !== 6 && <h6 className="overset"><span className="required" />
          {`Hay campos obligatorios${props.complex ? ' (algunos fueron heredados)' : ''}.`}
        </h6>}
      </div>
      {Boolean(showDiscardDialog) && <Dialog confirmBtn={{ type: 'error', value: 'Aceptar' }}
        id='discard-estate-dialog'
        ignoreOnResolveHideAnimation
        message='Esta propiedad será descartada después de cerrar la ventana.'
        onHide={() => setShowDiscardDialog(false)}
        onResolve={popupHandleOnDispose}
        rejectBtn={{ value: 'Cancelar' }}
        renderButtonsEmpty
        renderButtonsRounded
        renderButtonsSwitched />}
      {showLocSel && <LocSelector
        defaultValue={showLocSel.defaultValue}
        onHide={() => setShowLocSel(undefined)}
        onResolve={showLocSel.onResolve}
      />}
    </div>
  );
}

export default CreateEstate;