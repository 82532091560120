import Generic from "./Generic";

class Transaction extends Generic {
  static get PAY_TYPE_OTHER() { return 0 }
  static get PAY_TYPE_INAPP() { return 1 }
  static get PAY_TYPE_TRANSFER() { return 2 }
  static get PAY_TYPE_CASH() { return 3 }

  /** @param {Transaction} [obj] */
  constructor(obj) {
    super(obj);

    const { amount, blockHash, payer, payType } = obj ?? {};

    this.setAmount(amount);
    this.setBlockHash(blockHash);
    this.setPayer(payer);
    this.setPayType(payType);
  }

  /** Obtains the transaction's amount. */
  getAmount() {
    return this.amount;
  }

  /** Assings an amount.
   * @param {number} amount 
   */
  setAmount(amount) {
    this.amount = isNaN(amount) || Number(amount) < 0
      ? undefined
      : Number(amount);
  }

  /** Obtains the transaction's payer */
  getPayer() {
    return this.payer;
  }

  /** Obtains the block hash where this transaction is located. */
  getBlockHash() {
    return this.blockHash;
  }

  /** Assings the block hash where this transaction is located.
   * @param {string} [blockHash] 
   */
  setBlockHash(blockHash) {
    this.blockHash = blockHash;
  }

  /** Assings a payer.
   * @param {import('./User').default} payer 
   */
  setPayer(payer) {
    this.payer = payer;
  }

  /** Obtains the pay type.
   * @returns {0|1|2|3}
   */
  getPayType() {
    return this.payType;
  }

  /** Assings the pay type
   * @param {0|1|2|3} payType 
   */
  setPayType(payType) {
    this.payType = payType >= Transaction.PAY_TYPE_OTHER && payType <= Transaction.PAY_TYPE_CASH
      ? payType
      : Transaction.PAY_TYPE_OTHER;
  }
}

export default Transaction;