import GenericFile from "./GenericFile";
import Global from "./Global";
import License from "./License";
import Pass from "./Pass";
import Persona from "./Persona";
import Rate from './Rate';

class User extends Persona {
  /** Username max length. */
  static get USERNAME_MAX_LENGHT() { return 21 }
  /** Username min length. */
  static get USERNAME_MIN_LENGTH() { return 5 }
  /** Password max length. */
  static get PASSWORD_MAX_LENGTH() { return 30 }
  /** Password min length. */
  static get PASSWORD_MIN_LENGTH() { return 8 }
  /** Value for currSession, meaning TST is verified and active. */
  static get SESSION_ACT() { return 1 }
  /** Value for currSession, meaning TST has expired. */
  static get SESSION_EXP() { return 2 }
  /** Value for currSession, meaning TST is not active. */
  static get SESSION_INA() { return 3 }
  /** User status is deleted, being -2 */
  static get STATUS_DEL() { return -2 }
  /** User status is suspended, being -1 */
  static get STATUS_SUS() { return -1 }
  /** User status is inactive, being 0 */
  static get STATUS_INA() { return 0 }
  /** User status is active, being 1 */
  static get STATUS_ACT() { return 1 }

  /** @param {User} [user] */
  constructor(user) {
    super(user);

    const {
      emailVer,
      lastSession,
      license,
      pass,
      password,
      picture,
      status,
      superuser,
      rate,
      tst,
      username
    } = user ?? {};

    this.setEmailVerified(emailVer);
    this.setLastSession(lastSession);
    this.setLicense(new License(license));
    this.setPass(new Pass(pass));
    this.setPassword(password);
    this.setPicture(picture ? new GenericFile(picture) : undefined);
    this.setSuperuser(superuser ? new User(superuser) : undefined);
    this.setRate(rate ? new Rate(rate) : undefined);
    this.setStatus(status);
    this.setTST(tst);
    this.setUsername(username);
  }

  /** Obtains the email verification */
  getEmailVerified() {
    return this.emailVer;
  }

  /** Assings the email verification
   * @param {Boolean} emailVer If not boolean, attribute will be undefined
   */
  setEmailVerified(emailVer) {
    this.emailVer = typeof emailVer === 'boolean' ? emailVer : undefined;
  }

  /** Obtains the last session of the user (milli) */
  getLastSession() {
    return this.lastSession;
  }

  /** Assings the last sessioj of the user (milli)
   * @param {number} lastSession 
   */
  setLastSession(lastSession) {
    if (typeof lastSession === 'number' || !isNaN(Number(lastSession))) {
      this.lastSession = Number(lastSession);
    } else if (lastSession === undefined || lastSession === null || lastSession === '') {
      this.lastSession = undefined;
    } else {
      this.setBirthdate(Date.parse(`${lastSession}`));
    }
  }

  /** Obtains the user's license.
   * @returns {License}
   */
  getLicense() {
    return this.license;
  }

  /** Assigns a license for the user. Given license will be used to create a new instance.
   * @param {License} license 
   * @example (aLicense) => this.license = new License(aLicense);
   * @example (undefined || null) => this.license = undefined;
   */
  setLicense(license) {
    this.license = license ? new License(license) : undefined;
  }

  /** Obtains the user's PassObject
   * @returns {Pass}
   */
  getPass() {
    return this.pass;
  }

  /** Assigns the PassObject for the user.
   * @param {Pass} pass 
   */
  setPass(pass) {
    if (pass instanceof Pass) this.pass = pass;
    else this.pass = new Pass(pass);
  }

  /** Obtains the user's password.
   * @returns {string}
   */
  getPassword() {
    return this.password;
  }

  /** Assings a password for the user.
   * @param {string} password 
   */
  setPassword(password) {
    this.password = password;
  }

  /** Obtains the user's profile picture, parsed to a string.
   */
  getPicture() {
    return this.picture;
  }

  /** Assign a picture for the user's profile picture.
   * @param {GenericFile} picture 
   */
  setPicture(picture) {
    this.picture = picture instanceof GenericFile
      ? picture : undefined;
  }

  /** Assings a status for the user (defined by User.STATUS_... globals). User.STATUS_ACT is
   * the default.
   * @param {number} status 
   */
  setStatus(status) {
    this.status = status === User.STATUS_ACT
      || status === User.STATUS_DEL
      || status === User.STATUS_INA
      || status === User.STATUS_SUS
      ? status : User.STATUS_ACT;
  }

  /** Obtains the user status (defined by User.STATUS_... globals) */
  getStatus() {
    return this.status;
  }

  /** Obtains the user's superuser. */
  getSuperuser() {
    return this.superuser;
  }

  /** Assigns a superuser to the user.
   * @param {User} [superuser] 
   */
  setSuperuser(superuser) {
    this.superuser = superuser;
  }

  /** Obtains the user's rating. */
  getRate() {
    return this.rate;
  }

  /** Assigns a rating for the user.
   * @param {Rate} [rate] 
   */
  setRate(rate) {
    this.rate = rate;
  }

  /** Obtains user's Temporal Session Token.
   * @returns {string}
   */
  getTST() {
    return this.tst;
  }

  /** Assings a Temporal Session Token for the user.
   * @param {string} tst 
   */
  setTST(tst) {
    this.tst = tst?.trim();
  }

  /** Obtains the user's username.
   * @returns {string}
   */
  getUsername() {
    return this.username;
  }

  /** Assigns a username for the user.
   * @param {string} username 
   */
  setUsername(username) {
    let aux = Global.replace(username?.toLowerCase(), ['no-spaces']);

    if (aux?.length > User.USERNAME_MAX_LENGHT) aux = aux?.substring(0, User.USERNAME_MAX_LENGHT);

    this.username = aux;
  }
}

export default User;