import './styles/file-manager.css';
import { AddIcon, DeleteIcon, DwnldIcon } from '../assets/images';
import { useContext, useState } from "react";
import { globalContext } from '../context/GlobalContext';
import Button from './Button';
import Global from '../objects/Global';
import fileDownload from 'js-file-download';
import GenericFile from '../objects/GenericFile';
import DAOServ from '../objects/DAOServ';
import ErrHandler from '../objects/ErrHandler';

/** Renders a FileManager compo.
 * @param {Object} props The props object.
 * @param {boolean} props.allowDownload If true, files in the list can be
 * downloaded.
 * @param {boolean} props.allowRemove If true, files in the list can be
 * removed.
 * @param {boolean} props.allowUpload If true, File manager can upload files.
 * @param {boolean} [props.disabled] If true, disables compo interface
 * @param {File[]|GenericFile[]} [props.files] A collection of files (defaultValue). Given array
 * will be copied. Array can contain File, GenericFile or both instances. If an instance is a
 * GenericFile instance, download is allowed and user pushes download btn, FileManager will try
 * to retrieve file from server and cast that instance to a that file.
 * @param {string[]} [props.fileTypes] An array of allowed file types.
 * @param {number} [props.maxFiles] A limit for files, measured in Bytes.
 * @param {number} [props.maxSize] A limit for file size.
 * @param {(f: Blob) => void} props.onAdd A callback function that will be fired when a new file
 * has been added to the list. Files are added as they come, so feel free to add them with the push
 * method.
 * @param {(i: number) => void} props.onRemove A callback function that will be fired when a file
 * has been removed from the list. Will return the index of the removed file.
 * @param {string} [props.title] A custom title for the compo. 'Gestor de archivos' is the default.
 */
const FileManager = props => {
  // *** useContext ***
  const { getCacheFile, pushCacheFile, pushMessageHint } = useContext(globalContext);
  // *** useState ***
  const [files, setFiles] = useState(/** @type {[File&GenericFile]} */([...props.files]));
  const [waitDwnldBtn, setWaitDwnldBtn] = useState();

  /** @type {React.MouseEventHandler<HTMLButtonElement>} */
  const filesHandleOnClick = async e => {
    if (props.disabled) return;

    const btn = Global.findParent(e.target, { className: 'file-manager-tool-btn' });

    if (btn) {
      const fIdx = files.findIndex(file => file.name === btn.parentNode.parentNode.id);

      if (fIdx >= 0) {
        if (btn.id === 'delete-file') { // Delete file.
          files.splice(fIdx, 1);
          props.onRemove(fIdx);
          setFiles([...files]);
        } else if (btn.id === 'download-file') { // Download file.
          if (files[fIdx] instanceof GenericFile) { // Fetch file.
            setWaitDwnldBtn(fIdx);

            // Checking cached files.
            const auxCF = getCacheFile(files[fIdx].getPathname());

            if (auxCF !== undefined) { // Return file from cache.
              fileDownload(auxCF.content, auxCF.name);
            } else { // Fetch file from server.
              await DAOServ.getFile(files[fIdx].getPathname(), files[fIdx].getIsTemporal())
                .then(blob => {
                  files[fIdx] = new File([blob], files[fIdx].getName(), { type: blob.type });
                  pushCacheFile({ content: files[fIdx], name: files[fIdx].name, size: files[fIdx].size });
                  fileDownload(files[fIdx], files[fIdx].name);
                }).catch(err => pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' }));
            }

            setWaitDwnldBtn();
          } else {
            fileDownload(files[fIdx], files[fIdx].name);
          }
        }
      }
    }
  }

  /** @type {(file: File) => void} */
  const onFileUpload = file => {
    if (file.size > props.maxSize) {
      pushMessageHint({ message: `El archivo "${file.name}" es demasiado grande`, type: 'error' });
    } else if (props.fileTypes && !props.fileTypes.find(type => file.type.includes(type))) {
      pushMessageHint({ message: `Tipo de archivo no admitido`, type: 'error' });
    } else {
      const findFile = files.find(f => f.name === file.name);

      if (!findFile) {
        props.onAdd(file);
        setFiles([...files, file]);
      } else pushMessageHint({ message: `"${file.name}" ya fue subido`, type: 'error' });
    }
  }

  const renderFiles = () => {
    let index = -1;

    return files.map(file => {
      index++;
      const fileName = (file instanceof GenericFile)
        ? file.getName() || file.getPathname() || `file-${index}`
        : file.name || `file-${index}`;

      return (
        <div className="file-item" key={fileName} id={fileName}>
          <h5 className={`title${props.disabled ? ' overset' : ''}`}>{file.name}</h5>
          <h5 className={`size${props.disabled ? ' overset' : ''}`}>{renderFileSize(file.size)}</h5>
          {(props.allowDownload || props.allowRemove) && <div className="tools">
            {props.allowDownload && <Button
              disabled={props.disabled}
              className='file-manager-tool-btn'
              id='download-file'
              icon={DwnldIcon}
              isWaiting={waitDwnldBtn === index}
              reduced
              empty
              rounded
              title='Descargar archivo' />}
            {props.allowRemove && <Button
              disabled={props.disabled || waitDwnldBtn === index}
              className='file-manager-tool-btn'
              id='delete-file'
              icon={DeleteIcon}
              reduced
              empty
              rounded
              title='Quitar archivo'
              typeRender='error' />}
          </div>}
        </div>
      );
    });
  }

  const renderFilesLimit = () => {
    return `Hasta ${props.maxFiles} archivo${props.maxFiles > 1 ? 's' : ''}.`;
  }

  const renderFileSize = size => {
    if (size > Global.U_MB) {
      return `${parseFloat(size / Global.U_MB).toFixed(1)} MB`;
    } else {
      return `${parseFloat(size / Global.U_KB).toFixed(1)} KB`
    }
  }

  return (
    <div className="file-manager">
      <h5 className="overset">{props.title ?? 'Gestor de archivos'}</h5>
      <div className="files-container" onClick={filesHandleOnClick}>
        {/* File item */}
        {renderFiles()}
      </div>
      {props.allowUpload && <div className="header">
        {props.maxFiles > 0 && <h6>{renderFilesLimit()}</h6>}
        {props.maxSize > 0 && <h6>{renderFileSize(props.maxSize)} Máx.</h6>}
        <Button
          disabled={props.disabled || files.length === props.maxFiles}
          icon={AddIcon}
          id='add-file'
          empty
          reduced
          value='Agregar'
          type='file'
          title='Agrega un nuevo documento'
          fileSubmit={{
            fileTypes: props.fileTypes,
            onChange: onFileUpload
          }} />
      </div>}
    </div>
  );
}

export default FileManager;