import { useEffect, useRef, useState } from 'react';
import './styles/selectbar.css';

/** OptionObject Typedef
 * @typedef {Object} OptionObject
 * @property {*} value The value of the option.
 * @property {string} displayValue The value that will be shown in the option.
 */

/** Renders a Selectbar compo.
 * @param {Object} props The props object.
 * @param {*} [props.defaultValue] Default value selected in the compo (from OptionObject.value).
 * @param {boolean} [props.disabled] If true, disables the compo.
 * @param {React.MutableRefObject<(val?: string) => void>} [props.forceChangeRef] A ref.
 * A callback function will be assigned to its 'current' attribute. Call current to force input value to
 * change (f.e. <ref_def.current(<new_value>)). If undefined or given value does not exist in the
 * options array, compo will be restored to its default value. Doing this won't trigger any assigned
 * callback function.
 * @param {*} [props.forceChangeValue] Use this property to force the option value to change
 * from the parent compo (with a useState hook). Doing this won't trigger any assigned callback
 * function. This callback won't be called if input is undefined or its value is not in the options
 * array.
 * @param {*} [props.icon] An icon to be displayed aside placeholder.
 * @param {(option: *) => void} [props.onChange] When selection change, returns the value of current
 * selection (the value attribute from OptionObject).
 * @param {OptionObject[]} props.options The set of options to be stored in the compo.
 * @param {string} [props.placeholder] A place holder to be shown. 'Plantilla' is the default value.
 * @param {React.MutableRefObject<HTMLSelectElement>} [props.reference] A ref for the select compo.
 * @param {boolean} [props.required] If true, a red * symbol will be shown.
 * @param {string} [props.undefinedOption] The unselection option (the very first one). 'Sin especificar'
 * @param {number} [props.width] Adds a custom width in pixels. If undefined, 100% will be set by default.
 */
const Selectbar = props => {
  // *** useRef ***
  const currOption = useRef(props.defaultValue);
  const defaultValue = useRef(props.defaultValue);
  const selectRef = useRef(/** @type {HTMLSelectElement} */(undefined));
  // *** useState ***
  const [placeholder, setPlaceholder] = useState(props.placeholder || 'Plantilla');

  const getClassName = () => {
    return 'selectbar';
  }

  const renderOptions = () => {
    return props.options?.map(op => <option key={op.value} value={op.value}>{op.displayValue}</option>);
  }

  /** @type {React.ChangeEventHandler<HTMLSelectElement>} */
  const selectHandleOnChange = e => {
    if (props.disabled) {
      e.target.value = currOption.current;
      return;
    }

    if (e.target.value === '') {
      props.onChange(undefined);
      currOption.current = undefined;
    } else {
      const option = props.options.find(op => `${op.value}` === e.target.value);

      if (option) {
        props.onChange(option.value);
        currOption.current = e.target.value;
      }
    }
  }

  useEffect(() => setPlaceholder(props.placeholder || 'Plantilla'), [props.placeholder]);

  useEffect(() => {
    if (props.reference) selectRef.current = props.reference.current;
    if (props.width) selectRef.current.parentElement.style.width = `${props.width}px`;
  }, [props.reference, props.width]);

  useEffect(() => {
    if (typeof props.forceChangeRef === 'object') {
      props.forceChangeRef.current = option => {
        if (option !== currOption.current) {
          currOption.current = option !== ''
            ? props.options.find(op => op.value === option)?.value || defaultValue.current
            : option;
        }

        if (selectRef.current && selectRef.current.value !== option)
          selectRef.current.value = currOption.current;
      }
    }
  }, [props.forceChangeRef, props.options]);

  useEffect(() => {
    if (props.forceChangeValue !== undefined && props.forceChangeValue !== currOption.current) {
      const index = props.options.findIndex(op => op.value === props.forceChangeValue);

      if (index !== -1) {
        currOption.current = props.options[index].value;
        selectRef.current.value = currOption.current;
      }
    }
  }, [props.forceChangeValue, props.options]);

  return (
    <div className="selectbar-container" disabled={props.disabled}>
      <select disabled={props.disabled}
        ref={props.reference || selectRef}
        className={getClassName()}
        defaultValue={defaultValue.current}
        onChange={selectHandleOnChange}>
        <option value="">{props.undefinedOption || 'Sin especificar'}</option>
        {renderOptions()}
      </select>
      <label>
        {props.required && <span className="required" />}
        {props.icon && <img src={props.icon} alt="inputbar-icon" />}
        {placeholder}
      </label>
    </div>
  );
}

export default Selectbar;