import './styles/page-selector.css';
import Button from "./Button";
import { useRef, useState } from 'react';

/** Renders a PageSelector compo.
 * @param {Object} props The props object 
 * @param {number} props.currentPage The current page (zero-based).
 * @param {boolean} [props.disabled] If true, buttons will be disabled.
 * @param {number} props.maxPage Max pages (non-zero-based).
 * @param {(page: number) => void} props.onBtnPush A callback function that will be
 * triggered when a button is pushed.
 */
const PageSelector = props => {
  // *** useRef ***
  const buttons = useRef(/** @type {{ compo: React.MutableRefObject<Element>, value: number }[]} */(undefined));
  const goFirstBtn = useRef(/** @type {HTMLButtonElement} */(undefined));
  const goLastBtn = useRef(/** @type {HTMLButtonElement} */(undefined));
  const goNextBtn = useRef(/** @type {HTMLButtonElement} */(undefined));
  const goPreviousBtn = useRef(/** @type {HTMLButtonElement} */(undefined));
  // *** useState ***
  const [currentPage, setCurrentPage] = useState(props.currentPage);

  /** @type {React.MouseEventHandler} */
  const compoClickHandler = e => {
    e.stopPropagation();

    let pushedBtn;

    if (e.target === goFirstBtn.current || goFirstBtn.current?.contains(e.target))
      pushedBtn = 0;
    else if (e.target === goLastBtn.current || goLastBtn.current?.contains(e.target))
      pushedBtn = props.maxPage - 1;
    else if (e.target === goNextBtn.current || goNextBtn.current?.contains(e.target))
      pushedBtn = currentPage + 1;
    else if (e.target === goPreviousBtn.current || goPreviousBtn?.current.contains(e.target))
      pushedBtn = currentPage - 1;
    else {
      const btn = buttons.current.find(b => b.compo.current === e.target || b.compo.current.contains(e.target));

      if (btn !== undefined) pushedBtn = btn.value;
    }

    if (pushedBtn !== undefined) {
      props.onBtnPush(pushedBtn);
      setCurrentPage(pushedBtn);
    }
  }

  const renderButtons = () => {
    const compos = [];
    let firstPage = currentPage - 2 < 0
      ? 0 : currentPage - 2;

    if (currentPage >= props.maxPage - 2)
      firstPage = currentPage === props.maxPage - 2
        ? currentPage - 3 >= 0 && currentPage - 3
        : currentPage === props.maxPage - 1
          ? currentPage - 4 >= 0 && currentPage - 4
          : 0

    buttons.current = [];

    for (let i = firstPage; i < props.maxPage && i < firstPage + 5; i++) {
      const auxRef = { current: undefined };
      compos.push(<Button disabled={props.disabled || currentPage === i}
        empty
        key={`page-${i}`}
        reference={auxRef}
        value={i + 1} />);

      buttons.current.push({ compo: auxRef, value: i });
    }

    return compos;
  }

  return (<div className="page-selector" onClick={compoClickHandler}>
    {props.maxPage > 5 && <Button disabled={props.disabled || currentPage === 0}
      empty
      reduced
      reference={goFirstBtn}
      value='<<' />}
    {props.maxPage > 5 && <Button disabled={props.disabled || currentPage === 0}
      empty
      reduced
      reference={goPreviousBtn}
      value='<' />}
    {renderButtons()}
    {props.maxPage > 5 && <Button disabled={props.disabled || currentPage === props.maxPage - 1}
      empty
      reduced
      reference={goNextBtn}
      value='>' />}
    {props.maxPage > 5 && <Button disabled={props.disabled || currentPage === props.maxPage - 1}
      empty
      reduced
      reference={goLastBtn}
      value='>>' />}
  </div>);
}

export default PageSelector;