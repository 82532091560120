import { useCallback, useEffect, useRef } from 'react';
import PetAssistant from '../../components/PetAssistant';
import PSCollection from '../../objects/PSCollection';
import Selectbar from '../../components/Selectbar';
import { EngIcon, FoodIcon, GasIcon, HkpIcon, NetIcon, WtrIcon } from '../../assets/images';

/** Renders a Step4 compo for CreateEstate.
 * @param {Object} props The props object.
 * @param {import('../../objects/Estate').default} props.estate An estate instance.
 * @param {(val: boolean) =>} props.setEnableNextBtn A callback function used to enable
 * or disable next step button.
 */
const Step5 = props => {
  // *** useRef ***
  /** @type {React.MutableRefObject<function(boolean)>} */
  const propsCallBack = useRef(props.setEnableNextStep);

  /** Select change event handler.
   * @param {0|1} [option] Selected option.
   * @param {string} id Service id.
   */
  const selectHandleOnChange = (option, id) => {
    const inclServ = props.estate.getContract().getInclServs();

    switch (option) {
      case 1: {
        if (!inclServ.find(serv => serv === id)) inclServ.push(id);
        break;
      } default: {
        const index = inclServ.findIndex(serv => serv === id);

        if (index !== -1) inclServ.splice(index, 1);
      }
    }

    requestNextStep(); // Render request.
  }

  /** Request next step to parent component. */
  const requestNextStep = useCallback(() => {
    propsCallBack.current(true);
  }, [])

  useEffect(() => requestNextStep(), [requestNextStep])

  return (
    <div className="popup-content create-estate-content" id="step-4" >
      <PetAssistant pet='kevin' animOnDwarf
        message={'Selecciona los servicios que vendrán incluidos en el periodo de renta y'
          + ' los que deben ser pagados por el arrendatario.'} />
      {props.estate.hasService(PSCollection.SERV_WTER) && <Selectbar
        options={[
          { displayValue: 'Lo paga el arrendatario', value: 0 },
          { displayValue: 'Incluido en el arrendamiento', value: 1 }]
        }
        defaultValue={props.estate.getContract().getInclServs().includes(PSCollection.SERV_WTER) ? 1 : 0}
        icon={WtrIcon}
        onChange={option => selectHandleOnChange(option, PSCollection.SERV_WTER)}
        placeholder='Pago del servicio de agua'
        required
        undefinedOption='Sin especificar (lo paga el arrendatario)' />}
      {props.estate.hasService(PSCollection.SERV_ENGY) && <Selectbar
        options={[
          { displayValue: 'Lo paga el arrendatario', value: 0 },
          { displayValue: 'Incluido en el arrendamiento', value: 1 }]
        }
        defaultValue={props.estate.getContract().getInclServs().includes(PSCollection.SERV_ENGY) ? 1 : 0}
        icon={EngIcon}
        onChange={option => selectHandleOnChange(option, PSCollection.SERV_ENGY)}
        placeholder='Pago del servicio de luz'
        required
        undefinedOption='Sin especificar (lo paga el arrendatario)' />}
      {props.estate.hasService(PSCollection.SERV_FOOD) && <Selectbar
        options={[
          { displayValue: 'Lo paga el arrendatario', value: 0 },
          { displayValue: 'Incluido en el arrendamiento', value: 1 }]
        }
        defaultValue={props.estate.getContract().getInclServs().includes(PSCollection.SERV_FOOD) ? 1 : 0}
        icon={FoodIcon}
        onChange={option => selectHandleOnChange(option, PSCollection.SERV_FOOD)}
        placeholder='Pago del servicio de alimentos'
        required
        undefinedOption='Sin especificar (lo paga el arrendatario)' />}
      {props.estate.hasService(PSCollection.SERV_GAS) && <Selectbar
        options={[
          { displayValue: 'Lo paga el arrendatario', value: 0 },
          { displayValue: 'Incluido en el arrendamiento', value: 1 }]
        }
        defaultValue={props.estate.getContract().getInclServs().includes(PSCollection.SERV_GAS) ? 1 : 0}
        icon={GasIcon}
        onChange={option => selectHandleOnChange(option, PSCollection.SERV_GAS)}
        placeholder='Pago del servicio de gas'
        required
        undefinedOption='Sin especificar (lo paga el arrendatario)' />}
      {props.estate.hasService(PSCollection.SERV_INET) && <Selectbar
        options={[
          { displayValue: 'Lo paga el arrendatario', value: 0 },
          { displayValue: 'Incluido en el arrendamiento', value: 1 }]
        }
        defaultValue={props.estate.getContract().getInclServs().includes(PSCollection.SERV_INET) ? 1 : 0}
        icon={NetIcon}
        onChange={option => selectHandleOnChange(option, PSCollection.SERV_INET)}
        placeholder='Pago del servicio de internet'
        required
        undefinedOption='Sin especificar (lo paga el arrendatario)' />}
      {props.estate.hasService(PSCollection.SERV_HKPG) && <Selectbar
        options={[
          { displayValue: 'Lo paga el arrendatario', value: 0 },
          { displayValue: 'Incluido en el arrendamiento', value: 1 }]
        }
        defaultValue={props.estate.getContract().getInclServs().includes(PSCollection.SERV_HKPG) ? 1 : 0}
        icon={HkpIcon}
        onChange={option => selectHandleOnChange(option, PSCollection.SERV_HKPG)}
        placeholder='Pago del servicio de limpieza'
        required
        undefinedOption='Sin especificar (lo paga el arrendatario)' />}
    </div>
  );
}

export default Step5;