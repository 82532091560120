import './styles/estate-item.css';
import { useContext, useEffect, useRef, useState } from 'react';
import Estate from '../objects/Estate';
import LoadingBlock from './LoadingBlock';
import DAOServ from '../objects/DAOServ';
// Icons.
import { EstateDefaultCover } from '../assets/images';
import { globalContext } from '../context/GlobalContext';
import FileChooser from '../objects/FileChooser';

const EstateItem = props => {
  // *** useContext ***
  const { getCacheFile, pushCacheFile } = useContext(globalContext);
  // *** useRef ***
  /** @type {React.MutableRefObject<Estate>} */
  const estate = useRef(props.estate || new Estate());
  // *** useState ***
  const [cover, setCover] = useState(/** @type {string} */(undefined));

  const getSpacesInfo = () => {
    const free = estate.current.getFreeSpaces();
    const total = estate.current.getTotalSpaces();

    if (free === total) return 'Todos disponibles';
    else if (free === 0) return 'No disponibles';
    else return `${free} de ${total} disponibles`;
  }

  useEffect(() => {
    const fetchCover = async () => {
      let newCover = EstateDefaultCover;
      const cover = estate.current.getImages().at(0);

      if (cover?.getName() !== undefined) {
        // Checking cache files.
        const auxCF = getCacheFile(cover.getName());

        if (auxCF !== undefined) { // Fetch file from cache.
          newCover = await FileChooser.readAsDataURL(auxCF.content);
          cover.setSize(auxCF.size);
          cover.setURLData(newCover);
          setCover(newCover);
        } else { // Fetch file from server.
          DAOServ.getFileCallback(cover.getName(), async (err, file) => {
            if (file) {
              newCover = await FileChooser.readAsDataURL(file);
              cover.setSize(file.size);
              cover.setURLData(newCover);
            }

            setCover(newCover);
          });
        }
      } else setCover(newCover);
    }

    if (!estate.current.getImages()[0]?.getURLData())
      fetchCover();
    else
      setCover(estate.current.getImages()[0].getURLData());
  }, [getCacheFile, pushCacheFile]);

  return (
    <div className="estate-item flex-box" id={`est-${estate.current.getId()}`}>
      <div className="child cover">
        {!cover && <LoadingBlock />}
        {cover && <img src={cover} alt="cover" />}
      </div>
      <div className="estate-info child dir-column al-start jc-left p5">
        {/* Title */}
        <h5 className="highlight title">{estate.current.getTitle()}</h5>
        {/* Contract status */}
        <span className={`pill ${Estate.sellMethodToClass(estate.current)}`} />
        {/* Price */}
        <h4 className="price">{Estate.priceToString(estate.current)}</h4>
        {/* Free spaces */}
        <h6 className="free-spaces">{getSpacesInfo()}</h6>
      </div>
    </div>
  );
}

export default EstateItem;