import Generic from "./Generic";

class Address extends Generic {
  /** @param {Address} [object] */
  constructor(object) {
    super(object);

    const { city, country, geoData, state, street, suburb } = object || {};

    this.city = city;
    this.country = country;
    this.geoData = { lat: geoData?.lat, lng: geoData?.lng }
    this.state = state;
    this.street = street;
    this.suburb = suburb;
  }

  /** Obtains a formated address (street, suburb, city, state and country order). From previous list,
   * if any element is undefined, the element and its predecessors will be omited. For example, if suburb
   * is the only undefined attribute, the return value will be an string containing the city, the state and the
   * country. If state is the only undefined attribute, the return value will be a string containinig
   * only the country.
   * 
   * @example (street = 'a', suburb = undefined, city = 'c', state = 'd', country = 'e') => return 'c, d, e';
   * @example (street = 'a', suburb = 'b', city = 'c', state = undefined, country = 'e') => return 'e';
   */
  getAddress() {
    const country = this.getCountry() || '';
    const state = country && this.getState() ? `${this.getState()}, ` : '';
    const city = state && this.getCity() ? `${this.getCity()}, ` : '';
    const suburb = city && this.getSuburb() ? `${this.getSuburb()}, ` : '';
    const street = suburb && this.getStreet() ? `${this.getStreet()}, ` : '';

    return `${street}${suburb}${city}${state}${country}`;
  }

  /** Obtains the city from the address.
   * @returns {string}
   */
  getCity() {
    return this.city;
  }

  /** Assigns a city for the address.
   * @param {string} city 
   */
  setCity(city) {
    this.city = city;
  }

  /** Obtains country from the address.
   * @returns {string}
   */
  getCountry() {
    return this.country;
  }

  /** Assigns a country for the address.
   * @param {string} country 
   */
  setCountry(country) {
    this.country = country;
  }

  /** Obtains latitude and longitude from the address.
   * @returns {{ lat: number|undefined, lng: number|undefined }}
   */
  getGeoData() {
    return this.geoData;
  }

  /**Assigns a new geoData object. Creates a copy of given object.
   * @param {{lat:number,lng:number}} geoData 
   */
  setGeoData(geoData) {
    const latV = geoData?.lat !== null && !isNaN(geoData?.lat);
    const lngV = geoData?.lng !== null && !isNaN(geoData?.lng);

    this.geoData = {
      lat: latV ? geoData?.lat : undefined,
      lng: lngV ? geoData?.lng : undefined
    };
  }

  /** Obtains the state from the address.
   * @returns {string}
   */
  getState() {
    return this.state;
  }

  /** Assigns a state for the address.
   * @param {string} state 
   */
  setState(state) {
    this.state = state;
  }

  /** Obtains the street from the address.
   * @returns {string}
   */
  getStreet() {
    return this.street;
  }

  /** Assigns a street for the address. Includes street name and house number.
   * @param {string} street 
   */
  setStreet(street) {
    this.street = street;
  }

  /** Obtains the suburb from the address.
   * @returns {string}
   */
  getSuburb() {
    return this.suburb;
  }

  /** Assigns a suburb for the address.
   * @param {string} suburb 
   */
  setSuburb(suburb) {
    this.suburb = suburb;
  }
}

export default Address;