import '../styles/create-subuser.css';
import { useContext, useEffect, useRef, useState } from "react";
import Button from "../components/Button";
import Global from "../objects/Global";
import UIRender from "../objects/UIRender";
import User from "../objects/User";
import { AddIcon, CloseIcon, InfoIcon, HideIcon, ShowIcon } from "../assets/images";
import Inputbar from '../components/Inputbar';
import Selectbar from '../components/Selectbar';
import DAOServ from '../objects/DAOServ';
import { globalContext } from '../context/GlobalContext';
import ErrHandler from '../objects/ErrHandler';
import LoadingBlock from '../components/LoadingBlock';
import Hintbox from '../components/Hintbox';
import Persona from '../objects/Persona';

/** Renders A CreateSubUser popup compo.
 * @param {Object} props The props object. 
 * @param {() => void} props.onHide A callback function that will be called when compo is hidden.
 * @param {(u: User) => void} props.onUserCreation A callback function that will be called when
 * user has been created successfully. Sends a User instance with the id assigned by server.
 * @param {import('../Signup').PrefixObject[]} props.prefixes An array of
 * prefixes.
 */
const CreateSubUser = props => {
  // *** useContext ***
  const { currSession, pushMessageHint, timezoneOffset } = useContext(globalContext);
  // *** useRef ***
  const compoId = useRef('create-subuser-popup');
  const user = useRef(new User());
  const popup = useRef(/** @type {HTMLDivElement} */);
  // *** useState ***
  const [disableUI, setDisableUI] = useState(false);
  const [disableRFCField, setDisableRFCField] = useState(true);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [rfc, setRFC] = useState(/** @type {string} */(undefined));
  const [today, setToday] = useState(/** @type {number} */(undefined));
  const [togglePassword, setTogglePassword] = useState(true);

  /** @type {React.AnimationEventHandler} */
  const dispose = e => {
    if (e.target === popup.current && UIRender.isHidden(popup.current))
      props.onHide();
  }

  const formHandleOnSubmit = async () => {
    setDisableUI(true);
    const purgeObj = JSON.parse(JSON.stringify(Global.purgeObject(user.current)));

    await DAOServ.post('create_subuser', { tst: currSession.tst, user: purgeObj }, 'JSON')
      .then(data => {
        user.current.setId(data['iduser']);
        user.current.getPass().setId(data['idpass']);
        user.current.setCreationDate(data['creation_date']);
        props.onUserCreation(user.current);
        UIRender.hideElement(popup.current);
        pushMessageHint({ message: `${user.current.getUsername()} ha sido creado`, type: 'complete' });
      })
      .catch(err => {
        const pErr = `${err}`;
        let message = ErrHandler.parseError(err);

        if (ErrHandler.getCode(pErr) === ErrHandler.CODES.PARAM_DUPLICATE) {
          if (pErr.includes('email')) message = 'No puedes usar este correo. Registra otro';
          else if (pErr.includes('username')) message = 'No puedes usar este nombre de usuario. Registra otro';
          else if (pErr.includes('rfc')) message = 'No puedes usar este RFC. Registra otro o deja el campo vacío';
        }

        pushMessageHint({ message, type: 'error' });
        setDisableUI(false);
      });
  }

  const requestEnableRFC = () => {
    setDisableRFCField(!user.current.getFirstName()
      || !user.current.getLastName()
      || !user.current.getBirthdate());
  }

  const requestSubmit = () => {
    const name = user.current.getName() && user.current.getLastName();
    const genre = user.current.getGenre() !== undefined;
    const birthdate = user.current.getBirthdate();
    const phone = user.current.getPhone().code && user.current.getPhone().number;
    const email = user.current.getEmail();
    const selfInfo = name && genre && birthdate && phone && email;
    const userData = user.current.getUsername() && user.current.getPassword();

    setDisableSubmit(!selfInfo || !userData);
  }

  useEffect(() => {
    user.current.setRFC(rfc);
  }, [rfc]);

  useEffect(() => {
    const fetchToday = async () => {
      await DAOServ.getCurrentDay()
        .then(day => setToday(day))
        .catch(err => {
          pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' });
          UIRender.hideElement(popup.current);
        });
    }

    const id = compoId.current;
    const options = { footer: true, navbar: true };
    const parent = popup.current?.parentNode;

    UIRender.disableGlobalScroll(id);
    UIRender.disableSiblings(popup.current, options); // Disable siblings.
    fetchToday();

    return () => {
      UIRender.enableGlobalScroll(id);
      UIRender.enableChilds(parent, options, id);
    }
  }, [pushMessageHint]);

  return (
    <div ref={popup} className="popup-wrapper create-subuser-popup" id={compoId.current} onAnimationEnd={dispose}>
      <div className="popup">
        {!today && <LoadingBlock noBackground />}
        {today && <div>
          <div className="top-bar">
            <h2 className="title highlight">Crea una subcuenta</h2>
            <Button disabled={disableUI}
              className='error empty rounded reduced borderless'
              icon={CloseIcon}
              onClick={() => UIRender.hideElement(popup.current)} />
          </div>
          <div className="popup-content">
            <h5 className="overset">Datos personales</h5>
            <div className="flex-box wrap m3">
              <div className="child">
                <Inputbar disabled={disableUI}
                  filters={[{ regExp: Global.REGEXP_FILTER_SYMBOLS }]}
                  maxLength={50}
                  minLength={2}
                  onBlur={input => input?.trim()?.replace(/\s+/g, ' ')}
                  onChange={input => {
                    user.current.setFirstName(input);
                    setRFC('');
                    requestEnableRFC();
                    requestSubmit();
                  }}
                  placeholder={{ default: 'Nombre(s)' }}
                  required
                  stopPropagation
                  textTransform='capitalize' />
              </div>
              <div className="child">
                <Inputbar disabled={disableUI}
                  filters={[{ regExp: Global.REGEXP_FILTER_SYMBOLS }]}
                  maxLength={50}
                  minLength={2}
                  onBlur={input => input?.trim()?.replace(/\s+/g, ' ')}
                  onChange={input => {
                    user.current.setLastName(input);
                    setRFC('');
                    requestEnableRFC();
                    requestSubmit();
                  }}
                  placeholder={{ default: 'Apellido(s)' }}
                  required
                  stopPropagation
                  textTransform='capitalize' />
              </div>
            </div>
            <div className="flex-box wrap m3">
              <div className="child">
                <Selectbar disabled={disableUI}
                  onChange={option => {
                    user.current.setGenre(option);
                    requestSubmit();
                  }}
                  options={[
                    { displayValue: 'Hombre', value: Persona.GENRE_MALE },
                    { displayValue: 'Mujer', value: Persona.GENRE_FEMALE },
                    { displayValue: 'Otro o prefiero no decirlo', value: Persona.GENRE_UNSET }
                  ]}
                  placeholder='Género'
                  required />
              </div>
              <div className="child">
                <Inputbar disabled={disableUI}
                  isValid={input => input
                    && today - Global.dateToMilliUTC(Global.transformDate(input), -timezoneOffset)
                    >= Persona.MIN_AGE_MILLI}
                  onChange={input => {
                    user.current.setBirthdate(input);
                    setRFC('');
                    requestEnableRFC();
                    requestSubmit();
                  }}
                  placeholder={{
                    default: 'Fecha de nacimiento',
                    onIsValidFail: 'Mayoría de edad requerida'
                  }}
                  required
                  stopPropagation
                  type='date' />
              </div>
            </div>
            <div className="flex-box m3">
              <div className="child">
                <Inputbar disabled={disableUI || disableRFCField}
                  forceChangeValue={rfc}
                  filters={[{ regExp: Global.REGEXP_FILTER_ALL_SYMBOLS }]}
                  isValid={input => Global.REGEXP_RFC.test(input) && user.current.testRFC(input)}
                  maxLength={13}
                  minLength={13}
                  onChange={input => {
                    setRFC(input);
                    requestSubmit();
                  }}
                  placeholder={{
                    default: 'RFC',
                    onIsValidFail: 'El RFC no coincide o es inválido',
                    onMinLengthFail: 'El RFC debe tener 13 caracteres'
                  }}
                  required
                  stopPropagation
                  textTransform='uppercase' />
              </div>
              <div className="child">
                <Hintbox icon={InfoIcon}
                  message={'No es necesario el RFC para que este usuario pueda registrar '
                    + 'propiedades. Podrás modificarlo en el futuro.'} />
              </div>
            </div>
            <div className="flex-box wrap m3">
              <div className="flex-box m3">
                <div className="child auto-width">
                  <Selectbar disabled={disableUI}
                    onChange={option => {
                      const phoneAux = option?.split('_');

                      if (phoneAux) user.current.setPhone({ code: phoneAux[0], prefix: phoneAux[1] });
                      else user.current.setPhone({ code: undefined, prefix: undefined });

                      requestSubmit();
                    }} options={props.prefixes.map(p => {
                      return { displayValue: `${p.name} ${p.dial_code}`, value: `${p.code}_${p.dial_code}` }
                    })} placeholder='Prefijo'
                    required
                    width={115} />
                </div>
                <div className="child">
                  <Inputbar disabled={disableUI}
                    filters={[{ regExp: Global.REGEXP_FILTER_INTEGER }, { regExp: /^0{5,}/, replace: '0000' }]}
                    inputMode='tel'
                    maxLength={10}
                    minLength={10}
                    onChange={input => {
                      user.current.setPhone({ number: input });
                      requestSubmit();
                    }} placeholder={{ default: 'Teléfono', onMinLengthFail: 'Ingresa 10 dígitos' }}
                    required
                    stopPropagation />
                </div>
              </div>
            </div>
            <div className="flex-box m3">
              <Inputbar disabled={disableUI}
                filters={[{ regExp: Global.REGEXP_FILTER_EMAIL }]}
                inputMode='email'
                isValid={input => Global.REGEXP_EMAIL.test(input)}
                maxLength={255}
                onChange={input => {
                  user.current.setEmail(input);
                  requestSubmit();
                }} placeholder={{ default: 'Correo electrónico', onIsValidFail: 'Correo inválido' }}
                required
                stopPropagation />
            </div>
            <h6 className="overset">Datos de usuario</h6>
            <div className="flex-box wrap m3">
              <div className="child">
                <Inputbar disabled={disableUI}
                  filters={[{ regExp: Global.REGEXP_FILTER_USERNAME }]}
                  isValid={input => input !== currSession.username && Global.REGEXP_USERNAME.test(input)}
                  maxLength={20}
                  minLength={5}
                  onChange={input => {
                    user.current.setUsername(input);
                    requestSubmit();
                  }} placeholder={{ default: 'Nombre de usuario', onIsValidFail: 'Usuario inválido' }}
                  required
                  stopPropagation />
              </div>
            </div>
            <div className="flex-box">
              <div className="child">
                <Inputbar disabled={disableUI}
                  filters={[{ regExp: Global.REGEXP_FILTER_FORBIDDEN_SYMBOLS }]}
                  isValid={input => !Global.REGEXP_RACCHOME.test(input)}
                  maxLength={30}
                  minLength={8}
                  onChange={input => {
                    user.current.setPassword(input);
                    requestSubmit();
                  }} placeholder={{ default: 'Contraseña', onIsValidFail: 'Contraseña inválida' }}
                  required
                  stopPropagation
                  type={togglePassword ? 'password' : 'text'} />
              </div>
              <div className="child auto-width m3">
                <Button disabled={disableUI}
                  borderless
                  rounded
                  empty
                  icon={togglePassword ? ShowIcon : HideIcon}
                  onClick={() => setTogglePassword(!togglePassword)}
                  reduced />
              </div>
            </div>
          </div>
          <h6 className="overset req-msg"><span className="required" />Hay campos obligatorios</h6>
          <Button disabled={disableSubmit}
            icon={AddIcon}
            isWaiting={disableUI}
            onClick={formHandleOnSubmit}
            value='Crear' />
        </div>}
      </div>
    </div >
  );
}

export default CreateSubUser;