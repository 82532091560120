import './styles/priv-estate.css';
import { globalContext } from "./context/GlobalContext";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import * as Icons from './assets/images';
import BiddingBox from './components/BiddingBox';
import Button from './components/Button';
import Dialog from './components/popups/Dialog';
import ImageViewer from './components/ImageViewer';
import Estate from './objects/Estate';
import EstateItem from './components/EstateItem';
import Contract from './objects/Contract';
import Global from './objects/Global';
import LoadingBlock from './components/LoadingBlock';
import LoadingPanel from './components/LoadingPanel';
import PageNotFound from './PageNotFound';
import User from './objects/User';
import DAOServ from './objects/DAOServ';
import Bidding from './objects/Bidding';
import ErrHandler from './objects/ErrHandler';
import FileChooser from './objects/FileChooser';
import Switch from './components/Switch';
import UIRender from './objects/UIRender';
import NotFoundBox from './components/NotFoundBox';
import ContractItem from './components/ContractItem';
import CreateEstate from './popups/CreateEstate';
import Bid from './objects/Bid';
import GenericFile from './objects/GenericFile';
import SignProcess from './popups/SignProcess';
import Inputbar from './components/Inputbar';
import Selectbar from './components/Selectbar';
import StartBidding from './popups/StartBidding';
import PropDisplay from './components/PropDisplay';
import Hintbox from './components/Hintbox';
import Textbox from './components/Textbox';
import PSCollection from './objects/PSCollection';
import Inputbox from './components/Inputbox';
import Agreement from './objects/Agreement';
import ContactInfoCard from './components/ContactInfoCard';
import Persona from './objects/Persona';

/** ContactRequestObject typedef
 * @typedef {Object} ContactRequestObject
 * @property {number} creationDate The creation of the request (milli).
 * @property {User} user The user object, containing username and picture.
 */

/** FieldObject typedef
 * @typedef {Object} FieldObject
 * @property {*} [id] An identifier for the field that's being updated. Can be used the linked
 * column name from database.
 * @property {*} newValue The new value (on edit value) for the field.
 * @property {*} oldValue The previous value of the field.
 * @property {(nV: *) => void} onResolve A callback function that will be triggered when save to
 * database is resolved. The parameter will be newValue of this object.
 * @property {boolean} [required] Specifies if field is required for submit.
 * @property {{current: (value: *) => void}} rollback An object that stores a callback function
 * used to replace or restore input / select current value. Use this when user cancels the edit.
 */

/** EditFieldObject Typedef
 * @typedef {Object} EditFieldObject
 * @property {'title'|'dscr'|'requirements'|'con-inf'|'spaces'
 * |'images'|'size'|'build_date'|'charac'|'insurance'|'contract'} id The
 * id of the block being modified.
 * @property {FieldObject[]} fields
 */

/** @typedef {import('./popups/CreateEstate').CreateEstatePropsObject} CreateEstatePropsObject */

/** Renders the Private Estate page. */
const PrivEstate = () => {
  // *** useContext ***
  const {
    currSession,
    getCacheFile,
    pushAlertMessage,
    pushCacheFile,
    pushMessageHint,
    removeCacheFile,
    setSearchMethod,
    setShowLoadingScreen
  } = useContext(globalContext);
  // *** useNavigate ***
  const navigate = useNavigate();
  // *** useParams ***
  const { idEstate: estateIdParam } = useParams();
  // *** useRef ***
  const conInfPlaceHolder = useRef('');
  const fetchingsAll = useRef(false);
  const maxSpaces = useRef(0);
  const pageRef = useRef(/** @type {HTMLDivElement} */(undefined));
  const paramRef = useRef(estateIdParam);
  const selfConInf = useRef(/** @type {Persona} */(undefined));
  const subDiv = useRef(/** @type {{current: number, max: number}} */({}));
  // *** useState ***
  const [allowBidding, setAllowBidding] = useState(/** @type {boolean} */(undefined));
  const [contactRequests, setContactRequests] = useState(/** @type {ContactRequestObject[]} */(undefined));
  const [dialog, setDialog] = useState(
    /** @type {import('./components/popups/Dialog').DialogPropsObject} */(undefined)
  );
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [disableUI, setDisableUI] = useState(false); // Disable UI.
  const [estate, setEstate] = useState(/** @type {Estate} */(undefined));
  const [fetchBidding, setFetchBidding] = useState(/** @type {Bidding|undefined} */(undefined));
  const [fetchImages, setFetchImages] = useState(/** @type {GenericFile[]} */(undefined));
  const [fetchings, setFetchings] = useState(/** @type {(Contract|Estate)[]} */(undefined));
  const [onEdit, setOnEdit] = useState(/** @type {EditFieldObject} */(undefined));
  const [prefixes, setPrefixes] = useState(/** @type {import('./Signup').PrefixObject[]} */(undefined));
  const [showPopup, setShowPopup] = useState(
    /** @type {{ mode: 0|1|2, props?: CreateEstatePropsObject }} */(undefined)
  );
  const [today, setToday] = useState(/** @type {number} */(undefined));

  const biddingBtnHandleOnClick = async () => {
    if (currSession.isSubuser) {
      setShowLoadingScreen(true);
      const pass = await DAOServ.fetchPass(currSession.tst);
      setShowLoadingScreen(false);

      if (!pass.getAllPasses().de_bidd) {
        pushMessageHint({ message: 'No tienes permiso para realizar esta acción', type: 'error' });
        return;
      }
    }

    if (estate.getBidding().getStatus()) { // Active bidding: end bidding.
      const hasBids = estate.getBidding().getBids().length > 0;
      const message = 'Ya no podrás recibir pujas después de finalizar la subasta.'
        + (!hasBids ? '' : ' El ganador actual será notificado por email y se le otorgará acceso a'
          + ' la información de contacto de esta propiedad. También tendrás acceso a los datos de'
          + ' contacto de la persona ganadora. ');

      setDialog({
        action: () => DAOServ.post('end_bidding', { tst: currSession.tst, idEstate: estate.getId() }, 'JSON'),
        confirmBtn: { icon: Icons.TimeIcon, onWaitValue: 'Finalizando...', type: 'error', value: 'Finalizar' },
        id: 'end-bidding-dialog',
        message,
        onResolve: data => {
          const auxBidding = new Bidding(estate.getBidding());
          const auxEstate = new Estate(estate);
          auxBidding.setManualStatus(0);
          auxBidding.setStatus(false);
          auxBidding.setEndDate(Number(data['end_date']));
          auxEstate.setBidding(auxBidding);
          setFetchBidding(auxBidding);
          setEstate(auxEstate);
          setToday(data['end_date'])
          pushMessageHint({ message: 'La subasta fue finalizada', type: 'warning' });
        }, onReject: err => err && pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' }),
        rejectBtn: { value: 'Regresar' },
        renderButtonsSwitched: true
      });
    } else { // Inactive bidding: start bidding.
      const message = 'Esta acción activará la publicación y creará una nueva subasta. '
        + 'Se te solicitará que ingreses la fecha de finalización y la puja mínima para registrarla.'
        + (!estate.getBidding().getId() ? '' : ' La subasta anterior y toda la información involucrada'
          + ' será descartada e inaccesible.');

      setDialog({
        action: () => DAOServ.getCurrentDay(),
        confirmBtn: { value: 'Continuar' },
        id: 'confirm-start-bidding-dialog',
        message,
        onResolve: data => {
          setToday(data);
          setShowPopup({ mode: 2 });
        }, rejectBtn: { type: 'error', value: 'Cancelar' }
      });
    }
  }

  const cancelBtnHandleOnClick = () => {
    if (onEdit.id !== 'images') {
      onEdit.fields.forEach(field => {
        field.rollback?.current && field.rollback.current(onEdit.id === 'build_date'
          ? Global.transformDateForInput(field.oldValue)
          : onEdit.id === 'contract' && field.id === 'charge_at_start'
            ? estate.getContract().getPayAmount()
            : field.oldValue
        );
      });
    }

    setOnEdit();
  }

  const canInclServBeRendered = (id) => {
    if (onEdit?.id === 'charac')
      return onEdit.fields.find(f => f.id === id)?.newValue === true;
    else return estate.hasService(id);
  }

  /** @type {React.AnimationEventHandler<HTMLDivElement>} */
  const containerHandleOnAnimationEnd = e => {
    if (e.target === pageRef.current)
      pageRef.current.classList.remove('init');
  }

  const copyLocationHandleOnClick = () => {
    Global.copyToClipboard(estate.getLocation().getAddress())
      .then(() => pushAlertMessage({ message: 'Dirección copiada al portapapeles', type: 'complete' }))
      .catch(err => pushAlertMessage({ message: ErrHandler.parseError(err), type: 'error' }))
  }

  /** @param {Bid} b */
  const deleteBiddingWinnerClickHandler = b => {
    const action = () => DAOServ.post('remove_bid', { tst: currSession.tst, idBid: b.getId() }, 'JSON');

    const moreBids = fetchBidding.getBids().length > 1
      ? ' La siguiente oferta será notificada y tendrá acceso a la información de'
      + ' contacto de esta propiedad. Tu también tendrás acceso a la información'
      + ' del nuevo ganador.'
      : ' Ya no quedarán más ofertas disponibles.'

    setDialog({
      action,
      message: `Borrarás la oferta de ${b.getBidder().getUsername()}.${moreBids}`,
      onHide: () => setDialog(),
      confirmBtn: {
        delay: 3000,
        icon: Icons.DeleteIcon,
        onWaitValue: 'Eliminando...',
        type: 'error',
        value: 'Eliminar'
      }, id: 'delete-bid-popup',
      onReject: err => err && pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' }),
      onResolve: () => {
        fetchBidding.getBids().splice(fetchBidding.getBids().indexOf(b), 1);
        estate.setBidding(new Bidding(fetchBidding))
        setFetchBidding(estate.getBidding());
        pushMessageHint({ message: 'Oferta eliminada', type: 'complete' });
      }, rejectBtn: { value: 'Cancelar' },
      renderButtonsSwitched: true
    })
  }

  const deleteBtnHandleOnClick = async () => {
    if (currSession.isSubuser) {
      setShowLoadingScreen(true);
      const pass = await DAOServ.fetchPass(currSession.tst);
      setShowLoadingScreen(false);

      if (!pass.getAllPasses().de_esta) {
        pushMessageHint({ message: ErrHandler.getCodes().ACCESS_DENIED.message, type: 'error' });
        return;
      }
    }

    setDialog({
      action: () => DAOServ.post('delete_estate', { tst: currSession.tst, idEstate: estate.getId() }, 'JSON'),
      confirmBtn: { icon: Icons.DeleteIcon, onWaitValue: 'Eliminando...', type: 'error', value: 'Eliminar' },
      id: 'delete-estate-dialog',
      message: 'Ya no podrás acceder a esta propiedad ni a su información asociada. Esta acción'
        + ' es irreversible.',
      onResolve: () => {
        pushMessageHint({ message: 'Propiedad eliminada', type: 'complete' })
        navigate('/', { replace: true })
      }, onReject: err => err && pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' }),
      rejectBtn: { type: 'error', value: 'Cancelar' },
      renderButtonsSwitched: true
    });
  }

  /** Edit button click handler.
   * @param {string} id
   * @param {{currVal?: string|*[]|number, required?: boolean|boolean[]}} [valObj]
   * Contains the current value or values and a flag or flags of requirement. If id
   * equals to 'charac', 'con-inf', 'contract' or 'images', this param will be ignored.
   * @param {boolean|boolean[]} [required] Specifies if an element is required
   * @param {(nV: *) => void} [onResolve] A function that will be triggered when
   * Promise resolves. If id equals to 'charac', 'con-inf' 'contract' or
   * 'images', this param will be ignored.
   */
  const editBtnHandleOnClick = async (id, valObj, onResolve) => {
    if (!onEdit) { // On edit begin.
      /** @type {FieldObject[]} */
      let fields = [];

      if (currSession.isSubuser) { // Subuser confirmation.
        setShowLoadingScreen(true);
        const pass = await DAOServ.fetchPass(currSession.tst);
        setShowLoadingScreen(false);

        if (!pass.getAllPasses().up_esta) {
          pushMessageHint({ message: ErrHandler.getCodes().ACCESS_DENIED.message, type: 'error' });
          return;
        }
      }

      switch (id) {
        case 'charac': {
          // Gets all static const names from services and properties ids.
          const psIds = Object.getOwnPropertyNames(PSCollection).filter(id => /^(PROP|SERV)_.*/.test(id));
          // Parsing PSCollection and included services.
          psIds.forEach(psId => {
            const isProp = /^PROP_.*/.test(psId);
            const auxPS = isProp
              ? estate.getProperty(PSCollection[psId])
              : estate.getService(PSCollection[psId]);

            fields.push({ // Push properties and services.
              id: PSCollection[psId],
              newValue: auxPS?.value,
              oldValue: auxPS?.value,
              onResolve: nV => isProp
                ? estate.setProperty(PSCollection[psId], nV)
                : estate.setService(PSCollection[psId], nV),
              required: Estate.isPropertyRequired(estate, PSCollection[psId])
                || PSCollection[psId] === PSCollection.SERV_WTER
                || PSCollection[psId] === PSCollection.SERV_ENGY,
              rollback: {}
            });

            if (!isProp) {
              const isIncluded = estate
                .getContract()
                .getInclServs()
                .find(iS => iS === PSCollection[psId]);

              fields.push({ // Push included services.
                id: `incl_${PSCollection[psId]}`,
                newValue: !auxPS || !isIncluded ? 0 : 1,
                oldValue: !auxPS || !isIncluded ? 0 : 1,
                onResolve: nV => {
                  if (!nV) {
                    const index = estate
                      .getContract()
                      .getInclServs()
                      .findIndex(iS => iS === PSCollection[psId]);

                    if (index >= 0)
                      estate.getContract().getInclServs().splice(index, 1);
                  } else if (!estate.getContract().getInclServs().find(iS => iS === PSCollection[psId])) {
                    estate.getContract().getInclServs().push(nV);
                  }
                },
                rollback: {}
              });
            }
          });
          break;
        } case 'con-inf': {
          /** @type {Persona} */
          const auxConInf = estate.getContactInfo() !== -1
            ? estate.getContactInfo()
            : selfConInf.current;

          fields.push({ // switch.
            id: 'coninf_switch',
            newValue: estate.getContactInfo().getId() === undefined,
            oldValue: estate.getContactInfo().getId() === undefined,
            onResolve: nV => nV === true && estate.setContactInfo(-1),
            rollback: {}
          });

          fields.push({ // name.
            id: 'name',
            newValue: auxConInf.getName(),
            oldValue: auxConInf.getName(),
            onResolve: nV => auxConInf.setFirstName(nV),
            required: true,
            rollback: {}
          });

          fields.push({ // email.
            id: 'email',
            newValue: auxConInf.getEmail(),
            oldValue: auxConInf.getEmail(),
            onResolve: nV => auxConInf.setEmail(nV),
            required: true,
            rollback: {}
          });

          fields.push({ // prefix.
            id: 'prefix',
            newValue: `${auxConInf.getPhone().code}_${auxConInf.getPhone().prefix}`,
            oldValue: `${auxConInf.getPhone().code}_${auxConInf.getPhone().prefix}`,
            onResolve: nV => {
              const prefix = nV.split('_');
              auxConInf.setPhone({ code: prefix[0], prefix: prefix[1] });
            },
            required: true,
            rollback: {}
          });

          fields.push({ // number.
            id: 'number',
            newValue: auxConInf.getPhone().number,
            oldValue: auxConInf.getPhone().number,
            onResolve: nV => auxConInf.setPhone({ number: nV }),
            required: true,
            rollback: {}
          });
          break;
        } case 'contract': {
          // Getting max sub div.
          setShowLoadingScreen(true);
          const licStat = await DAOServ.fetchLicenseStatistics(currSession.tst);
          subDiv.current.max = licStat.max_sub_div;
          setShowLoadingScreen(false);

          fields.push({ // Sell method.
            id: 'sell_method',
            newValue: estate.getSellMethod(),
            oldValue: estate.getSellMethod(),
            onResolve: nV => estate.setSellMethod(Number(nV)),
            required: true,
            rollback: {}
          });
          fields.push({ // Pay amount.
            id: 'pay_amount',
            newValue: estate.getContract().getPayAmount(),
            oldValue: estate.getContract().getPayAmount(),
            onResolve: nV => estate.getContract().setPayAmount(nV),
            required: true,
            rollback: {}
          });
          fields.push({ // Term method.
            id: 'term_method',
            newValue: estate.getContract().getTermMethod(),
            oldValue: estate.getContract().getTermMethod(),
            onResolve: nV => estate.getContract().setTermMethod(nV),
            required: true,
            rollback: {}
          });
          fields.push({ // Term.
            id: 'term',
            newValue: estate.getContract().getTerm(),
            oldValue: estate.getContract().getTerm(),
            onResolve: nV => estate.getContract().setTerm(nV),
            required: true,
            rollback: {}
          });
          fields.push({ // Pay frequency.
            id: 'pay_frequency',
            newValue: estate.getContract().getPayFrequency(),
            oldValue: estate.getContract().getPayFrequency(),
            onResolve: nV => estate.getContract().setPayFrequency(nV),
            required: true,
            rollback: {}
          });
          fields.push({ // Charge type
            id: 'charge_type',
            newValue: estate.getSellMethod() === Estate.ON_LEASE
              ? estate.getContract().getChargeType()
              : '',
            oldValue: estate.getSellMethod() === Estate.ON_LEASE
              ? estate.getContract().getChargeType()
              : '',
            required: true,
            rollback: {}
          });
          fields.push({ // Charge
            id: 'charge_at_start',
            newValue: estate.getContract().getCharge(),
            oldValue: estate.getContract().getCharge(),
            onResolve: nV => estate.getContract().setCharge(nV),
            required: true,
            rollback: {}
          });
          break;
        } case 'images': {
          fields.push({
            id,
            newValue: fetchImages.map(img => new GenericFile(img)),
            oldValue: fetchImages,
            onResolve,
            rollback: {}
          });
          break;
        } case 'size': {
          fields.push({
            id: 'x',
            newValue: estate.getSize().x,
            oldValue: estate.getSize().x,
            onResolve,
            required: true,
            rollback: {}
          });
          fields.push({
            id: 'y',
            newValue: estate.getSize().y,
            oldValue: estate.getSize().y,
            onResolve,
            required: true,
            rollback: {}
          });
          break;
        } case 'spaces': {
          fields.push({
            id: 'spaces',
            newValue: estate.getTotalSpaces(),
            oldValue: estate.getTotalSpaces(),
            onResolve: nV => estate.setTotalSpaces(nV),
            required: true,
            rollback: {}
          });
          break;
        } default: {
          const values = valObj && Array.isArray(valObj.currVal)
            ? valObj.currVal
            : [valObj.currVal];
          const required = valObj && Array.isArray(valObj.required)
            ? valObj.required
            : valObj.required !== undefined
              ? [Boolean(valObj.required)]
              : false;
          for (let i = 0; i < values.length; i++) {
            fields.push({
              id,
              newValue: typeof values[i] === 'object' ? { ...values[i] } : values[i],
              oldValue: typeof values[i] === 'object' ? { ...values[i] } : values[i],
              onResolve,
              required: (Array.isArray(required) && (required[i] || false)) || required,
              rollback: {}
            });
          }
        }
      }

      setOnEdit({ fields, id });
      setDisableSubmit(true);
    } else { // On save begin.
      setDisableUI(true);

      try {
        if (onEdit.id === 'images') {
          /** @type {GenericFile[]} */
          const newImages = onEdit.fields[0].newValue;
          /** @type {GenericFile[]} */
          const oldImages = onEdit.fields[0].oldValue;
          const deletedImages = oldImages.filter(oI => !newImages.find(nI => nI.getName() === oI.getName()));
          const payload = new FormData();
          payload.append('tst', currSession.tst);
          payload.append('idEstate', estate.getId());

          // Append new images.
          for (const nImg of newImages) {
            if (!oldImages.find(oImg => oImg.getName() === nImg.getName()))
              payload.append('files', nImg.toFile());

            payload.append('newImages', nImg.getName());
          }

          // Append old images names.
          oldImages.forEach(oImg => payload.append('oldImages', oImg.getName()));

          await DAOServ.post('update_estate_images', payload, 'MULTI')
            .then(data => {
              // Update new files name.
              for (const d of data) {
                const auxImg = newImages.find(nImg => nImg.getName() === d['prevName']);

                if (auxImg) auxImg.setName(d['newName']);
              }

              estate.setImages(newImages); // Update estate images.
              deletedImages.forEach(dI => removeCacheFile(dI.getName())); // Remove deleted image from cache.
              pushMessageHint({ message: 'Fotos actualizadas', type: 'complete' });
              setFetchImages(newImages);
              setOnEdit();
            }).catch(err => pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' }));
        } else {
          const body = { tst: currSession.tst, idEstate: estate.getId(), payload: {} }

          // Setting fields.
          switch (onEdit.id) {
            case 'charac': {
              body.payload['sell_method'] = estate.getSellMethod(); // Sell method is needed.
              // Included services.
              const inclServ = onEdit.fields.filter(f => /^incl_s_[a-z]+$/.test(f.id) && f.newValue === 1);
              const propKeys = onEdit.fields.filter(f => /^p_[a-z]+$/.test(f.id)); // Property keys.
              const servKeys = onEdit.fields.filter(f => /^s_[a-z]+$/.test(f.id)); // Service keys.

              body.payload['contract'] = { // Adding contract data to payload.
                charge_at_start: estate.getContract().getCharge(),
                incl_serv: inclServ.map(iS => iS.newValue && iS.id.replace(/^incl_/, '')),
                pay_amount: estate.getContract().getPayAmount(),
                pay_frequency: estate.getContract().getPayFrequency(),
                term: estate.getContract().getTerm(),
                term_method: estate.getContract().getTermMethod()
              };

              body.payload['pscollection'] = { // Adding properties and services to payload.
                props: propKeys.filter(p => /^p_[a-z]+$/.test(p.id) && p.newValue > 0).map(p => {
                  return { id: p.id, value: p.newValue };
                }), servs: servKeys.filter(f => /^s_[a-z]+$/.test(f.id)).map(s => {
                  return { id: s.id, value: Boolean(s.newValue) };
                })
              };

              break;
            } case 'con-inf': {
              body.payload['sell_method'] = estate.getSellMethod();
              body.payload['contactinfo'] = {
                action: onEdit.fields.find(f => f.id === 'coninf_switch').newValue
                  ? 'delete' : 'update',
                id: estate.getContactInfo() instanceof Persona
                  ? estate.getContactInfo().getId() || undefined
                  : undefined,
                name: onEdit.fields.find(f => f.id === 'name').newValue,
                email: onEdit.fields.find(f => f.id === 'email').newValue,
                phone: `${onEdit.fields.find(f => f.id === 'prefix').newValue}_`
                  + `${onEdit.fields.find(f => f.id === 'number').newValue}`
              }
              break;
            } case 'contract': {
              body.payload['sell_method'] = onEdit.fields.find(f => f.id === 'sell_method').newValue;
              body.payload['contract'] = {
                'incl_serv': body.payload['sell_method'] === Estate.ON_LEASE
                  && estate.getContract().getInclServs().length > 0
                  ? estate.getContract().getInclServs().join(Global.ARR_SEPARATOR)
                  : undefined
              };

              for (const f of onEdit.fields)
                if (f.id !== 'sell_method') body.payload['contract'][f.id] = f.newValue;

              break;
            } case 'size': {
              body.payload['dims'] = JSON.stringify({
                x: onEdit.fields[0].newValue,
                y: onEdit.fields[1].newValue
              });
              break;
            } default: {
              if (onEdit.id === 'requirements') {
                if (onEdit.fields.length > 0) {
                  body.payload[onEdit.id] = '';
                  onEdit.fields.forEach(f => body.payload[onEdit.id] += !body.payload[onEdit.id]
                    ? f.newValue
                    : `${Global.ARR_SEPARATOR}${f.newValue}`);
                } else body.payload = null;
              } else {
                for (const field of onEdit.fields)
                  body.payload[field.id] = field.newValue || null;
              }
            }
          }

          await DAOServ.post('update_estate', body, 'JSON');
          editBtnHandleOnResolve();
        }
      } catch (err) {
        pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' });
      }

      setDisableUI(false);
    }
  }

  const editBtnHandleOnResolve = () => {
    const message = onEdit.fields.length > 1
      ? 'Campos actualizados'
      : 'Campo actualizado';
    pushMessageHint({ message, type: 'complete' });

    if (onEdit.id === 'contract') {
      onEdit.fields.forEach(f => {
        if (/^incl_s_[a-z]+$/.test(f.id))
          f.onResolve && f.onResolve(f.newValue || 0);
        else
          f.onResolve && f.onResolve(f.newValue);
      });
      setEstate(new Estate(estate));
    } if (onEdit.id === 'requirements') {
      estate.setRequirements(onEdit.fields.map(f => f.newValue));
    } else if (onEdit.id === 'charac') {
      onEdit.fields.forEach(f => {
        if (/^incl_s_[a-z]+$/.test(f.id)) {
          f.rollback?.current && f.rollback.current(f.newValue || 0);
          f.onResolve && f.onResolve(f.newValue || undefined);
        } else f.onResolve && f.onResolve(f.newValue);
      });
    } else onEdit.fields.forEach(f => f.onResolve && f.onResolve(f.newValue));

    setOnEdit();
  }

  /** Subestates items click handler
   * @type {React.MouseEventHandler}
   * @deprecated
   */
  const fetchedContentsHandleOnClick = e => {
    e.stopPropagation();

    if (disableUI) return;

    if (estate.getSellMethod() === Estate.ON_COMPLEX) {
      const estateItem = Global.findParent(e.target, { className: 'estate-item', maxChecks: 4 });

      if (estateItem !== undefined) { // Clicked over an estate item.
        const id = estateItem.id.split('-')[1];
        navigate(`${Global.PATH_ESTATES_VIEW}/${id}`, { state: { idEstate: id } });
      }
    } else {
      const contractItem = Global.findParent(e.target, { className: 'contract-item', maxChecks: 5 });

      if (contractItem !== undefined) {
        navigate(`${Global.PATH_CONTRACT}/${contractItem.id}`, { state: { hash: contractItem.id } })
      }
    }
  }

  const getChargeInputDefaultValue = () => {
    const charge = onEdit?.id === 'contract'
      ? onEdit.fields.find(f => f.id === 'charge_at_start').newValue
      : estate.getContract().getCharge();
    const chargeType = onEdit?.id === 'contract'
      ? onEdit.fields.find(f => f.id === 'charge_type').newValue
      : estate.getContract().getChargeType();

    if (chargeType === Contract.CHARGE_CUSTOM) { // Custom charge.
      return charge === Contract.CHARGE_CUSTOM ? '' : charge;
    } else if (chargeType === Contract.CHARGE_SAME_AS_PAY) { // Charge same as pay.
      return estate.getContract().getPayAmount();
    } else { // Charge not required
      return '';
    }
  }

  const getChargeInputPlaceholder = () => {
    /** @type {import('./components/Inputbar').InputbarPlaceholderObject} */
    const placeholder = {};
    const chargeType = !onEdit || onEdit.id !== 'contract'
      ? estate.getContract().getChargeType()
      : onEdit.fields.find(f => f.id === 'charge_type').newValue;

    if (chargeType === Contract.CHARGE_CUSTOM) {
      placeholder.default = 'Cantidad del cargo (MXN)';
      placeholder.onIsValidFail = 'Ingresa un cargo mayor a cero';
    } else if (chargeType === Contract.CHARGE_SAME_AS_PAY) {
      placeholder.default = 'Cargo igual que la renta';
    } else {
      placeholder.default = 'Cargo no requerido';
    }

    return placeholder;
  }

  const getEstateCurrentPrice = () => {
    if (fetchBidding?.getBids().length > 0) {
      const auxEst = new Estate({ ...estate });
      auxEst.getContract().setPayAmount(Math.max(...fetchBidding.getBids().map(b => b.getAmount())));

      return Estate.priceToString(auxEst);
    } else return Estate.priceToString(estate);
  }

  const getPayFrequencyOptions = () => {
    const termMethod = !onEdit || onEdit.id !== 'contract'
      ? estate.getContract().getTermMethod()
      : onEdit.fields.find(f => f.id === 'term_method').newValue;
    /** @type {import('../../components/Selectbar').OptionObject[]} */
    const options = [
      { displayValue: 'Pago único', value: Contract.PAY_FREQ_UNIQUE },
      { displayValue: 'Diario', value: Contract.PAY_FREQ_DAILY },
    ];

    if (termMethod > Contract.TERM_METHOD_DAY)
      options.push({ displayValue: 'Mensual', value: Contract.PAY_FREQ_MONTHLY });

    if (termMethod > Contract.TERM_METHOD_MONTH)
      options.push({ displayValue: 'Anual', value: Contract.PAY_FREQ_YEARLY })

    return options;
  }

  const getPillClass = () => {
    if (estate.getStatus() === Estate.STATUS_ACT || estate.getSellMethod() === Estate.ON_COMPLEX)
      return Estate.sellMethodToClass(estate);
    else if (estate.getStatus() === Estate.STATUS_INA)
      return 'inactive';
    else
      return 'suspended';
  }

  const getPricePlaceholder = () => {
    const sellMethod = onEdit?.id === 'contract'
      ? onEdit.fields.find(f => f.id === 'sell_method').newValue
      : estate.getSellMethod();

    return estate.getBidding().getStatus()
      ? (sellMethod === Estate.ON_LEASE
        ? 'Renta publicada (MXN)' : 'Precio publicado (MXN)')
      : (sellMethod === Estate.ON_LEASE
        ? 'Pago por periodo (MXN)'
        : 'Precio (MXN)');
  }

  const getSellMethodOptions = () => {
    /** @type {import('../../components/Selectbar').OptionObject[]} */
    const options = [{ displayValue: 'En venta', value: Estate.ON_SALE }];

    if (Estate.canBeOnLease(estate))
      options.push({ displayValue: 'En renta', value: Estate.ON_LEASE });

    return options;
  }

  /** @returns {import('../../components/Inputbar').InputbarPlaceholderObject} */
  const getTermPlaceholder = () => {
    const termMethod = !onEdit || onEdit.id !== 'contract'
      ? estate.getContract().getTermMethod()
      : onEdit.fields.find(f => f.id === 'term_method').newValue;

    switch (termMethod) {
      case Contract.TERM_METHOD_DAY: {
        return {
          default: 'Duración (días)',
          onIsValidFail: 'Ingresa de 1 a 31 días'
        };
      } case Contract.TERM_METHOD_MONTH: {
        return {
          default: 'Duración (meses)',
          onIsValidFail: 'Ingresa de 1 a 11 meses'
        };
      } case Contract.TERM_METHOD_YEAR: {
        return {
          default: 'Duración (años)',
          onIsValidFail: 'Ingresa de 1 a 10 años'
        }
      } default: {
        return { default: 'Método aún sin especificar' }
      }
    }
  }

  const hasABidding = () => estate?.getBidding()?.getId() !== undefined;

  /** Image Viewer change handler.
   * @param {GenericFile|{ newIdx: number, prevIdx: number }|number|string} input 
   * @param {'add'|'error'|'move'|'remove'} instruction 
   */
  const imageViewerHandleOnChange = (input, instruction) => {
    switch (instruction) {
      case 'add': {
        onEdit.fields[0].newValue.push(input);
        break;
      } case 'error': {
        pushMessageHint({ message: `'${input}' ya fue subido`, type: 'error' });
        break;
      } case 'move': {
        const auxImg = onEdit.fields[0].newValue[input.prevIdx];
        onEdit.fields[0].newValue[input.prevIdx] = onEdit.fields[0].newValue[input.newIdx];
        onEdit.fields[0].newValue[input.newIdx] = auxImg;
        break;
      } case 'remove': {
        onEdit.fields[0].newValue.splice(input, 1);
        break;
      } default: return;
    }

    requestSubmit();
  }

  /** @param {string} input */
  const inputboxHandleOnAdd = input => {
    const isIncluded = estate.getRequirements().includes(input);
    const onResolve = nV => estate.setRequirements(nV);

    onEdit.fields.push({
      newValue: input,
      oldValue: isIncluded ? input : null,
      onResolve,
      rollback: {}
    });

    setOnEdit({ ...onEdit });
    requestSubmit();
  }

  /** @param {number} index */
  const inputboxHandleOnDelete = index => {
    onEdit.fields.splice(index, 1);
    requestSubmit();
  }

  /** Input / Select change handler.
   * @param {*} input The new input value.
   * @param {*} [id] The if of the FieldObject instance linked to the input to change.
   */
  const inputHandleOnChange = (input, id) => {
    if (onEdit.id === 'charac') {
      const field = onEdit.fields.find(f => f.id === id);

      if (/^p_[a-z]+/.test(id)) { // Property.
        field.newValue = input || undefined;
      } else if (/^incl_s_[a-z]+$/.test(id)) { // Included service.
        field.newValue = input || 0;
      } else { // Service.
        field.newValue = input !== undefined ? Boolean(input) : undefined;

        if (!input) {
          const inclServ = onEdit.fields.find(f => f.id === `incl_${id}`);

          if (inclServ) inclServ.newValue = 0;
        }

        setOnEdit({ ...onEdit });
      }
    } else if (onEdit.id === 'con-inf') {
      if (id === 'coninf_switch') {
        onEdit.fields.forEach(f => {
          if (f.id !== 'coninf_switch') {
            switch (f.id) {
              case 'email': {
                f.newValue = input ? selfConInf.current.getEmail() : '';
                break;
              } case 'name': {
                f.newValue = input ? selfConInf.current.getName() : '';
                break;
              } case 'number': {
                f.newValue = input ? selfConInf.current.getPhone().number : '';
                break;
              } case 'prefix': {
                const auxP = selfConInf.current.getPhone();

                f.newValue = input
                  ? `${auxP.code}_${auxP.prefix}`
                  : '';
                break;
              } default: { }
            }

            f.rollback?.current(f.newValue);
          } else f.newValue = input;
        })

        setOnEdit({ ...onEdit });
      } else onEdit.fields.find(f => f.id === id).newValue = input;
    } else if (onEdit.id === 'contract') {
      onEdit.fields.find(f => f.id === id).newValue = input;

      if (id === 'sell_method' || id === 'term_method' || id === 'charge_type') {
        // Any other param.
        if (id === 'term_method' || id === 'sell_method') {
          const pFreField = onEdit.fields.find(f => f.id === 'pay_frequency');
          const termField = onEdit.fields.find(f => f.id === 'term');
          termField.newValue = '';
          pFreField.newValue = '';
          termField.rollback.current && termField.rollback.current('');
          pFreField.rollback.current && termField.rollback.current('');

          if (id === 'sell_method') {
            const termMeth = onEdit.fields.find(f => f.id === 'term_method');
            termMeth.newValue = '';
            termMeth.rollback.current && termMeth.rollback.current('');
          }
        } else if (id === 'charge_type') {
          const chargeField = onEdit.fields.find(f => f.id === 'charge_at_start');

          if (input === Contract.CHARGE_SAME_AS_PAY) {
            chargeField.newValue = Contract.CHARGE_SAME_AS_PAY;
            chargeField.rollback.current(onEdit.fields.find(f => f.id === 'pay_amount').newValue);
          } else {
            chargeField.newValue = undefined;
            chargeField.rollback.current('');
          }
        }

        setOnEdit({ ...onEdit }); // Force re-render.
      } else if (id === 'pay_amount') {
        if (onEdit.fields.find(f => f.id === 'charge_type').newValue === Contract.CHARGE_SAME_AS_PAY)
          onEdit.fields.find(f => f.id === 'charge_at_start').rollback.current(input || '');
      }
    } else if (id && onEdit.fields.length > 1) {
      onEdit.fields.find(f => f.id === id).newValue = input;
    } else onEdit.fields[0].newValue = input;

    requestSubmit();
  }

  const isBiddingActive = () => {
    const bidding = estate?.getBidding();

    return hasABidding()
      && bidding.getStatus()
      && bidding.getManualStatus() === 1
      && bidding.getEndDate() > today;
  }

  const removeBiddingBtnHandleOnClick = () => {
    setDialog({
      action: () => DAOServ.post('remove_bidding', { tst: currSession.tst, idEstate: estate.getId() }, 'JSON'),
      confirmBtn: { icon: Icons.DeleteIcon, onWaitValue: 'Quitando...', type: 'error', value: 'Quitar' },
      id: 'remove-bidding-dialog',
      message: 'Después de quitar la subasta anterior, otras personas podrán solicitar'
        + ' la información de contacto de esta propiedad'
        + (!estate.getBidding().getId() ? '.'
          : ', pero toda la información de la subasta'
          + ' se perderá y no podrás acceder a la información personal de quienes ofertaron'
          + ' tu propiedad.'),
      onResolve: () => {
        estate.setBidding();
        setEstate(new Estate(estate));
        pushMessageHint({ message: 'La subasta fue eliminada', type: 'complete' });
      }, onReject: err => err && pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' }),
      rejectBtn: { value: 'Cancelar' },
      renderButtonsSwitched: true
    });
  }

  const renderContactInfoRequests = () => {
    if (contactRequests?.length === 0) return undefined;

    /** @param {User} usr */
    const deleteContactRequestBtnClickHandler = usr => {
      const payload = { tst: currSession.tst, idEstate: estate.getId(), idRequester: usr.getId() };

      setDialog({
        action: () => DAOServ.post('delete_contactinfo_request', payload, 'JSON'),
        confirmBtn: { icon: Icons.DeleteIcon, onWaitValue: 'Eliminando...', type: 'error', value: 'Eliminar' },
        message: `Eliminarás la solicitud de ${usr.getFirstName()} de la lista.`
          + ' Podrá solicitar nuevamente la información de contacto en el futuro.',
        onResolve: () => {
          contactRequests.splice(contactRequests.findIndex(aReq => aReq.user === usr), 1);
          setContactRequests([...contactRequests]);
          pushMessageHint({ message: 'Solicitud eliminada', type: 'complete' })
        }, onReject: err => err && pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' }),
        rejectBtn: { value: 'Cancelar' },
        renderButtonsSwitched: true
      })
    }

    return contactRequests?.map(cR => <ContactInfoCard creationDate={cR.creationDate}
      onRemoveBtnClick={() => deleteContactRequestBtnClickHandler(cR.user)}
      key={cR.user.getUsername()}
      user={cR.user} />)
  }

  const requestSubmit = () => {
    let disable = true;

    switch (onEdit.id) {
      case 'insurance': {
        disable = onEdit.fields[0].newValue === onEdit.fields[0].oldValue;
        break;
      } case 'build_date': {
        disable = !onEdit.fields[0].newValue || onEdit.fields[0].newValue > today;
        break;
      } case 'con-inf': {
        const switchC = onEdit.fields.find(f => f.id === 'coninf_switch');

        if (switchC.newValue !== switchC.oldValue && switchC.newValue) {
          disable = false;
        } else {
          for (const f of onEdit.fields) {
            if (f.id === 'coninf_switch') {
              if (f.newValue !== f.oldValue && f.newValue) {
                disable = false;
                break;
              }
            } else if (!f.newValue) {
              disable = true;
              break;
            } else if (f.newValue !== f.oldValue) {
              disable = false;
            }
          }
        }

        break;
      } case 'contract': {
        for (const field of onEdit.fields) {
          if (field.newValue !== field.oldValue) {
            disable = false;
            break;
          }
        }


        if (!disable) {
          const charge = onEdit.fields.find(f => f.id === 'charge_at_start')?.newValue;
          const chargeType = onEdit.fields.find(f => f.id === 'charge_type')?.newValue;
          const payAmount = onEdit.fields.find(f => f.id === 'pay_amount')?.newValue;
          const payFrequency = onEdit.fields.find(f => f.id === 'pay_frequency')?.newValue;
          const sellMethod = onEdit.fields.find(f => f.id === 'sell_method')?.newValue;
          const term = onEdit.fields.find(f => f.id === 'term')?.newValue;
          const termMethod = onEdit.fields.find(f => f.id === 'term_method')?.newValue;

          disable = !sellMethod || !payAmount || (sellMethod !== Estate.ON_SALE
            && (!termMethod || !term || !payFrequency || (chargeType === Contract.CHARGE_CUSTOM && !charge)));
        }

        break;
      } case 'images': {
        /** @type {GenericFile[]} */
        const newImgs = onEdit.fields[0].newValue;
        /** @type {GenericFile[]} */
        const oldImgs = onEdit.fields[0].oldValue;

        if (newImgs.length === oldImgs.length) {
          for (let i = 0; i < newImgs.length; i++) {
            if (newImgs[i].getName() !== oldImgs[i].getName()) {
              disable = false;
              break;
            }
          }
        } else disable = newImgs.length < 3;

        break;
      } default: {
        if (onEdit.id === 'requirements') {
          const newVals = onEdit.fields.map(f => f.newValue);
          const oldVals = onEdit.fields.map(f => f.oldValue);
          const oriVals = estate.getRequirements();

          if (oriVals.length > newVals.length) {
            disable = false;
          } else {
            for (const nV of newVals) {
              if (!oldVals.includes(nV)) {
                disable = false;
                break;
              }
            }
          }
        } else {
          for (const field of onEdit.fields) {
            if (field.newValue !== field.oldValue && (!field.required || field.newValue !== undefined)) {
              disable = false;
              break;
            } else {
              if (field.newValue === undefined && field.required) break;
              else disable = disable === true;
            }
          }
        }
      }
    }

    setDisableSubmit(disable);
  }

  const signOrCreateBtnHandleOnClick = async () => {
    let pass;
    setShowLoadingScreen(true);

    try {
      if (currSession.isSubuser) { // Pass request.
        pass = await DAOServ.fetchPass(currSession.tst);

        if (estate.getSellMethod() === Estate.ON_COMPLEX) {
          if (!pass.getAllPasses().in_esta)
            throw new Error(ErrHandler.getError(ErrHandler.CODES.ACCESS_DENIED));
        } else if (!pass.getAllPasses().in_cont) {
          throw new Error(ErrHandler.getError(ErrHandler.CODES.ACCESS_DENIED));
        }
      }

      // Update current day.
      setToday(await DAOServ.getCurrentDay());
      // Getting spaces.
      const spaces = await DAOServ.get('get_estate_spaces', [estate.getId()], 'JSON');
      estate.setTotalSpaces(spaces['total']);
      estate.setFreeSpaces(spaces['free']);

      if (estate.getFreeSpaces() !== -1 && estate.getFreeSpaces() <= 0)
        throw new Error(ErrHandler.getError(ErrHandler.CODES.LIMIT_REACHED));

      if (estate.getSellMethod() === Estate.ON_COMPLEX) { // Create subestate.
        // License Statistics.
        const licStats = await DAOServ.fetchLicenseStatistics(currSession.tst);
        // Request user's contacts info.
        const contInfQ = await DAOServ.post('get_user_contactinfo', { tst: currSession.tst }, 'JSON');
        const auxPhone = contInfQ['phone'].split('_');
        const auxConInf = new Persona();

        auxConInf.setEmail(contInfQ['email']);
        auxConInf.setFirstName(contInfQ['name']);
        auxConInf.setPhone({ code: auxPhone[0], number: auxPhone[2], prefix: auxPhone[1] });
        // Request phone prefixes.
        const prefixes = Array.from(await (await fetch(Global.FETCH_PREFIXES)).json());

        // Showing create property component.
        /** @type {CreateEstatePropsObject} */
        const auxProps = {
          contactInfo: auxConInf,
          enableBidding: licStats.bidding && (!currSession.isSubuser || pass.getAllPasses().in_bidd),
          prefixes,
        };

        setShowPopup({ mode: 0, props: auxProps });
      } else { // Sign process.
        setShowPopup({ mode: 1 });
      }
    } catch (err) {
      pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' });
    }

    setShowLoadingScreen(false);
  }

  /** Status switch on change handler.
   * @param {boolean} state Current compo state. 
   * @param {() => void} rollback Rollback function.
   */
  const statusHandleOnChange = async (state, rollback) => {
    setShowLoadingScreen(true);

    const payload = { tst: currSession.tst, idEstate: estate.getId(), payload: { status: Number(state) } };

    await DAOServ.post('update_estate', payload, 'JSON')
      .then(() => {
        estate.setStatus(payload.payload.status);
        const message = state
          ? 'La propiedad fue activada'
          : 'La propiedad fue desactivada';
        const type = !state ? 'warning' : 'complete';

        pushMessageHint({ message, type });
      }).catch(err => {
        const isForbiden = ErrHandler.getCode(err) === ErrHandler.CODES.FORBIDDEN;

        const getMessage = () => {
          if (!isForbiden)
            return ErrHandler.parseError(err)
          else if (estate.getSellMethod() === Estate.ON_COMPLEX)
            return 'Debes registrar al menos una subpropiedad para activarla';
          else
            return 'No hay espacios disponibles para esta propiedad';
        }

        rollback();

        const type = isForbiden ? 'warning' : 'error';
        pushMessageHint({ message: getMessage(), type });
      });

    setShowLoadingScreen(false);
  }

  /** Obtains the default value for the select elements from step = 3.
   * @param {*} id The identifier of the estate.
   */
  const propertyDefaultValue = id => {
    const prop = estate.getProperty(id);
    return prop?.value || "";
  }

  /** Obtains the default value for the select elements from step = 3.
   * @param {*} id The identifier of the service.
   */
  const serviceDefaultValue = id => {
    const service = estate.getService(id);
    return !service ? "" : Number(service.value);
  }

  // searchBar set defaults useEffect.
  useEffect(() => setSearchMethod(), [setSearchMethod]);

  // Restore page on render
  useEffect(() => {
    paramRef.current = Number(estateIdParam);

    setDialog();
    setDisableUI();
    setEstate();
    setFetchBidding();
    setFetchings();
    setFetchImages();
    setOnEdit();
    setShowLoadingScreen();
  }, [estateIdParam, setShowLoadingScreen]);

  // Fetch estate info.
  useEffect(() => {
    /** @param {Estate} estate */
    const fetchAllowBidding = async estate => {
      let result = false;

      if (estate.getSellMethod() !== Estate.ON_COMPLEX)
        result = (await DAOServ.fetchLicenseStatistics(currSession.tst)).bidding;

      return Promise.resolve(result);
    }

    const fetchEstate = async () => {
      if (!paramRef.current) { // Param Id not found.
        navigate(Global.PATH_NOT_FOUND, { replace: true });
        return;
      }

      /** @type {Estate} */
      let auxEstate;

      try {
        auxEstate = await DAOServ.fetchEstate(currSession.tst, paramRef.current);
        // Spaces parsing.
        if (auxEstate.getTotalSpaces() < 0) maxSpaces.current = -1;
        else maxSpaces.current = auxEstate.getFreeSpaces();

        // Contact info.
        const qCInf = await DAOServ
          .post('get_estate_contactinfo', { tst: currSession.tst, idEstate: paramRef.current }, 'JSON');

        const auxConInf = new Persona();
        const phone = qCInf['phone'].split('_');
        auxConInf.setEmail(qCInf['email']);
        auxConInf.setId(qCInf['idcontactinfo'] ? Number(qCInf['idcontactinfo']) : undefined);
        auxConInf.setFirstName(qCInf['name']);
        auxConInf.setPhone({ code: phone[0], number: phone[2], prefix: phone[1] });
        auxEstate.setContactInfo(auxConInf);

        if (auxEstate.getSellMethod() !== Estate.ON_COMPLEX) {
          const { username } = currSession;

          if (qCInf['creator_username'] === username || qCInf['owner_username'] === username) {
            conInfPlaceHolder.current = 'Usar mi información';
            selfConInf.current = await DAOServ.fetchUserContactInfo(currSession.tst);
          } else {
            const username = qCInf['creator_username'] || qCInf['owner_username'];
            conInfPlaceHolder.current = 'Usar la información del creador';
            selfConInf.current = await DAOServ.fetchUserContactInfo(currSession.tst, username);
          }
        }

        // Bidding.
        setAllowBidding(await fetchAllowBidding(auxEstate));
        // set Estate.
        setEstate(auxEstate);

      } catch (err) {
        if (auxEstate === undefined) // Estate not found.
          setEstate(new Estate());
        else { // Another fetch failed.
          pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' });
          navigate(Global.PATH_HOME, { replace: true }); // Go to start menu.
        }
      }
    }

    const fetchPrefixes = () => {
      fetch(Global.FETCH_PREFIXES)
        .then(data => data.json())
        .then(data => setPrefixes(Array.from(data))
        ).catch(() => {
          pushMessageHint({
            message: 'No se pudo obtener la lista de prefijos. La página no puede cargar.',
            type: 'error'
          });
        });
    }

    const fetchToday = () => {
      DAOServ.getCurrentDay()
        .then(day => setToday(day))
        .catch(err => {
          ErrHandler.parseError(err);
          pushMessageHint({
            message: 'No se pudo obtener la fecha de hoy. La página no puede cargar',
            type: 'error'
          });
        });
    }

    if (currSession.sessionStatus) {
      if (currSession.sessionStatus === User.SESSION_ACT) {
        fetchEstate(); // Fetch bidding allowed called in this function.
        fetchPrefixes();
        fetchToday();
      } else { // Session expired.
        navigate('/', { replace: true });
      }
    }
  }, [currSession, navigate, pushMessageHint]);

  // Fetch subestates / agreements from estate.
  useEffect(() => {
    /** Obtains the contracts of the current page's estate */
    const fetchAgreements = async () => {
      const payload = { idEstate: estate.getId(), getAll: fetchingsAll.current };

      // Getting active contracts.
      try {
        /** @type {{collection: *[], ok: boolean}} */
        const request = await DAOServ.post('get_estate_agreements', payload, 'JSON');
        const auxFetchings = [];

        request.agreements.forEach(block => {
          const auxAgr = new Agreement();
          const auxCon = new Contract({ agreement: auxAgr });

          auxAgr.setCreationDate(block['timestamp'])
          auxAgr.setEndDate(block['data']['endDate']);
          auxAgr.setHash(block['hash']);
          auxAgr.setId(block['data']['id']);
          auxAgr.setLessees(block['data']['lessees'].map(l => new User({ username: l })));
          auxAgr.setNextDeadline(block['data']['nextDeadline']);
          auxCon.setPayFrequency(block['data']['payFrequency']);
          auxAgr.setStartDate(block['data']['startDate']);
          auxAgr.setCancellationDate(block['data']['cancellationDate']);
          auxCon.setTerm(block['data']['term']);
          auxCon.setTermMethod(block['data']['termMethod']);

          auxFetchings.push(auxCon);
        });

        setFetchings(auxFetchings);
      } catch (err) {
        pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' });
        setFetchings([]);
      }
    }

    /** Obtains the subestates of the current page's estate */
    const fetchSubestates = async page => {
      try {
        const payload = {
          tst: currSession.tst,
          idEstate: paramRef.current,
          currentPage: page ?? 1
        };

        const resp = await DAOServ.post('get_estate_subdivisions', payload, 'JSON');
        const rows = Array.from(resp.rows);
        const auxArr = [];

        // Casting estates.
        for (let i = 0; i < rows.length; i++) {
          const auxCon = new Contract();
          const auxEst = new Estate();

          // Setting bidding.
          if (auxArr['idbidding']) {
            const auxBid = new Bid();
            const auxBng = new Bidding();

            auxBid.setId(Number(rows[i]['idmax_bid']));
            auxBid.setAmount(Number(rows[i]['max_bid_amount']));
            auxBng.getBids().push(auxBid);
            auxBng.setId(Number(rows[i]['idbidding']));
            auxBng.setEndDate(Number(rows[i]['end_date']));
            auxBng.setMinimumBid(Number(rows[i]['min_bid']));
            auxEst.setBidding(auxBng);
          }

          // Setting base contract.
          auxCon.setId(Number(rows[i]['idcontract']));
          auxCon.setCharge(Number(rows[i]['charge_at_start']));
          auxCon.setPayAmount(Number(rows[i]['pay_amount']));
          auxCon.setPayFrequency(Number(rows[i]['pay_frequency']));
          auxCon.setTerm(Number(rows[i]['term']));
          auxCon.setTermMethod(Number(rows[i]['term_method']));
          auxEst.setContract(auxCon);

          // Setting estate info.
          auxEst.getImages().push(new GenericFile({ name: rows[i]['cover'] }));
          auxEst.setId(Number(rows[i]['idestate']));
          auxEst.setCreationDate(Number(rows[i]['creation_date']));
          auxEst.setStatus(Number(rows[i]['status']));
          auxEst.setTitle(rows[i]['title']);
          auxEst.setTotalSpaces(rows[i]['total_spaces']);
          auxEst.setType(Number(rows[i]['e_type']));
          auxEst.setSellMethod(Number(rows[i]['sell_method']));
          auxEst.setFreeSpaces(rows[i]['free_spaces']);

          // Push to array.
          auxArr.push(auxEst);
        }

        setFetchings(auxArr);
      } catch (err) {
        pushMessageHint({
          message: ErrHandler.parseError(err),
          type: 'error'
        })
      }
    }

    if (estate?.getId() !== undefined) {
      if (fetchings === undefined) {
        if (estate.getSellMethod() === Estate.ON_COMPLEX) fetchSubestates();
        else if (estate.getSellMethod() === Estate.ON_LEASE) fetchAgreements();
      }
    }
  }, [currSession, estate, fetchings, pushMessageHint]);

  // Fetch bids.
  useEffect(() => {
    /** Obtains bids from current page's estate */
    const fetchBiddingBids = async () => {
      try {
        const query = Array.from(await DAOServ.get('get_bidding_bids', [estate.getBidding().getId()]));
        // Parsing bids.
        query.forEach(q => {
          const bid = new Bid();
          const bidder = new User();

          bid.setAmount(Number(q['amount']));
          bid.setBidder(bidder);
          bid.setCreationDate(Number(q['bid_creation_date']));
          bid.setId(Number(q['idbid']));
          bidder.setId(Number(q['idbidder']));
          bidder.setPicture(new GenericFile({ name: q['picture'] }));
          bidder.setUsername(q['username']);

          estate.getBidding().getBids().push(bid);
        });
      } catch (err) {
        pushMessageHint({
          message: ErrHandler.parseError(err),
          type: 'error'
        });
      } finally {
        setFetchBidding(estate.getBidding());
      }
    }

    const execute = estate?.getId() !== undefined
      && estate.getBidding().getId() !== undefined
      && fetchBidding === undefined;

    if (execute) fetchBiddingBids();
  }, [currSession, estate, fetchBidding, pushMessageHint])

  // Fetch contact info requests.
  useEffect(() => {
    /** Obtains the contact info requests */
    const fetchContactRequests = () => {
      const body = { tst: currSession.tst, idEstate: estate.getId() };

      DAOServ.post('get_estate_contactinfo_requests', body, 'JSON')
        .then(data => {
          /** @type {ContactRequestObject[]} */
          const auxContRequ = [];

          data.forEach(d => {
            const id = d['iduser'];
            const name = d['user_name']?.split(' ');
            const phone = d['user_phone']?.split('_');
            const user = new User({
              birthdate: d['user_birthdate'],
              email: d['user_email'],
              firstName: (id && name[0]) || undefined,
              lastName: (id && name[1]) || undefined,
              id,
              phone: (id && { code: phone[0], number: phone[2], prefix: phone[1] }) || undefined,
              picture: new GenericFile({ name: d['user_picture'] }),
              username: d['user_username']
            });

            auxContRequ.push({
              creationDate: Number(d['request_date']),
              fetchingPicture: false,
              user,
            });
          });

          setContactRequests(auxContRequ);
        }).catch(err => pushMessageHint({ message: err, type: 'error' }));
    }

    if (estate?.getId() !== undefined && contactRequests === undefined)
      fetchContactRequests();
  }, [contactRequests, currSession, estate, getCacheFile, pushCacheFile, pushMessageHint]);

  // Fetch images.
  useEffect(() => {
    /** Obtains the empty images from estate */
    const emptyImages = () => estate?.getImages().filter(i => !i.getURLData()) ?? [];

    /** Obtains images for current page's estate */
    const fetchPictures = async () => {
      try {
        const images = [...estate.getImages()];
        const empImages = emptyImages();
        let pos = 0;

        while (empImages.length > 0 && pos < empImages.length) {
          const eI = empImages[pos];
          const auxCF = getCacheFile(eI.getName());

          if (auxCF !== undefined) {
            eI.setSize(auxCF.size);
            eI.setURLData(typeof auxCF.content === 'string'
              ? auxCF.content
              : await FileChooser.readAsDataURL(auxCF.content)
            );

            images[images.findIndex(i => i.getName() === eI.getName())] = eI;
            empImages.splice(pos, 1);
          } else pos++;
        }

        if (empImages.length > 0) { // All or some files are not cached.
          const files = await Promise.all(empImages.map(nCF => DAOServ.getFile(nCF.getName())));
          // Parse images.
          const urlDatas = await Promise.all(files.map(file => FileChooser.readAsDataURL(file)));
          // Assigning and storing to cacheFiles.
          for (let i = 0; i < urlDatas.length; i++) {
            empImages[i].setURLData(urlDatas[i]);
            empImages[i].setSize(files[i].size);
            pushCacheFile({ content: urlDatas[i], name: empImages[i].getName(), size: files[i].size })
          }
        }

        estate.setImages(images);
        setFetchImages(images);
      } catch (err) {
        ErrHandler.parseError(err);
        pushMessageHint({ message: 'No se pudieron obtener las imágenes de la propiedad', type: 'error' });
      }
    }

    if (emptyImages().length > 0)
      fetchPictures();
  }, [estate, getCacheFile, pushCacheFile, pushMessageHint]);

  // On estate render.
  useEffect(() => {
    UIRender.scrollTo();
    pageRef.current?.classList.add('init');
  }, [estate]);

  if (!estate || !prefixes || (estate?.getId() !== undefined && allowBidding === undefined))
    return (<LoadingPanel className='full-height' />)
  else if (estate?.getId() === undefined)
    return <PageNotFound />
  else return (
    <div className="container priv-estate init"
      disabled={disableUI}
      ref={pageRef}
      onAnimationEnd={containerHandleOnAnimationEnd}>
      <div className="estate-container">
        {estate.getStatus() === Estate.STATUS_SUS && <Hintbox icon={Icons.WarningIcon}
          message={'La publicación fue suspendida porque infringe nuestros Términos y Condiciones.'
            + ' No podrás reactivar esta publicación. Si crees que esto se trata de un error, '
            + 'ponte en contacto con nosotros.'}
          type='error' />}
        {/* Images */}
        <ImageViewer disabled={onEdit && (onEdit.id !== 'images' || disableUI)}
          images={onEdit?.id === 'images' ? onEdit.fields[0].newValue : fetchImages}
          max={10}
          onAdd={file => imageViewerHandleOnChange(file, 'add')}
          onError={file => imageViewerHandleOnChange(file, 'error')}
          onMove={(newIdx, prevIdx) => imageViewerHandleOnChange({ newIdx, prevIdx }, 'move')}
          onRemove={index => imageViewerHandleOnChange(index, 'remove')}
          showImagesList={true}
          showToolsList={onEdit && onEdit.id === 'images'} />
        <div className="flex-box m3 jc-left">
          <Button disabled={onEdit && (onEdit.id !== 'images' || disableSubmit)}
            empty
            icon={onEdit?.id === 'images' ? Icons.SaveIcon : Icons.EditIcon}
            isWaiting={!fetchImages || (onEdit?.id === 'images' && disableUI)}
            onClick={() => editBtnHandleOnClick('images')}
            onWaitValue={!fetchImages ? 'Cargando fotos' : undefined}
            rounded
            value={onEdit?.id === 'images' ? 'Guardar' : 'Modificar fotos'}
            typeRender={onEdit?.id === 'images' ? 'complete' : ''} />
          {onEdit && onEdit.id === 'images' && <Button disabled={disableUI}
            empty
            icon={Icons.CloseIcon}
            onClick={cancelBtnHandleOnClick}
            rounded
            typeRender='error'
            value='Cancelar' />}
        </div>
        {/* Title, sell method / status (only display) and bidding (only display) */}
        <div className="flex-box wrap">
          <div className="child jc-left">
            <div className="child jc-left">
              {(!onEdit || onEdit.id !== 'title') && <h3 className='estate-title'>{estate.getTitle()}</h3>}
              {onEdit && onEdit.id === 'title' && <Inputbar defaultValue={estate.getTitle()}
                disabled={!onEdit || onEdit.id !== 'title' || disableUI}
                filters={[{ regExp: Global.REGEXP_FILTER_FORBIDDEN_SYMBOLS }]}
                forceChangeRef={onEdit?.fields[0].rollback}
                maxLength={Estate.MAX_TITLE_LENGTH}
                minLength={Estate.MIN_TITLE_LENGTH}
                onBlur={input => input?.replace(/\s+/g, ' ')?.replace(/^\s|\s$/g, '')}
                onChange={inputHandleOnChange}
                placeholder={{ default: 'Título' }}
                requestFocus={onEdit?.id === 'title'}
                required={onEdit?.id === 'title' && onEdit.fields[0].required} />}
            </div>
            <div className="child auto-width m3">
              <Button disabled={onEdit && (onEdit.id !== 'title' || disableSubmit)}
                empty
                icon={onEdit && onEdit.id === 'title' ? Icons.SaveIcon : Icons.EditIcon}
                isWaiting={onEdit?.id === 'title' && disableUI}
                onClick={() => editBtnHandleOnClick('title', { currVal: estate.getTitle(), required: true }, nV => estate.setTitle(nV))}
                reduced
                rounded
                typeRender={onEdit && onEdit.id === 'title' ? 'complete' : ''} />
              {onEdit && onEdit.id === 'title' && <Button empty
                icon={Icons.CloseIcon}
                onClick={cancelBtnHandleOnClick}
                reduced
                rounded
                typeRender={'error'} />}
            </div>
          </div>
          {/* Bidding icon */}
          {estate.getBidding().getStatus() && <div className="child auto-width">
            <img src={Icons.BiddIcon} alt="bidding" className='img-icon' title='Esta propiedad está en subasta' />
          </div>}
          <div className="child auto-width">
            <span className={`pill ${getPillClass()}`} />
          </div>
        </div>
        {/* Price (only display, bidding has effect) and status */}
        <div className="flex-box jc-right wrap">
          {estate.getSellMethod() !== Estate.ON_COMPLEX && <div className="child jc-left">
            <h2 className="highlight">{getEstateCurrentPrice()}</h2>
          </div>}
          <div className="child auto-width">
            <Switch defaultValue={estate.getStatus() === Estate.STATUS_ACT}
              disabled={estate.getStatus() === Estate.STATUS_SUS
                || estate.getBidding().getStatus()
                || onEdit
                || disableUI}
              onChange={statusHandleOnChange}
              placeholder={{
                default: estate.getStatus() === Estate.STATUS_SUS ? 'Suspendida' : 'Inactiva',
                onChecked: 'Activa'
              }} />
          </div>
        </div>
        {fetchBidding !== undefined && <h5 className="overset">Mejor oferta</h5>}
        {/* Contracts or subdivisions fetch contents */}
        {estate.getSellMethod() !== Estate.ON_SALE && <div className='fetch-contents box shadowless' >
          {/* Top bar */}
          <div className="top-bar flex-box">
            <div className="child jc-left">
              <h3 className="highlight">{estate.getSellMethod() === Estate.ON_COMPLEX
                ? 'Subdivisiones'
                : 'Contratos'}
              </h3>
            </div>
            <div className="child auto-width">
              <Switch defaultValue={fetchingsAll.current}
                disabled={fetchings === undefined || onEdit}
                onChange={state => {
                  fetchingsAll.current = state;
                  setFetchings();
                }} placeholder={{
                  default: estate.getSellMethod() === Estate.ON_COMPLEX ? 'Activas' : 'Activos',
                  onChecked: 'Todo'
                }} />
            </div>
          </div>
          {/* Box content. */}
          <div className="fetch-results box borderless shadowless" onClick={fetchedContentsHandleOnClick}>
            {fetchings === undefined && <LoadingBlock noBackground={true} position='relative' />}
            {fetchings?.length === 0 && <NotFoundBox />}
            {fetchings?.length > 0 && fetchings.map(e => {
              if (estate.getSellMethod() === Estate.ON_LEASE)
                return (<ContractItem contract={e}
                  key={e.getAgreement().getHash()}
                  currentDay={today} />);
              else
                return (<EstateItem estate={e} key={`estate-${e.getId()}`} />)
            })}
          </div>
          <div className="flex-box wrap jc-left">
            <div className="child auto-width">
              <Button empty borderless animated
                disabled={(estate.getFreeSpaces() !== -1 && estate.getFreeSpaces() <= 0)
                  || estate.getBidding().getStatus() || onEdit || disableUI}
                icon={estate.getSellMethod() === Estate.ON_COMPLEX ? Icons.PropertyIcon : Icons.SignIcon}
                onClick={signOrCreateBtnHandleOnClick}
                value={estate.getSellMethod() === Estate.ON_COMPLEX
                  ? 'Crear subdivisión'
                  : 'Firmar contrato'} />
            </div>
            {estate.getBidding().getStatus() && <div className="child">
              <Hintbox icon={Icons.WarningIcon}
                type='warning'
                message='No puedes firmar contratos mientras la subasta está activa.' />
            </div>}
            {estate.getFreeSpaces() !== -1 && estate.getFreeSpaces() <= 0 && <div className='child'>
              <Hintbox icon={Icons.WarningIcon}
                type='warning'
                message={estate.getSellMethod() === Estate.ON_COMPLEX
                  ? 'No hay más espacios para crear más subdivisiones.'
                  : 'No hay más espacios para firmar más contratos.'
                } />
            </div>}
          </div>
        </div>}
        {/* Contact info requests */}
        {estate.getSellMethod() !== Estate.ON_COMPLEX && !hasABidding() && <div className="box shadowless">
          <h3 className="highlight">Solicitudes de contacto</h3>
          {contactRequests?.length > 0 && <Hintbox icon={Icons.InfoIcon}
            message='Las personas en esta lista tienen acceso a la información de contacto de esta propiedad' />}
          <div className="contactinfo-requests">
            {renderContactInfoRequests() ?? <NotFoundBox message='Sin solicitudes' />}
          </div>
        </div>}
        {/* Bidding */}
        {estate.getSellMethod() !== Estate.ON_COMPLEX && allowBidding && <div className='box shadowless' >
          <h3 className="highlight">Subasta.</h3>
          {/* A bidding is found */}
          {hasABidding() && <BiddingBox bidding={fetchBidding}
            endTimeOptions={{ today, type: 2 }}
            onDeleteWinner={deleteBiddingWinnerClickHandler} />}
          {/* No bidding found. Can create a bidding */}
          {!hasABidding() && <div className="bidding-banner">
            <div className="bidding-img-container">
              <img src={Icons.KevinImg} alt="bidding-kevin" />
            </div>
            <div className="bidding-info-container">
              <h2 className="highlight">¡Esta propiedad se puede subastar!</h2>
              <h5 className="overset">No hay una subasta anterior{
                estate.getFreeSpaces() > 0
                  ? ', pero puedes crear una.'
                  : '. Agrega espacios para crear una.'
              }</h5>
            </div>
          </div>}
          {/* Bottom bar buttons */}
          <div className="flex-box m3 jc-right">
            {hasABidding() && !isBiddingActive() && <div className="child jc-right">
              <Button borderless
                empty
                icon={Icons.DeleteIcon}
                onClick={removeBiddingBtnHandleOnClick}
                typeRender='error'
                value='Quitar subasta' />
            </div>}
            <div className="child jc-right auto-width">
              <Button borderless
                disabled={onEdit || estate.getFreeSpaces() <= 0}
                empty
                icon={!estate.getBidding().getStatus() ? Icons.SignIcon : Icons.ArchiveIcon}
                onClick={biddingBtnHandleOnClick}
                typeRender={!estate.getBidding().getStatus() ? '' : 'error'}
                value={!estate.getBidding().getStatus() ? 'Nueva subasta' : 'Finalizar'} />
            </div>
            {estate.getFreeSpaces() <= 0 && <div className='child jc-right' >
              <Hintbox icon={Icons.WarningIcon}
                message='No puedes iniciar una subasta porque no hay espacios disponibles.'
                type='warning' />
            </div>}
          </div>
          {/* Bidding ended hintbox */}
          {hasABidding() && !isBiddingActive() && <Hintbox icon={Icons.WarningIcon}
            message={fetchBidding?.getBids().length > 0
              ? 'La subasta ha finalizado. Solo el ganador puede ver la información de contacto'
              : 'La subasta ha finalizado, pero nadie puede ver la información de contacto'
              + ' ni solicitarla hasta que la quites.'
            } type='warning' />}
        </div>}
      </div>
      {/* Contact info */}
      {estate?.getSellMethod() !== Estate.ON_COMPLEX && <div className="box borderless">
        <div className="flex-box m3">
          <div className="child jc-left">
            <h4 className='highlight'>Información de contacto</h4>
          </div>
          <div className="child m3 auto-width">
            <Button disabled={onEdit && (onEdit.id !== 'con-inf' || disableSubmit)}
              empty
              icon={onEdit?.id === 'con-inf' ? Icons.SaveIcon : Icons.EditIcon}
              isWaiting={onEdit?.id === 'con-inf' && disableUI}
              onClick={() => editBtnHandleOnClick('con-inf')}
              reduced
              rounded
              typeRender={onEdit?.id === 'con-inf' ? 'complete' : ''} />
            {onEdit && onEdit.id === 'con-inf' && <Button empty
              icon={Icons.CloseIcon}
              onClick={cancelBtnHandleOnClick}
              reduced
              rounded
              typeRender='error' />}
          </div>
        </div>
        <Switch defaultValue={estate.getContactInfo().getId() === undefined}
          disabled={!onEdit || onEdit.id !== 'con-inf'}
          forceChangeRef={onEdit?.id === 'con-inf' && onEdit.fields.find(f => f.id === 'coninf_switch').rollback}
          onChange={state => inputHandleOnChange(state, 'coninf_switch')}
          placeholder={{ default: conInfPlaceHolder.current }} />
        <br />
        <Inputbar filters={[{ regExp: Global.REGEXP_FILTER_SYMBOLS }]}
          defaultValue={estate.getContactInfo() === -1
            ? selfConInf.current.getName()
            : estate.getContactInfo().getName()}
          disabled={!onEdit || onEdit.id !== 'con-inf' || onEdit.fields.find(f => f.id === 'coninf_switch').newValue === true}
          forceChangeRef={onEdit?.id === 'con-inf' && onEdit.fields.find(f => f.id === 'name').rollback}
          icon={Icons.UserIcon}
          maxLength={100}
          minLength={2}
          onBlur={input => input?.replace(/^\s+|\s+$/g, '')?.replace(/\s+/g, ' ')}
          onChange={input => inputHandleOnChange(input, 'name')}
          placeholder={{ default: 'Nombre del titular' }}
          required={onEdit?.id === 'con-inf'}
          textTransform='capitalize' />
        <Inputbar filters={[{ regExp: Global.REGEXP_FILTER_EMAIL }]}
          defaultValue={estate.getContactInfo() === -1
            ? selfConInf.current.getEmail()
            : estate.getContactInfo().getEmail()}
          disabled={!onEdit || onEdit.id !== 'con-inf' || onEdit.fields.find(f => f.id === 'coninf_switch').newValue === true}
          forceChangeRef={onEdit?.id === 'con-inf' && onEdit.fields.find(f => f.id === 'email').rollback}
          icon={Icons.MailIcon}
          inputMode='email'
          isValid={input => Global.REGEXP_EMAIL.test(input)}
          maxLength={Persona.EMAIL_MAX_LENGTH}
          onChange={input => inputHandleOnChange(input, 'email')}
          placeholder={{ default: 'Correo electrónico', onIsValidFail: 'Correo inválido' }}
          required={onEdit?.id === 'con-inf'}
          textTransform='lowercase'
          type='email' />
        <div className="flex-box m3">
          <div className="child auto-width">
            <Selectbar width={125}
              disabled={!onEdit || onEdit.id !== 'con-inf' || onEdit.fields.find(f => f.id === 'coninf_switch').newValue === true}
              defaultValue={estate.getContactInfo() === -1
                ? `${selfConInf.current.getPhone().code}_${selfConInf.current.getPhone().prefix}`
                : `${estate.getContactInfo().getPhone().code}_${estate.getContactInfo().getPhone().prefix}`}
              forceChangeRef={onEdit?.id === 'con-inf' && onEdit.fields.find(f => f.id === 'prefix').rollback}
              onChange={option => inputHandleOnChange(option, 'prefix')}
              options={prefixes.map(p => {
                return {
                  displayValue: `${p.name} (${p.dial_code})`,
                  value: `${p.code}_${p.dial_code}`
                }
              })} placeholder='Prefijo'
              required={onEdit?.id === 'con-inf'} />
          </div>
          <div className="child">
            <Inputbar filters={[{ regExp: Global.REGEXP_FILTER_INTEGER }]}
              defaultValue={estate.getContactInfo() === -1
                ? selfConInf.current.getPhone().number
                : estate.getContactInfo().getPhone().number}
              disabled={!onEdit || onEdit.id !== 'con-inf' || onEdit.fields.find(f => f.id === 'coninf_switch').newValue === true}
              forceChangeRef={onEdit?.id === 'con-inf' && onEdit.fields.find(f => f.id === 'number').rollback}
              icon={Icons.PhoneIcon}
              inputMode='tel'
              isValid={input => Global.REGEXP_PHONE_NUMBER.test(input)}
              maxLength={10}
              minLength={10}
              onChange={input => inputHandleOnChange(input, 'number')}
              placeholder={{ default: 'Número de teléfono' }}
              required={onEdit?.id === 'con-inf'} />
          </div>
        </div>
        {onEdit?.id === 'con-inf' && <Hintbox icon={Icons.InfoIcon}
          message={'El número de teléfono debe contener 10 dígitos. Si tu número tiene menos '
            + 'dígitos, puedes agregar hasta cuatro ceros al inicio del número.'} />}
      </div>}
      {/* More info. */}
      <div className="box borderless">
        <h4 className="highlight">Más información de la propiedad</h4>
        {/* Spaces */}
        {estate?.getComplex() !== undefined && <div className="flex-box m3">
          <div className="child">
            <Inputbar defaultValue={estate.getTotalSpaces()}
              disabled={onEdit?.id !== 'spaces'}
              filters={[{ regExp: Global.REGEXP_FILTER_INTEGER }]}
              forceChangeRef={onEdit?.id === 'spaces' ? onEdit.fields[0].rollback : undefined}
              inputMode='numeric'
              isValid={input => Number(input) > 0
                && Number(input) < (maxSpaces.current === -1 ? 1000 : maxSpaces.current)}
              maxLength={maxSpaces.current === -1 ? 1000 : `${maxSpaces.current}`.length}
              placeholder={{
                default: (onEdit?.id === 'spaces' ? `Espacios (max. ${maxSpaces.current})` : 'Espacios'),
                onIsValidFail: 'Número inválido'
              }} requestFocus={onEdit?.id === 'spaces'}
              required={onEdit?.id === 'spaces' && onEdit.fields[0].required} />
          </div>
          <div className="child auto-width">
            <Button empty
              disabled={estate.getBidding().getStatus()
                || (onEdit && (onEdit.id !== 'spaces' || disableSubmit))}
              icon={onEdit?.id === 'spaces' ? Icons.SaveIcon : Icons.EditIcon}
              isWaiting={onEdit?.id === 'spaces' && disableUI}
              onClick={() => editBtnHandleOnClick('spaces')}
              reduced
              rounded
              typeRender={onEdit?.id === 'spaces' ? 'complete' : ''} />
            {onEdit?.id === 'spaces' && <Button disabled={disableUI}
              empty
              icon={Icons.CloseIcon}
              onClick={cancelBtnHandleOnClick}
              reduced
              rounded
              typeRender='error' />}
          </div>
        </div>}
        {/* Type */}
        <div className="flex-box m3">
          <div className="child auto-width">
            <PropDisplay header='Tipo de propiedad'
              property={Estate.getTypes().find(t => t.value === estate.getType()).displayName} />
          </div>
          <div className="child jc-left">
            <Hintbox icon={Icons.WarningIcon}
              message='Este campo no puede cambiar.'
              type='warning' />
          </div>
        </div>
        {/* Location */}
        <div className="flex-box wrap m3">
          <div className="child">
            <PropDisplay header='Ubicación' property={estate.getLocation().getAddress()} />
          </div>
          <div className="child auto-width">
            <Button borderless disabled={onEdit || disableUI} empty icon={Icons.CopyIcon}
              onClick={copyLocationHandleOnClick}
              reduced rounded title='Copiar dirección' />
          </div>
          <div className="child auto-width">
            <Hintbox icon={Icons.WarningIcon} message='Este campo no puede cambiar.' type='warning' />
          </div>
        </div>
        {/* Base contract and included services */}
        {estate.getSellMethod() !== Estate.ON_COMPLEX && <div className="box shadowless">
          {estate.getBidding().getStatus() && <Hintbox icon={Icons.InfoIcon}
            message='No puedes modificar el contrato base durante una subasta'
            type='warning' />}
          <div className="flex-box">
            <div className="child jc-left">
              <h4 className="overset">Contrato base</h4>
            </div>
            <div className="child auto-width m3">
              <Button empty
                disabled={estate.getBidding().getStatus()
                  || (onEdit && (onEdit.id !== 'contract' || disableSubmit))}
                icon={onEdit?.id === 'contract' ? Icons.SaveIcon : Icons.EditIcon}
                isWaiting={onEdit?.id === 'contract' && disableUI}
                onClick={() => editBtnHandleOnClick('contract')}
                reduced
                rounded
                typeRender={onEdit?.id === 'contract' ? 'complete' : ''} />
              {onEdit && onEdit.id === 'contract' && <Button disabled={disableUI}
                empty
                icon={Icons.CloseIcon}
                onClick={cancelBtnHandleOnClick}
                reduced
                rounded
                typeRender='error' />}
            </div>
          </div>
          <div className="flex-box m3 wrap">
            <div className="child">
              <Selectbar defaultValue={estate.getSellMethod()}
                disabled={!onEdit || onEdit.id !== 'contract' || fetchings?.length > 0 || disableUI}
                forceChangeRef={onEdit?.id === 'contract'
                  && onEdit.fields.find(f => f.id === 'sell_method').rollback}
                onChange={option => inputHandleOnChange(option, 'sell_method')}
                options={getSellMethodOptions()}
                placeholder='Método de promoción'
                required={onEdit?.id === 'contract'} />
            </div>
            <div className="child">
              <Inputbar defaultValue={estate.getContract().getPayAmount()}
                disabled={!onEdit || onEdit.id !== 'contract'
                  || !onEdit.fields.find(f => f.id === 'sell_method').newValue || disableUI}
                filters={[{ regExp: Global.REGEXP_FILTER_DECIMAL }, { regExp: /^0+\.|^\./, replace: '0.' }]}
                forceChangeRef={onEdit?.id === 'contract'
                  && onEdit.fields.find(f => f.id === 'pay_amount').rollback}
                inputMode='decimal'
                isValid={input => input && Number(input) > 0}
                maxLength={10}
                onChange={input => inputHandleOnChange(input ? Number(input) : undefined, 'pay_amount')}
                placeholder={{
                  default: getPricePlaceholder(),
                  onIsValidFail: 'Ingresa un valor mayor que cero',
                }} required={onEdit?.id === 'contract'} />
            </div>
          </div>
          {/* ON_LEASE contract properties */}
          {((!onEdit && estate.getSellMethod() === Estate.ON_LEASE) || (onEdit && (onEdit.id !== 'contract'
            || onEdit.fields.find(f => f.id === 'sell_method').newValue === Estate.ON_LEASE))) &&
            <div className="flex-box wrap m3">
              <div className="child">
                <Selectbar placeholder={'Método de duración'}
                  defaultValue={onEdit?.id === 'contract'
                    ? onEdit.fields.find(f => f.id === 'term_method').newValue
                    : estate.getContract().getTermMethod()}
                  disabled={!onEdit || onEdit.id !== 'contract' || disableUI}
                  forceChangeRef={onEdit?.id === 'contract'
                    && onEdit.fields.find(f => f.id === 'term_method').rollback}
                  onChange={option => inputHandleOnChange(option, 'term_method')}
                  options={[
                    { displayValue: 'Año(s)', value: Contract.TERM_METHOD_YEAR },
                    { displayValue: 'Día(s)', value: Contract.TERM_METHOD_DAY },
                    { displayValue: 'Mese(s)', value: Contract.TERM_METHOD_MONTH }
                  ]} required={onEdit?.id === 'contract'} />
              </div>
              <div className="child">
                <Inputbar maxLength={2}
                  defaultValue={onEdit?.id === 'contract'
                    ? onEdit.fields.find(f => f.id === 'term').newValue
                    : estate.getContract().getTerm()}
                  disabled={!onEdit
                    || onEdit.id !== 'contract'
                    || !onEdit.fields.find(f => f.id === 'term_method').newValue
                    || disableUI}
                  filters={[{ regExp: Global.REGEXP_FILTER_INTEGER }, { regExp: /^0+/ }]}
                  forceChangeRef={onEdit?.id === 'contract' && onEdit.fields.find(f => f.id === 'term').rollback}
                  inputMode='numeric'
                  isValid={input => Contract
                    .isTermValid(onEdit?.fields.find(f => f.id === 'term_method').newValue, input)}
                  onChange={input => inputHandleOnChange(input ? Number(input) : undefined, 'term')}
                  placeholder={getTermPlaceholder()}
                  required={onEdit?.id === 'contract'} />
              </div>
              <div className="child">
                <Selectbar options={getPayFrequencyOptions()}
                  defaultValue={onEdit?.id === 'contract'
                    ? onEdit.fields.find(f => f.id === 'pay_frequency').newValue
                    : estate.getContract().getPayFrequency()}
                  disabled={!onEdit
                    || onEdit.id !== 'contract'
                    || !onEdit.fields.find(f => f.id === 'term_method').newValue
                    || disableUI}
                  forceChangeRef={onEdit?.id === 'contract'
                    && onEdit.fields.find(f => f.id === 'pay_frequency').rollback}
                  onChange={input => inputHandleOnChange(input, 'pay_frequency')}
                  placeholder="Frecuencia de pago"
                  required={onEdit?.id === 'contract'} />
              </div>
            </div>
          }
          {((!onEdit && estate.getSellMethod() === Estate.ON_LEASE) || (onEdit && (onEdit.id !== 'contract'
            || onEdit.fields.find(f => f.id === 'sell_method').newValue === Estate.ON_LEASE))) && <div>
              <br />
              <h5 className="overset">Cargo extra</h5>
            </div>}
          {((!onEdit && estate.getSellMethod() === Estate.ON_LEASE) || (onEdit && (onEdit.id !== 'contract'
            || onEdit.fields.find(f => f.id === 'sell_method').newValue === Estate.ON_LEASE))) &&
            <div className="flex-box wrap m3">
              <div className="child">
                <Selectbar placeholder="Cargo al iniciar contrato (ninguno por defecto)"
                  defaultValue={onEdit?.id === 'contract'
                    ? onEdit.fields.find(f => f.id === 'charge_type').newValue
                    : estate.getContract().getChargeType()}
                  disabled={!onEdit || onEdit.id !== 'contract' || disableUI}
                  forceChangeRef={onEdit?.id === 'contract'
                    && onEdit.fields.find(f => f.id === 'charge_type').rollback}
                  onChange={option => inputHandleOnChange(option, 'charge_type')}
                  options={[
                    { displayValue: 'Igual que la renta', value: Contract.CHARGE_SAME_AS_PAY },
                    { displayValue: 'Personalizado', value: Contract.CHARGE_CUSTOM }
                  ]}

                  undefinedOption="Ninguno" />
              </div>
              <div className="child">
                <Inputbar placeholder={getChargeInputPlaceholder()}
                  defaultValue={getChargeInputDefaultValue()}
                  disabled={!onEdit
                    || onEdit.id !== 'contract'
                    || onEdit.fields.find(f => f.id === 'charge_type').newValue !== Contract.CHARGE_CUSTOM
                    || disableUI}
                  filters={[
                    { regExp: Global.REGEXP_FILTER_DECIMAL },
                    { regExp: /^0{2,}\./, replace: '0.' },
                    { regExp: /\.{2,}/, replace: '.' }]}
                  forceChangeRef={onEdit?.id === 'contract'
                    && onEdit.fields.find(f => f.id === 'charge_at_start').rollback}
                  inputMode="decimal"
                  isValid={input => input && Number(input) > 0}
                  maxLength={11}
                  onChange={input => inputHandleOnChange(input ? Number(input) : undefined, 'charge_at_start')}
                  required />
              </div>
            </div>
          }
          {onEdit && onEdit.id === 'contract'
            && onEdit.fields.find(f => f.id === 'sell_method').newValue === Estate.ON_LEASE &&
            <Hintbox icon={Icons.WarningIcon}
              message={'El primer pago de renta será requerido cuando un contrato entre en vigor'
                + (onEdit.fields.find(f => f.id === 'charge_type')?.newValue
                  ? ', junto con el cargo de primer pago.' : '.')}
              type="warning"
            />
          }
        </div>}
        {/* Description, build date, size and assurance */}
        <div className="box shadowless">
          <Textbox defaultValue={estate.getDescription()}
            disabled={!onEdit || onEdit.id !== 'dscr' || disableUI}
            filters={[{ regExp: Global.REGEXP_FILTER_FORBIDDEN_SYMBOLS }]}
            forceChangeRef={onEdit?.id === 'dscr' && onEdit.fields[0].rollback}
            header={{ default: 'Descripción' }}
            maxLength={Estate.MAX_DESC_LENGTH}
            minLength={Estate.MIN_DESC_LENGTH}
            onChange={inputHandleOnChange}
            placeholder='Escribe sobre tu propiedad aquí'
            requestFocus={onEdit?.id === 'dscr'}
            required={onEdit?.id === 'dscr' && onEdit.fields[0].required} />
          <div className="flex-box jc-left m3">
            <Button disabled={onEdit && (onEdit.id !== 'dscr' || disableSubmit)}
              empty
              icon={onEdit?.id === 'dscr' ? Icons.SaveIcon : Icons.EditIcon}
              isWaiting={onEdit?.id === 'dscr' && disableUI}
              onClick={() => editBtnHandleOnClick('dscr', { currVal: estate.getDescription(), required: true }, nV => estate.setDescription(nV))}
              rounded
              typeRender={onEdit?.id === 'dscr' ? 'complete' : ''}
              value={onEdit?.id === 'dscr' ? 'Guardar' : 'Cambiar descripción'} />
            {onEdit && onEdit.id === 'dscr' && <Button empty
              icon={Icons.CloseIcon}
              onClick={cancelBtnHandleOnClick}
              rounded
              typeRender='error'
              value='Cancelar' />}
          </div>
          <br />
          <h5 className="overset">Terreno</h5>
          <div className="flex-box m3">
            <div className="child">
              <Inputbar defaultValue={estate.getSize().x}
                disabled={!onEdit || onEdit.id !== 'size' || disableUI}
                filters={[{ regExp: Global.REGEXP_FILTER_DECIMAL }, { regExp: /^0+./, replace: '0.' }]}
                forceChangeRef={onEdit?.id === 'size' && onEdit.fields[0].rollback}
                icon={Icons.SizeIcon}
                inputMode='decimal'
                isValid={input => input && Number(input) > 0}
                maxLength={10}
                onChange={input => inputHandleOnChange(input ? Number(input) : undefined, 'x')}
                placeholder={{ default: 'Ancho (mts)', onIsValidFail: 'Ingresa un valor mayor a 0' }}
                requestFocus={onEdit?.id === 'size'}
                required={onEdit?.id === 'size'} />
            </div>
            <div className="child auto-width">
              <h4 className="highlight">X</h4>
            </div>
            <div className="child">
              <Inputbar defaultValue={estate.getSize().y}
                disabled={!onEdit || onEdit.id !== 'size' || disableUI}
                filters={[{ regExp: Global.REGEXP_FILTER_DECIMAL }, { regExp: /^0+./, replace: '0.' }]}
                forceChangeRef={onEdit?.id === 'size' && onEdit.fields[1].rollback}
                icon={Icons.SizeIcon}
                inputMode='decimal'
                isValid={input => input && Number(input) > 0}
                maxLength={10}
                onChange={input => inputHandleOnChange(input ? Number(input) : undefined, 'y')}
                placeholder={{ default: 'Largo (mts)', onIsValidFail: 'Ingresa un valor mayor a 0' }}
                required={onEdit?.id === 'size'} />
            </div>
            <div className="child auto-width m3">
              <Button disabled={onEdit && (onEdit.id !== 'size' || disableSubmit)}
                empty
                icon={onEdit && onEdit.id === 'size' ? Icons.SaveIcon : Icons.EditIcon}
                isWaiting={onEdit?.id === 'size' && disableUI}
                onClick={() => editBtnHandleOnClick('size', undefined, nV => estate.setSize(nV))}
                reduced
                rounded
                typeRender={onEdit && onEdit.id === 'size' ? 'complete' : ''} />
              {onEdit && onEdit.id === 'size' && <Button disabled={disableUI}
                empty
                icon={Icons.CloseIcon}
                onClick={cancelBtnHandleOnClick}
                reduced
                rounded
                typeRender={'error'} />}
            </div>
          </div>
          <div className="flex-box m3 wrap">
            <div className="flex-box m3">
              {estate.getComplex() === undefined && <div className="child">
                <Inputbar defaultValue={Global.transformDateForInput(estate.getBuildDate())}
                  disabled={!onEdit || onEdit.id !== 'build_date' || disableUI}
                  forceChangeRef={onEdit?.id === 'build_date' && onEdit.fields[0].rollback}
                  icon={Icons.BuilIcon}
                  isValid={input => input && Date.parse(input) <= today}
                  onChange={input => inputHandleOnChange(input ? Date.parse(input) : undefined)}
                  placeholder={{
                    default: estate.getType() === Estate.TYPE_TERRAIN
                      ? 'Fecha de adquisición'
                      : 'Fecha de construcción',
                    onIsValidFail: 'Ingresa la fecha de hoy o anterior'
                  }} requestFocus={onEdit?.id === 'build_date'}
                  type='date' />
              </div>}
              <div className="child auto-width m3">
                <Button disabled={estate.getComplex() !== undefined
                  || (onEdit && (onEdit.id !== 'build_date' || disableSubmit))}
                  empty
                  icon={onEdit?.id === 'build_date' ? Icons.SaveIcon : Icons.EditIcon}
                  isWaiting={onEdit?.id === 'build_date' && disableUI}
                  onClick={() => editBtnHandleOnClick('build_date', { currVal: estate.getBuildDate(), required: true })}
                  reduced
                  rounded
                  typeRender={onEdit?.id === 'build_date' ? 'complete' : ''} />
                {onEdit && onEdit.id === 'build_date' && <Button disabled={disableUI}
                  empty
                  icon={Icons.CloseIcon}
                  onClick={cancelBtnHandleOnClick}
                  reduced
                  rounded
                  typeRender={'error'} />}
              </div>
            </div>
            <div className="flex-box m3">
              <div className="child">
                <Inputbar defaultValue={estate.getInsurance()}
                  disabled={!onEdit || onEdit.id !== 'insurance' || disableUI}
                  filters={[{ regExp: Global.REGEXP_FILTER_SYMBOLS }]}
                  forceChangeRef={onEdit?.id === 'insurance' && onEdit.fields}
                  icon={Icons.InsuIcon}
                  maxLength={50}
                  onBlur={input => input?.replace(/\s+/g, ' ')?.replace(/^\s|\s$/g, '')}
                  onChange={inputHandleOnChange}
                  placeholder={{ default: 'Aseguradora' }}
                  requestFocus={onEdit?.id === 'insurance'}
                  textTransform='uppercase' />
              </div>
              <div className="child auto-width m3">
                <Button disabled={onEdit && (onEdit.id !== 'insurance' || disableSubmit)}
                  empty
                  icon={onEdit?.id === 'insurance' ? Icons.SaveIcon : Icons.EditIcon}
                  isWaiting={onEdit?.id === 'insurance' && disableUI}
                  onClick={() => editBtnHandleOnClick('insurance', { currVal: estate.getInsurance() })}
                  reduced
                  rounded
                  typeRender={onEdit?.id === 'insurance' ? 'complete' : ''} />
                {onEdit && onEdit.id === 'insurance' && <Button disabled={disableUI}
                  empty
                  icon={Icons.CloseIcon}
                  onClick={cancelBtnHandleOnClick}
                  reduced
                  rounded
                  typeRender='error' />}
              </div>
            </div>
          </div>
        </div>
        {/* Props, services and included services */}
        {estate.getSellMethod() !== Estate.ON_COMPLEX && <div className="box shadowless">
          <div className="flex-box">
            <div className="child jc-left">
              <h4 className="overset">Características</h4>
            </div>
            <div className="child auto-width m3">
              <Button disabled={onEdit && (onEdit.id !== 'charac' || disableSubmit)}
                empty
                icon={onEdit?.id === 'charac' ? Icons.SaveIcon : Icons.EditIcon}
                onClick={() => editBtnHandleOnClick('charac')}
                reduced
                rounded
                typeRender={onEdit?.id === 'charac' ? 'complete' : ''} />
              {onEdit && onEdit.id === 'charac' && <Button disabled={disableUI}
                empty
                icon={Icons.CloseIcon}
                onClick={cancelBtnHandleOnClick}
                reduced
                rounded
                typeRender='error' />}
            </div>
          </div>
          <div className="flex-box m3 wrap">
            <div className="box borderless">
              <h5 className="overset">Básicas</h5>
              {/* Bathroom */}
              {PSCollection.canHaveProperty(estate, PSCollection.PROP_BTHR) && <Inputbar
                defaultValue={propertyDefaultValue(PSCollection.PROP_BTHR)}
                disabled={!onEdit || onEdit.id !== 'charac' || disableUI}
                filters={[{ regExp: Global.REGEXP_FILTER_INTEGER }]}
                forceChangeRef={onEdit?.id === 'charac'
                  && onEdit.fields.find(r => r.id === PSCollection.PROP_BTHR).rollback}
                icon={Icons.BathIcon}
                inputMode='numeric'
                maxLength={3}
                onChange={input => inputHandleOnChange(input ? Number(input) : undefined, PSCollection.PROP_BTHR)}
                placeholder={{ default: 'No. de baños' }}
                required={PSCollection.isPropertyRequired(estate, PSCollection.PROP_BTHR)} />}
              {/* Kitchen */}
              {PSCollection.canHaveProperty(estate, PSCollection.PROP_KTCH) && <Inputbar
                defaultValue={propertyDefaultValue(PSCollection.PROP_KTCH)}
                disabled={!onEdit || onEdit.id !== 'charac' || disableUI}
                filters={[{ regExp: Global.REGEXP_FILTER_INTEGER }]}
                forceChangeRef={onEdit?.id === 'charac'
                  && onEdit.fields.find(r => r.id === PSCollection.PROP_KTCH).rollback}
                icon={Icons.KitchIcon}
                inputMode='numeric'
                maxLength={3}
                onChange={input => inputHandleOnChange(input ? Number(input) : undefined, PSCollection.PROP_KTCH)}
                placeholder={{ default: 'No. de cocinas' }}
                required={PSCollection.isPropertyRequired(estate, PSCollection.PROP_KTCH)} />}
              {/* Parking lot */}
              {PSCollection.canHaveProperty(estate, PSCollection.PROP_PARK) && <div>
                {Estate.getParkingType(estate) === 1 && <Inputbar
                  defaultValue={propertyDefaultValue(PSCollection.PROP_PARK)}
                  disabled={!onEdit || onEdit.id !== 'charac' || disableUI}
                  filters={[{ regExp: Global.REGEXP_FILTER_INTEGER }]}
                  forceChangeRef={onEdit?.id === 'charac'
                    && onEdit.fields.find(r => r.id === PSCollection.PROP_PARK).rollback}
                  icon={Icons.ParkIcon}
                  inputMode='numeric'
                  maxLength={3}
                  onChange={input => inputHandleOnChange(input ? Number(input) : undefined, PSCollection.PROP_PARK)}
                  placeholder={{ default: 'No. de garajes o espacios para estacionar' }} />}
                {Estate.getParkingType(estate) === 0 && <Selectbar
                  disabled={!onEdit || onEdit.id !== 'charac' || disableUI}
                  options={[{ displayValue: 'Sí', value: 1 }, { displayValue: 'No', value: 0 }]}
                  defaultValue={!propertyDefaultValue(PSCollection.PROP_PARK) ? 0 : 1}
                  forceChangeRef={onEdit?.id === 'charac'
                    && onEdit.fields.find(r => r.id === PSCollection.PROP_PARK).rollback}
                  onChange={option => inputHandleOnChange(option, PSCollection.PROP_PARK)}
                  placeholder='Estacionamiento' />}
              </div>}
              {/* Bedroom */}
              {PSCollection.canHaveProperty(estate, PSCollection.PROP_BEDR) && <Inputbar
                defaultValue={propertyDefaultValue(PSCollection.PROP_BEDR)}
                disabled={!onEdit || onEdit.id !== 'charac' || disableUI}
                filters={[{ regExp: Global.REGEXP_FILTER_INTEGER }]}
                forceChangeRef={onEdit?.id === 'charac'
                  && onEdit.fields.find(r => r.id === PSCollection.PROP_BEDR).rollback}
                icon={estate.getType() === Estate.TYPE_BUILDING ? Icons.OfficeIcon : Icons.BedrIcon}
                inputMode='numeric'
                maxLength={3}
                onChange={input => inputHandleOnChange(input ? Number(input) : undefined, PSCollection.PROP_BEDR)}
                placeholder={{
                  default: estate.getType() === Estate.TYPE_BUILDING
                    ? 'No. de oficinas' : 'No. de habitaciones'
                }}
                required={PSCollection.isPropertyRequired(estate, PSCollection.PROP_BEDR)} />}
              {/* Levels */}
              {PSCollection.canHaveProperty(estate, PSCollection.PROP_LVEL) && <Inputbar
                defaultValue={propertyDefaultValue(PSCollection.PROP_LVEL)}
                disabled={!onEdit || onEdit.id !== 'charac' || disableUI}
                filters={[{ regExp: Global.REGEXP_FILTER_INTEGER }]}
                forceChangeRef={onEdit?.id === 'charac'
                  && onEdit.fields.find(r => r.id === PSCollection.PROP_LVEL).rollback}
                icon={Icons.LvelIcon}
                inputMode='numeric'
                maxLength={3}
                onChange={input => inputHandleOnChange(input ? Number(input) : undefined, PSCollection.PROP_LVEL)}
                placeholder={{ default: 'No. de niveles' }}
                required={PSCollection.isPropertyRequired(estate, PSCollection.PROP_LVEL)}
                title='Número de pisos (incluyendo planta baja)' />}
              {/* Water service */}
              <Selectbar options={[{ displayValue: 'No', value: 0 }, { displayValue: 'Sí', value: 1 }]}
                defaultValue={serviceDefaultValue(PSCollection.SERV_WTER)}
                disabled={!onEdit || onEdit.id !== 'charac' || disableUI}
                forceChangeRef={onEdit?.id === 'charac'
                  && onEdit.fields.find(r => r.id === PSCollection.SERV_WTER).rollback}
                icon={Icons.WtrIcon}
                onChange={option => inputHandleOnChange(option, PSCollection.SERV_WTER)}
                placeholder='Servicio de agua y alcantarillado'
                required
                undefinedOption='Sin especificar' />
              {/* Electric service */}
              <Selectbar options={[{ displayValue: 'No', value: 0 }, { displayValue: 'Sí', value: 1 }]}
                defaultValue={serviceDefaultValue(PSCollection.SERV_ENGY)}
                disabled={!onEdit || onEdit.id !== 'charac' || disableUI}
                forceChangeRef={onEdit?.id === 'charac'
                  && onEdit.fields.find(r => r.id === PSCollection.SERV_ENGY).rollback}
                icon={Icons.EngIcon}
                onChange={option => inputHandleOnChange(option, PSCollection.SERV_ENGY)}
                placeholder='Servicio de luz'
                required
                undefinedOption='Sin especificar' />
            </div>
            <div className="box borderless">
              <h5 className="overset">Extras</h5>
              {/* People capacity */}
              {PSCollection.canHaveProperty(estate, PSCollection.PROP_PLCY) && <Inputbar
                defaultValue={propertyDefaultValue(PSCollection.PROP_PLCY)}
                disabled={!onEdit || onEdit.id !== 'charac' || disableUI}
                filters={[{ regExp: Global.REGEXP_FILTER_INTEGER }]}
                forceChangeRef={onEdit?.id === 'charac'
                  && onEdit.fields.find(r => r.id === PSCollection.PROP_PLCY).rollback}
                icon={Icons.PplCapIcon}
                inputMode='numeric'
                maxLength={3}
                onChange={input => inputHandleOnChange(input ? Number(input) : undefined, PSCollection.PROP_PLCY)}
                placeholder={{ default: 'Capacidad de habitantes' }} />}
              {/* Cistern */}
              {PSCollection.canHaveProperty(estate, PSCollection.PROP_CSTR) && <Inputbar
                defaultValue={propertyDefaultValue(PSCollection.PROP_CSTR)}
                disabled={!onEdit || onEdit.id !== 'charac' || disableUI}
                filters={[{ regExp: Global.REGEXP_FILTER_INTEGER }]}
                forceChangeRef={onEdit?.id === 'charac'
                  && onEdit.fields.find(r => r.id === PSCollection.PROP_CSTR).rollback}
                icon={Icons.CisternIcon}
                inputMode='numeric'
                maxLength={3}
                onChange={input => inputHandleOnChange(input ? Number(input) : undefined, PSCollection.PROP_CSTR)}
                placeholder={{ default: 'No. de cisternas' }} />}
              {/* Exterior */}
              {PSCollection.canHaveProperty(estate, PSCollection.PROP_PTIO) && <Inputbar
                defaultValue={propertyDefaultValue(PSCollection.PROP_PTIO)}
                disabled={!onEdit || onEdit.id !== 'charac' || disableUI}
                filters={[{ regExp: Global.REGEXP_FILTER_INTEGER }]}
                forceChangeRef={onEdit?.id === 'charac'
                  && onEdit.fields.find(r => r.id === PSCollection.PROP_PTIO).rollback}
                icon={Icons.PatioIcon}
                inputMode='numeric'
                maxLength={3}
                onChange={input => inputHandleOnChange(input ? Number(input) : undefined, PSCollection.PROP_PTIO)}
                placeholder={{ default: 'No. de exteriores' }}
                title='Número de patios o exteriores (sin contar cocheras).' />}
              {/* Pool */}
              {PSCollection.canHaveProperty(estate, PSCollection.PROP_POOL) && <Inputbar
                defaultValue={propertyDefaultValue(PSCollection.PROP_POOL)}
                disabled={!onEdit || onEdit.id !== 'charac' || disableUI}
                filters={[{ regExp: Global.REGEXP_FILTER_INTEGER }]}
                forceChangeRef={onEdit?.id === 'charac'
                  && onEdit.fields.find(r => r.id === PSCollection.PROP_POOL).rollback}
                icon={Icons.PoolIcon}
                inputMode='numeric'
                maxLength={3}
                onChange={input => inputHandleOnChange(input ? Number(input) : undefined, PSCollection.PROP_POOL)}
                placeholder={{ default: 'No. de piscinas' }}
                title='Número de piscinas o similares.' />}
              {/* Water tank */}
              {PSCollection.canHaveProperty(estate, PSCollection.PROP_WTNK) && <Inputbar
                defaultValue={propertyDefaultValue(PSCollection.PROP_WTNK)}
                disabled={!onEdit || onEdit.id !== 'charac' || disableUI}
                filters={[{ regExp: Global.REGEXP_FILTER_INTEGER }]}
                forceChangeRef={onEdit?.id === 'charac'
                  && onEdit.fields.find(r => r.id === PSCollection.PROP_WTNK).rollback}
                icon={Icons.WtrTankIcon}
                inputMode='numeric'
                maxLength={3}
                onChange={input => inputHandleOnChange(input ? Number(input) : undefined, PSCollection.PROP_WTNK)}
                placeholder={{ default: 'No. de tinacos' }} />}
              {/* Food service */}
              {PSCollection.canHaveService(estate, PSCollection.SERV_FOOD) && <Selectbar
                options={[{ displayValue: 'No', value: 0 }, { displayValue: 'Sí', value: 1 }]}
                defaultValue={serviceDefaultValue(PSCollection.SERV_FOOD)}
                disabled={!onEdit || onEdit.id !== 'charac' || disableUI}
                forceChangeRef={onEdit?.id === 'charac'
                  && onEdit.fields.find(r => r.id === PSCollection.SERV_FOOD).rollback}
                icon={Icons.FoodIcon}
                onChange={option => inputHandleOnChange(option, PSCollection.SERV_FOOD)}
                placeholder='Servicio de alimentos'
                undefinedOption='Sin especificar' />}
              {/* Gas service */}
              {PSCollection.canHaveService(estate, PSCollection.SERV_GAS) && <Selectbar
                options={[{ displayValue: 'No', value: 0 }, { displayValue: 'Sí', value: 1 }]}
                defaultValue={serviceDefaultValue(PSCollection.SERV_GAS)}
                disabled={!onEdit || onEdit.id !== 'charac' || disableUI}
                forceChangeRef={onEdit?.id === 'charac'
                  && onEdit.fields.find(r => r.id === PSCollection.SERV_GAS).rollback}
                icon={Icons.GasIcon}
                onChange={option => inputHandleOnChange(option, PSCollection.SERV_GAS)}
                placeholder='Servicio de gas'
                undefinedOption='Sin especificar' />}
              {/* Net service */}
              {PSCollection.canHaveService(estate, PSCollection.SERV_INET) && <Selectbar
                options={[{ displayValue: 'No', value: 0 }, { displayValue: 'Sí', value: 1 }]}
                defaultValue={serviceDefaultValue(PSCollection.SERV_INET)}
                disabled={!onEdit || onEdit.id !== 'charac' || disableUI}
                forceChangeRef={onEdit?.id === 'charac'
                  && onEdit.fields.find(r => r.id === PSCollection.SERV_INET).rollback}
                icon={Icons.NetIcon}
                onChange={option => inputHandleOnChange(option, PSCollection.SERV_INET)}
                placeholder='Servicio de internet y/o cable'
                undefinedOption='Sin especificar' />}
              {/* Housekeeping service */}
              {PSCollection.canHaveService(estate, PSCollection.SERV_HKPG) && <Selectbar
                options={[{ displayValue: 'No', value: 0 }, { displayValue: 'Sí', value: 1 }]}
                defaultValue={serviceDefaultValue(PSCollection.SERV_HKPG)}
                disabled={!onEdit || onEdit.id !== 'charac' || disableUI}
                forceChangeRef={onEdit?.id === 'charac'
                  && onEdit.fields.find(r => r.id === PSCollection.SERV_HKPG).rollback}
                icon={Icons.HkpIcon}
                onChange={option => inputHandleOnChange(option, PSCollection.SERV_HKPG)}
                placeholder='Servicio de limpieza'
                undefinedOption='Sin especificar' />}
            </div>
          </div>
          {/* Included services */}
          {estate.getSellMethod() === Estate.ON_LEASE && <div className="box borderless">
            {canInclServBeRendered(PSCollection.SERV_WTER) && <Selectbar
              defaultValue={Number(estate.getContract().getInclServs().includes(PSCollection.SERV_WTER))}
              disabled={!onEdit || onEdit.id !== 'charac' || disableUI}
              forceChangeRef={onEdit?.id === 'charac'
                && onEdit.fields.find(f => f.id === `incl_${PSCollection.SERV_WTER}`).rollback}
              icon={Icons.WtrIcon}
              onChange={option => inputHandleOnChange(option, `incl_${PSCollection.SERV_WTER}`)}
              options={[
                { displayValue: 'Lo paga el arrendatario', value: 0 },
                { displayValue: 'Incluido en el arrendamiento', value: 1 }]
              }
              placeholder='Pago del servicio de agua'
              required={onEdit?.id === 'charac'} />}
            {canInclServBeRendered(PSCollection.SERV_ENGY) && <Selectbar
              defaultValue={Number(estate.getContract().getInclServs().includes(PSCollection.SERV_ENGY))}
              disabled={!onEdit || onEdit.id !== 'charac' || disableUI}
              forceChangeRef={onEdit?.id === 'charac'
                && onEdit.fields.find(f => f.id === `incl_${PSCollection.SERV_ENGY}`).rollback}
              icon={Icons.EngIcon}
              onChange={option => inputHandleOnChange(option, `incl_${PSCollection.SERV_ENGY}`)}
              options={[
                { displayValue: 'Lo paga el arrendatario', value: 0 },
                { displayValue: 'Incluido en el arrendamiento', value: 1 }]
              }
              placeholder='Pago del servicio de luz'
              required={onEdit?.id === 'charac'} />}
            {canInclServBeRendered(PSCollection.SERV_FOOD) && <Selectbar
              defaultValue={Number(estate.getContract().getInclServs().includes(PSCollection.SERV_FOOD))}
              disabled={!onEdit || onEdit.id !== 'charac' || disableUI}
              forceChangeRef={onEdit?.id === 'charac'
                && onEdit.fields.find(f => f.id === `incl_${PSCollection.SERV_FOOD}`).rollback}
              icon={Icons.FoodIcon}
              onChange={option => inputHandleOnChange(option, `incl_${PSCollection.SERV_FOOD}`)}
              options={[
                { displayValue: 'Lo paga el arrendatario', value: 0 },
                { displayValue: 'Incluido en el arrendamiento', value: 1 }]
              }
              placeholder='Pago del servicio de alimentos'
              required={onEdit?.id === 'charac'} />}
            {canInclServBeRendered(PSCollection.SERV_GAS) && <Selectbar
              defaultValue={Number(estate.getContract().getInclServs().includes(PSCollection.SERV_GAS))}
              disabled={!onEdit || onEdit.id !== 'charac' || disableUI}
              forceChangeRef={onEdit?.id === 'charac'
                && onEdit.fields.find(f => f.id === `incl_${PSCollection.SERV_GAS}`).rollback}
              icon={Icons.GasIcon}
              onChange={option => inputHandleOnChange(option, `incl_${PSCollection.SERV_GAS}`)}
              options={[
                { displayValue: 'Lo paga el arrendatario', value: 0 },
                { displayValue: 'Incluido en el arrendamiento', value: 1 }]
              }
              placeholder='Pago del servicio de gas'
              required={onEdit?.id === 'charac'} />}
            {canInclServBeRendered(PSCollection.SERV_INET) && <Selectbar
              defaultValue={Number(estate.getContract().getInclServs().includes(PSCollection.SERV_INET))}
              disabled={!onEdit || onEdit.id !== 'charac' || disableUI}
              forceChangeRef={onEdit?.id === 'charac'
                && onEdit.fields.find(f => f.id === `incl_${PSCollection.SERV_INET}`).rollback}
              icon={Icons.NetIcon}
              onChange={option => inputHandleOnChange(option, `incl_${PSCollection.SERV_INET}`)}
              options={[
                { displayValue: 'Lo paga el arrendatario', value: 0 },
                { displayValue: 'Incluido en el arrendamiento', value: 1 }]
              }
              placeholder='Pago del servicio de internet'
              required={onEdit?.id === 'charac'} />}
            {canInclServBeRendered(PSCollection.SERV_HKPG) && <Selectbar
              defaultValue={Number(estate.getContract().getInclServs().includes(PSCollection.SERV_HKPG))}
              disabled={!onEdit || onEdit.id !== 'charac' || disableUI}
              forceChangeRef={onEdit?.id === 'charac'
                && onEdit.fields.find(f => f.id === `incl_${PSCollection.SERV_HKPG}`).rollback}
              icon={Icons.HkpIcon}
              onChange={option => inputHandleOnChange(option, `incl_${PSCollection.SERV_HKPG}`)}
              options={[
                { displayValue: 'Lo paga el arrendatario', value: 0 },
                { displayValue: 'Incluido en el arrendamiento', value: 1 }]
              }
              placeholder='Pago del servicio de limpieza'
              required={onEdit?.id === 'charac'} />}
          </div>}
        </div>}
        {/* Requirements */}
        {estate.getSellMethod() === Estate.ON_LEASE && <div className="box shadowless">
          <div className="flex-box">
            <div className="child jc-left">
              <h4 className="overset">Requisitos</h4>
            </div>
            <div className="child auto-width m3">
              <Button disabled={onEdit && (onEdit.id !== 'requirements' || disableSubmit)}
                empty
                icon={onEdit?.id === 'requirements' ? Icons.SaveIcon : Icons.EditIcon}
                isWaiting={onEdit?.id === 'requirements' && disableUI}
                onClick={() => editBtnHandleOnClick('requirements', { currVal: estate.getRequirements() }, nV => estate.setRequirements(nV))}
                reduced
                rounded
                typeRender={onEdit?.id === 'requirements' ? 'complete' : ''} />
              {onEdit && onEdit.id === 'requirements' && <Button disabled={disableUI}
                empty
                icon={Icons.CloseIcon}
                onClick={cancelBtnHandleOnClick}
                reduced
                rounded
                typeRender='error' />}
            </div>
          </div>
          <Inputbox disabled={!onEdit || onEdit.id !== 'requirements' || disableUI}
            filters={[{ regExp: Global.REGEXP_FILTER_FORBIDDEN_SYMBOLS }]}
            inputIcon={Icons.FilterIcon}
            inputPlaceholder={{ default: 'Requisito' }}
            inputMaxLength={30}
            inputMinLength={2}
            items={onEdit?.id === 'requirements'
              ? onEdit.fields.map(f => f.newValue)
              : estate.getRequirements()}
            maxItems={Estate.MAX_REQUIREMENTS}
            onAdd={inputboxHandleOnAdd}
            onBeforeAdd={input => input?.replace(/\s+/g, ' ')}
            onDelete={inputboxHandleOnDelete}
            requestFocus={onEdit?.id === 'requirements'}
            showInputbar />
        </div>}
        {/* Deletion */}
        <div className="flex-box jc-right m3">
          <div className="child auto-width">
            <Hintbox icon={Icons.WarningIcon} type='warning'
              message='La eliminación de una propiedad es irreversible. Tener precaución con esta acción' />
          </div>
          <div className="child auto-width">
            <Button typeRender='error'
              empty
              borderless
              icon={Icons.DeleteIcon}
              onClick={deleteBtnHandleOnClick}
              value='Borrar' />
          </div>
        </div>
      </div>
      {/* Dialog (delete, end bidding, remove contact info request) */}
      {dialog !== undefined && <Dialog action={dialog.action}
        confirmBtn={dialog.confirmBtn}
        id={dialog.id ?? 'dialog-priv-estate-popup'}
        message={dialog.message}
        onHide={() => setDialog()}
        onReject={dialog.onReject}
        onResolve={dialog.onResolve}
        rejectBtn={dialog.rejectBtn}
        renderButtonsEmpty
        renderButtonsRounded
        renderButtonsSwitched={dialog.renderButtonsSwitched} />}
      {/* Create estate popup */}
      {Boolean(showPopup?.mode === 0) && <CreateEstate complex={estate}
        contactInfo={showPopup.props.contactInfo}
        enableBidding={showPopup.props.enableBidding}
        onHide={() => setShowPopup()}
        onResolve={() => {
          estate.setFreeSpaces(estate.getFreeSpaces() - 1);
          setFetchings();
        }} prefixes={showPopup.props.prefixes}
        today={today} />}
      {/* Signing process popup */}
      {Boolean(showPopup?.mode === 1) && <SignProcess estate={estate}
        onHide={() => setShowPopup()}
        onResolve={statusChanged => {
          if (statusChanged) estate.setStatus(Estate.STATUS_INA);

          estate.setFreeSpaces(estate.getFreeSpaces() - 1);
          setFetchings();
        }} ownerMode={true}
        today={today} />}
      {/* Start bidding popup */}
      {Boolean(showPopup?.mode === 2) && <StartBidding estate={estate}
        onHide={() => setShowPopup()}
        onResolve={bidding => {
          estate.setBidding(bidding);
          estate.setStatus(Estate.STATUS_ACTIVE);
          setEstate(new Estate(estate));
          setFetchBidding(bidding);
        }} />}
    </div>
  );
}

export default PrivEstate;