import { useContext, useRef } from 'react';
import { globalContext } from '../../context/GlobalContext';
import Button from '../../components/Button';
import Contract from '../../objects/Contract';
import Estate from '../../objects/Estate';
import Global from '../../objects/Global';
import ImageViewer from '../../components/ImageViewer';
import List from '../../components/List';
import PetAssistant from '../../components/PetAssistant';
import SearchItem from '../../components/SearchItem';
import Switch from '../../components/Switch';
import User from '../../objects/User';
import UserCard from '../../components/UserCard';
import { BathIcon, BedrIcon, BiddIcon, BuilIcon, CisternIcon, EngIcon, FoodIcon, GasIcon, HkpIcon, InsuIcon, KitchIcon, LocIcon, LvelIcon, NetIcon, ParkIcon, PatioIcon, PoolIcon, PublIcon, SizeIcon, WtrIcon, WtrTankIcon } from '../../assets/images';
import PSCollection from '../../objects/PSCollection';

/** Renders a Step7 compo for CreateEstate.
 * @param {Object} props The props object.
 * @param {Estate} [props.complex] A complex instance.
 * @param {Estate} props.estate An estate instance.
 * @param {boolean} props.isWaiting A boolean used to disable de UI.
 * @param {() => void} props.onSubmit A callback function that will be called when submit
 * button is performed.
 */
const Step7 = props => {
  // *** useContext ***
  const { currSession, currSessionPicture } = useContext(globalContext);
  // *** useRef ***
  const user = useRef(new User({ username: currSession.username }));

  const getBasicElements = () => {
    /** @type {import('../../components/List').ListElementObject[]} */
    const elements = [];

    for (const prop of props.estate.getProperties()) {
      let text = prop.value, icon, ignore = false;

      switch (prop.id) {
        case PSCollection.PROP_BTHR: {
          icon = BathIcon;
          text = `No. de baños: ${text}`;
          break;
        } case PSCollection.PROP_KTCH: {
          icon = KitchIcon;
          text = `No. de cocinas: ${text}`;
          break;
        } case PSCollection.PROP_PARK: {
          icon = ParkIcon;
          text = Estate.getParkingType(props.estate) === 0
            ? 'Estacionamiento: Sí'
            : `No. de garajes o espacios para estacionar: ${text}`;
          break;
        } case PSCollection.PROP_BEDR: {
          icon = BedrIcon;
          text = `No. de habitaciones: ${text}`;
          break;
        } case PSCollection.PROP_LVEL: {
          icon = LvelIcon;
          text = `No. de niveles: ${text}`;
          break;
        } default: ignore = true;
      }

      if (!ignore) elements.push({ icon, text });
    }

    return elements;
  }

  const getContractInfo = () => {
    /** @type {import('../../components/List').ListElementObject[]} */
    const elements = [];
    const auxCont = props.estate.getContract();

    const getChargeTypePlaceholder = () => {
      switch (auxCont.getChargeType()) {
        case Contract.CHARGE_CUSTOM: return `1er pago + ${Global.formatNumber(auxCont.getCharge())}`;
        case Contract.CHARGE_SAME_AS_PAY: return `igual que la renta (1er pago doble)`;
        default: return 'no requerido'
      }
    }

    const getPayFreqPlaceholder = () => {
      switch (auxCont.getPayFrequency()) {
        case Contract.PAY_FREQ_DAILY: return 'diario';
        case Contract.PAY_FREQ_MONTHLY: return 'mensual';
        case Contract.PAY_FREQ_UNIQUE: return 'único';
        case Contract.PAY_FREQ_YEARLY: return 'anual';
        default: return undefined;
      }
    }

    // Price.
    elements.push({
      text: `Precio: $ ${Global.formatNumber(auxCont.getPayAmount())} MXN`
    });
    // Duration.
    elements.push({
      text: `Duración: ${Estate.contractToString(props.estate)?.toLowerCase()}`
    });
    // Pay frequency.
    elements.push({
      text: `Frecuencia de cobro: ${getPayFreqPlaceholder()}`
    });
    // Extra charge.
    elements.push({
      text: `Cargo extra: ${getChargeTypePlaceholder()}`
    })

    return elements;
  }

  const getExtraElements = () => {
    /** @type {import('../../components/List').ListElementObject[]} */
    const elements = [];

    for (const prop of props.estate.getProperties()) {
      let text = prop.value, icon, ignore = false;

      switch (prop.id) {
        case PSCollection.PROP_CSTR: {
          icon = CisternIcon;
          text = `No. de cisternas: ${text}`;
          break;
        } case PSCollection.PROP_PTIO: {
          icon = PatioIcon;
          text = `No. de exteriores: ${text}`;
          break;
        } case PSCollection.PROP_POOL: {
          icon = PoolIcon;
          text = `No. de picsinas: ${text}`;
          break;
        } case PSCollection.PROP_WTNK: {
          icon = WtrTankIcon;
          text = `No. de tinacos: ${text}`;
          break;
        } default: ignore = true;
      }

      if (!ignore) elements.push({ icon, text });
    }

    return elements;
  }

  const getTerrainElements = () => {
    /** @type {import('../../components/List').ListElementObject[]} */
    const elements = [];
    // Terrain size.
    elements.push({
      icon: SizeIcon,
      text: `Dimensiones: 
      ${props.estate.getSquaredSize()} mts2 (
        ${props.estate.getSize().x} mts x 
        ${props.estate.getSize().y} mts )`
    });
    // Build date.
    elements.push({
      icon: BuilIcon,
      text: `Fecha de adquisición o construcción: ${Global.parseDateUTC(props.estate.getBuildDate())}`
    });
    // Assurance.
    elements.push({
      icon: InsuIcon,
      text: `Aseguradora: ${props.estate.getInsurance() || 'ninguna'}`
    });

    return elements;
  }

  const getServiceElements = () => {
    /** @type {import('../../components/List').ListElementObject[]} */
    const elements = [];

    for (const serv of props.estate.getServices()) {
      let text = serv.value ? 'Sí' : 'No', icon;

      if (props.estate.getSellMethod() === Estate.ON_LEASE && serv.value) {
        if (props.estate.getContract().getInclServs().find(s => s === serv.id))
          text += ' (incluido)';
        else
          text += ' (no incluido)';
      }

      switch (serv.id) {
        case PSCollection.SERV_WTER: {
          icon = WtrIcon;
          text = `Agua y alcantarillado: ${text}`;
          break;
        } case PSCollection.SERV_ENGY: {
          icon = EngIcon
          text = `Agua y alcantarillado: ${text}`;
          break;
        } case PSCollection.SERV_FOOD: {
          icon = FoodIcon
          text = `Alimentos: ${text}`;
          break;
        } case PSCollection.SERV_GAS: {
          icon = GasIcon
          text = `Gas: ${text}`;
          break;
        } case PSCollection.SERV_INET: {
          icon = NetIcon
          text = `Internet: ${text}`;
          break;
        } case PSCollection.SERV_HKPG: {
          icon = HkpIcon
          text = `Limpieza: ${text}`;
          break;
        } default: {
          text = `Elemento inválido (${serv.id}): ${text}`;
        }
      }

      elements.push({ icon, text });
    }

    return elements;
  }

  const getPillClass = () => {
    let className = 'pill';

    switch (props.estate.getSellMethod()) {
      case Estate.ON_LEASE: {
        className += ' lease';
        break;
      } case Estate.ON_SALE: {
        className += ' sale';
        break;
      } case Estate.ON_COMPLEX: {
        className += ' complex';
        break;
      } default: {
        className += ' template';
      }
    }

    return className;
  }

  return (
    <div className="popup-content create-estate-content step-6" id='step-6'>
      <PetAssistant pet='kevin' animOnDwarf
        message={'Hecho. Aquí tienes un resumen de la publicación. Si quieres, puedes'
          + ' regresar atrás para realizar cambios. Si has terminado, pulsa el botón '
          + ' "Terminar" que está en la parte inferior. Yo me encargaré del resto. :3'} />
      <div className="box box-search-item">
        <h5 className="overset">Así lo verán otras personas en los resultados de búsqueda.</h5>
        <SearchItem estate={props.estate} />
      </div>
      <div className="box">
        <h5 className="overset">Y así cuando entren a tu publicación...</h5>
        {/* Header */}
        <ImageViewer images={props.estate.getImages()} showImagesList={true} />
        <div className="flex-box wrap">
          <div className="child jc-left">
            <h3 className='title'>{props.estate.getTitle()}</h3>
          </div>
          <div className="child auto-width">
            <span className={getPillClass()} />
          </div>
          {props.estate.getBidding().status && <div className="child auto-width">
            <img className='icon' src={BiddIcon} alt="bidding" title='Esta propiedad está en subasta' />
          </div>}
        </div>
        {props.estate.getSellMethod() !== Estate.ON_COMPLEX && <h2 className="highlight">
          $ {Global.formatNumber(props.estate.getContract().getPayAmount())} MXN
        </h2>}
        {Estate.contractToString(props.estate) && <h6>{Estate.contractToString(props.estate)}</h6>}
        <div className="flex-box m3">
          <div className="child auto-width">
            <h5 className="overset">De</h5>
          </div>
          <div className="child jc-left">
            <UserCard user={user.current} mini defaultPicture={currSessionPicture} />
          </div>
        </div>
        {/* Location */}
        <div className="box borderless">
          <div className="flex-box m3">
            <div className="child auto-width">
              <img className='icon' src={LocIcon} alt="" />
            </div>
            <div className="child jc-left">
              <h5 className="overset location">En {
                `${props.estate.getLocation().getCity()}, ` +
                `${props.estate.getLocation().getState()}, ` +
                `${props.estate.getLocation().getCountry()}`
              }</h5>
            </div>
          </div>
        </div>
        {/* Bidding */}
        {props.estate.getBidding().getStatus() && <div className="box borderless">
          <h4 className="overset">Info. de la subasta</h4>
          <List elements={[
            { text: `Fecha de finalización: ${Global.parseDateUTC(props.estate.getBidding().getEndDate())}` },
            { text: `Puja mínima: $ ${Global.formatNumber(props.estate.getBidding().getMinimumBid())} MXN` }
          ]} />
        </div>}
        {/* Description */}
        <div className="box borderless">
          <h4 className="overset">Descripción de la propiedad.</h4>
          <p className='description'>{props.estate.getDescription()}</p>
        </div>
        {/* Contract info */}
        {props.estate.getSellMethod() === Estate.ON_LEASE && <div className="box borderless details">
          <h4 className="overset">Info. preliminar del contrato</h4>
          <List elements={getContractInfo()} />
        </div>}
        {/* Requirements */}
        {props.estate.getSellMethod() === Estate.ON_LEASE && props.estate.getRequirements().length > 0 &&
          <div className="box borderless">
            <h4 className="overset">Requisitos para el arrendatario.</h4>
            <List inlineItems elements={props.estate.getRequirements().map(req => { return { text: req } })} />
          </div>}
        {/* Properties */}
        <div className="box borderless">
          <h4 className="overset">Infraestructura.</h4>
          {/* Terrain */}
          <List elements={getTerrainElements()} title='Del terreno' />
          {/* Basics */}
          {props.estate.getSellMethod() !== Estate.ON_COMPLEX && <List elements={getBasicElements()}
            title='Básico' />}
          {/* Extras */}
          {props.estate.getSellMethod() !== Estate.ON_COMPLEX && <List elements={getExtraElements()}
            title='Extras' />}
          {/* Services */}
          {props.estate.getSellMethod() !== Estate.ON_COMPLEX && <List elements={getServiceElements()}
            title='Servicios' />}
        </div>
      </div>
      <div className="flex-box jc-spacebtw">
        {!props.estate.getBidding().getStatus() && props.estate.getSellMethod() !== Estate.ON_COMPLEX &&
          <div className="child">
            <Switch defaultValue={props.estate.getStatus() === Estate.STATUS_ACT}
              disabled={props.isWaiting}
              onChange={state => props.estate.setStatus(!state ? Estate.STATUS_INA : Estate.STATUS_ACT)}
              placeholder={{ default: 'Solo guardar', onChecked: 'Guardar y publicar' }} />
          </div>
        }
        <div className="child">
          <Button animated
            isWaiting={props.isWaiting}
            icon={PublIcon}
            onClick={props.onSubmit}
            onWaitValue='Finalizando...'
            value='Terminar' />
        </div>
      </div>
    </div>
  );
}

export default Step7;