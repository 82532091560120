import '../styles/loading-screen.css'
import { useEffect, useRef } from 'react';
import Global from '../objects/Global';
import UIRender from '../objects/UIRender';

const LoadingScreen = () => {
  const figClass = useRef(Global.getRandom(['f1', 'f2', 'f3']));
  const wrapper = useRef();

  useEffect(() => {
    const options = { container: true, footer: true, navbar: true };
    const parent = wrapper.current?.parentNode;

    UIRender.blurFocus();
    UIRender.disableGlobalScroll("loading-screen");
    UIRender.disableSiblings(wrapper.current, options); // Disable siblings.

    return () => {
      UIRender.enableGlobalScroll('loading-screen');
      UIRender.enableChilds(parent, options, 'loading-screen');
    }
  }, []);

  return (
    <div ref={wrapper} className="loading-screen popup-wrapper" id='loading-screen'>
      <div className="content">
        <span className={`animated-figure ${figClass.current}`} />
      </div>
    </div>
  );
}

export default LoadingScreen;