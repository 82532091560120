import './styles/loading-block.css';
import { useRef } from 'react';
import Global from "../objects/Global";

/** Renders a LoadingBlock compo.
 * @param {Object} props The props object.
 * @param {boolean} [props.noBackground] If true, background will be transparent.
 * @param {boolean} [props.miniFigure] If true, loading figure will be small.
 * @param {'relative'} [props.position] Set position. Undefined if you want the compo to not be relative
 */
const LoadingBlock = props => {
  const animFig = useRef(Global.getRandom(['f1', 'f2', 'f3']));

  const getClassName = () => {
    let clss = 'loading-block';

    if (props.noBackground) clss += ' no-background';
    if (props.position) clss += ` ${props.position}`;

    return clss;
  }

  return (
    <div className={getClassName()}>
      <span className={`animated-figure gray ${animFig.current}${props.miniFigure ? ' mini' : ''}`} />
    </div>
  );
}

export default LoadingBlock;