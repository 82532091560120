import './styles/foot.css';

const Foot = () => {
  return (
    <footer className="foot">
      {/* Revisar href... */}
      <a className="foot-item" href="/">Política de privacidad</a>
      <a className="foot-item" href="/">Términos y condiciones</a>
    </footer>
  );
}

export default Foot;