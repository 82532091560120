import Generic from "./Generic";
import User from "./User";

class Rate extends Generic {
  /** @param {Rate} [obj] */
  constructor(obj) {
    super(obj);

    const { concept, count, evaluator, opinion, value } = obj || {};

    this.setConcept(concept);
    this.setCount(count);
    this.setEvaluator(new User(evaluator));
    this.setOpinion(opinion);
    this.setValue(value);
  }

  /** Obtains the concept or title of the rate. */
  getConcept() {
    return this.concept;
  }

  /** Assings a concept or title for the rate.
   * @param {string} [concept] 
   */
  setConcept(concept) {
    this.concept = concept;
  }

  /** Obtains the count of rates used to calculate value (from database) */
  getCount() {
    return this.count;
  }

  /** Assings a count of rates used to calculate value (from database). Assing this
   * attribute if this instance will not be used as a single rate.
   * @param {number} [count] 
   */
  setCount(count) {
    this.count = count === null || isNaN(count) || count <= 0
      ? undefined
      : Number(count);
  }

  /** Obtains the evaluator of the rate */
  getEvaluator() {
    return this.evaluator;
  }

  /** Assings an evaluator to the rate.
   * @param {User} [evaluator] 
   */
  setEvaluator(evaluator) {
    this.evaluator = evaluator;
  }

  /** Obtains the opinion of rater. */
  getOpinion() {
    return this.opinion;
  }

  /** Assings a opinion made by rater.
   * @param {string} [opinion] 
   */
  setOpinion(opinion) {
    this.opinion = opinion;
  }

  /** Obtains the rate (from 1 up to 5) assigned */
  getValue() {
    return this.value;
  }

  /** Obtains the rate (from 1 up to 5) assigned. 
   * @param {number} [value] If undefined, less than 1 or greater than 5, value
   * will be set to undefined.
   */
  setValue(value) {
    this.value = value >= 1 && value <= 5
      ? Number(Number(value).toFixed(2))
      : undefined;
  }
}

export default Rate;