import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import * as Icons from "../../assets/images";
import Button from "../../components/Button";
import FileManager from "../../components/FileManager";
import Global from "../../objects/Global";
import List from "../../components/List"
import PetAssistant from "../../components/PetAssistant";
import UserCard from "../../components/UserCard";
import PropDisplay from "../../components/PropDisplay";
import Contract from "../../objects/Contract";
import Hintbox from "../../components/Hintbox";
import DAOServ from "../../objects/DAOServ.js";
import User from "../../objects/User";
import { globalContext } from "../../context/GlobalContext";
import ErrHandler from "../../objects/ErrHandler";
import PSCollection from "../../objects/PSCollection.js";

/** Props Type Definition.
 * @typedef {Object} Props The props definition.
 * @property {Contract} contract The contract object. It's agreement attribute must be defined.
 * @property {boolean} disableUI If true, UI will be disabled.
 * @property {import('../../objects/Estate.js').default} estate The estate object.
 * @property {import('../SignProcess.jsx').GuestObject[]} guests The guests array.
 * @property {() => void} onNextPhase A callback function that will be trigger to go to the next phase.
 * @property {(statusChanged?: boolean) => void} onResolve A callback function that will be called when
 * a new contract is created (pushed to blockchain).
 * @property {boolean} [ownerMode] If true. phase 1 will be rendered for the owner.
 * @property {React.LegacyRef<HTMLDivElement>} ref A reference for the root compo.
 * @property {(e: boolean) => void} setDisableUI A callback function from parent to  disable UI.
 * @property {(o: import('../../components/popups/Dialog').DialogPropsObject) => void} setShowDialog 
 * A callback function to show dialog.
 * @property {(b: boolean) => void} [sign] A callback function used when ownerMode is false.
 * When the guest want to sign or reject the contract, calls this function sending a boolean
 * value (true to sign the contract, false to reject it).
 * @property {boolean} [signature] The guest signature (not ownerMode). If true, guest has
 * signed the contract. If false, guest has rejected the contract. If undefined, guest is reviewing the contract.
 * @property {import('../../objects/ClientSocket.js').default} socket Current ClientSocket.
 * @property {(hash: string) => void} updateHash A callback function that will be called to update the hash
 * from SignProcess (only called when ownerMode = true).
 */

/** Renders a Phase3 compo for SignProcess component.
 * @type {React.ForwardRefRenderFunction<?, Props>}
 */
const Phase3 = forwardRef((props, ref) => {
  // *** useContext ***
  const { currSession, currSessionPicture, pushMessageHint, timezoneOffset } = useContext(globalContext);
  // *** useRef ***
  const animFig = useRef(Global.getRandom(['f1', 'f2', 'f3']));
  const compoId = useRef('phase-3');
  // *** useState ***
  const [disableBackBtn, setDisableBackBtn] = useState(true);
  const [disableSignBtn, setDisableSignBtn] = useState(true);

  /** @type {React.MouseEventHandler<Element>} */
  const signBtnHandleOnClick = () => {
    const extrChrg = props.contract.getChargeType() !== 0 ? ' más el cargo extra' : '';

    props.setShowDialog({
      confirmBtn: { icon: Icons.SignIcon, type: 'complete', value: 'Aceptar y firmar' },
      id: `${compoId.current}-dialog-popup`,
      message: `Una vez que el arrendador haya firmado, podrás pagar el primer periodo ${extrChrg}.`
        + ' No te desconectes de la sala hasta que el contrato haya sido creado.',
      onResolve: () => {
        props.setDisableUI(true);
        props.socket.sendMessage('SIGNATURE_RESOLVE');
      }, rejectBtn: { type: 'error', value: 'Cancelar' }
    });
  }

  const rejectBtnHandleOnClick = () => {
    props.setShowDialog({
      confirmBtn: { icon: Icons.BanIcon, type: 'error', value: 'Rechazar contrato' },
      id: `${compoId.current}-dialog-popup`,
      message: 'El arrendador deberá regresar un paso atrás para modificarlo y'
        + ' reenviarlo para que puedas revisarlo nuevamente.',
      onResolve: () => {
        props.setDisableUI(true);
        props.socket.sendMessage('SIGNATURE_REJECT');
      }, rejectBtn: { value: 'Cancelar' },
      renderButtonsSwitched: true
    });
  }

  const returnBtnHandleOnClick = () => {
    props.setShowDialog({
      confirmBtn: { type: 'error', value: 'Regresar' },
      id: `${compoId.current}-dialog-popup`,
      message: 'Vas a regresar al paso anterior. Los invitados también serán regresados al'
        + ' paso anterior y su firma será descartada.',
      onResolve: () => {
        props.setDisableUI(true);
        props.socket.sendMessage('GO_BACK');
      }, rejectBtn: { value: 'Permanecer aquí' },
      renderButtonsSwitched: true,
    });
  }

  const createContractBtnHandleOnClick = () => {
    const lessors = props.guests.length > 1 ? 'Uno de los arrendatarios' : 'El arrendatario'
    const extrChrg = props.contract.getChargeType() !== 0 ? ' más el cargo extra' : '';

    props.setShowDialog({
      action: () => DAOServ.post('create_agreement', { tst: currSession.tst }, 'JSON'),
      confirmBtn: { icon: Icons.SignIcon, type: 'complete', value: 'Firmar y crear' },
      id: `${compoId.current}-dialog-popup`,
      message: `El contrato entrará en vigor. ${lessors} deberá pagar el primer periodo ${extrChrg}`
        + ' antes o el mismo día del inicio de contrato.',
      onResolve: data => {
        props.updateHash(data['hash']);
        props.onNextPhase();
        props.onResolve(data['statusChanged']);
      }, onReject: err => {
        if (err) pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' });
      }, rejectBtn: { type: 'error', value: 'Cancelar' }
    });
  }

  const getSignatureStatus = () => {
    if (props.ownerMode) {
      const singleGuest = props.guests.length === 1;

      if (props.guests.find(guest => guest.signature === false)) // A guest rejected the agreement
        return `${singleGuest ? 'El invitado ha' : 'Uno o más invitados han'} rechazado el contrato.`;
      else if (props.guests.find(guest => guest.signature === undefined)) // A guest is reviewing the agreement.
        return `Esperando a que ${singleGuest ? 'el invitado firme' : 'los invitados firmen'} el contrato.`;
      else // Guests have signed the agreement.
        return `${singleGuest ? 'El invitado ha' : 'Los invitados han'} firmado el contrato.`;
    } else {
      if (props.guests.length === 1) {
        if (props.signature === true)
          return 'Has aceptado y firmado el contrato. Esperando acción del anfitrión...';
        else
          return 'Has rechazado el contrato. Esperando acción del anfitrión...';
      }

      let guestsSignature;

      for (const guest of props.guests) {
        switch (guest.si) {
          case undefined: {
            guestsSignature = 0;
            break;
          } case true: {
            guestsSignature = 1;
            break;
          } default: guestsSignature = 2;
        }

        if (guestsSignature !== 1) break;
      }

      switch (guestsSignature) {
        case 0: return 'Esperando a que los demás integrantes revisen el contrato...';
        case 1: return 'Todos han firmado el contrato. Esperando acción del anfitrión...';
        default: return 'Alguien ha rechazado el contrato. Esperando acción del anfitrión...';
      }
    }
  }

  /** Used to render guests list (only when owner mode is false) */
  const renderGuestsList = () => {
    let start = !props.ownerMode;

    return props.guests.map(guest => {
      const isHost = start;
      start = false;

      return (<UserCard
        animFig={!isHost && guest.signature === undefined}
        key={guest.data.getUsername()}
        icon={isHost ? Icons.CrownIcon : guest.signature === false ? Icons.CloseIcon : Icons.CheckIcon}
        iconFilter={isHost ? undefined : guest.signature ? 'complete' : 'error'}
        type={isHost ? "gold" : guest.signature === false
          ? 'error'
          : guest.signature === true
            ? 'complete'
            : ''
        }
        user={guest.data}
      />);
    });
  }

  useEffect(() => {
    // After 5 seconds, enables back button.
    setTimeout(() => setDisableBackBtn(false), props.ownerMode ? 5000 : 8000);
  }, [props.ownerMode]);

  useEffect(() => {
    if (props.ownerMode) setDisableSignBtn(props.guests.find(g => !g.signature));
  }, [props.ownerMode, props.guests]);

  // For host.
  if (props.ownerMode || props.signature !== undefined) return (
    <div className="popup-content init" id={compoId.current} ref={ref}>
      <div className="phase-container">
        <div className="box estate-container">
          <div className="img-container">
            <img src={props.estate.getImages()[0].getURLData()} alt="cover" />
          </div>
          <div className="info-container">
            <h5 className="highlight estate-title">{props.estate.getTitle()}</h5>
            <div className="flex-box">
              {props.guests.find(guest => guest.signature === undefined) && <div className="child auto-width">
                <span className={`animated-figure ${animFig.current} mini gray`} />
              </div>}
              <div className="child m3 jc-left text-wrap">
                <h6 className="overset">{getSignatureStatus()}</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="guests-container">
          <h4>{props.ownerMode ? 'Invitados' : 'Integrantes de la sala'}</h4>
          <div className="items-list">
            <UserCard
              displayName={'Tú'}
              defaultPicture={currSessionPicture}
              type="self"
              user={new User({ username: currSession.username })} />
            {renderGuestsList()}
          </div>
        </div>
        {props.ownerMode && <div className="flex-box button-box">
          <div className="child jc-right m3">
            <Button disabled={props.disableUI}
              empty
              id="go-back"
              icon={Icons.ArrowIcon}
              isWaiting={disableBackBtn}
              onClick={returnBtnHandleOnClick}
              onWaitValue="Regresar"
              rotation={180}
              typeRender="error"
              value="Regresar" />
          </div>
          <div className="child jc-left m3">
            <Button disabled={props.disableUI || disableSignBtn || props.guests.length === 0}
              empty
              id="sign-and-close"
              icon={Icons.SignIcon}
              onClick={createContractBtnHandleOnClick}
              typeRender="complete"
              value="Firmar y terminar" />
          </div>
        </div>}
      </div>
    </div>
  );
  // For guests.
  else return (
    <div className="popup-content init" id='phase-3' ref={ref}>
      <div className="phase-container">
        <PetAssistant animOnDwarf pet="vicky"
          message={"Revisa el contrato. Cada una de las características debe estar tal cual como fueron"
            + " acordadas. También puedes descargar los archivos anexos y revisarlos. Si algo no cuadra o"
            + " no está como se acordó, puedes rechazar el contrato haciendo clic en el botón rojo inferior."
            + " Si todo está bien, haz clic en el botón verde inferior."} />
        <div className="box borderless">
          <h4 className="highlight title">Información de pagos</h4>
          {props.contract.getAgreement().getId() && <div className="flex-box">
            <div className="child box">
              <PropDisplay propertyClass="gray"
                largeFont
                header="Marca del contrato"
                property={props.contract.getAgreement().getId()} />
            </div>
            <div className="child">
              <Hintbox icon={Icons.InfoIcon}
                message={'Podrás identificar tu contrato con esta marca fácilmente.'} />
            </div>
          </div>}
          <div className="flex-box wrap">
            <div className="child box borderless">
              <PropDisplay
                propertyClass="gray"
                header="Pago por periodo"
                property={`$ ${Global.formatNumber(props.contract.getPayAmount())} MXN`} />
            </div>
            <div className="child box borderless">
              <PropDisplay
                propertyClass="gray"
                header="Frecuencia"
                property={props.contract.getTermMethod() === Contract.TERM_METHOD_DAY
                  ? 'Diario'
                  : props.contract.getTermMethod() === Contract.TERM_METHOD_MONTH
                    ? 'Mensual'
                    : 'Anual'} />
            </div>
          </div>
          <div className="flex-box wrap">
            <div className="child box borderless">
              <PropDisplay
                propertyClass="gray"
                header="Duración del contrato"
                property={`${props.contract.getTerm()} ${props.contract.getTermMethod() === Contract.TERM_METHOD_DAY
                  ? 'día(s)'
                  : props.contract.getTermMethod() === Contract.TERM_METHOD_MONTH
                    ? 'mes(es)'
                    : 'año(s)'
                  }`} />
            </div>
            <div className="child box borderless">
              <PropDisplay
                propertyClass="gray"
                header="Fecha de inicio"
                property={Global
                  .transformDate(Global
                    .transformDateForInput(props.contract.getAgreement().getStartDate() + timezoneOffset))} />
            </div>
          </div>
          <div className="flex-box wrap">
            <div className="child box borderless">
              <PropDisplay
                propertyClass="gray"
                header="Cargo en el primer pago"
                property={props.contract.getChargeType() === Contract.CHARGE_SAME_AS_PAY
                  ? 'Igual que el pago'
                  : props.contract.getChargeType() === Contract.CHARGE_CUSTOM
                    ? `$ ${Global.formatNumber(props.contract.getCharge())} MXN`
                    : 'No requerido'
                } />
            </div>
            <div className="child box dir-column">
              <PropDisplay
                largeFont
                header="Primer pago"
                property={`$ ${Global.formatNumber(props.contract.getChargeType() === 0
                  ? props.contract.getPayAmount()
                  : props.contract.getChargeType() === Contract.CHARGE_CUSTOM
                    ? props.contract.getPayAmount() + props.contract.getCharge()
                    : props.contract.getPayAmount() * 2)} MXN`} />
              {props.contract.getChargeType() !== 0 && <Hintbox icon={Icons.WarningIcon}
                message={'Esta es la cantidad del primer pago, luego pagarás '
                  + `$${Global.formatNumber(props.contract.getPayAmount())} MXN en los demás pagos.`
                  + ' Asegúrate de pagarlo durante o antes del inicio del contrato.'}
                type="warning" />}
            </div>
          </div>
        </div>
        {/* Services */}
        <div className="box borderless">
          <h4 className="highlight title">Servicios</h4>
          <List elements={props.estate.getServices().map(s => {
            return { icon: PSCollection.getServiceIcon(s.id), text: PSCollection.getServiceName(s.id) }
          })} title="Todos los servicios" />
          <List elements={props.contract.getInclServs().map(s => {
            return { icon: PSCollection.getServiceIcon(s), text: PSCollection.getServiceName(s) }
          })} title="Servicios incluidos en el arrendamiento"
            emptyMessage="No hay servicios incluidos" />
          <Hintbox icon={Icons.WarningIcon}
            message="Los servicios que no aparezcan aquí deberás pagarlos aparte."
            type="warning" />
        </div>
        <FileManager
          allowDownload
          disabled={props.disableUI}
          files={props.contract.getAgreement().getFiles()} />
      </div>
      <div className="bottom-bar">
        <div className="flex-box">
          <div className="child jc-right m3">
            <Button empty
              icon={Icons.SignIcon}
              isWaiting={disableBackBtn}
              onClick={signBtnHandleOnClick}
              onWaitValue="Aceptar y firmar"
              typeRender="complete"
              value="Aceptar y firmar" />
          </div>
          <div className="child jc-left m3">
            <Button empty
              isWaiting={disableBackBtn}
              icon={Icons.CloseIcon}
              onClick={rejectBtnHandleOnClick}
              onWaitValue="Rechazar"
              typeRender="error"
              value="Rechazar" />
          </div>
        </div>
      </div>
    </div>
  );
})

export default Phase3;