import './styles/biddingbox.css'
import { useContext } from 'react';
import { globalContext } from '../context/GlobalContext';
import Global from '../objects/Global';
import Hintbox from "./Hintbox";
import NotFoundBox from './NotFoundBox';
import UserCard from './UserCard';
import ContactInfoCard from './ContactInfoCard';
import * as Icons from '../assets/images';
import Button from './Button';
import PropDisplay from './PropDisplay';

/** EndTimeObject typedef
 * @typedef {Object} EndTimeObject
 * @property {1|2|3} type Defines the time for the end time to be rendered. If 1, end time will be
 * displayed in a date. If 2, time will be displayed in remaining days or months. If 3, end time will
 * be displayed in both ways. If any other value, nothing will be rendered.
 * @property {number} today The current day in milliseconds. If undefined, nothing will be rendered.
 */

/** OfferButtonObject typedef
 * @typedef {Object} OfferButtonObject
 * @property {boolean} [disabled] Disables the button.
 * @property {*} [icon] An icon for the button. BidIcon is the default.
 * @property {() => void} onClick A callback function that will be triggered when pushed. Must
 * be defined.
 * @property {string} [value] The value to be displayed. 'Ofertar' is the default.
 */

/** Renders a BiddingBox compo.
 * @param {Object} props The props object.
 * @param {import('../objects/Bidding').default} props.bidding A bidding object. If undefined, compo
 * will render on wait state. If bidding is finalized and bidding belongs to currentSession, min bid
 * won't be displayed and compo will try to fetch winner contact info in a ContactInfoCard compo.
 * @param {EndTimeObject} [props.endTimeOptions] Defines the options for the end time to be
 * displayed. If undefined, end time won't be displayed.
 * @param {OfferButtonObject} [props.offerBtn] The offer button. If undefined, button won't be
 * displayed. If bidding is inactive, button will be disabled by default.
 * @param {(bidOnDelete: import('../objects/Bid').default) => void} [props.onDeleteWinner] A callback
 * function that will be triggered when delete winner's contact info button is pushed. If undefined,
 * winner's contact info cannot be deleted. Won't have any effect if bidding does not belong to
 * currSession or bidding is active.
 * @param {string} [props.title] A title for the compo. Set to undefined to omit this attribute.
 */
const BiddingBox = props => {
  // *** useContext ***
  const { currSession, timezoneOffset } = useContext(globalContext);

  const canEndTimeBeRendered = () => {
    const options = props.endTimeOptions;

    return Boolean(props.bidding && options?.today && options?.type >= 1 && options?.type <= 3);
  }


  const getWinningBid = () => {
    if (isBiddingActive()) return undefined;

    const maxOffer = Math.max(...props.bidding.getBids().map(b => b.getAmount()));

    return props.bidding.getBids().find(b => b.getAmount() === maxOffer);
  }

  const getEndTimeToString = () => {
    if (props.bidding.getManualStatus() === 0)
      return 'Subasta finalizada.';

    /** @param {number} date */
    const getEndDate = date => Global.parseDateUTC(date, timezoneOffset);

    /** @param {number} date */
    const getRemaining = date => {
      const remain = options.today > date
        ? options.today - date
        : date - options.today;
      const type = remain <= Global.DAY_TO_MILLI * 30
        ? `día${remain >= Global.DAY_TO_MILLI * 2 ? 's' : ''}`
        : remain <= Global.DAY_TO_MILLI * 365
          ? `mes${remain >= Global.DAY_TO_MILLI * 30 ? 's' : ''}`
          : `año${remain >= Global.DAY_TO_MILLI * 365 ? 's' : ''}`;

      return remain <= Global.DAY_TO_MILLI
        ? 'hoy'
        : `${options.today > date ? 'hace' : 'en'} ${Math.floor(remain / Global.DAY_TO_MILLI)} ${type}`;
    }

    const endDate = props.bidding.getEndDate();
    const options = props.endTimeOptions;
    let text = endDate > options.today ? 'Finaliza ' : 'Finalizó ';

    switch (options.type) {
      case 1: { // Date display.
        text += `el ${getEndDate(endDate)}`;
        break;
      } case 2: { // Remaining days display.
        text += getRemaining(endDate);
        break;
      } default: { // Both display.
        text += `el ${getEndDate(endDate)} (${getRemaining(endDate)})`;
      }
    }

    return text;
  }

  const getHintboxType = () => {
    const endDate = props.bidding.getEndDate();
    const options = props.endTimeOptions;

    if (props.bidding.getManualStatus() === 0 || options.today > endDate) return 'error';
    else if (endDate - options.today <= Global.DAY_TO_MILLI * 3) return 'warning'
    else return ''
  }

  const isCurrSessionFirstPlace = () => {
    return currSession.username !== undefined
      && props.bidding.getBids().at(0)?.getBidder().getUsername() === currSession.username;
  }

  const isBiddingActive = () => {
    return props.bidding?.getStatus() && props.bidding.getManualStatus() === 1;
  }

  const renderBids = () => {
    const bids = [];

    for (let i = 1; i < props.bidding.getBids().length; i++) {
      const bid = props.bidding.getBids()[i];

      bids.push(
        <div className={`bid${i === 1 ? ' second' : i === 2 ? ' third' : ''}`} key={`bid-${bid.getId()}`}>
          <span className="position">{i + 1}°</span>
          <UserCard mini rounded type='glass'
            user={bid.getBidder()} />
          <h4 className="bid"><span>ofreció</span> $ {Global.formatNumber(bid.getAmount())} MXN</h4>
        </div>
      );
    }

    return bids;
  }

  const renderFirstBid = () => {
    const bid = props.bidding.getBids()[0];

    return (
      <div className="bid first">
        <img src={Icons.CrownIcon} alt="crown-icon" className="crown-icon" />
        <span className="position">1°</span>
        <UserCard mini
          rounded
          type='glass'
          user={bid.getBidder()} />
        <h4 className="bid"><span>ofreció</span> $ {Global.formatNumber(bid.getAmount())} MXN</h4>
      </div>
    );
  }

  return (<div className={`bidding-box${props.bidding === undefined ? ' wait' : ''}`}>
    {props.bidding === undefined && <span className="wait-curtain" />}
    {props.bidding !== undefined && props.title !== undefined && <div className="header">
      <h4 className="overset">{props.title}</h4>
    </div>}
    {canEndTimeBeRendered() && <Hintbox icon={Icons.InfoIcon}
      message={getEndTimeToString()}
      empty
      type={getHintboxType()} />}
    {props.bidding?.getBids().length > 0 && <div className="highest-bid">
      <h5 className="overset higher-offer">Mejor oferta</h5>
      {renderFirstBid()}
    </div>}
    {props.bidding?.getBids().length === 0 && isBiddingActive() && <NotFoundBox message='Sin ofertas aún.' />}
    {props.bidding?.getBids().length > 1 && <div className="bids">
      <h5 className="overset">Otras ofertas</h5>
      {renderBids()}
    </div>}
    {props.bidding?.getStatus() && <PropDisplay header='Puja mínima'
      largeFont
      property={`$ ${Global.formatNumber(props.bidding.getMinimumBid())} MXN`} />}
    <br />
    {props.bidding !== undefined && props.offerBtn !== undefined && <div className="flex-box jc-left m3">
      <div className="child auto-width">
        <Button disabled={!props.bidding.getStatus() || props.offerBtn.disabled || isCurrSessionFirstPlace()}
          empty
          icon={props.offerBtn.icon || Icons.BidIcon}
          onClick={props.offerBtn.onClick}
          rounded
          typeRender='warning'
          value={props.offerBtn.value || 'Ofertar'} />
      </div>
      {isCurrSessionFirstPlace() && <div className="child">
        <Hintbox fullWidth
          icon={Icons.WarningIcon}
          message='No puedes ofertar porque ya estás en primer lugar.'
          type='warning' />
      </div>}
    </div>}
    {props.bidding !== undefined && !isBiddingActive() && <div>
      {props.bidding.getBids().length === 0 && <NotFoundBox img={Icons.KevinNotFoundImg}
        message='Nadie ofertó la subasta' />}
      {props.bidding.getBids().length > 0 && <div>
        <h5 className="overset">Datos del ganador.</h5>
        <ContactInfoCard user={getWinningBid().getBidder()}
          onRemoveBtnClick={props.onDeleteWinner ? () => props.onDeleteWinner(getWinningBid()) : undefined} />
      </div>}
    </div>}
  </div>);
}

export default BiddingBox;