import Generic from './Generic';
import Global from './Global';

class Bidding extends Generic {
  /** Specifies minimum bid to offer, being 50. */
  static get MIN_BID() { return 50 }

  /** @param {Bidding} [object] */
  constructor(object) {
    super(object);

    const { bids, minimumBid, endDate, manualStatus, status } = object || {};

    this.setBids(Array.isArray(bids) && [...bids]);
    this.setEndDate(endDate);
    this.setMinimumBid(minimumBid);
    this.setManualStatus(manualStatus);
    this.setStatus(status);
  }

  /** Obtains the array of bids made for de bidding. */
  getBids() {
    return this.bids;
  }

  /** Assaigns an array of bids made for the bidding.
   * @param {import('./Bid').default[]} bids 
   */
  setBids(bids) {
    this.bids = bids || []
  }

  /** Obtains the bidding's end date. */
  getEndDate() {
    return this.endDate;
  }

  /** Assings an end date to the bidding. Value passes through Global.formatDateUTC
   * @param {number} endDate 
   */
  setEndDate(endDate) {
    this.endDate = Global.formatDateUTC(endDate);
  }

  /** Obtains the manual status of bidding. */
  getManualStatus() {
    return this.manualStatus;
  }

  /** Assings the manual status (between 0 and 1).
   * @param {0|1} manualStatus 
   */
  setManualStatus(manualStatus) {
    this.manualStatus = manualStatus;
  }

  /** Obtains the minimum bid for the bidding. */
  getMinimumBid() {
    return this.minimumBid;
  }

  /** Assigns a minimum bid for the bidding. Must be equal or greater than MIN_BID.
   * @param {number} minimumBid
   */
  setMinimumBid(minimumBid) {
    this.minimumBid = minimumBid >= Bidding.MIN_BID ? minimumBid : undefined;
  }

  /** Obtains the bidding current status.
   * @returns {boolean} If true, bidding is active, false if inactive.
   */
  getStatus() {
    return this.status;
  }

  /** Assing a status for the bidding.
   * @param {boolean} status 
   */
  setStatus(status) {
    this.status = Boolean(status);
  }
}

export default Bidding;