import Generic from './Generic';
import User from './User';

class Bid extends Generic {
  /** @param {Bid} [bid] */
  constructor(bid) {
    super(bid);

    const { amount, bidder } = bid || {};

    this.setAmount(amount);
    this.setBidder(bidder);
  }

  /** Obtains the amount of the bid. */
  getAmount() {
    return this.amount;
  }

  /** Assigns an amount for the bid.
   * @param {number} amount 
   */
  setAmount(amount) {
    this.amount = amount === null || isNaN(amount)
     ? undefined
     : Number(amount);
  }

  /** Obtains the bidder. */
  getBidder() {
    return this.bidder;
  }

  /** Assigns a user to the bid.
   * @param {User} bidder 
   */
  setBidder(bidder) {
    this.bidder = bidder || new User();
  }
}

export default Bid;