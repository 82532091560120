import '../styles/pay-records.css';
import { useContext, useEffect, useRef, useState } from "react";
import { globalContext } from '../context/GlobalContext';
import Button from "../components/Button";
import LoadingBlock from "../components/LoadingBlock"
import NotFountBox from "../components/NotFoundBox"
import UIRender from "../objects/UIRender";
import DAOServ from '../objects/DAOServ';
import User from '../objects/User';
import ErrHandler from '../objects/ErrHandler';
import * as Icons from '../assets/images'

/** The TransactionObject typedef
 * @typedef {Object} TransactionObject
 * @property {string} concept The concept of the transaction.
 * @property {string} [contractHash] The contract block's hash which the transacion is
 * linked. If undefined, the transaction is linked to a subscription.
 * @property {string} [hash] The hash from the block where the transaction is located.
 * @property {string} reference The transaction reference.
 * @property {number} timestamp The timestamp of the transaction.
 * @property {string} user The user's username that made the transaction.
 */

/** Renders a PayRecords compo, used to manage payments made.
 * @param {Object} props The props object.
 * @param {() => void} props.onHide A callback funcion that will be activated when
 * @param {boolean} [props.filters] If true, filtres will be shown.
 * compo is hidden.
 */
const PayRecords = props => {
  // *** useContext
  const { currSession, pushMessageHint } = useContext(globalContext);
  // *** useRef ***
  const compId = useRef('pay-records');
  const popup = useRef(/** @type {HTMLDivElement} */(undefined));
  const filters = useRef(/** @type {HTMLDivElement} */(undefined))
  // *** useState ***
  const [showFilters, setShowFilters] = useState(false);
  const [transactions, setTransactions] = useState(/** @type {TransactionObject[]} */(undefined))

  /** The OnAnimationEnd event handler.
   * @type {React.AnimationEventHandler<HTMLDivElement>}
   */
  const dispose = e => {
    e.stopPropagation();

    if (e.target === popup.current && UIRender.isHidden(popup.current))
      props.onHide();
  }

  /** @type {React.AnimationEventHandler<HTMLDivElement>} */
  const filtersAnimationEndHandler = e => {
    e.stopPropagation();

    if (filters.current.classList.contains('hide'))
      setShowFilters(false);
    else
      filters.current.classList.remove('show');
  }

  const filtersClickHandler = () => {
    if (showFilters) {
      filters.current.classList.add('hide');
      UIRender.addInert(filters.current);
    } else setShowFilters(true);
  }

  const renderTransactions = () => {
    return transactions.map(transaction => {
      return (<div className="transaction-item" key={transaction.reference}>

      </div>);
    });
  }

  useEffect(() => {
    const fetchTransactions = async () => {
      await DAOServ.post('request_subscription_transactions', { tst: currSession.tst, concept: 'Pago de licencia' }, 'JSON')
        .then(data => setTransactions(data))
        .catch(err => {
          const message = ErrHandler.parseError(err);

          if (ErrHandler.getCode(message) !== ErrHandler.CODES.NOT_FOUND)
            pushMessageHint({ message, type: 'error' });

          setTransactions([]);
        });
    }

    if (currSession.sessionStatus === User.STATUS_ACT && transactions === undefined)
      fetchTransactions();
  }, [currSession, pushMessageHint, transactions])

  // First render useEffect.
  useEffect(() => {
    const id = compId.current;
    const parent = popup.current?.parentNode;
    const options = { footer: true, navbar: true };

    UIRender.disableGlobalScroll(id);
    UIRender.disableSiblings(popup.current, options);

    return () => {
      // Enable childs.
      UIRender.enableChilds(parent, options, id);
      // Enable global scroll.
      UIRender.enableGlobalScroll(id);
    }
  }, []);

  return (
    <div className="popup-wrapper" id={compId.current} ref={popup} onAnimationEnd={dispose}>
      <div className="popup">
        <div className="top-bar">
          <h2 className="highlight">Historial de pagos.</h2>
          <Button onClick={() => UIRender.hideElement(popup.current)}
            borderless
            empty
            icon={Icons.CloseIcon}
            reduced
            rounded
            typeRender='error' />
        </div>
        <div className="popup-content">
          {props.filters && <div className="filters-bar">
            <Button animated
              borderless
              empty={!showFilters}
              icon={Icons.FilterIcon}
              onClick={filtersClickHandler}
              rounded
              value='Filtros' />
            {showFilters &&
              <div className="filters-menu show" onAnimationEnd={filtersAnimationEndHandler} ref={filters}>
                <Button borderless
                  empty
                  icon={Icons.UpdateIcon}
                  onClick={filtersClickHandler}
                  rounded
                  value='Actualizar' />
              </div>
            }
          </div>}
          {transactions === undefined && <LoadingBlock position='relative' noBackground />}
          {transactions?.length === 0 && <NotFountBox />}
          {transactions?.length > 0 && <div className="transactions">
            {renderTransactions()}
          </div>}
        </div>
      </div>
    </div>
  );
}

export default PayRecords;