import ErrHandler from "./ErrHandler"

class ImageCropper {
  /** Image resolution to 240p. */
  static get R_240P() { return 240 }
  /** Image resolution to 480p. */
  static get R_480P() { return 480 }
  /** Image resolution to 512p. */
  static get R_512P() { return 512 }
  /** Image resolution to 640p. */
  static get R_640P() { return 640 }
  /** Image resolution to 720p. */
  static get R_720P() { return 720 }

  /** Crops an image with a given resolution. Crop will always be made in the center.
   * @param {GenericFile} file A file object. This object is the one after file has been readed. This object
   * must have 'urlData' attribute.
   * @param {number} resolution New resolution for the image. It must be least than width and/or height
   * (see ImageCropper.R_...);
   * @returns {Promise<string>}
   */
  static cropImage(file, resolution) {
    const toCrop = urlData => {
      return new Promise((resolve, reject) => {
        const auxImg = document.createElement('img');

        auxImg.onload = e => {
          // If ratio equals 1, means image is already squared.
          if (e.target.naturalHeight === e.target.naturalWidth) return resolve(urlData);

          const imgWidth = e.target.naturalWidth; // Image width.
          const imgHeight = e.target.naturalHeight; // Image height.
          const minSize = imgWidth > imgHeight ? imgHeight : imgWidth; // Wich size is smaller?
          const dSize = Number(resolution) - minSize;// White-space size.
          const dX = imgWidth > imgHeight ? dSize / 2 : 0; // x-axis of origin.
          const dY = imgWidth > imgHeight ? 0 : dSize / 2; // y-axis of origin.
          // Creating canvas element.
          const canvas = document.createElement('canvas');
          // Setting canvas to max resolution.
          canvas.width = resolution;
          canvas.height = resolution;
          // Getting context.
          const ctx = canvas.getContext('2d');
          // Resizing...
          ctx.drawImage(auxImg, dX, dY, minSize, minSize, 0, 0, canvas.width, canvas.height);
          // Returning url data.
          return resolve(canvas.toDataURL(file.getType()));
        }

        if (!urlData) {
          return reject(ErrHandler.getError(ErrHandler.CODES.PARAM_MISSING, 'urlData is undefined'));
        } else auxImg.src = urlData;
      });
    }

    return new Promise((resolve, reject) => {
      // Resizing image...
      if (!resolution || typeof resolution !== 'number') {
        const dataUrl = toCrop(file.getURLData());

        if (!dataUrl) return reject(ErrHandler.getError(ErrHandler.CODES.FAIL, "Couldn't crop image"));
        else return resolve(dataUrl);
      } else {
        ImageCropper.reduceImage(file, resolution)
          .then(data => toCrop(data))
          .then(urlData => resolve(urlData))
          .catch(error => reject(error));
      }
    });
  }

  /** Reduce an image's resolution.
   * @param {GenericFile} file A file object. This object is the one after file has been readed. This object
   * must have 'urlData' attribute.
   * @param {number} resolution New resolution for the image. It must be least than width and/or height
   * @returns {Promise<string>}
   */
  static reduceImage(file, resolution) {
    return new Promise((resolve, reject) => {
      if (!file || !resolution || typeof resolution !== 'number') {
        const msg = `${!file ? 'File' : 'Resolution'} not valid`;
        return reject(ErrHandler.getError(ErrHandler.CODES.PARAM_INVALID, msg));
      }

      const auxImg = document.createElement('img');

      auxImg.onload = e => {
        const imgWidth = e.target.naturalWidth; // Image width.
        const imgHeight = e.target.naturalHeight; // Image height.
        const sense = imgWidth > imgHeight; // Image sense: witch size is larger?.
        const diference = sense ? imgWidth - Number(resolution) : imgHeight - Number(resolution);
        const ratio = sense ? imgHeight / imgWidth : imgWidth / imgHeight;

        if ((sense && resolution > imgWidth) || (!sense && resolution > imgHeight)) {
          const msg = 'Image dimensions are smaller than new resolution';
          return reject(ErrHandler.getError(ErrHandler.CODES.PARAM_INVALID, msg));
        }

        // Creatinc canvas element.
        const canvas = document.createElement('canvas');
        // Setting canvas dimensions.
        canvas.width = sense ? resolution : imgWidth - (diference * ratio);
        canvas.height = !sense ? resolution : imgHeight - (diference * ratio);
        // Context.
        const ctx = canvas.getContext('2d');
        // Resizing...
        ctx.drawImage(auxImg, 0, 0, canvas.width, canvas.height);
        // Returning url data.
        return resolve(ctx.canvas.toDataURL(file.getType()));
      }

      auxImg.src = file.getURLData();
    });
  }
}

export default ImageCropper;