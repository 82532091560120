import ErrHandler from "./ErrHandler";
import Global from "./Global";
import DAOServ from "./DAOServ";

/** Package Typedef
 * @typedef {Object} PackageObject A package object.
 * @property {string} code Code response.
 * @property {string} message Response message.
 * @property {boolean} ok Response status.
 * @property {Object.<string, *>} params Parameters atached
 */

/** The client socket definition. Assing its callbacks before connection. */
class ClientSocket {
  /** Kicked disconection (3000) */
  static get D_KICKED() { return 3000 }
  /** Normal disconnection (1000) */
  static get D_NORMAL() { return 1000 }
  /** Endpoint is going away (1001) */
  static get D_GOING_AWAY() { return 1001 }
  /** Protocol error (1002) */
  static get D_PROTOCOL_ERROR() { return 1002 }
  /** Endpoint received data that cannot handle (1003) */
  static get D_UNSUPORTED_DATA() { return 1003 }
  /** A meaning might be defined in the future (1004) */
  static get D_RESERVED() { return 1004 }
  /** Status code expected, received nothing (1005) */
  static get D_NO_STATUS_RECEIVED() { return 1005 }
  /** Connection closed abnormaly (1006) */
  static get D_ABNORMAL_CLOSURE() { return 1006 }
  /** Connection terminated because of invalid data (1007) */
  static get D_INVALID_DATA() { return 1007 }
  /** Enpoint received a message that violates its policy (1008) */
  static get D_POLICY_VIOLATION() { return 1008 }
  /** Message sent too big (1009) */
  static get D_MESSAGE_TOO_BIG() { return 1009 }
  /** Client terminated connection because of a petition reject from server (1010) */
  static get D_MANDATORY_EXIT() { return 1010 }
  /** Server internal error (1011) */
  static get D_INTERNAL_ERROR() { return 1011 }
  /** Server is restarting (1012) */
  static get D_SERVICE_RESTART() { return 1012 }
  /** Server not available (1013) */
  static get D_SERVICE_UNAVAILABLE() { return 1013 }
  /** Server is a gateway or proxy and received an invalid response from upstream server (1014) */
  static get D_BAD_GATEWAY() { return 1014 }
  /** Failure on a TLS handshake (1015) */
  static get D_TLS() { return 1015 }

  /**
   * @param {number} [idEstate] The estate's id (assing if open hall request has been made).
   * @param {string} [key] Key param for the connection.
   * @param {string} [name] Name param for the conection.
   */
  constructor(idEstate, key, name) {
    this.mode = !Global.DEV_MODE ? 'wss' : 'ws';
    this.setEstateId(idEstate);
    this.setKey(key);
    this.setTST(name);
    /** @type {WebSocket|undefined} */
    this.socket = undefined;
  }

  /** Obtains a generic message when onclose triggers
   * @param {number} code The code obtained in a CloseEvent.
   */
  static getDisconnectMsg(code) {
    switch (code) {
      case ClientSocket.D_KICKED: return 'Conexión con la sala perdida.';
      case ClientSocket.D_NORMAL: return 'Desconectado de la sala.';
      case ClientSocket.D_PROTOCOL_ERROR: return 'Error de protocolo.'
      case ClientSocket.D_UNSUPORTED_DATA: return 'Información recibida no soportada.';
      case ClientSocket.D_RESERVED: return 'Información recibida reservada.';
      case ClientSocket.D_NO_STATUS_RECEIVED: return 'Respuesta no recibida.';
      case ClientSocket.D_ABNORMAL_CLOSURE: return 'Conexion con la sala perdida.';
      case ClientSocket.D_INVALID_DATA: return 'Información recibida inválida.';
      case ClientSocket.D_POLICY_VIOLATION: return 'Información viola las políticas del servidor.';
      case ClientSocket.D_MESSAGE_TOO_BIG: return 'Información demasiado grande.';
      case ClientSocket.D_MANDATORY_EXIT: return 'Conexión finalizada por denegación de servicios.'
      case ClientSocket.D_INTERNAL_ERROR: return 'Desconexión por error interno del servidor.';
      case ClientSocket.D_SERVICE_RESTART: return 'Servidor en proceso de reinicio.';
      case ClientSocket.D_SERVICE_UNAVAILABLE: return 'Servidor o sala no disponible.';
      case ClientSocket.D_BAD_GATEWAY: return 'Error recibido en el puente.';
      case ClientSocket.D_TLS: return 'Error en el protocolo TLS.';
      default: {
        if (ClientSocket.D_KICKED || ClientSocket.D_GOING_AWAY)
          return 'Conexión con la sala perdida';
        else
          return 'Ha ocurrido un error desconocido';
      }
    }
  }

  /** Parses the data from a MessageEvent.
   * @param {string} pkg 
   * @returns {PackageObject}
   */
  static parseMessage(pkg) {
    return JSON.parse(pkg);
  }

  /** Connects the socket
   * @trows An error if socket is not closed.
   */
  connect() {
    if (this.isOpen()) {
      throw new Error(ErrHandler.getError(ErrHandler.CODES.CONNECTION_OPEN));
    }

    const eId = this.getEstateId();
    const key = this.getKey();
    const tst = this.getTST();
    let param = '';
    const port = Global.DEV_MODE ? `:${DAOServ.PORT}` : '';

    if (eId && !key) param = `?estate=${eId}`;
    if (key && eId) param = `?key=${key}`;

    if (tst) {
      if (!param) param = `?tst=${tst}`;
      else param = `${param}&tst=${tst}`;
    }

    this.socket = new WebSocket(`${this.mode}://${DAOServ.HOST}${port}${param}`);

    // Events assignation.
    this.socket.addEventListener('error', e => {
      if (this.onError) this.onError(e);
    });

    this.socket.addEventListener('open', e => {
      if (this.onOpen) this.onOpen(e);

      // Adding rest of the event listeners.
      this.socket.addEventListener('close', e => {
        if (this.onClose) this.onClose(e);
      });

      this.socket.addEventListener('message', e => {
        if (this.onMessage) this.onMessage(e);
      })
    });
  }

  /** Disconnects the socket */
  close() {
    this.socket?.close(ClientSocket.D_NORMAL);
  }

  /** Obtains the ready state of the socket. Can return undefined if socket is undefined*/
  getReadyState() {
    return this.socket?.readyState;
  }

  /** Checks if socket open */
  isOpen() {
    return this.socket && this.getReadyState() !== WebSocket.CLOSED;
  }

  /**
   * 
   * @param {string} instruction The instruction code to be parsed by the server.
   * @param {Object.<string, *>} params The params attached to the instruction.
   */
  sendMessage(instruction, params) {
    const pkg = {
      instruction,
      params: params || {}
    }

    this.socket.send(JSON.stringify(pkg));
  }

  /** Obtains the current estate id */
  getEstateId() {
    return this.idEstate;
  }

  /** Assings an estate's id. Won't disconnect the socket if it's already connected.
   * If key is already assigned, this param will be omited when connecting.
   * @param {number} [idEstate] 
   */
  setEstateId(idEstate) {
    this.idEstate = idEstate === null || isNaN(idEstate)
     ? undefined
     : Number(idEstate);
  }

  /** Obtains the current key */
  getKey() {
    return this.key;
  }

  /** Assings a new key for the ClientSocket. Won't disconnect the socket if it's already connected.
   * If idEstate is already assigned, this param will be omited when connecting.
   * @param {string} [key]
   */
  setKey(key) {
    this.key = key;
  }

  /** Obtains the current client's TST */
  getTST() {
    return this.tst;
  }

  /** Assings a new client's TST. Won't disconnect the socket if it's already connected.
   * @param {string} [tst] 
   */
  setTST(tst) {
    this.tst = tst;
  }

  /** Assings a callback to be triggered when an connectipon closes.
   * @param {(e: CloseEvent) => void} cb 
   */
  setOnCloseCallBack(cb) {
    this.onClose = cb;
  }

  /** Assigns a callback to be triggered when an error happens.
   * @param {(e: Event) => void} cb 
   */
  setOnErrorCallback(cb) {
    this.onError = cb;
  }

  /** Assings a callback to be triggered when a message reception happens.
   * @param {(e: MessageEvent<*>) => void} cb 
   */
  setOnMessageCallback(cb) {
    this.onMessage = cb;
  }

  /** Assings a callback to be triggered when an connectipon opens.
   * @param {(e: Event) => void} cb 
   */
  setOnOpenCallBack(cb) {
    this.onOpen = cb;
  }
}

export default ClientSocket;