import Generic from "./Generic";
import Global from "./Global";

class BankCard extends Generic {
  /** The max CVC length, being 4 */
  static get CVC_MAX_LENGTH() { return 4 }
  /** The min CVC length, being 3 */
  static get CVC_MIN_LENGTH() { return 3 }
  static get REGEXP_MASTERCARD() { return /^5\d{15}/ };
  static get REGEXP_VISA() { return /^4\d{15}/ };

  /** @param {BankCard} [object] */
  constructor(object) {
    super(object);

    const { cardNumber, cvc, exp } = object ?? {};

    this.setCardNumber(cardNumber);
    this.setCVC(cvc);
    this.setExp(exp);
  }

  /** Verifies if a card number is valid. This method uses Luhn algorithm.
   * @param {string} cardNumber 
   */
  static cardNumberValid(cardNumber) {
    const regExp = BankCard.REGEXP_MASTERCARD.test(cardNumber)
      || BankCard.REGEXP_VISA.test(cardNumber);

    if (isNaN(cardNumber) || cardNumber.length !== 16 || !regExp)
      return false;

    // 2121 2121 2121.
    let res = 0;

    for (let i = 0; i < cardNumber.length; i++) {
      const mult = i === 0 || i % 2 === 0 ? 2 : 1;
      let auxVal = Number(cardNumber[i]) * mult;

      if (auxVal >= 10) auxVal = (auxVal - 10) + 1;

      res += auxVal;
    }

    return res % 10 === 0;
  }

  /** Obtains the card type (4 for VISA, 5 for MASTERCARD) based in the card number. */
  getCardNumberType() {
    const cardNumber = this.getCardNumber();

    if (BankCard.REGEXP_MASTERCARD.test(cardNumber))
      return 5;
    else if (BankCard.REGEXP_VISA.test(cardNumber))
      return 4;
  }

  /** Obtains the assigned card number. */
  getCardNumber() {
    return this.cardNumber;
  }

  /** Assings a card number.
   * @param {string} cardNumber 
   */
  setCardNumber(cardNumber) {
    this.cardNumber = cardNumber;
  }

  /** Obtains the CVC. */
  getCVC() {
    return this.cvc;
  }

  /** Assings a code.
   * @param {number} cvc 
   */
  setCVC(cvc) {
    this.cvc = cvc === null || isNaN(cvc) ? undefined : Number(cvc);
  }

  /** Obtains the expiration date. */
  getExp() {
    return this.exp;
  }

  /** Assings an expiration date in milliseconds. Value passes through Global.formatDateUTC
   * @param {number} exp 
   */
  setExp(exp) {
    this.exp = Global.formatDateUTC(exp);
  }
}

export default BankCard;