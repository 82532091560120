import Generic from "./Generic";
import Global from "./Global";

class Agreement extends Generic {
  /** @param {Agreement} [agreement] */
  constructor(agreement) {
    super(agreement);

    const {
      cancelationDate,
      endDate,
      files,
      hash,
      lessees,
      nextDeadline,
      startDate
    } = agreement ?? {}

    this.setCancellationDate(cancelationDate);
    this.setEndDate(endDate);
    /** @type {(import('./GenericFile').default&Blob)[]} */
    this.files = Array.isArray(files) ? [...files] : [];
    this.setHash(hash);
    this.setLessees(Array.isArray(lessees) ? [...lessees] : undefined);
    this.setNextDeadline(nextDeadline);
    this.setStartDate(startDate);
  }

  /** Obtains the agreement's cancelation date. When defined, means the agreement's status is
   * cancelled */
  getCancellationDate() {
    return this.cancelationDate;
  }

  /** Assings a date of cancelation for the agreement. Value passes through Global.formatDateUTC
   * @param {number} cancelationDate
   */
  setCancellationDate(cancelationDate) {
    this.cancelationDate = Boolean(cancelationDate)
      ? Global.formatDateUTC(cancelationDate)
      : undefined;
  }

  /** Obtains the end date */
  getEndDate() {
    return this.endDate;
  }

  /** Assings an end date to the agreement. Value passes through Global.formatDateUTC
   * @param {number} endDate 
   */
  setEndDate(endDate) {
    this.endDate = Global.formatDateUTC(endDate);
  }

  /** Obtains the documents attached to the agreement. */
  getFiles() {
    return this.files;
  }

  /** Assings documents to this agreement.
   * @param {(import('./GenericFile').default&Blob)[]} files 
   */
  setFiles(files) {
    this.files = files ?? [];
  }

  /** Obtains the blockchain block's hash from where this agreement is located. */
  getHash() {
    return this.hash;
  }

  /** Assings a blockchain block's hash.
   * @param {string} hash 
   */
  setHash(hash) {
    this.hash = hash;
  }

  /** Obtains the lessees involved in this agreement. */
  getLessees() {
    return this.lessees;
  }

  /** Assings the lessees for the agreement.
   * @param {import('./User').default[]} lessees 
   */
  setLessees(lessees) {
    this.lessees = lessees ?? [];
  }

  /** Obtains the next deadline */
  getNextDeadline() {
    return this.nextDeadline;
  }

  /** Assings the next deadline. Value passes through Global.formatDateUTC
   * @param {number} nextDeadline 
   */
  setNextDeadline(nextDeadline) {
    this.nextDeadline = Global.formatDateUTC(nextDeadline);
  }

  /** Obtains the agreement's start date. */
  getStartDate() {
    return this.startDate;
  }

  /** Assings the agreement's start date.
   * @param {number} startDate 
   */
  setStartDate(startDate) {
    this.startDate = Global.formatDateUTC(startDate);
  }
}

export default Agreement;