import './styles/inputbox.css';
import { useContext, useEffect, useRef, useState } from "react";
import Inputbar from "./Inputbar";
import Button from "./Button";
import { AddIcon, CloseIcon } from "../assets/images";
import { globalContext } from "../context/GlobalContext";
import Global from "../objects/Global";
import ErrHandler from "../objects/ErrHandler";

/** Renders an Inputbox compo.
 * @param {Object} props The props object.
 * @param {boolean} [props.disabled] If true, whole compo will be disabled and inputbar and button will
 * be hidden.
 * @param {import('./Inputbar').InputbarFilterObject[]} [props.filters] Filters for the inputbar.
 * @param {*} [props.inputIcon] An icon for input compo.
 * @param {number} [props.inputMaxLength] Max length for input compo. If undefined, no max length
 * will be determined.
 * @param {number} [props.inputMinLength] Min length for an input to be added. If undefined, min length
 * will be 1.
 * @param {import('./Inputbar').InputbarPlaceholderObject} [props.inputPlaceholder] A placeholder
 * for input compo.
 * @param {(input?: string) => boolean} [props.isValid] An isValid callback function for
 * input compo.
 * @param {string[]} [props.items] An array of items. Given items will be added to the compo.
 * If undefined, compo will be empty.
 * @param {number} [props.maxItems] If undefined or less than zero, this number will be ignored.
 * @param {(input: string) => void} props.onAdd A callback function that will be triggered when
 * a new item is added to the box.
 * @param {(input: string) => string} [props.onBeforeAdd] A callback function that will be triggered
 * before a new item is added. Use this function to validate or modify given input. The returned input will
 * be pushed to the input box (if undefined or empty, input will be ignored).
 * @param {(index: number) => void} props.onDelete A callback fuction that will be triggered when
 * an item is deleted from the box. An index from array will be returned.
 * @param {string} [props.onEmptyPlaceholder] A placeholder for the items box when empty. If undefined,
 * 'Sin elementos' will be placed. Set to empty string to show no placeholder.
 * @param {boolean} [props.requestFocus] If true, enabled and inputbar is shown, inputbar will request
 * focus to UIRender.
 * @param {boolean} [props.showInputbar] If true, inputbar and button will be shown.
 */
const Inputbox = props => {
  // *** useContext ***
  const { pushMessageHint } = useContext(globalContext);
  // *** useRef ***
  const boxRef = useRef(/** @type {HTMLDivElement} */(undefined));
  const currInput = useRef('');
  const inputForceChange = useRef(/** @type {(val: string) => void} */(undefined));
  const minLength = useRef(props.inputMinLength > 0 ? props.inputMinLength : 1);
  // *** useState ***
  const [items, setItems] = useState(/** @type {string[]} */(undefined));

  /** @type {React.MouseEventHandler<HTMLButtonElement>} */
  const boxHandleOnClick = e => {
    const isBtn = Global.findParent(e.target, { maxChecks: 2, tagName: 'button' });

    if (!isBtn) return;

    const itemEl = isBtn.parentNode;
    const index = Array.from(boxRef.current.children).findIndex(child => child === itemEl);

    if (index !== -1) {
      items.splice(index, 1);
      props.onDelete(index);
      setItems([...items]);
    } else {
      pushMessageHint({
        message: ErrHandler.getCodes().FAIL.message,
        type: 'error'
      });
    }
  }

  const inputHandleOnEnter = () => {
    if (!currInput.current || currInput.current.length < minLength.current)
      return;

    let input = (props.onBeforeAdd && props.onBeforeAdd(currInput.current)) || currInput.current;
    currInput.current = '';
    inputForceChange.current('');

    if (!input) return

    if (items.find(item => item === input)) {
      pushMessageHint({ message: `'${input}' ya existe`, type: 'warning' });
      return;
    }

    props.onAdd(input);

    setItems([...items, input]);
  }

  const renderBoxItems = () => {
    return items.map(item => {
      return (<div className="inputbox-item" id={item} key={item}>
        <p>{item}</p>
        {!props.disabled && <Button borderless
          empty
          icon={CloseIcon}
          reduced
          rounded
          typeRender='white-stone' />}
      </div>);
    });
  }

  useEffect(() => { setItems(props.items || []) }, [props.items]);

  return (
    <div className="inputbox">
      {!props.disabled && props.showInputbar && <div className="inputbox-bar">
        <Inputbar disabled={props.maxItems > 0 && items?.length >= props.maxItems}
          filters={props.filters}
          forceChangeRef={inputForceChange}
          icon={props.inputIcon}
          isValid={props.isValid}
          maxLength={props.inputMaxLength}
          minLength={props.inputMinLength}
          onChange={input => currInput.current = input}
          onEnter={inputHandleOnEnter}
          placeholder={props.inputPlaceholder}
          requestFocus={props.requestFocus} />
        <Button disabled={props.maxItems > 0 && items?.length >= props.maxItems}
          empty
          icon={AddIcon}
          onClick={inputHandleOnEnter}
          reduced
          rounded />
      </div>}
      <div className="inputbox-container" onClick={boxHandleOnClick} ref={boxRef}>
        {items?.length > 0 && renderBoxItems()}
        {items?.length === 0 && props.onEmptyPlaceholder !== '' && <p className="placeholder">
          {props.onEmptyPlaceholder || 'Sin elementos'}
        </p>}
      </div>
    </div>
  );
}

export default Inputbox;