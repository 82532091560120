import './styles/button.css'
import './styles/push-button.css';
import { useState } from 'react';

/** Renders a PushButton compo.
 * @param {{
 *  borderless?: boolean,
 *  className: string,
 *  disabled?: boolean,
 *  empty?: boolean,
 *  id: string,
 *  img: string,
 *  onClick: React.MouseEventHandler<boolean>,
 *  onPushedClassName: string,
 *  onPushedValue: string,
 *  reference?: React.LegacyRef<*>
 *  rounded?: boolean,
 *  stopPropagation: boolean,
 *  title: string,
 *  value: string
 * }} props
 */
const PushButton = props => {
  // *** useState ***
  const [isPushed, setIsPushed] = useState(false);

  const getClassName = () => {
    let result = `button push-button${props.className ? ` ${props.className}` : ''}`;

    if (props.borderless) result += ' borderless';
    if (props.empty) result += ' empty';
    if (props.rounded) result += ' rounded';
    if (isPushed) result += ' pushed';
    if (isPushed && props.onPushedClassName) result += ` ${props.onPushedClassName}`

    return result;
  }

  /** @type {React.MouseEventHandler<HTMLButtonElement>} */
  const pushBtnHandleOnClick = e => {
    e.preventDefault();

    if (props.stopPropagation) e.stopPropagation();
    if (props.disabled) return;

    const newVal = !isPushed;
    setIsPushed(newVal);

    if (props.onClick) props.onClick(newVal);
  }

  return (
    <button disabled={props.disabled}
      id={props.id}
      ref={props.reference}
      onClick={pushBtnHandleOnClick}
      title={props.title}
      type='button'
      className={getClassName()}>
      {props.img && <img id={props.id ? `${props.id}-img` : undefined} src={props.img} alt="button-img" />}
      {props.value && <p id={props.id ? `${props.id}-p` : undefined}>{isPushed ? props.onPushedValue || props.value : props.value}</p>}
    </button>
  );
}

export default PushButton;