import '../styles/login.css'
import { useContext, useEffect, useRef, useState } from 'react';
import { globalContext } from '../context/GlobalContext';
import { Link } from 'react-router-dom';
import Global from '../objects/Global';
import Button from '../components/Button';
import UIRender from '../objects/UIRender';
import DAOServ from '../objects/DAOServ';
import ErrHandler from '../objects/ErrHandler';
import Inputbar from '../components/Inputbar';
// Icons
import { CloseIcon, LoginIcon } from '../assets/images';
import User from '../objects/User';

/** UserDataObject typedef
 * @typedef {Object} UserDataObject
 * @property {string} password Password input.
 * @property {string} username Username input.
 */

/** Renders a Login compo.
 * @param {Object} props The props object.
 * @param {() => void} props.onHide A callback function that will be called when
 * popup is hidden.
 */
const Login = props => {
  // *** useContext ***
  const { currSessionPicture, pushMessageHint } = useContext(globalContext);
  // *** useRef ***
  const compoId = useRef('login-popup');
  const popup = useRef(/** @type {HTMLDivElement} */(undefined));
  const userData = useRef(/** @type {UserDataObject} */({}));
  // *** useState ***
  const [disableUI, setDisableUI] = useState(false);
  const [disableOnEmptyField, setDisableOnEmptyField] = useState(true);

  /** @type {React.AnimationEventHandler} */
  const dispose = e => {
    if (e.target === popup.current && UIRender.isHidden(popup.current))
      props.onHide();
  }

  /** @type {React.KeyboardEventHandler<HTMLInputElement>} */
  const formHandleOnKeyPress = e => {
    if (e.target.value && e.key === 'Enter') {
      const formElms = Global.getElements('.login-form-element');

      if (formElms.findIndex(elem => elem === e.target) === 0)
        UIRender.setFocus(formElms.at(1));
    }
  }

  /** @type {React.FormEventHandler<HTMLFormElement>} */
  const popupHandleOnSubmit = async e => {
    e.preventDefault(); // Form prevent default.
    setDisableUI(true); // Disabling UI.
    UIRender.blurFocus(); // Removing focus.

    // Payload.
    const payload = { username: userData.current.username, passcode: userData.current.password };
    // Request fetch data.
    await DAOServ.post('login', payload, 'JSON')
      .then(query => {
        try {
          localStorage.setItem('tst', query.tst);
          UIRender.reloadPage();
        } catch (err) {
          pushMessageHint({
            message: ErrHandler.getError(ErrHandler.CODES.BROWSER_STORAGE_FULL),
            type: 'error'
          });

          localStorage.removeItem('tst');
          setDisableUI(false);
        }
      }).catch(err => {
        let message = ErrHandler.parseError(err), type = 'error';

        if (ErrHandler.getCode(err) === ErrHandler.CODES.NOT_FOUND) {
          // User not found.
          if (`${err}`.includes('suspended')) {
            message = 'Esta cuenta fue suspendida. Contacta con soporte para más información';
            type = 'warning'
          } else if (`${err}`.includes('inactive')) {
            message = 'Esta cuenta está inactiva. Solicita a tu administrador la activación';
            type = 'warning';
          } else {
            message = 'El nombre de usuario o contraseña son incorrectos';
            type = 'error';
          }
        }

        pushMessageHint({ message, type });
        setDisableUI(false);
      });
  }

  useEffect(() => {
    const parent = popup.current?.parentNode;
    const options = { container: true, footer: true, navbar: true };

    UIRender.disableGlobalScroll('login-popup');
    UIRender.disableSiblings(popup.current, options);

    return () => {
      UIRender.enableGlobalScroll('login-popup');
      UIRender.enableChilds(parent, options, 'login-popup');
    }
  }, [])

  return (
    <div ref={popup} className="popup-wrapper login-popup" id={compoId.current} onAnimationEnd={dispose}>
      <div className="popup">
        <div className="top-bar">
          <h2 className="title highlight">Identifícate</h2>
          <Button
            disabled={disableUI}
            id='popup-close'
            className='error empty rounded reduced borderless'
            icon={CloseIcon}
            onClick={() => UIRender.hideElement(Global.getElement('div.login-popup'))} />
        </div>
        <div className="popup-content login">
          <div className="flex-box wrap">
            <div className="child">
              <img className='login-img' src={currSessionPicture} alt="" />
            </div>
            <div className="child">
              <form id='login-form' onSubmit={e => popupHandleOnSubmit(e)} onKeyDown={formHandleOnKeyPress}>
                <Inputbar
                  stopPropagation
                  onChange={input => {
                    userData.current.username = input;
                    setDisableOnEmptyField(!userData.current.username || !userData.current.password);
                  }}
                  className='login-form-element'
                  disabled={disableUI}
                  filters={[{ regExp: Global.REGEXP_FILTER_USERNAME }]}
                  maxLength={User.USERNAME_MAX_LENGHT}
                  placeholder={{ default: 'Usuario' }}
                  requestFocus
                  textCenter
                  textTransform='lowercase' />
                <Inputbar
                  stopPropagation
                  onChange={input => {
                    userData.current.password = input;
                    setDisableOnEmptyField(!userData.current.username || !userData.current.password);
                  }}
                  className='login-form-element'
                  disabled={disableUI}
                  filters={[{ regExp: Global.REGEXP_FILTER_FORBIDDEN_SYMBOLS }]}
                  maxLength={User.PASSWORD_MAX_LENGTH}
                  placeholder={{ default: 'Contraseña' }}
                  textCenter
                  type='password' />
                <Button
                  animated
                  fullWidth
                  disabled={disableOnEmptyField}
                  id='login-btn'
                  type='submit'
                  className='login-form-element'
                  value='Iniciar sesión'
                  onWaitValue='Solicitando...'
                  isWaiting={disableUI}
                  form='login-form'
                  icon={LoginIcon} />
              </form>
            </div>
          </div>
          <div onClick={(e => {
            if (e.target.localName === 'a') UIRender.hideElement(popup.current);
          })}>
            <label>¿No tienes cuenta? <Link to={Global.PATH_SIGNUP}>regístrate</Link></label>
            <br />
            {/* Assign a path location */}
            <label>¿Olvidaste tus datos? <Link to={Global.PATH_RESTORE}>recupéralos</Link></label>
          </div>
        </div>
      </div>
    </div>
  );
}


export default Login;