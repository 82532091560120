import './styles/list.css';

/** ListElementObject typedef
 * @typedef {Object} ListElementObject
 * @property {*} icon An icon to be displayed aside the text.
 * @property {string} text The text to be displayed.
 */

/** Renders a List compo.
 * @param {Object} props The props object.
 * @param {ListElementObject[]} props.elements The list of elements to display in the list. It will be
 * replaced to an empty array if undefined is given. If no element is found, the props.emptyMessage
 * will be displayed.
 * @param {string} [props.emptyMessage] A message to display when props.elements is empty. 'Sin elementos'
 * is the default.
 * @param {boolean} [props.inlineItems] If true, list items will be displayed as inline-blocks
 * @param {string} [props.title] The title of the list. If undefined or empty, title won't be rendered.
 * @returns 
 */
const List = props => {
  const getClassName = () => {
    let className = 'list';

    if (props.inlineItems) className += ' inline-items';

    return className;
  }

  const getElementsLenght = () => {
    return props.elements?.length ?? 0;
  }

  const getNoElementsMessage = () => {
    return props.emptyMessage ?? 'Sin elementos';
  }

  const renderElements = () => {
    if (!props.elements) return [];

    const elements = [];

    for (let i = 0; i < props.elements.length; i++) {
      elements.push(<li key={`${props.elements[i].text}-${i}`}>
        {props.elements[i].icon !== undefined && <img
          className='item-icon'
          src={props.elements[i].icon}
          alt="item-icon" />}
        {props.elements[i].text}
      </li>);
    }

    return elements;
  }

  return (
    <div className={getClassName()}>
      {Boolean(props.title) && <h4 className="title">{props.title}</h4>}
      {getElementsLenght() > 0 && <ul>{renderElements()}</ul>}
      {getElementsLenght() === 0 && <h5 className="no-elements-message">{getNoElementsMessage()}</h5>}
    </div>
  );
}

export default List;