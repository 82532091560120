import '../styles/start-bidding.css';
import { useContext, useEffect, useRef, useState } from "react";
import { globalContext } from '../context/GlobalContext';
import Button from "../components/Button";
import * as Icons from '../assets/images';
import UIRender from "../objects/UIRender";
import LoadingPanel from "../components/LoadingPanel";
import DAOServ from "../objects/DAOServ";
import Inputbar from "../components/Inputbar";
import Bidding from "../objects/Bidding";
import Global from "../objects/Global";
import Hintbox from "../components/Hintbox";
import ErrHandler from '../objects/ErrHandler';

/** Renders a StartBidding popup compo.
 * @param {Object} props The props object.
 * @param {import('../objects/Estate').default} props.estate The estate that will start its bidding.
 * @param {() => void} props.onHide A callback function that will be triggered when popup hides.
 * @param {(bidding: Bidding) => void} props.onResolve A callback function that will be triggered
 * when popup resolves.
 */
const StartBidding = props => {
  // *** useContext ***
  const { currSession, pushMessageHint, timezoneOffset } = useContext(globalContext);
  // *** useRef ***
  const data = useRef({ endDate: '', minBid: 0 });
  const id = useRef('start-bidding');
  const popup = useRef(/** @type {HTMLDivElement} */(undefined));

  // *** useState ***
  const [disableUI, setDisableUI] = useState(/** @type {0|1|2} */(1));
  const [today, setToday] = useState(/** @type {number} */(undefined));

  const dateIsValidHandler = input => {
    if (!input) return false;

    const auxDate = Global.dateToMilliUTC(Global.transformDate(input), -timezoneOffset);

    return auxDate > today
      && auxDate - today <= Global.DAY_TO_MILLI * 30
      && auxDate - today >= Global.DAY_TO_MILLI * 14;
  }

  const inputHandleOnChange = (input, id) => {
    input = input ? id === 'endDate' ? Date.parse(input) : Number(input) : undefined;
    data.current[id] = input;
    setDisableUI(data.current.endDate && data.current.minBid ? 2 : 1);
  }

  /** @type {React.AnimationEventHandler} */
  const popupHandleOnHide = e => {
    if (e.target === popup.current && UIRender.isHidden(popup.current))
      props.onHide();
  }

  const submitBtnHandleOnClick = async () => {
    setDisableUI(0);

    const payload = {
      tst: currSession.tst,
      idEstate: props.estate?.getId(),
      endDate: data.current.endDate,
      minBid: data.current.minBid
    };

    await DAOServ.post('start_bidding', payload, 'JSON')
      .then(biddingId => {
        const bidding = new Bidding();

        bidding.setCreationDate(today);
        bidding.setEndDate(data.current.endDate);
        bidding.setId(biddingId['id']);
        bidding.setManualStatus(1);
        bidding.setMinimumBid(data.current.minBid);
        bidding.setStatus(true);
        props.onResolve(bidding);
        pushMessageHint({ message: 'Se ha registrado la subasta con éxito', type: 'complete' });
        UIRender.hideElement(popup.current);
      }).catch(err => {
        pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' });
        setDisableUI(2);
      });
  }

  useEffect(() => {
    const getToday = async () => {
      const auxToday = await DAOServ.getCurrentDay();
      setToday(auxToday);
    }

    getToday();
  }, []);

  // Dispose useEffect.
  useEffect(() => {
    const auxId = id.current;
    const options = {
      footer: true,
      exceptions: [Global.getElement('div#dialog-priv-estate-popup')],
      navbar: true
    };
    const parent = popup.current?.parentNode;

    UIRender.disableGlobalScroll(auxId);
    UIRender.disableSiblings(popup.current, options);

    return () => {
      // Enable childs.
      UIRender.enableChilds(parent, options);
      // Enable global scroll.
      UIRender.enableGlobalScroll(auxId);
    }
  }, [])

  return (
    <div className={`${id.current} popup-wrapper`} ref={popup} onAnimationEnd={popupHandleOnHide}>
      <div className="popup">
        <div className="top-bar">
          <h2 className="title highlight">Nueva subasta</h2>
          <Button borderless
            disabled={disableUI === 0}
            empty
            icon={Icons.CloseIcon}
            onClick={() => UIRender.hideElement(popup.current)}
            reduced
            rounded
            typeRender="error" />
        </div>
        {today === undefined && <LoadingPanel />}
        {today !== undefined && <div className="popup-content">
          <Inputbar disabled={disableUI === 0}
            filters={[{ regExp: Global.REGEXP_FILTER_INTEGER }]}
            isValid={input => input && Number(input) >= Bidding.MIN_BID}
            inputMode="numeric"
            maxLength={7}
            onChange={input => inputHandleOnChange(input, 'minBid')}
            placeholder={{
              default: 'Puja mínima (MXN)',
              onIsValidFail: 'La puja mínima debe ser de $50 MXN o mayor'
            }} requestFocus
            required />
          <Inputbar disabled={disableUI === 0}
            isValid={dateIsValidHandler}
            onChange={input => inputHandleOnChange(input, 'endDate')}
            placeholder={{
              default: 'Fecha de término',
              onIsValidFail: 'La subasta debe durar entre 14 y 30 días a partir de hoy'
            }} required
            type="date" />
          <Hintbox icon={Icons.InfoIcon}
            message={"Al hacer clic en 'Iniciar subasta' la propiedad se activará y entrará en subasta. "
              + "No podrás desactivar la propiedad, eliminarla ni crear contratos mientras la subasta esté "
              + "activa. Podrás finalizar la subasta antes si así lo deseas."}
            type='warning' />
          <br />
          <Button animated
            disabled={disableUI === 1}
            icon={Icons.PublIcon}
            isWaiting={disableUI === 0}
            onClick={submitBtnHandleOnClick}
            onWaitValue="Solicitando..."
            value="Iniciar subasta" />
        </div>}
      </div>
    </div>
  );
}

export default StartBidding;