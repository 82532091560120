import './styles/page-not-found.css';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Icons from './assets/images';
import Button from "./components/Button";
import UIRender from "./objects/UIRender";

/** Renders the PageNotFound compo.
 * @param {Object} props The props object
 * @param {string} [props.message] A mesage to be shown. 'La página solicitada no ha sido encontrada'
 * is the default.
 * @param {'kevin'|'vicky'|'aaron'|'alfred_1'|'alfred_2'} [props.pet] A pet to be rendered. If invalid
 * or undefined, 'kevin_not_found' will be rendered.
 * @param {string} [props.previousPage] The previous page. If undefined. Page will return to MAIN_PAGE
 * @param {string} [props.title] A title to be shown. '404 :(' is the default.
 */
const PageNotFound = props => {
  // *** useNavigate ***
  const navigate = useNavigate();

  const getImage = () => {
    switch (props.pet) {
      case 'aaron': return Icons.AaronImg;
      case 'alfred-1': return Icons.Alfred1Img;
      case 'alfred-2': return Icons.Alfred2Img;
      case 'kevin': return Icons.KevinImg;
      case 'vicky': return Icons.VickyImg;
      default: return Icons.KevinNotFoundImg;
    }
  }

  const getImageAlt = () => props.pet ? `${props.pet}` : 'kevin_not_found'

  const getImageClassName = () => props.pet || 'kevin-not-found'

  const goToMenuBtnClickHandler = () => navigate(props.previousPage || '/', { replace: true });

  useEffect(() => { UIRender.scrollTo(); }, [])

  return (
    <div className='container page-not-found'>
      <div className="box">
        <div className="img-container">
          <img className={getImageClassName()} src={getImage()} alt={getImageAlt()} />
        </div>
        <div className='message-container'>
          <h1 className='title'>{props.title || '404 :('}</h1>
          <h5 className='message' >{props.message || 'La página solicitada no se encontró.'}</h5>
          <div className="button-container">
            <Button id="go-to-menu-btn"
              empty
              borderless
              onClick={goToMenuBtnClickHandler}
              rotation={180}
              rounded
              value={props.previousPage ? 'Regresar' : 'Ir a inicio'}
              icon={Icons.ArrowIcon} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageNotFound;