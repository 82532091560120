import { useCallback, useEffect, useRef, useState } from 'react';
import Estate from '../../objects/Estate';
import Global from '../../objects/Global';
import Inputbar from '../../components/Inputbar';
import Inputbox from '../../components/Inputbox'
import PetAssistant from '../../components/PetAssistant';
import PropDisplay from '../../components/PropDisplay';
import PSCollection from '../../objects/PSCollection';
import Selectbar from '../../components/Selectbar';
import Textbox from '../../components/Textbox';
// Icons.
import * as Icons from '../../assets/images';
import Hintbox from '../../components/Hintbox';

/** Renders a Step4 compo for CreateEstate.
 * @typedef {import('../../objects/Address').default} Address
 * @param {Object} props The props object.
 * @param {(step: number) => void} props.addIgnoredStep A callback function that adds
 * an ignored step to the ignored steps array.
 * @param {Estate} [props.complex] A complex estate.
 * @param {Estate} props.estate The estate that's being created.
 * @param {(step: number)} props.removeIgnoredStep A callback function that removes
 * an ignored step to the ignored steps array.
 * @param {(val: boolean) =>} props.setEnableNextBtn A callback function used to enable
 * or disable next step button.
 * @param {number} props.today The current day in milliseconds.
 * @param {(obj: import('../CreateEstate').ShowLocObject) => void} props.toggleLocSel A 
 * function to toggle Select Location Popup.
 */
const Step4 = props => {
  // *** useRef ***
  const enableNextStepCallback = useRef(/** @type {flag: boolean => void} */(props.setEnableNextBtn));
  // *** useState ***
  const [sqSize, setSqSize] = useState(/** @type {Number} */(undefined));

  const getBuildDate = () => {
    if (props.complex !== undefined)
      return Global.transformDateForInput(props.complex.getBuildDate())
    else props.estate.getBuildDate();
  }

  const inputboxHandleOnAdd = input => {
    props.estate.getRequirements().push(input);
  }

  const inputboxHandleOnBeforeAdd = input => {
    return input?.replace(/\s+/g, ' ');
  }

  const inputboxHandleOnDelete = index => {
    props.estate.getRequirements().splice(index, 1);
  }

  /** Obtains the default value for the select elements from step = 3.
   * @param {*} id The identifier of the estate.
   * @returns {string} Property current value or undefined.
   */
  const propertyDefaultValue = id => {
    const prop = props.estate.getProperty(id);
    return prop ? prop.value : "";
  }

  /** Property and Service change handler
   * @param {*} value 
   * @param {string} id Specified by PSCollection globals.
   */
  const propServHandleOnChange = (value, id) => {
    if (/^s_[a-z]+$/.test(id)) { // Service management.
      props.estate.setService(id, value);

      if (props.estate.getSellMethod() === Estate.ON_LEASE) {
        if (!value) {
          const inclServ = props.estate.getContract().getInclServs();
          const indx = inclServ.find(iS => iS === id);

          if (indx !== -1) inclServ.splice(indx, 1);
        }

        // Ignoring on lease including services.
        if (!props.estate.hasService()) props.addIgnoredStep(4);
        else props.removeIgnoredStep(4);
      }
    } else { // Property management.
      const currProp = props.estate.getProperty(id); // Current property.
      const parkingLot = id === PSCollection.PROP_PARK && Estate.getParkingType(props.estate) === 0;

      if (currProp) {
        if (!value || (!parkingLot && Number(value) <= 0)) props.estate.removeProperty(id);
        else props.estate.setProperty(id, Number(value));
      } else if (value > 0) {
        props.estate.addProperty(id, Number(value));
      }
    }

    requestNextStep();
  }

  /** Request next step to parent component */
  const requestNextStep = useCallback(() => {
    const description = props.estate.getDescription();
    const dimension = props.estate.getSquaredSize();
    const construct = PSCollection.constructProperties(props.estate);
    const location = props.estate.getLocation().getGeoData().lat && props.estate.getLocation().getGeoData().lng;
    const validBuildDate = (props.complex?.getBuildDate() || props.estate.getBuildDate()) <= props.today;

    enableNextStepCallback.current(construct && description && dimension && location && validBuildDate);
  }, [props.complex, props.estate, props.today]);

  /**
   * Obtains the default value for the select elements from step = 3.
   * @param {*} id The identifier of the service.
   * @returns {string} Service current value or undefined.
   */
  const serviceDefaultValue = id => {
    const service = props.estate.getService(id);
    return !service ? "-1" : Number(service.value);
  }

  useEffect(() => requestNextStep(), [requestNextStep])

  return (
    <div className="popup-content create-estate-content" id="step-3">
      <PetAssistant pet='kevin'
        message={'¡Genial! Háblame más de tu propiedad: Ubicación, descripción,'
          + ' características, cosas como esas...'} />
      <div>
        <div className="flex-box">
          <div className="child m3">
            {/* Location */}
            <PropDisplay disabled={props.complex} className='max-width clickable'
              header='Ubicación (Motorizado por Google Maps).'
              id='location'
              property={props.estate.getLocation().getAddress() || 'Haz click aquí para cambiarla'}
              onClick={() => {
                if (!props.complex && props.toggleLocSel) {
                  props.toggleLocSel({
                    defaultValue: props.estate.getLocation().getAddress(),
                    onResolve: addr => {
                      props.estate.setLocation(addr);
                      requestNextStep();
                    }
                  });
                }
              }}
            />
          </div>
          {props.complex && <div className="child auto-width m3">
            <Hintbox icon={Icons.InfoIcon}
              message={'La ubicación fue heredada de la super propiedad'}
              maxWidth={250} />
          </div>}
        </div>
        {/* Description */}
        <Textbox defaultValue={props.estate.getDescription()}
          filters={[{ regExp: Global.REGEXP_FILTER_FORBIDDEN_SYMBOLS }]}
          header={{ default: 'Descripción', onMinLengthFail: 'Ingresa al menos 20 caracteres' }}
          maxLength={2000}
          minLength={20}
          onBlur={input => input?.replace(/^\s+|\s+$/, '')}
          onChange={input => {
            props.estate.setDescription(input);
            requestNextStep();
          }}
          placeholder='Escribe sobre tu propiedad aquí....'
          required />
        <br />
        {/* Details */}
        <h5 className="overset">Características</h5>
        <div className="box shadowless borderless">
          <h4 className="highlight">
            Del terreno{sqSize > 0 && <span> ({Global.formatNumber(sqSize)} mts<span className='exp-text'>2</span>)</span>}
          </h4>
          {/* Size */}
          <div className="flex-box m5">
            <div className="child">
              <Inputbar
                onChange={input => {
                  props.estate.setSize({ x: (input && Number(input)) || 0 });
                  setSqSize((props.estate.getSize().x * props.estate.getSize().y).toFixed(2));
                  requestNextStep();
                }}
                defaultValue={props.estate.getSize().x || ''}
                filters={[{ regExp: Global.REGEXP_FILTER_DECIMAL }]}
                inputMode='decimal'
                isValid={input => !isNaN(Number(input)) && Number(input) > 0}
                maxLength={5}
                placeholder={{ default: 'Ancho (mts)', onIsValidFail: 'Ingresa un valor mayor a 0' }}
                required />
            </div>
            <div className="child auto-width">
              <h4 className="highlight">X</h4>
            </div>
            <div className="child">
              <Inputbar
                onChange={input => {
                  props.estate.setSize({ y: (input && Number(input)) || 0 });
                  setSqSize((props.estate.getSize().x * props.estate.getSize().y).toFixed(2));
                  requestNextStep();
                }}
                defaultValue={props.estate.getSize().y || ''}
                filters={[{ regExp: Global.REGEXP_FILTER_DECIMAL }]}
                inputMode='decimal'
                isValid={input => !isNaN(Number(input)) && Number(input) > 0}
                maxLength={5}
                placeholder={{ default: 'Largo (mts)', onIsValidFail: 'Ingresa un valor mayor a 0' }}
                required />
            </div>
          </div>
          <div className="flex-box m3 wrap">
            {/* Build date. */}
            <div className="child">
              <Inputbar defaultValue={getBuildDate()}
                disabled={props.complex}
                icon={Icons.BuilIcon}
                isValid={input => Global.REGEXP_DATE.test(Global.transformDate(input))
                  && Date.parse(input) <= props.today}
                onChange={input => {
                  props.estate.setBuildDate(input);
                  requestNextStep();
                }} placeholder={{
                  default: props.estate.getType() === Estate.TYPE_TERRAIN
                    ? 'Fecha de adquisición' : 'Fecha de construcción',
                  onIsValidFail: 'Ingresa la fecha de hoy o anterior'
                }} required
                type='date' />
            </div>
            {/* Insurance */}
            {props.estate.getType() !== Estate.TYPE_HOTEL && <div className="child">
              <Inputbar defaultValue={props.complex?.getInsurance() || props.estate.getInsurance()}
                disabled={props.complex}
                filters={[{ regExp: Global.REGEXP_FILTER_SYMBOLS }]}
                icon={Icons.InsuIcon}
                maxLength={Estate.MAX_INSURANCE_LENGTH}
                onChange={input => {
                  props.estate.setInsurance(input);
                  requestNextStep();
                }}
                placeholder={{
                  default: props.complex
                    ? !props.complex.getInsurance()
                      ? 'Sin seguro' : 'Seguro del complejo'
                    : 'Nombre de la aseguradora'
                }}
                textTransform='uppercase' />
            </div>}
          </div>
          {props.complex !== undefined && <Hintbox icon={Icons.InfoIcon}
            message={'La fecha de construcción o adquisición y el seguro fueron heredados de la super '
              + 'propiedad.'} />}
        </div>
        {/* Basic and extra properties and services */}
        <div className={`${props.estate.getType() !== Estate.TYPE_TERRAIN ? 'flex-box wrap' : ''}`}>
          {/* Basics */}
          {props.estate.getSellMethod() !== Estate.ON_COMPLEX && <div className="box shadowless borderless">
            <h4 className="highlight">Básicas</h4>
            {/* Bathroom */}
            {PSCollection.canHaveProperty(props.estate, PSCollection.PROP_BTHR) && <Inputbar
              defaultValue={propertyDefaultValue(PSCollection.PROP_BTHR)}
              filters={[{ regExp: Global.REGEXP_FILTER_INTEGER }]}
              icon={Icons.BathIcon}
              inputMode='numeric'
              maxLength={3}
              onChange={input => propServHandleOnChange(input, PSCollection.PROP_BTHR)}
              placeholder={{ default: 'No. de baños' }}
              required={PSCollection.isPropertyRequired(props.estate, PSCollection.PROP_BTHR)} />}
            {/* Kitchen */}
            {PSCollection.canHaveProperty(props.estate, PSCollection.PROP_KTCH) && <Inputbar
              defaultValue={propertyDefaultValue(PSCollection.PROP_KTCH)}
              filters={[{ regExp: Global.REGEXP_FILTER_INTEGER }]}
              icon={Icons.KitchIcon}
              inputMode='numeric'
              maxLength={3}
              onChange={input => propServHandleOnChange(input, PSCollection.PROP_KTCH)}
              placeholder={{ default: 'No. de cocinas' }}
              required={PSCollection.isPropertyRequired(props.estate, PSCollection.PROP_KTCH)} />}
            {/* Parking lot */}
            {PSCollection.canHaveProperty(props.estate, PSCollection.PROP_PARK) && <div>
              {Estate.getParkingType(props.estate) === 1 && <Inputbar
                defaultValue={propertyDefaultValue(PSCollection.PROP_PARK)}
                filters={[{ regExp: Global.REGEXP_FILTER_INTEGER }]}
                icon={Icons.ParkIcon}
                inputMode='numeric'
                maxLength={3}
                onChange={input => propServHandleOnChange(input, PSCollection.PROP_PARK)}
                placeholder={{ default: 'No. de garajes o espacios para estacionar' }} />}
              {Estate.getParkingType(props.estate) === 0 && <Selectbar
                options={[{ displayValue: 'Sí', value: 1 }, { displayValue: 'No', value: 0 }]}
                defaultValue={!propertyDefaultValue(PSCollection.PROP_PARK) ? 0 : 1}
                onChange={input => propServHandleOnChange(input, PSCollection.PROP_PARK)}
                placeholder='Estacionamiento' />}
            </div>}
            {/* Bedroom */}
            {PSCollection.canHaveProperty(props.estate, PSCollection.PROP_BEDR) && <Inputbar
              defaultValue={propertyDefaultValue(PSCollection.PROP_BEDR)}
              filters={[{ regExp: Global.REGEXP_FILTER_INTEGER }]}
              icon={props.estate.getType() === Estate.TYPE_BUILDING ? Icons.OfficeIcon : Icons.BedrIcon}
              inputMode='numeric'
              maxLength={3}
              onChange={input => propServHandleOnChange(input, PSCollection.PROP_BEDR)}
              placeholder={{
                default: props.estate.getType() === Estate.TYPE_BUILDING
                  ? 'No. de oficinas' : 'No. de habitaciones'
              }}
              required={PSCollection.isPropertyRequired(props.estate, PSCollection.PROP_BEDR)} />}
            {/* Levels */}
            {PSCollection.canHaveProperty(props.estate, PSCollection.PROP_LVEL) && <Inputbar
              defaultValue={propertyDefaultValue(PSCollection.PROP_LVEL)}
              filters={[{ regExp: Global.REGEXP_FILTER_INTEGER }]}
              icon={Icons.LvelIcon}
              inputMode='numeric'
              maxLength={3}
              onChange={input => propServHandleOnChange(input, PSCollection.PROP_LVEL)}
              placeholder={{ default: 'No. de niveles' }}
              required={PSCollection.isPropertyRequired(props.estate, PSCollection.PROP_LVEL)}
              title='Número de pisos (incluyendo planta baja)' />}
            {/* Water service */}
            <Selectbar options={[{ displayValue: 'No', value: 0 }, { displayValue: 'Sí', value: 1 }]}
              defaultValue={serviceDefaultValue(PSCollection.SERV_WTER)}
              icon={Icons.WtrIcon}
              onChange={option => propServHandleOnChange(option, PSCollection.SERV_WTER)}
              placeholder='Servicio de agua y alcantarillado'
              required
              undefinedOption='Sin especificar' />
            {/* Electric service */}
            <Selectbar options={[{ displayValue: 'No', value: 0 }, { displayValue: 'Sí', value: 1 }]}
              defaultValue={serviceDefaultValue(PSCollection.SERV_ENGY)}
              icon={Icons.EngIcon}
              onChange={option => propServHandleOnChange(option, PSCollection.SERV_ENGY)}
              placeholder='Servicio de luz'
              required
              undefinedOption='Sin especificar' />
          </div>}
          {/* Extra properties */}
          {props.estate.getSellMethod() !== Estate.ON_COMPLEX && props.estate.getType() !== Estate.TYPE_TERRAIN &&
            <div className="box shadowless borderless">
              <h4 className="highlight">Extras</h4>
              {/* People capacity */}
              {PSCollection.canHaveProperty(props.estate, PSCollection.PROP_PLCY) && <Inputbar
                defaultValue={propertyDefaultValue(PSCollection.PROP_PLCY)}
                filters={[{ regExp: Global.REGEXP_FILTER_INTEGER }]}
                icon={Icons.PplCapIcon}
                inputMode='numeric'
                maxLength={3}
                onChange={input => propServHandleOnChange(input, PSCollection.PROP_PLCY)}
                placeholder={{ default: 'Capacidad de habitantes' }} />}
              {/* Cistern */}
              {PSCollection.canHaveProperty(props.estate, PSCollection.PROP_CSTR) && <Inputbar
                defaultValue={propertyDefaultValue(PSCollection.PROP_CSTR)}
                filters={[{ regExp: Global.REGEXP_FILTER_INTEGER }]}
                icon={Icons.CisternIcon}
                inputMode='numeric'
                maxLength={3}
                onChange={input => propServHandleOnChange(input, PSCollection.PROP_CSTR)}
                placeholder={{ default: 'No. de cisternas' }} />}
              {/* Exterior */}
              {PSCollection.canHaveProperty(props.estate, PSCollection.PROP_PTIO) && <Inputbar
                defaultValue={propertyDefaultValue(PSCollection.PROP_PTIO)}
                filters={[{ regExp: Global.REGEXP_FILTER_INTEGER }]}
                icon={Icons.PatioIcon}
                inputMode='numeric'
                maxLength={3}
                onChange={input => propServHandleOnChange(input, PSCollection.PROP_PTIO)}
                placeholder={{ default: 'No. de exteriores' }}
                title='Número de patios o exteriores (sin contar cocheras).' />}
              {/* Pool */}
              {PSCollection.canHaveProperty(props.estate, PSCollection.PROP_POOL) && <Inputbar
                defaultValue={propertyDefaultValue(PSCollection.PROP_POOL)}
                filters={[{ regExp: Global.REGEXP_FILTER_INTEGER }]}
                icon={Icons.PoolIcon}
                inputMode='numeric'
                maxLength={3}
                onChange={input => propServHandleOnChange(input, PSCollection.PROP_POOL)}
                placeholder={{ default: 'No. de piscinas' }}
                title='Número de piscinas o similares.' />}
              {/* Water tank */}
              {PSCollection.canHaveProperty(props.estate, PSCollection.PROP_WTNK) && <Inputbar
                defaultValue={propertyDefaultValue(PSCollection.PROP_WTNK)}
                filters={[{ regExp: Global.REGEXP_FILTER_INTEGER }]}
                icon={Icons.WtrTankIcon}
                inputMode='numeric'
                maxLength={3}
                onChange={input => propServHandleOnChange(input, PSCollection.PROP_WTNK)}
                placeholder={{ default: 'No. de tinacos' }} />}
              {/* Food service */}
              {PSCollection.canHaveService(props.estate, PSCollection.SERV_FOOD) && <Selectbar
                options={[{ displayValue: 'No', value: 0 }, { displayValue: 'Sí', value: 1 }]}
                defaultValue={serviceDefaultValue(PSCollection.SERV_FOOD)}
                icon={Icons.FoodIcon}
                onChange={option => propServHandleOnChange(option, PSCollection.SERV_FOOD)}
                placeholder='Servicio de alimentos'
                undefinedOption='Sin especificar' />}
              {/* Gas service */}
              {PSCollection.canHaveService(props.estate, PSCollection.SERV_GAS) && <Selectbar
                options={[{ displayValue: 'No', value: 0 }, { displayValue: 'Sí', value: 1 }]}
                defaultValue={serviceDefaultValue(PSCollection.SERV_GAS)}
                icon={Icons.GasIcon}
                onChange={option => propServHandleOnChange(option, PSCollection.SERV_GAS)}
                placeholder='Servicio de gas'
                undefinedOption='Sin especificar' />}
              {/* Net service */}
              {PSCollection.canHaveService(props.estate, PSCollection.SERV_INET) && <Selectbar
                options={[{ displayValue: 'No', value: 0 }, { displayValue: 'Sí', value: 1 }]}
                defaultValue={serviceDefaultValue(PSCollection.SERV_INET)}
                icon={Icons.NetIcon}
                onChange={option => propServHandleOnChange(option, PSCollection.SERV_INET)}
                placeholder='Servicio de internet y/o cable'
                undefinedOption='Sin especificar' />}
              {/* Housekeeping service */}
              {PSCollection.canHaveService(props.estate, PSCollection.SERV_HKPG) && <Selectbar
                options={[{ displayValue: 'No', value: 0 }, { displayValue: 'Sí', value: 1 }]}
                defaultValue={serviceDefaultValue(PSCollection.SERV_HKPG)}
                icon={Icons.HkpIcon}
                onChange={option => propServHandleOnChange(option, PSCollection.SERV_HKPG)}
                placeholder='Servicio de limpieza'
                undefinedOption='Sin especificar' />}
            </div>}
        </div>
        {/* Requirements and Tags */}
        {props.estate.getSellMethod() !== Estate.ON_COMPLEX && <div>
          {props.estate.getSellMethod() === Estate.ON_LEASE && <div className='box borderless shadowless'>
            <h4 className="highlight">Requisitos</h4>
            <Hintbox icon={Icons.InfoIcon}
              message={'Agrega los requisitos que las personas deben cumplir para arrendar tu '
                + 'propiedad (por ejemplo: aval). Puedes agregar hasta '
                + `${Estate.MAX_REQUIREMENTS}.`} />
            <Inputbox filters={[{ regExp: Global.REGEXP_FILTER_FORBIDDEN_SYMBOLS }]}
              inputIcon={Icons.FilterIcon}
              inputMaxLength={Estate.MAX_REQ_TAQ_LENGTH}
              inputMinLength={3}
              inputPlaceholder={{ default: 'Requisito (enter para enviar)' }}
              items={[...props.estate.getRequirements()]}
              maxItems={Estate.MAX_REQUIREMENTS}
              onAdd={input => inputboxHandleOnAdd(input)}
              onDelete={index => inputboxHandleOnDelete(index)}
              onBeforeAdd={inputboxHandleOnBeforeAdd}
              showInputbar />
          </div>}
        </div>
        }
      </div>
    </div>
  );
}

export default Step4;