import './styles/user-contact-info.css'
import { useContext, useEffect, useRef, useState } from 'react';
import { globalContext } from '../context/GlobalContext';
import * as Icons from '../assets/images';
import Button from './Button';
import DAOServ from '../objects/DAOServ';
import ErrHandler from '../objects/ErrHandler';
import FileChooser from '../objects/FileChooser';
import Global from '../objects/Global';
import LoadingBlock from './LoadingBlock';
import PropDisplay from './PropDisplay';
import Persona from '../objects/Persona';


/** Renders a ContactInfoCard compo
 * @param {Object} props The props object.
 * @param {number} props.creationDate Use this param to set the creation date (when the contact info
 * is a contact request, for example). No offset will be applied
 * @param {() => void} [props.onRemoveBtnClick] A callback function that will be triggered when delete
 * button is pushed. If undefined, button won't be rendered.
 * @param {'normal'|'golden'} [props.type] The compo's type render. 'normal' is the default.
 * @param {import('../objects/User').default} props.user The user to be displayed. App will crash if
 * undefined. If its contact info is undefined or incomplete or its picture is undefined, compo will
 * try to fetch it (its username attribute must be defined for the compo to fetch it).
 */
const ContactInfoCard = props => {
  // *** useContext ***
  const {
    currSession,
    getCacheFile,
    pushAlertMessage,
    pushCacheFile,
    pushMessageHint
  } = useContext(globalContext);
  // *** useRef ***
  const user = useRef(/** @type {import('../objects/User').default} */(undefined));
  // *** useState ***
  const [contactInfo, setContactInfo] = useState(
    /** @type {import('../objects/Persona').default} */(undefined)
  );
  const [userPic, setUserPic] = useState(props.user.getPicture().getURLData())

  const copyToClipboard = copy => {
    Global.copyToClipboard(copy)
      .then(() => pushAlertMessage({ message: 'Copiado al portapapeles', type: 'complete' }))
      .catch(err => pushAlertMessage({ message: ErrHandler.parseError(err), type: 'error' }));
  }

  useEffect(() => {
    user.current = props.user;
    setContactInfo(new Persona({
      email: user.current.getEmail(),
      firstName: user.current.getName(),
      phone: user.current.getPhone()
    }));
    setUserPic();
  }, [props.user]);

  useEffect(() => {
    const fetchUserData = () => {
      DAOServ.fetchUserContactInfo(currSession.tst, user.current.getUsername())
        .then(data => {
          data.setId(data.getId() || 0)
          setContactInfo(data);
        }).catch(err => pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' }));
    }

    const fetchUserPicture = async () => {
      const picture = user.current.getPicture();
      let newPicture = Icons.DefUsPic;
      // Checking if picture is in cache.
      const auxCF = getCacheFile(picture?.getName());

      if (auxCF !== undefined) { // Fetch file from cache.
        newPicture = typeof auxCF.content === 'string'
          ? auxCF.content
          : await FileChooser.readAsDataURL(auxCF.content);
        picture.setSize(auxCF.size);
        picture.setURLData(newPicture);
        setUserPic(newPicture);
      } else { // Fetch file from server.
        DAOServ.getFileCallback(picture.getName(), async (err, file) => {
          if (file) {
            newPicture = await FileChooser.readAsDataURL(file);
            picture.setURLData(newPicture);
            picture.setSize(file.size);
            pushCacheFile({
              content: newPicture,
              name: picture.getName(),
              size: file.size
            });
          }

          setUserPic(newPicture);
        });
      }
    }

    const hasMissingData = () => {
      return contactInfo.getName() === undefined
        || contactInfo.getEmail() === undefined
        || contactInfo.getId() === undefined
        || contactInfo.getPhone()?.code === undefined
        || contactInfo.getPhone().number === undefined
        || contactInfo.getPhone().prefix === undefined;
    }

    if (currSession.sessionStatus && contactInfo !== undefined) {
      if (hasMissingData())
        fetchUserData();
      else if (userPic === undefined) {
        if (user.current.getPicture()?.getName() === undefined)
          setUserPic(Icons.DefUsPic);
        else if (user.current.getPicture()?.getURLData() !== undefined)
          setUserPic(user.current.getPicture().getURLData());
        else
          fetchUserPicture();
      }
    }
  }, [currSession, contactInfo, getCacheFile, pushCacheFile, pushMessageHint, userPic]);

  return (<div className={`user-contact-info${props.type === 'golden' ? ' golden' : ''}`}>
    <div className="top-info-container">
      <div className="img-container">
        {userPic === undefined && <LoadingBlock miniFigure />}
        {userPic !== undefined && <img src={userPic} alt={user.current.getPicture().getName() ?? 'def_pic'} />}
      </div>
      <div className="userdata-container">
        <h4 className="username">@{props.user.getUsername()}</h4>
        {props.creationDate !== undefined && <h6 className="creation-date highlight">
          Creación: <span className="overset">{Global.parseDateWithTime(props.creationDate)}</span>
        </h6>}
      </div>
      {props.onRemoveBtnClick !== undefined && <div className="delete-button-container">
        <Button borderless
          empty
          icon={Icons.DeleteIcon}
          isWaiting={contactInfo === undefined}
          onClick={props.onRemoveBtnClick}
          rounded
          typeRender='error' />
      </div>}
    </div>
    <div className="contact-info-container">
      <h5 className="highlight">Contacto.</h5>
      {/* Name */}
      <div className="flex-box">
        <div className="child jc-left">
          <PropDisplay header='Nombre'
            property={contactInfo?.getName()}
            waiting={contactInfo === undefined} />
        </div>
        <div className="child auto-width">
          <Button borderless
            empty
            icon={Icons.CopyIcon}
            isWaiting={contactInfo === undefined}
            onClick={() => copyToClipboard(contactInfo.getName())}
            reduced
            rounded />
        </div>
      </div>
      {/* E-mail */}
      <div className="flex-box">
        <div className="child jc-left">
          <PropDisplay header='Correo'
            property={contactInfo?.getEmail()}
            waiting={contactInfo === undefined} />
        </div>
        <div className="child auto-width">
          <Button borderless
            empty
            icon={Icons.CopyIcon}
            isWaiting={contactInfo === undefined}
            onClick={() => copyToClipboard(contactInfo.getEmail())}
            reduced
            rounded />
        </div>
      </div>
      {/* Phone */}
      <div className="flex-box">
        <div className="child jc-left">
          <PropDisplay header='Teléfono'
            property={`${contactInfo?.getPhone().prefix} ${contactInfo?.getPhone().number}`}
            waiting={contactInfo === undefined} />
        </div>
        <div className="child auto-width">
          <Button borderless
            empty
            icon={Icons.CopyIcon}
            onClick={() => copyToClipboard(`${contactInfo.getPhone().prefix}${contactInfo.getPhone().number}`)}
            reduced
            rounded />
        </div>
      </div>
    </div>
  </div>);
}

export default ContactInfoCard;