import './styles/agre-esta-viewer.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useRef, useState } from 'react';
import { globalContext } from './context/GlobalContext';
import AgreEstaResultItem from './components/AgreEstaResultItem';
import LoadingPanel from './components/LoadingPanel';
import NotFoundBox from './components/NotFoundBox';
import Global from './objects/Global';
import User from './objects/User';
import { KevinNotFoundImg } from './assets/images';
import UIRender from './objects/UIRender';
import ErrHandler from './objects/ErrHandler';
import DAOServ from './objects/DAOServ';
import Bidding from './objects/Bidding';
import Contract from './objects/Contract';
import Estate from './objects/Estate';
import GenericFile from './objects/GenericFile';
import Bid from './objects/Bid';
import PageSelector from './components/PageSelector';

/** The Agreements or Estates viewer.
 * @param {Object} props The props object.
 * @param {string} props.key A key assigned by App.jsx.
 * @param {'contracts'|'estates'} props.mode Depending of what's set, it's what fetched data will be.
 */
const AgreEstaViewer = props => {
  // *** useContext ***
  const { currSession, pushMessageHint, setSearchMethod } = useContext(globalContext);
  // *** useLocation ***
  const location = useLocation();
  // *** useNavigate ***
  const navigate = useNavigate();
  // *** useState ***
  const [fetchData, setFetchData] = useState(    /** @type {Estate[]} */(undefined));
  // *** useRef ***
  const currPage = useRef(/** @type {number} */(location.state?.page || 0));
  const elements = useRef(/** @type {{e: React.LegacyRef<Element> , id: string}[]} */(undefined));
  const mode = useRef(props.mode);
  const searchbarInput = useRef(/** @type {string} */(location.state?.searchbarInput || ''));

  /** @type {React.MouseEventHandler<Element>} */
  const estatesContainerClickHandler = e => {
    const element = elements.current.find(el => {
      const { current } = el.e;

      return current === e.target || current.contains(e.target);
    });

    if (element !== undefined) {
      const id = props.mode === 'estates'
        ? element.id.split('-')?.at(1)
        : element.id;
      const path = props.mode === 'estates'
        ? Global.PATH_ESTATES_VIEW
        : Global.PATH_CONTRACT;

      navigate(`${path}/${id}`, { state: { idEstate: id } })
    }
  }

  const renderResults = () => {
    return fetchData.map(fD => {
      const id = props.mode === 'contracts'
        ? `${fD.getContract().getAgreement().getHash()}`
        : `estate-${fD.getId()}`;

      const getKey = () => {
        return fD.getContract()?.getAgreement()?.getHash() !== undefined
          ? fD.getContract().getAgreement().getHash()
          : `estate-${fD.getId()}`;
      }

      const element = elements.current.find(e => e.id === id);

      return <AgreEstaResultItem estate={fD} key={getKey()} reference={element.e} />
    })
  }

  // fetch useEffect.
  useEffect(() => {
    const fetchAgreements = async () => {
      setFetchData([]);
    }

    const fetchEstates = async () => {
      const { tst } = currSession;
      let auxData = [];

      try {
        const request = await DAOServ.post('get_publishments_priv', { tst, getAll: true }, 'JSON');
        const reqArr = Array.from(request ?? []);

        // Saving estates into array.
        for (let i = 0; i < reqArr.length; i++) {
          const auxEsta = new Estate();

          auxEsta.getImages().push(new GenericFile({ name: reqArr[i]['cover'] }));
          auxEsta.setCreationDate(Number(reqArr[i]['creation_date']));
          auxEsta.setId(Number(reqArr[i]['idestate']));
          auxEsta.setStatus(Number(reqArr[i]['status']));
          auxEsta.setTitle(reqArr[i]['title']);
          auxEsta.setType(Number(reqArr[i]['e_type']));
          auxEsta.setSellMethod(Number(reqArr[i]['sell_method']));

          // ** Bidding **
          if (reqArr[i]['idbidding']) {
            const bidding = new Bidding();

            bidding.setEndDate(Number(reqArr[i]['end_date']));
            bidding.setId(Number(reqArr[i]['idbidding']));
            bidding.setMinimumBid(Number(reqArr[i]['min_bid']));
            bidding.setStatus(true);

            if (reqArr[i]['idmax_bid']) { // A bid has been offered.
              const bid = new Bid();

              bid.setAmount(Number(reqArr[i]['max_bid_amount']));
              bid.setId(Number(reqArr[i]['idmax_bid']));

              bidding.getBids().push(bid);
            }

            // max bid is pending.
            auxEsta.setBidding(bidding);
          }

          // ** Contract **
          if (reqArr[i]['idcontract']) {
            const contract = new Contract();

            contract.setCharge(Number(reqArr[i]['charge_at_start']));
            contract.setId(Number(reqArr[i]['idcontract']));
            contract.setPayAmount(Number(Number(reqArr[i]['pay_amount'])));
            contract.setPayFrequency(Number(reqArr[i]['pay_frequency']));
            contract.setTerm(Number(reqArr[i]['term']));
            contract.setTermMethod(Number(reqArr[i]['term_method']));

            auxEsta.setContract(contract);
          }

          auxData.push(auxEsta);
        }
      } catch (err) {
        pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' })
        auxData = [];
      } finally {
        elements.current = [];
        auxData.forEach(aD => elements.current.push({ e: { current: undefined }, id: `estate-${aD.getId()}` }));
        setFetchData(auxData);
      }
    }

    if (currSession.sessionStatus) {
      if (currSession.sessionStatus === User.STATUS_ACT) {
        if (currSession.isSubuser) {
          pushMessageHint({ message: 'Acceso denegado', type: 'error' });
          navigate('/', { replace: true });
        } else if (fetchData === undefined) {
          if (mode.current === 'contracts') fetchAgreements();
          else fetchEstates();

          UIRender.scrollTo();
        }
      } else navigate('/', { replace: true });
    }

  }, [currSession, fetchData, navigate, pushMessageHint]);

  // search bar useEffect.
  useEffect(() => {
    if (!location.state || location.state.search !== searchbarInput.current) {
      searchbarInput.current = location.state?.search || '';

      setSearchMethod({
        action: input => navigate(Global.PATH_ESTATES_VIEW, { replace: true, state: { search: input || '' } }),
        type: 'input',
        placeholder: 'Buscar por título',
        value: location.state?.search || ''
      });
    }
  }, [navigate, location, setSearchMethod]);

  // Page update.
  useEffect(() => {
    if (location.state && location.state.page !== currPage) {
      currPage.current = location.state.page || 0;
      setFetchData(undefined);
    }
  }, [location]);

  return (
    <div className="agre-esta-viewer">
      <h1 className="highlight">
        {mode.current === 'estates' ? 'Mis propiedades.' : 'Mis contratos.'}
      </h1>
      {fetchData === undefined && <LoadingPanel className='full-height' />}
      <div className={`box borderless fetch-results${fetchData?.length === 0 ? ' empty' : ''}`}
        onClick={estatesContainerClickHandler}>
        {fetchData?.length === 0 && <NotFoundBox img={KevinNotFoundImg} />}
        {fetchData?.length > 0 && renderResults()}
      </div>
      {fetchData?.length > 0 && <PageSelector currentPage={currPage.current}
        maxPage={10}
        onBtnPush={btn => navigate(Global.PATH_ESTATES_VIEW, { replace: true, state: { page: btn } })} />}
    </div>
  );
}

export default AgreEstaViewer;