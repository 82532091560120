import './styles/not-found-box.css';
import { NoDataImg } from '../assets/images';

/** Renders a not found box compo.
 * @param {Object} props The props object.
 * @param {string} [props.className] A custom class name.
 * @param {*} [props.img] An image to be displayed. NoDataImg is the default image.
 * @param {string} [props.message] A message to be shown. 'Sin resultados' is the default message.
 * @param {boolean} [props.reduced] If true, compo will be reduced.
 */
const NotFoundBox = props => {
  const getClassName = () => {
    let className = 'not-found-box';

    if (props.reduced) className += ' reduced';
    if (props.className) className += ` ${props.className}`;

    return className;
  }

  return (
    <div className={getClassName()}>
      <img src={props.img || NoDataImg} alt="" />
      <h4 className="overset">{props.message || 'Sin resultados'}</h4>
    </div>
  );
}

export default NotFoundBox;