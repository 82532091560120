import { forwardRef, useRef } from "react";
import * as Icons from '../../assets/images';
import Button from "../../components/Button";
import Global from "../../objects/Global";
import GuestItem from "../../components/GuestItem";
import NotFoundBox from "../../components/NotFoundBox";

/** Props Type Definition.
 * @typedef {Object} Props The props object
 * @property {boolean} disableUI A boolean to disable ui.
 * @property {import('../SignProcess.jsx').GuestObject[]} guests The list of connected and banned guests.
 * @property {import('../../objects/ClientSocket.js').default} socket The client socket.
 * @property {boolean} ownerMode If true, GuestManagement compo will be rendered for the owner.
 * @property {(e: import('../../components/popups/Dialog.jsx').DialogPropsObject) => void} setShowDialog
 * A callback function to invoke Dialog Popup from parent if needed.
 * @property {(e: boolean) => void} setDisableUI A callback function to disable ui.
 * @property {(username: string) => void} setUserOnBan A callback function to ser the username
 * of an user that's being banned.
 */

/** Renders a GuestsManagement compo for SignProcess component.
 * @type {React.ForwardRefRenderFunction<?, Props>}
 */
const GuestsManagement = forwardRef((props, popup) => {
  // *** useRef ***
  const compoId = useRef('guests-management');

  /** @type {React.MouseEventHandler<HTMLButtonElement>} */
  const banListHandleOnClick = e => {
    const btn = Global.findParent(e.target, { className: 'button', maxChecks: '2' });

    if (btn) { // A ban button was clicked.
      // Obtains the guest item.
      const item = btn.parentNode.parentNode;
      // Toggle dialog.
      props.setShowDialog({
        confirmBtn: { icon: Icons.UnbanIcon },
        message: `${item.id} será eliminado de la lista negra y podrá unirse a la sala nuevamente.`,
        onResolve: () => {
          props.setDisableUI(true)
          props.socket.sendMessage('UNBAN_GUEST', { name: item.id });
        }, rejectBtn: { value: 'Cancelar', type: 'error' }
      });
    }
  }

  /** @type {React.MouseEventHandler<HTMLButtonElement>} */
  const guestsListHandleOnClick = e => {
    const btn = Global.findParent(e.target, { className: 'button', maxChecks: '2' });

    if (btn) { // A ban button was clicked.
      // Obtains the guest item.
      const item = btn.parentNode.parentNode;
      // Toggle dialog.
      props.setShowDialog({
        confirmBtn: { value: 'Continuar' },
        message: `${item.id} será expulsado y ya no podrá unirse a la sala.`,
        onReject: err => { if (err === undefined) props.setUserOnBan() },
        onResolve: () => {
          props.setDisableUI(true);
          props.socket.sendMessage('BAN_GUEST', { name: item.id });
        }, rejectBtn: { value: 'Cancelar', type: 'error' }
      });

      props.setUserOnBan(item.id);
    }
  }

  const hasBannedGuests = () => Boolean(props.guests?.find(g => g.isBanned));

  const hasConnectedGuests = () => props.guests?.length > 0;

  /** @type {React.MouseEventHandler<HTMLButtonElement>} */
  const returnBtnHandleOnClick = e => {
    props.setDisableUI(true);
    popup.current.classList.add('prev');
  }

  return (
    <div className="popup-content init" id={compoId.current} ref={popup}>
      <Button
        className="rotate-img-180 empty borderless"
        disabled={props.disableUI}
        id="return-to-signing"
        icon={Icons.ArrowIcon}
        onClick={returnBtnHandleOnClick}
        value="Regresar" />
      <div className={`box shadowless white-list${props.ownerMode ? ' half' : ''}`} disabled={props.disableUI}>
        <h3 className="highlight">Usuarios conectados</h3>
        <div className="guests-list" onClick={guestsListHandleOnClick}>
          {hasConnectedGuests() && props.guests.map(guest => {
            if (guest.data && !guest.isBanned) {
              return <GuestItem
                key={guest.data.getUsername()}
                guest={guest.data}
                btnProp={props.ownerMode ? {
                  className: 'error',
                  icon: Icons.BanIcon,
                  title: 'Mover a la lista negra'
                } : undefined} />
            } else return undefined
          })}
          {!hasConnectedGuests() && <NotFoundBox img={Icons.KevinNotFoundImg}
            message="No hay usuarios conectados."
            reduced />}
        </div>
      </div>
      {props.ownerMode && <div className="box shadowless black-list half" disabled={props.disableUI}>
        <h3 className="overset">Usuarios bloqueados</h3>
        <div className="guests-list" onClick={banListHandleOnClick}>
          {hasBannedGuests() && props.guests.map(guest => {
            if (guest.isBanned) {
              return <GuestItem
                key={guest.data.getUsername()}
                guest={guest.data}
                userIcon={Icons.UsrBnIcon}
                pictureToGrayscale
                btnProp={{ icon: Icons.UnbanIcon, title: 'Eliminar de la lista negra' }} />
            } else return undefined;
          })}
          {!hasBannedGuests() && <NotFoundBox reduced
            message="No hay usuarios bloqueados." /> }
        </div>
      </div>}
    </div>);
})

export default GuestsManagement;