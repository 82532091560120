import Estate from "./Estate";
import Generic from "./Generic";
import { EngIcon, FoodIcon, GasIcon, HkpIcon, NetIcon, WtrIcon } from "../assets/images"

/** PSPropObject typedef
 * @typedef {Object} PSPropObject
 * @property {*} id An id for the property. Cannot be undefined.
 * @property {*} value A value for the property. 
 */

/** PSServObject typedef
 * @typedef {Object} PSServObject
 * @property {*} id An id for the service. Cannot be undefined.
 * @property {boolean} value A value for the service. 
 */

/** A collection of properties and services for a estate. A property is something that a estate can have, and
 * can be measured in quantity.
 */
class PSCollection extends Generic {
  /** Bedroom Property identifier for properties array. */
  static get PROP_BEDR() { return 'p_bedr' }
  /** Bathroom Property identifier for properties array. */
  static get PROP_BTHR() { return 'p_bthr' }
  /** Cistern Property identifier for properties array. */
  static get PROP_CSTR() { return 'p_cstr' }
  /** Kitchen Property identifier for properties array. */
  static get PROP_KTCH() { return 'p_ktch' }
  /** Level Property identifier for properties array. */
  static get PROP_LVEL() { return 'p_lvel' }
  /** Parking Property identifier for properties array. */
  static get PROP_PARK() { return 'p_park' }
  /** People Capacity Property identifier for properties array. */
  static get PROP_PLCY() { return 'p_plcy' }
  /** Pool Property identifier for properties array. */
  static get PROP_POOL() { return 'p_pool' }
  /** Patio Property identifier for properties array. */
  static get PROP_PTIO() { return 'p_ptio' }
  /** Water Tank Property identifier for properties array. */
  static get PROP_WTNK() { return 'p_wtnk' }
  /** Energy Service identifier for services array. */
  static get SERV_ENGY() { return 's_engy' }
  /** Gas Service identifier for services array. */
  static get SERV_GAS() { return 's_gas' }
  /** Housekeeping Service identifier for services array. */
  static get SERV_HKPG() { return 's_hkpg' }
  /** Internet Service identifier for services array. */
  static get SERV_INET() { return 's_inet' }
  /** Water Service identifier for services array. */
  static get SERV_WTER() { return 's_wter' }
  /** Food Service identifier for services array. */
  static get SERV_FOOD() { return 's_food' }

  /** @param {Estate|PSCollection} [object] */
  constructor(object) {
    super(object);

    const { properties, services } = object || {}

    this.setProperties((Array.isArray(properties) && properties.map(p => Object.assign({}, p))) || []);
    this.setServices((Array.isArray(services) && services.map(s => Object.assign({}, s))) || []);
  }

  /**
   * This function will check if the estate can have a certain property (0 - n). It's useful for
   * conditional rendering. Estate must be defined, otherwise will return false.
   * @param {Estate} estate An Estate instance.
   * @param {string} id A property id, assigned by PSCollection' globals.
   * @returns True if the estate can have the property received. False in other case.
   */
  static canHaveProperty(estate, id) {
    if (!Estate.isTypeValid(estate?.getType()) || !id || estate.getType() === Estate.TYPE_TERRAIN)
      return false;

    const type = estate.getType();

    // Evaluating by propId.
    if (id === PSCollection.PROP_BTHR || id === PSCollection.PROP_KTCH) { // Bathroom and kitchen.
      return type !== Estate.TYPE_HOTEL && type !== Estate.TYPE_BLACK_WORK;
    } else if (id === PSCollection.PROP_POOL) { // Pool
      return type !== Estate.TYPE_OFFICE;
    } else if (id === PSCollection.PROP_BEDR) { // Bedroom / office.
      return type !== Estate.TYPE_OFFICE
        && type !== Estate.TYPE_EVENT_HALL
        && type !== Estate.TYPE_BLACK_WORK;
    } else if (id === PSCollection.PROP_LVEL) {
      return type !== Estate.TYPE_OFFICE;
    } else if (id === PSCollection.PROP_PLCY) {
      return type !== Estate.TYPE_TERRAIN && type !== Estate.TYPE_BLACK_WORK;
    } else {
      return id === PSCollection.PROP_CSTR
        || id === PSCollection.PROP_WTNK
        || id === PSCollection.PROP_PARK
        || id === PSCollection.PROP_PTIO
        || type === Estate.TYPE_OTHER;
    }
  }

  /**Checks if the given estate can have a certain service (Water and Energy Services are not
   * expected in this function because those are always required. true will be returned). Estate
   * must be defined and not in sale, otherwise will return false.
   * @param {Estate} estate An Estate instance.
   * @param  servId A service index, assigned by 'PropAttCollection.SERV_...'.
   */
  static canHaveService(estate, servId) {
    if (!Estate.isTypeValid(estate?.getType()) || !servId) return false;

    if (servId === PSCollection.SERV_ENGY || servId === PSCollection.SERV_WTER) // Energy an Water Service.
      return true;

    // Checks if state is on sale or its type is terrain.
    if (estate.getSellMethod() === Estate.ON_SALE || estate.getType() === PSCollection.TYPE_TERRAIN)
      return false;

    if (servId === PSCollection.SERV_FOOD) // Food Service.
      return estate.getType() !== Estate.TYPE_DEPOT
        && estate.getType() !== Estate.TYPE_BUILDING
        && estate.getType() !== Estate.TYPE_BLACK_WORK;
    else if (servId === PSCollection.SERV_GAS // Gas, Internet and Housekeeping Services.
      || servId === PSCollection.SERV_INET
      || servId === PSCollection.SERV_HKPG)
      return estate.getType() !== Estate.TYPE_BLACK_WORK;
    else return false; // Any other non-existed service.
  }

  /** Verifies if estate has consistence on its properties and services. In other words, this function
   * will check if the estate has the minimum properties and services assigned to be published / saved.
   * 'estate.attributes.type' must be defined, otherwise will return false.
   * @param {Estate} estate
   */
  static constructProperties(estate) {
    // Checking type, on complex and essencial services.
    if (!Estate.isTypeValid(estate?.getType()) || (estate.getSellMethod() !== Estate.ON_COMPLEX
      && (!estate.getService(PSCollection.SERV_ENGY) || !estate.getService(PSCollection.SERV_WTER)))) {
      return false;
    }

    if (estate.getSellMethod() === Estate.ON_COMPLEX || estate.getType() === Estate.TYPE_OTHER) {
      return true;
    } else {
      let flag = true;
      // Level.
      flag = estate.getType() === Estate.TYPE_TERRAIN
        || estate.getType() === Estate.TYPE_HOTEL
        || estate.getType() === Estate.TYPE_OFFICE
        || estate.getProperty(PSCollection.PROP_LVEL);
      // Bathroom.
      flag = flag && (
        (estate.getType() >= Estate.TYPE_OFFICE && estate.getType() <= Estate.TYPE_DEPOT)
        || (estate.getType() >= Estate.TYPE_HOTEL && estate.getType() <= Estate.TYPE_BLACK_WORK)
        || estate.getProperty(PSCollection.PROP_BTHR));
      // Kitchen.
      flag = flag && (
        (estate.getType() >= Estate.TYPE_OFFICE && estate.getType() <= Estate.TYPE_HOTEL)
        || estate.getType() === Estate.TYPE_TERRAIN
        || estate.getType() === Estate.TYPE_BLACK_WORK
        || estate.getProperty(PSCollection.PROP_KTCH));
      // Bedroom
      flag = flag && (
        (estate.getType() !== Estate.TYPE_BUILDING
          && estate.getType() >= Estate.TYPE_OFFICE && estate.getType() <= Estate.TYPE_BLACK_WORK)
        || estate.getProperty(PSCollection.PROP_BEDR));

      return flag;
    }
  }

  /** Obtains the displayable name for the service
   * @param {string} id The service's id.
   */
  static getServiceName(id) {
    switch (id) {
      case PSCollection.SERV_ENGY: return 'Servicio de luz';
      case PSCollection.SERV_FOOD: return 'Servicio de alimentos';
      case PSCollection.SERV_GAS: return 'Servicio de gas';
      case PSCollection.SERV_HKPG: return 'Servicio de limpieza';
      case PSCollection.SERV_INET: return 'Servicio de internet y/o cable';
      case PSCollection.SERV_WTER: return 'Servicio de agua y alcantarillado';
      default: return undefined;
    }
  }

  /** Obtains the displayable icon for the service.
   * @param {string} id The service's id. 
   */
  static getServiceIcon(id) {
    switch (id) {
      case PSCollection.SERV_ENGY: return EngIcon;
      case PSCollection.SERV_FOOD: return FoodIcon;
      case PSCollection.SERV_GAS: return GasIcon;
      case PSCollection.SERV_HKPG: return HkpIcon;
      case PSCollection.SERV_INET: return NetIcon;
      case PSCollection.SERV_WTER: return WtrIcon;
      default: return undefined;
    }
  }

  /** Verifies if ID is a existing ID on 'PSColletion.PROP_...' or 'PSCollection.SERV_...'
   * @param {*} id 
   * @returns 
   */
  static isIdValid(id) {
    switch (id) {
      case PSCollection.PROP_BTHR: return true;
      case PSCollection.PROP_BEDR: return true;
      case PSCollection.PROP_CSTR: return true;
      case PSCollection.PROP_KTCH: return true;
      case PSCollection.PROP_LVEL: return true;
      case PSCollection.PROP_PARK: return true;
      case PSCollection.PROP_PTIO: return true;
      case PSCollection.PROP_POOL: return true;
      case PSCollection.PROP_PLCY: return true;
      case PSCollection.PROP_WTNK: return true;
      case PSCollection.SERV_ENGY: return true;
      case PSCollection.SERV_GAS: return true;
      case PSCollection.SERV_INET: return true;
      case PSCollection.SERV_WTER: return true;
      case PSCollection.SERV_HKPG: return true;
      case PSCollection.SERV_FOOD: return true;
      default: return false;
    }
  }

  /** This function checks if a certain property is required for the estate.
   * @param {Estate} estate An Estate instance 
   * @param {string} id A property index, assigned by class globals.
   * @returns True if property is required, false if it's optional or not valid.
   */
  static isPropertyRequired(estate, id) {
    if (!Estate.isTypeValid(estate?.getType()) || !id) return false;

    const type = estate.getType();

    // Level.
    if (id === PSCollection.PROP_LVEL)
      return type !== Estate.TYPE_TERRAIN && type !== Estate.TYPE_OTHER;
    // Bathroom
    if (id === PSCollection.PROP_BTHR || id === PSCollection.PROP_BEDR)
      return type === Estate.TYPE_HOUSE
        || type === Estate.TYPE_DEPARTMENT
        || type === Estate.TYPE_BUILDING
    else if (id === PSCollection.PROP_KTCH)
      return type === Estate.TYPE_HOUSE
        || type === Estate.TYPE_DEPARTMENT;
    else return false;
  }

  /** Purges an estate's properties and services, removing the ones that the property
   * cannot have because of its type.
   * @param {Estate} estate 
   */
  static purge(estate) {
    if (Estate.isTypeValid(estate?.getType())) {
      // Checking property by property.
      for (let i = 0; i < estate.getProperties().length; i++) {
        const prop = estate.getProperties()[i];

        if (!PSCollection.canHaveProperty(estate, prop.id)) estate.removeProperty(prop.id);
      }

      // Checking service by service.
      for (let i = 0; i < estate.getServices().length; i++) {
        const serv = estate.getServices()[i];

        if (!PSCollection.canHaveService(estate, serv.id)) estate.removeService(serv.id);
      }
    }
  }

  /** Push a new property to the properties array.
   * @param {*} id And identifier that can be used for the property to be accessible. It must be unique and
   * not null or undefined. It won't be added if the identifier is already used by other property in the array 
   * (Check 'PropAttCollection.PROP_...' values).
   * @param {*} value Property value.
   */
  addProperty(id, value) {
    if (PSCollection.isIdValid(id) && !this.getProperty(id)) {
      this.properties.push({ id: id, value: value });
    }
  }

  /** Push a new service to the services array.
   * @param {*} id An identifier that can be used for the service to be accessible. It must be unique and
   * not null or undefined. It won't be added if the identifier is already used by other service in the array 
   * (Check 'PropAttCollection.SERV_...' values).
   * @param {boolean} value Service value (true means that the property have that service,
   * false if not).
   */
  addService(id, value) {
    if (id && !this.services.find(serv => serv.id === id)) {
      this.services.push({
        id: id,
        value: Boolean(value)
      });
    }
  }

  /** Obtains the properties array. */
  getProperties() {
    return this.properties;
  }

  /** Assigns a properties array to the collection. This method can be used
   * for inheritance purposes.
   * @param {PSPropObject[]} properties 
   */
  setProperties(properties) {
    this.properties = properties || [];
  }

  /** Obtains a specified property from the properties array.
   * @param {*} id The identifier of the required property.
   */
  getProperty(id) {
    return this.properties.find(prop => prop.id === id);
  }

  /** Obtains a specified service from the services array.
   * @param {*} id The identifier of the required service.
   */
  getService(id) {
    return this.services.find(serv => serv.id === id);
  }

  /** Obtains the services array. */
  getServices() {
    return this.services;
  }

  /** Assigns a setvices array to the collection. This method can be used for
   * inheritance purposes.
   * @param {PSServObject[]} services 
   */
  setServices(services) {
    this.services = services || [];
  }

  /** A function that will check if the collection has a specified (or any if not specified)
   * service with value = true..
   * @param {*} [id] The identifier of the required service. If undefinied, function will search for any
   * contracted service.
   */
  hasService(id) {
    if (id === undefined) return Boolean(this.services.find(serv => serv.value === true));
    else return Boolean(this.getService(id)?.value);
  }

  /** Removes a specified property from the properties array.
   * @param {*} id The identifier of the property to be removed.
   */
  removeProperty(id) {
    const index = this.properties.findIndex(prop => prop.id === id);

    if (index !== -1) this.properties.splice(index, 1);
  }

  /** Removes a specified service from the services array.
   * @param {*} id The identifier of the service to be removed.
   */
  removeService(id) {
    const index = this.services.findIndex(serv => serv.id === id);

    if (index !== -1) this.services.splice(index, 1);
  }

  /** Assigns a new value for an existing property or deletes the property.
   * @param {*} id The identifier of the required property to be modified. If invalid,
   * operation will be ignored. If id is not found in the array and value is equal
   * or greater than 1, a new property will be added.
   * @param {*} [value] The new value for the required property. If undefined, false or
   * equal or minor to zero (if number), property will be deleted.
   */
  setProperty(id, value) {
    if (PSCollection.isIdValid(id)) {
      const index = this.properties.findIndex(prop => prop.id === id);

      if (index !== -1) {
        if (!value || (typeof value === 'number' && value <= 0))
          this.properties.splice(index, 1);
        else
          this.properties[index].value = value;
      } else if (typeof value === 'number' && value >= 0) {
        this.properties.push({ id, value });
      }
    }
  }

  /** Assigns a new value for an existing service or deletes the service.
   * @param {*} id The identifier of the required service to be modified. If undefined or id
   * is not in the services array, operation will be ignored.
   * @param {boolean} [value] The new value for the required service. If undefined,
   * service will be removed from the services array.
   */
  setService(id, value) {
    if (id !== undefined) {
      const index = this.services.findIndex(serv => serv.id === id);

      if (index !== -1) {
        if (value === undefined) this.services.splice(index, 1);
        else this.services[index].value = Boolean(value);
      } else if (value !== undefined) {
        this.services.push({ id, value: Boolean(value) });
      }
    }
  }
}

export default PSCollection;
