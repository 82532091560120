import './styles/image-viewer.css';
import { useEffect, useRef, useState } from 'react';
import Button from './Button';
import FileChooser from '../objects/FileChooser';
import GenericFile from '../objects/GenericFile';
import Global from '../objects/Global';
import ImageCropper from '../objects/ImageCropper';
// Icons.
import { AddIcon, ArrowIcon, DeleteIcon } from '../assets/images';
import ErrHandler from '../objects/ErrHandler';

/** Renders an ImageViewer compo.
 * @param {Object} props The props object.
 * @param {boolean} [props.disabled] Disables the compo.
 * @param {string} [props.id] A custom id for the compo.
 * @param {GenericFile[]} props.images An array of images. Cannot be undefined.
 * @param {number} [props.max] A maximum of images that can be uploaded. If undefined or less than
 * zero, max will be ignored.
 * @param {(file: GenericFile) => void} props.onAdd A callback function that will be triggered
 * when a new image is added. Cannot be undefined if showToolsList is true.
 * @param {(fileName?: string) => void} [props.onError] A callback function that will be triggered
 * when an error occurs.
 * @param {(prevIndex: number, newIndex: number) => void} props.onMove A callback function that
 * will be triggered when a file changes its position from array. Its previous and new index
 * will be providen. Cannot be undefined if showToolsList is true.
 * @param {(index: number) => void} props.onRemove A callback function that will be triggered
 * when a file is removed from array. Its index will be providen. Cannot be undefined if
 * showToolsList is true.
 * @param {boolean} props.showImagesList If true, the images list (the selector) will be
 * displayed.
 * @param {boolean} props.showToolsList If true, the tool list (to manage images) will be
 * displayed.
 */
const ImageViewer = props => {
  // *** useRef ***
  const id = useRef(props.id ? `#${props.id}` : '');
  // *** useState ***
  const [images, setImages] = useState( /** @type {GenericFile[]} */(undefined));
  const [disableUI, setDisableUI] = useState(props.disabled);
  const [viewImage, setViewImage] = useState(/** @type {{index:number,name:string}} */(undefined));

  const addImage = async () => {
    /** @type {GenericFile} */
    let gF;

    await new FileChooser().open('image', ['jpg', 'jpeg'])
      .then(file => { // Checking if file has been uploaded already (validating file name).
        setDisableUI(true); // Disables UI.
        gF = file; // Saves image loaded.
        return ImageCropper.reduceImage(file, ImageCropper.R_512P); // Resizing image file to 512p.
      }).then(urlData => {
        gF.setURLData(urlData); // Updating urlData

        if (images.find(image => image.getURLData() === urlData)) { // Image already exists.
          if (props.onError) props.onError(gF.getName());
        } else { // New image loaded.
          setViewImage({
            index: images.push(gF) - 1,
            name: gF.getName()
          });

          props.onAdd(gF);
        }
      }).catch((err) => ErrHandler.parseError(err));

    setDisableUI(false); // Unlocks UI.
  }

  const eventHandleOnClick = e => {
    if (disableUI) return;

    if (Global.findParent(e.target, { className: 'button', maxChecks: 2 })) { // Clicked a button.
      if (e.target.id.includes('add')) { // Add button clicked.
        addImage();
      } else if (e.target.id.includes('order-')) { // Move up / down button clicked. 
        moveImage(e.target.id.includes('up') ? -1 : 1);
      } else { // Clicked remove button.
        removeImage();
      }
    } else if (e.target.className === 'img-item') { // Clicked over an img item from images.
      selectImage(e.target);
    }
  }

  const getCurrentClass = () => {
    if (props.showImagesList) {
      return props.showToolsList ? ' l2' : ' l1';
    } else return ''
  }

  const moveImage = direction => {
    if (viewImage.index >= 0 && (direction === -1 || direction === 1)) {
      const newIndex = viewImage.index + direction; // Getting new view image index.
      const auxImg = images[newIndex]; // Obtaining previous/next image from images array.
      images[newIndex] = images[viewImage.index] // Setting selected image to new position.
      images[viewImage.index] = auxImg; // Moving up/down next/previous image.
      // Triggers onMove callback function.
      props.onMove(viewImage.index, newIndex);
      // Sets new index to viewImageIndex and triggers re-render.
      setViewImage({ index: newIndex, name: viewImage.name });
    }
  }

  const removeImage = () => {
    if (viewImage.index >= 0) {
      images.splice(viewImage.index, 1); // Removes img from images array.
      // Gets new view image index.
      const index = images[viewImage.index]
        ? viewImage.index
        : !viewImage.index ? undefined : viewImage.index - 1;
      // Triggers onRemove callback function.
      props.onRemove(viewImage.index);
      // Updates viewImageIndex and triggers re-render.
      setViewImage({
        index: index,
        name: index === undefined ? undefined : images[index].getName()
      });
    }
  }

  const selectImage = img => {
    const imgIndex = images.findIndex(image => image.getURLData() === img.src);

    if (img && imgIndex >= 0 && imgIndex !== viewImage.index) {
      // Updating view image.
      setViewImage({ index: imgIndex, name: images[imgIndex].getName() });
    }
  }

  useEffect(() => {
    setViewImage({
      index: images?.length ? 0 : undefined,
      name: images?.length ? images[0].getName() : undefined
    });
  }, [images]);

  useEffect(() => { setImages(props.images?.map(image => new GenericFile(image)) || undefined) }, [props.images]);

  useEffect(() => { setDisableUI(props.disabled) }, [props.disabled]);

  if (!images) return <div className="image-viewer block-pending"><span /></div>
  else return (
    <div className="image-viewer" id={id.current} onClick={e => eventHandleOnClick(e)} disabled={disableUI}>
      <div className={`display-img${getCurrentClass()}`}>
        {viewImage.index >= 0 && <img src={images[viewImage.index]?.getURLData()} alt="display-img" />}
        {viewImage.index === undefined && <div className="empty-message">
          <h2>No hay imágenes cargadas</h2>
          {props.showImagesList && props.showToolsList && <h5>Utiliza el botón + para agregar imágenes.</h5>}
        </div>}
      </div>
      {props.showImagesList && <div className="element-list images">
        {images.map(image => {
          return <img
            alt={image.getName()}
            className={`img-item${image.getName() === viewImage.name ? ' selected' : ''}`}
            key={image.getName()}
            src={image.getURLData()} />
        })}
      </div>}
      {props.showImagesList && props.showToolsList && <div className="element-list tools">
        <Button
          id='add'
          className='empty rounded'
          title='Subir imagen'
          icon={AddIcon}
          disabled={(props.max >= 0 && images.length >= props.max) || false} />
        <Button
          id='order-up'
          className='empty rounded rotate-img-270'
          title='Adelantar una posición'
          icon={ArrowIcon}
          disabled={viewImage.index === undefined || viewImage.index === 0} />
        <Button
          id='order-down'
          className='empty rounded rotate-img-90'
          title='Atrasar una posición'
          icon={ArrowIcon}
          disabled={viewImage.index === undefined || viewImage.index === images.length - 1} />
        <Button
          id='remove'
          className='empty rounded error'
          title='Eliminar imagen'
          icon={DeleteIcon}
          disabled={viewImage.index === undefined} />
      </div>}
    </div>
  );
}

export default ImageViewer;