import './styles/home.css';
import './styles/search.css'; // @deprecated ON CHANGE!!!
import { globalContext } from './context/GlobalContext';
import { useContext, useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
import Bidding from './objects/Bidding';
import Button from './components/Button';
import Contract from './objects/Contract';
import CreateEstate from './popups/CreateEstate';
import ErrHandler from './objects/ErrHandler';
import Estate from './objects/Estate';
import Global from './objects/Global';
import NotFoundBox from './components/NotFoundBox';
import SearchItem from './components/SearchItem';
import DAOServ from './objects/DAOServ';
import User from './objects/User';
import Bid from './objects/Bid';
import GenericFile from './objects/GenericFile';
import SignProcess from './popups/SignProcess';
import Pass from './objects/Pass';
// * Icons *
import { AddIcon, ArrowIcon, MapIcon, PublIcon } from './assets/images';
import UIRender from './objects/UIRender';
import Address from './objects/Address';
import Agreement from './objects/Agreement';

/** CEObject typedef
 * @typedef {Object} CEObject
 * @property {import('./objects/Persona').default} contactInfo User's personal contact info object.
 * @property {boolean} enableBidding Enable bidding options.
 * @property {number} maxSubdiv Max subdivisions for property. Set to 0 or undefined
 * to disable.
 * @property {import('./Signup').PrefixObject[]} prefixes An array of fetched prefixes.
 * @property {number} today Current day (milli).
 */

/** Renders a Home page compo. */
const Home = () => {
  // *** useContext ***
  const {
    currSession,
    pushMessageHint,
    setShowLoadingScreen,
    setSearchMethod
  } = useContext(globalContext);
  // *** useNavigate ***
  const navigate = useNavigate();
  // *** useState ***
  const [agreements, setAgreements] = useState(/** @type {Estate[]} */(undefined));
  const [estates, setEstates] = useState(/** @type {Estate[]} */(undefined));
  const [showPopup, setShowPopup] = useState(/** @type {CEObject|'sign-contract'} */(undefined));
  const [today, setToday] = useState(/** @type {number} */(undefined));

  const createEstateBtnHandleOnClick = async () => {
    /** @type {import('./objects/Persona').default} */
    let contactInfo;
    /** @type {import('./Signup').PrefixObject[]} */
    let prefixes; // Phone prefixes array fetch.
    /** @type {number} */
    let today; // Today date in milliseconds.

    try {
      setShowLoadingScreen(true);

      // Request user's permissions.
      const pass = await DAOServ.fetchPass(currSession.tst);

      if (!pass.getPass(Pass.IN_ESTA))
        throw new Error(ErrHandler.getError(ErrHandler.CODES.ACCESS_DENIED));

      // Request user has RFC.
      const tryRFC = await DAOServ.post('has_rfc', { tst: currSession.tst }, 'JSON');

      if (!tryRFC.ok) throw new Error('Debes registrar tu RFC para subir propiedades');

      // Request license statistics.
      const licStat = await DAOServ.fetchLicenseStatistics(currSession.tst);

      if (licStat.max_pub !== -1 && licStat.cur_pub >= licStat.max_pub)
        throw new Error('Has alcanzado el límite de publicaciones. Actualiza tu licencia');

      // Bidding.
      const enableBidding = !currSession.isSubuser ? licStat.bidding : pass.getAllPasses().in_bidd;
      // Request contact info.
      contactInfo = await DAOServ.fetchUserContactInfo(currSession.tst);
      // Request current day.
      today = await DAOServ.getCurrentDay();
      // Request phone prefixes.
      prefixes = Array.from(await (await fetch(Global.FETCH_PREFIXES)).json());

      // Showing create property component.
      setShowPopup({ contactInfo, prefixes, today, enableBidding, maxSubdiv: licStat.max_sub_div });
    } catch (err) {
      pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' });
    } finally {
      setShowLoadingScreen(false);
    }
  }

  /** @type {React.MouseEventHandler<HTMLDivElement>} */
  const myAgreementsHandleOnClick = async e => {
    const searchItem = Global.findParent(e.target, { className: 'search-item', maxChecks: 5 });

    if (searchItem)  // Clicked over search item.
      navigate(`${Global.PATH_CONTRACT}/${searchItem.id}`, { state: { contract: searchItem.id } })
  }

  /** @type {React.MouseEventHandler<HTMLDivElement>} */
  const myEstatesHandleOnClick = async e => {
    const searchItem = Global.findParent(e.target, { className: 'search-item', maxChecks: 3 });

    if (searchItem) { // Clicked over an estate card.
      const id = searchItem.id?.split('-')[1] || '';
      navigate(`${Global.PATH_ESTATES_VIEW}/${id}`, { state: { idEstate: id } })
    }
  }

  useEffect(() => {
    if (currSession.sessionStatus) {
      if (currSession.sessionStatus === User.SESSION_ACT) {
        setSearchMethod(!currSession.isSubuser
          ? {
            action: () => navigate(Global.PATH_SEARCH),
            type: 'button',
            icon: MapIcon,
            placeholder: 'Buscar propiedades'
          }
          : undefined
        );

        UIRender.scrollTo();
      } else {
        navigate('/', { replace: true });
      }
    }
  }, [currSession, navigate, setSearchMethod])

  // Fetch active publishments
  useEffect(() => {
    /** Obtains user's active publishments (as a lessor) */
    const fetchActivePublishments = async () => {
      try {
        const publishments = [];
        const request = await DAOServ.post('get_publishments_priv', { tst: currSession.tst }, 'JSON');
        const reqArr = Array.from(request ?? []);

        // Saving estates into array.
        for (let i = 0; i < reqArr.length; i++) {
          const estate = new Estate();

          estate.getImages().push(new GenericFile({ name: reqArr[i]['cover'] }));
          estate.setCreationDate(Number(reqArr[i]['creation_date']));
          estate.setId(Number(reqArr[i]['idestate']));
          estate.setStatus(Number(reqArr[i]['status']));
          estate.setTitle(reqArr[i]['title']);
          estate.setType(Number(reqArr[i]['e_type']));
          estate.setSellMethod(Number(reqArr[i]['sell_method']));

          // ** Bidding **
          if (reqArr[i]['idbidding']) {
            const bidding = new Bidding();

            bidding.setEndDate(Number(reqArr[i]['end_date']));
            bidding.setId(Number(reqArr[i]['idbidding']));
            bidding.setMinimumBid(Number(reqArr[i]['min_bid']));
            bidding.setStatus(true);

            if (reqArr[i]['idmax_bid']) { // A bid has been offered.
              const bid = new Bid();

              bid.setAmount(Number(reqArr[i]['max_bid_amount']));
              bid.setId(Number(reqArr[i]['idmax_bid']));

              bidding.getBids().push(bid);
            }

            // max bid is pending.
            estate.setBidding(bidding);
          }

          // ** Contract **
          if (reqArr[i]['idcontract']) {
            const contract = new Contract();

            contract.setCharge(Number(reqArr[i]['charge_at_start']));
            contract.setId(Number(reqArr[i]['idcontract']));
            contract.setPayAmount(Number(reqArr[i]['pay_amount']));
            contract.setPayFrequency(Number(reqArr[i]['pay_frequency']));
            contract.setTerm(Number(reqArr[i]['term']));
            contract.setTermMethod(Number(reqArr[i]['term_method']));

            estate.setContract(contract);
          }

          publishments.push(estate);
        }

        setEstates(publishments);
      } catch (err) {
        pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' });
        setEstates([]);
      }
    }

    if (currSession.sessionStatus === User.SESSION_ACT && estates === undefined)
      fetchActivePublishments();
  }, [currSession, estates, pushMessageHint]);

  // Fetch active agreements.
  useEffect(() => {
    /** Obtains user's active agreements (as a lessee) */
    const fetchActiveAgreements = async () => {
      let auxAgrs;

      try {
        const options = { tst: currSession.tst, filters: { active: true } };

        /** @type {*[]} */
        const request = await DAOServ.post('get_lessee_agreements', { options }, 'JSON');

        // Parse fetchings.
        auxAgrs = request['agreements'].map(r => {
          const { estate: estateData } = r['data'];
          const auxEst = new Estate();
          const auxAdd = new Address();
          const auxAgr = new Agreement();
          const auxCon = new Contract();
          const auxCov = new GenericFile();
          const auxOwn = new User();
          // Estate's data parse.
          auxEst.setContract(auxCon); // **************************************** Estate ***
          auxEst.setId(estateData['idestate']);
          auxEst.setImages([auxCov]);
          auxEst.setLocation(auxAdd);
          auxEst.setOwner(auxOwn);
          auxEst.setStatus(estateData['status']);
          auxEst.setTitle(estateData['title']);
          auxAgr.setHash(r['hash']); // ************************************** Agreement ***
          auxAgr.setEndDate(r['data']['endDate']);
          auxAgr.setId(estateData['id']);
          auxAgr.setLessees(r['data']['lessees'].map(username => new User({ username })));
          auxAgr.setNextDeadline(r['data']['nextDeadline']);
          auxAgr.setStartDate(r['data']['startDate']);
          auxAdd.setGeoData(estateData['g_location']); // ********************** Address ***
          auxCon.setAgreement(auxAgr); // ************************************* Contract ***
          auxCon.setPayAmount(r['data']['payAmount']);
          auxCon.setPayFrequency(r['data']['payFrequency']);
          auxCon.setTerm(r['data']['term']);
          auxCon.setTermMethod(r['data']['termMethod']);
          auxCov.setName(estateData['cover']); // ******************************** Cover ***
          auxOwn.setId(estateData['idowner']); // ******************************** Owner ***
          auxOwn.setUsername(estateData['owner_username']);

          return auxEst;
        });
      } catch (err) {
        pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' });
        auxAgrs = [];
      } finally { setAgreements(auxAgrs) }
    }

    const { sessionStatus, isSubuser } = currSession;

    if (sessionStatus === User.SESSION_ACT && isSubuser === false && agreements === undefined) {
      fetchActiveAgreements();
    }
  }, [agreements, currSession, pushMessageHint]);

  // Fetch today.
  useEffect(() => {
    DAOServ.getCurrentDay()
      .then(day => setToday(day))
      .catch(err => pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' }));
  }, [pushMessageHint]);

  return (
    <div className='container home'>
      {!currSession.isSubuser && <div className='box borderless leases'>
        <div className="header">
          <h1 className='highlight'>Mis contratos.</h1>
          <Button onClick={() => setShowPopup('sign-contract')} rounded empty icon={AddIcon} />
        </div>
        <div className={`search-container${agreements === undefined ? ' wait' : ''}`}>
          {(today === undefined || agreements === undefined) && <div>
            <SearchItem />
            <SearchItem />
            <SearchItem />
          </div>}
          {agreements?.length === 0 && <NotFoundBox message='No hay contratos activos' />}
          {/* Agreements list */}
          {today !== undefined && agreements?.length > 0 && <div onClick={myAgreementsHandleOnClick} >
            {agreements.map(estate => <SearchItem estate={estate}
              id={estate.getContract().getAgreement().getHash()}
              key={estate.getContract().getAgreement().getHash()}
              today={today} />)}
          </div>}
        </div>
        <Button onClick={() => navigate(Global.PATH_LEASES_VIEW, { state: { page: 0 } })}
          animated
          borderless
          empty
          value='Ver todos mis contratos'
          icon={PublIcon} />
      </div>}
      <div className="box hint">
        <p className="overset">Desliza a la derecha para ver más.</p>
        <img src={ArrowIcon} alt="" />
      </div>
      <div className='box borderless estates'>
        <div className="header">
          <h1 className='highlight'>Mis propiedades.</h1>
          <Button onClick={createEstateBtnHandleOnClick} rounded empty icon={AddIcon} />
        </div>
        <div className={`search-container${estates === undefined ? ' wait' : ''}`} >
          {/* Fetching estates */}
          {(today === undefined || estates === undefined) && <div>
            <SearchItem />
            <SearchItem />
            <SearchItem />
          </div>}
          {/* No estates in the list */}
          {estates?.length === 0 && <NotFoundBox message='No hay publicaciones activas' />}
          {/* Estates list */}
          {today !== undefined && estates?.length > 0 && <div onClick={e => myEstatesHandleOnClick(e)}>
            {estates.map(estate => <SearchItem estate={estate}
              id={`estate-${estate.getId()}`}
              key={`estate-${estate.getId()}`} />)}
          </div>}
        </div>
        <Button onClick={() => navigate(Global.PATH_ESTATES_VIEW, { state: { page: 0 } })}
          empty
          animated
          borderless
          value='Ver todas mis propiedades'
          icon={PublIcon} />
      </div>
      {/* Create property popup */}
      {Boolean(showPopup) && showPopup !== 'sign-contract' && <CreateEstate
        contactInfo={showPopup.contactInfo}
        enableBidding={showPopup.enableBidding}
        onHide={() => setShowPopup()}
        onResolve={() => setEstates()}
        prefixes={showPopup.prefixes}
        today={showPopup.today} />}
      {/* Sign process popup */}
      {Boolean(showPopup) && showPopup === 'sign-contract' && <SignProcess onResolve={() => setAgreements()}
        onHide={() => setShowPopup()} />}
    </div>
  );
}

export default Home;