import './styles/message-hint.css';
import { useEffect, useRef, useState } from 'react';
import Button from './Button';
import Global from '../objects/Global';
import UIRender from '../objects/UIRender';
import { CloseIcon } from '../assets/images';

/** Renders a MessageHint compo.
 * @param {{
 *  message: {message: string, type: string}
 * }} props
 */
const MessageHint = props => {
  // *** useRef ***
  /** @type {React.MutableRefObject<string>} */
  const lastPush = useRef();
  /** @type {React.MutableRefObject<{timeoutId:number, messageId:string}[]>} */
  const timeouts = useRef([]);
  // *** useState ***
  const [messages, setMessages] = useState(/** @type {{id:string, message:string, type:string}[]} */([]));

  /** @param {React.AnimationEvent} e */
  const messageHintHandleOnAnimationEnd = e => {
    if (!UIRender.isHidden(e.target)) return;

    const msgId = messages.findIndex(message => message.id === e.target.id);

    if (msgId !== -1) messages.splice(msgId, 1);

    if (!messages.length && props.onDispose) props.onDispose();
    else setMessages([...messages]);
  }

  /** @param {React.MouseEvent} e */
  const messageHintHandleOnClick = e => {
    const closeBtn = Global.findParent(e.target, { className: 'message-hint-close-btn', maxChecks: 2 });

    if (closeBtn) { // Clicked over a close button.
      // Getting parent items.
      const parent = closeBtn.parentNode.parentElement;

      // Clear timeout array.
      let tOIndex = timeouts.current.findIndex(tO => tO.messageId === parent.id);

      while (tOIndex !== -1) {
        clearTimeout(timeouts.current[tOIndex].timeoutId);
        timeouts.current.splice(tOIndex, 1);
        tOIndex = timeouts.current.findIndex(tO => tO.messageId === parent.id);
      }

      // Hide message.
      if (parent) UIRender.hideElement(parent);
    }
  }

  useEffect(() => {
    if (messages.length) { // Current messages.
      for (let i = 0; i < messages.length; i++) {
        const messageId = messages[i].id;

        if (!timeouts.current.find(tO => tO.messageId === messageId)) {
          // Add new timeout.
          timeouts.current.push({
            messageId: messages[i].id,
            timeoutId: setTimeout(() => {
              const e = Global.getElementById(messageId);
              // Clear timeout array.
              let tOIndex = timeouts.current.findIndex(tO => tO.messageId === messageId);

              while (tOIndex !== -1) {
                clearTimeout(timeouts.current[tOIndex].timeoutId);
                timeouts.current.splice(tOIndex, 1);
                tOIndex = timeouts.current.findIndex(tO => tO.messageId === messageId);
              }

              UIRender.hideElement(e)
            }, 5300)
          });
        }
      }
    }
  }, [messages]);

  useEffect(() => {
    if (lastPush.current !== props.message.message) {
      lastPush.current = props.message.message;

      let auxArr = [];

      for (let i = 0; i < messages.length; i++) {
        auxArr.push(messages[i]);
      }

      auxArr.push({
        id: `mh-${messages.length}`,
        message: props.message.message,
        type: props.message.type,
      });

      setMessages(auxArr);
    }
  }, [props.message, messages]);

  return (
    <div
      className="message-hint-box"
      onAnimationEnd={e => messageHintHandleOnAnimationEnd(e)}
      onClick={e => messageHintHandleOnClick(e)}>
      {messages.map(message => {
        return (
          <div
            className={`message-hint ${message.type}`}
            id={message.id}
            key={`${message.message}-${message.id}`}>
            <div className="elements">
              <p className='message'>{message.message}</p>
              <Button
                className={`message-hint-close-btn empty reduced rounded borderless ${message.type}`}
                icon={CloseIcon} />
            </div>
            <div className="progress-bar">
              <div className="bar" />
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default MessageHint;