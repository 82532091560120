import { useEffect, useRef } from 'react';
import './styles/loading-panel.css'

/** Renders a loading panel compo
 * @param {Object} props The props object.
 * @param {string} [props.height] A custom height for the compo. Can be any format.
 */
const LoadingPanel = props => {
  const compo = useRef(/** @type {HTMLDivElement} */(undefined));

  useEffect(() => {
    if (props.height !== undefined)
      compo.current.style.height = props.height;
  }, [props.height]);

  return (
    <div className={`loading-panel${props.className ? ` ${props.className}` : ''}`} ref={compo}>
      <div className="load-figures">
        <span className="animated-figure f1 purple" />
        <span className="animated-figure f2 purple" />
        <span className="animated-figure f3 purple" />
      </div>
    </div>
  );
}

export default LoadingPanel;