import { useCallback, useRef, useState } from "react";
import { globalContext } from "./GlobalContext";
import LoadingScreen from '../popups/LoadingScreen';
import Login from "../popups/Login";
import MessageHint from '../components/MessageHint';
import ProfileMenu from "../popups/ProfileMenu";
import SplashScreen from "../components/popups/SplashScreen";
import AlertMessage from "../components/AlertMessage";
import { DefUsPic } from "../assets/images";
import Foot from "../Foot";

/** The context provider
 * @param {Element} props 
 */
const StateContext = props => {
  // *** useCallback ***
  /** @type {(name: string) => import('./GlobalContext').ChacheFileObject|undefined } */
  const getCacheFile = useCallback(name => cacheFiles.current.find(cF => cF.name === name), []);

  /** Pushes a new alert message to the queue.
   * @type {(item: import('./GlobalContext').EmergentMessageObject) => void} */
  const pushAlertMessage = useCallback(item => {
    const { message, type } = item;

    if (message) setNewAlertMessage({ message, type: type || '' });
  }, []);

  /** @type {(file: import('./GlobalContext').ChacheFileObject) => boolean} */
  const pushCacheFile = useCallback(file => {
    if (file?.name !== undefined && file?.content !== undefined) {
      try {
        const auxCF = cacheFiles.current.find(cF => cF.name === file.name);

        if (auxCF !== undefined) {
          auxCF.content = file.content;
          auxCF.size = file.size;
          auxCF.timestamp = file.timestamp;
        } else cacheFiles.current.push(file);
      } catch (err) { console.log(err) }
    }
  }, []);

  /** @type {(name: string) => import('./GlobalContext').ChacheFileObject|undefined } */
  const removeCacheFile = useCallback(name => {
    const index = cacheFiles.current.findIndex(cF => cF.name === name);

    if (index !== -1) cacheFiles.current.splice(index, 1);
  }, []);

  /** Pushes a new message hint to the list.
   * @type {(item: import('./GlobalContext').EmergentMessageObject) => void} */
  const pushMessageHint = useCallback(item => {
    const { message, type } = item;

    if (message) setNewMessageHint({ message, type: type || '' });
  }, []);

  /** @type {(cS: import('./GlobalContext').CurrentSessionObject) => void} cS can't be undefined */
  const setNewCurrSession = useCallback(cS => {
    setCurrSession(typeof cS === 'object'
      ? cS
      : {
        creationDate: undefined,
        id: undefined,
        isSubuser: undefined,
        sessionStatus: undefined,
        tst: localStorage.getItem('tst') || undefined,
        username: undefined
      });
  }, []);

  // *** useState ***
  const [currSession, setCurrSession] = useState(/** @type {import('./GlobalContext').CurrentSessionObject} */({
    id: undefined,
    isSubuser: undefined,
    sessionStatus: undefined,
    tst: localStorage.getItem('tst') || undefined,
    username: undefined
  }));
  const [newAlertMessage, setNewAlertMessage] = useState(); // Alert Message.
  const [newMessageHint, setNewMessageHint] = useState(); // Message Hint.
  const [currSessionPicture, setCurrSessionPicture] = useState(DefUsPic);
  const [searchMethod, setSearchMethod] = useState(); // Search method.
  const [showFooter, setShowFooter] = useState(true); // Footer
  const [showLoadingScreen, setShowLoadingScreen] = useState(false); // Loading screen.
  const [showLogin, setShowLogin] = useState(false); // Login popup
  const [showProfileMenu, setShowProfileMenu] = useState(false); // Profile menu.
  const [showSplashScreen, setShowSplashScreen] = useState(true); // Splash screen.
  const [timezoneOffset, setTimezoneOffset] = useState(/** @type {number} */(undefined));
  // *** useRef ***
  const cacheFiles = useRef(/** @type {import('./GlobalContext').ChacheFileObject[]} */([]));

  return (
    <globalContext.Provider value={{
      // Functions & methods
      getCacheFile,
      pushAlertMessage,
      pushCacheFile,
      pushMessageHint,
      removeCacheFile,
      setCurrSession: setNewCurrSession,
      setCurrSessionPicture,
      setSearchMethod,
      setShowFooter,
      setShowLoadingScreen,
      setShowLogin,
      setShowProfileMenu,
      setShowSplashScreen,
      setTimezoneOffset,
      // Hook vars and use refs.
      currSession,
      currSessionPicture,
      searchMethod,
      showFooter,
      showLogin,
      showProfileMenu,
      timezoneOffset
    }}>
      {/* App components */}
      {props.children}
      {/* Footer */}
      {Boolean(showFooter) && <Foot />}
      {/* *** Side forms *** */}
      {/* Profile Menu component */}
      {showProfileMenu && <ProfileMenu />}
      {/* Login popup */}
      {showLogin && <Login onHide={() => setShowLogin(false)} />}
      {/* Message hint */}
      {newMessageHint && <MessageHint
        message={newMessageHint}
        onDispose={() => setNewMessageHint(undefined)} />}
      {newAlertMessage && <AlertMessage
        message={newAlertMessage}
        onDispose={() => setNewAlertMessage(undefined)} />}
      {/* Loading screen */}
      {showLoadingScreen && <LoadingScreen />}
      {/* Splash screen */}
      {showSplashScreen && <SplashScreen />}
    </globalContext.Provider>
  );
}

export default StateContext;