import Generic from "./Generic";

/** LicenseFeaturesObject typedef
 * @typedef {Object} LicenseFeaturesObject
 * @property {boolean} bidding Bidding allowed?
 * @property {number} [cur_sub_acc] Current subaccounts created.
 * @property {number} [cur_pub] Current publications uploaded.
 * @property {number} max_les Max lessees for a lease.
 * @property {number} max_pub Max publications (properties).
 * @property {number} max_sub_acc Max subaccounts.
 * @property {number} max_sub_div Max subdivisions per property.
 */

class License extends Generic {
  /** Beta license */
  static get TYPE_BETA() { return -3 }
  /** Custom hotelier license */
  static get TYPE_HOTELIER_CUSTOM() { return -2 }
  /** Custom general license */
  static get TYPE_GEN_CUSTOM() { return -1 }
  /** Limited license */
  static get TYPE_LIMITED() { return 0 }
  /** Starter license */
  static get TYPE_STARTER() { return 1 }
  /** Enterprising license */
  static get TYPE_ENTERPRISING() { return 2 }
  /** Comercial license */
  static get TYPE_COMERCIAL() { return 3 }
  /** Real Estate license */
  static get TYPE_REAL_ESTATE() { return 4 }
  /** Hotelier 1 license */
  static get TYPE_HOTELIER_1() { return 6 }
  /** Hotelier 2 license */
  static get TYPE_HOTELIER_2() { return 7 }
  /** Hotelier 3 license */
  static get TYPE_HOTELIER_3() { return 8 }
  /** Hotelier chain license */
  static get TYPE_HOTELIER_CHAIN() { return 9 }
  /** Unlimited value */
  static get VALUE_UNLIMITED() { return -1 }
  /** Custom value */
  static get VALUE_CUSTOM() { return -2 }

  /** @param {License|undefined} object */
  constructor(object) {
    super(object);

    const { className, description, discount, features, price, title, type } = object || {};

    this.setClassName(className);
    this.setDescription(description)
    this.setDiscount(discount);
    this.setFeatures(features);
    this.setPrice(price);
    this.setTitle(title);
    this.setType(type);
  }

  /** Obtains the correspondent class for the license type. It can be used as class name or id.
    * If param is not valid, undefined will be returned.
    * @param {number} type
    * @deprecated Class name is stored in database. This function is marked for deletion.
    */
  static getClass(type) {
    switch (type) {
      case License.TYPE_BETA: return 'beta';
      case License.TYPE_LIMITED: return 'limited';
      case License.TYPE_STARTER: return 'starter';
      case License.TYPE_ENTERPRISING: return 'enterprising';
      case License.TYPE_COMERCIAL: return 'commercial';
      case License.TYPE_REAL_ESTATE: return 'real-estate';
      case License.TYPE_GEN_CUSTOM: return 'general-custom';
      case License.TYPE_HOTELIER_1: return 'hotelier-1';
      case License.TYPE_HOTELIER_2: return 'hotelier-2';
      case License.TYPE_HOTELIER_3: return 'hotelier-3';
      case License.TYPE_HOTELIER_CHAIN: return 'hotelier-chain';
      case License.TYPE_HOTELIER_CUSTOM: return 'hotelier-custom';
      default: return undefined;
    }
  }

  /** Obtains the className from the license. */
  getClassName() {
    return this.className;
  }

  /** Assigns the className to the license.
   * @param {string} className 
   */
  setClassName(className) {
    this.className = className;
  }

  /** Obtains the description of the license. */
  getDescription() {
    return this.description;
  }

  /** Assigns a description for the license. 
   * @param {string} description 
   */
  setDescription(description) {
    this.description = description;
  }

  /** Obtains the discount from the license (a value from 0 to 1). */
  getDiscount() {
    return this.discount;
  }

  /** Assigns a discount for the license.
   * @param {number} discount Must be a value from 0 to 1. Default value is undefined.
   */
  setDiscount(discount) {
    this.discount = discount >= 0 && discount <= 1
      ? Number(discount)
      : undefined;
  }

  /** Obtains the features. */
  getFeatures() {
    return this.features;
  }

  /** Assigns the features for the license. If undefined, Free License features are loaded by
   * default.
   * @param {LicenseFeaturesObject} [features]
   */
  setFeatures(features) {
    this.features = features || {
      bidding: false,
      cur_sub_acc: 0,
      cur_pub: 0,
      max_les: 0,
      max_pub: 0,
      max_sub_acc: 0,
      max_sub_div: 0,
    }
  }

  /** Obtains the price of the license */
  getPrice() {
    return this.price;
  }

  /** Assigns a price for the license.
   * @param {number} price 
   */
  setPrice(price) {
    this.price = price === null || isNaN(price) || price < 0
      ? undefined
      : Number(price);
  }

  /** Obtains the title of the license */
  getTitle() {
    return this.title;
  }

  /** Assigns a title for the license.
   * @param {string} title 
   */
  setTitle(title) {
    this.title = title;
  }

  /** Obtains the type of the subscription specified by Subscription.TYPE_.... */
  getType() {
    return this.type;
  }

  /** Assigns a type for the subscription, specified by Subscription.TYPE_
   * @param {number} type 
   */
  setType(type) {
    this.type = type === null || isNaN(type)
      ? undefined
      : Number(type);
  }
}

export default License;