import Global from "./Global";

class Generic {
  /** @param {Generic} [object] */
  constructor(object) {
    const { creationDate, id } = object || {};

    this.setCreationDate(creationDate);
    this.setId(id);
  }

  /** Obtains the date of tuple creation in milliseconds. */
  getCreationDate() {
    return this.creationDate;
  }

  /** Assigns a date, being the date of creation of the tuple. Value passes through Global.formatDateUTC
   * @param {number} creationDate 
   */
  setCreationDate(creationDate) {
    this.creationDate = Global.formatDateUTC(creationDate);
  }

  /** Obtains the object's id. This might be the primary key assigned by database or
   * assigned manually by final user. */
  getId() {
    return this.id;
  }

  /** Assigns a primary key to the object.
   * @param {number|string} id 
   */
  setId(id) {
    this.id = id;
  }
}

export default Generic;