import '../styles/loc-selector.css';
import { globalContext } from '../../context/GlobalContext';
import { useContext, useEffect, useRef, useState } from 'react';
import { useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import Address from '../../objects/Address';
import Button from '../Button';
import ErrHandler from '../../objects/ErrHandler';
import LoadingScreen from '../../popups/LoadingScreen';
import DAOServ from '../../objects/DAOServ';
import UIRender from '../../objects/UIRender';
// Icons.
import { CloseIcon, InfoIcon, SaveIcon, WarningIcon } from '../../assets/images';
import Hintbox from '../Hintbox';

/** Renders a Location Selector compo.
 * @param {Object} props The props object.
 * @param {string} [props.defaultValue] The default value for the location input.
 * @param {() => void} props.onHide The callback function that will be activated when compo is hidden.
 * @param {(a: Address) => void} props.onResolve When compo resolves location fetch, this callback function
 * will be activated. Must be defined, otherwhise might throw an exception.
 */
const LocSelector = props => {
  // *** Google maps API ***
  const gLib = useRef(['places']);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_G_API,
    libraries: gLib.current
  });
  // *** useContext ***
  const { pushMessageHint } = useContext(globalContext);
  // *** useRef ***
  const address = useRef(/** @type {React.MutableRefObject<Address>} */(new Address()));
  const compoId = useRef('loc-selector');
  const input = useRef(/** @type {React.MutableRefObject<Element>} */(undefined));
  const popup = useRef(/** @type {React.LegacyRef<HTMLDivElement>} */(undefined));
  const submitBtn = useRef(/** @type {React.LegacyRef<HTMLButtonElement>} */(undefined));
  // *** useState ***
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [fetchingData, setFetchingData] = useState(false);

  /** @type {React.AnimationEventHandler} */
  const dispose = e => {
    if (popup.current === e.target && UIRender.isHidden(popup.current))
      props.onHide();
  }

  const inputHandleOnPlaceChanged = async () => {
    setDisableSubmit(true); // Disables submit.

    if (input.current) {
      const addArr = input.current.value.split(',');

      setFetchingData(true) // Disable fetching data.
      // Formating address.
      for (let i = 0; i < addArr.length; i++) addArr[i] = addArr[i].trim().replace(/\s+/g, ' ');
      // Updating address object.
      address.current.setCity(addArr.length === 5 ? addArr[2] : '_');
      address.current.setCountry(addArr[addArr.length === 5 ? 4 : 3]);
      address.current.setState(addArr[addArr.length === 5 ? 3 : 2]);
      address.current.setStreet(addArr[0]);
      address.current.setSuburb(addArr[1]);
      // Fetch data.
      await DAOServ.post('geolocate', address.current, 'JSON')
        .then(geoData => {
          address.current.setGeoData(geoData);
          setDisableSubmit(false); // Enable submit button.
        }).catch(err => {
          const message = ErrHandler.getCode(err) === ErrHandler.CODES.PARAM_NOT_SUPPORTED
            ? 'Solo se admiten direcciones dentro de la república mexicana'
            : 'No se pudo obtener la ubicación. Vuelve a intentarlo más tarde';
          pushMessageHint({ message, type: 'error' });
        });

      setFetchingData(false); // Restoring fetching data.
    }
  }

  /** Hides the popup. After animation end, triggers props.onHide function. */
  const popupHandleOnDispose = () => {
    UIRender.hideElement(popup.current); // Hide element.
  }

  const popupHandleOnSubmit = () => {
    props.onResolve(address.current);
    popupHandleOnDispose();
  }

  useEffect(() => {
    if (!disableSubmit) submitBtn.current.click(); // Submit button auto-click.
  }, [disableSubmit]);

  useEffect(() => {
    // Parent node.
    const id = compoId.current;
    const parent = popup.current?.parentNode;
    // UIRender.disableSiblings options.
    const options = { footer: true, navbar: true };

    if (isLoaded) {
      UIRender.disableGlobalScroll(id); // Disable global scroll.
      UIRender.disableSiblings(popup.current, options); // Disable siblings.
      UIRender.selectElementText(input.current); // Select input text.
      UIRender.setFocus(input.current); // Request focus.
    }

    return () => {
      // Enable siblings.
      UIRender.enableChilds(parent, options, id);
      // Enable global scroll.
      UIRender.enableGlobalScroll(id);
    }
  }, [isLoaded]);

  if (!isLoaded) return (<LoadingScreen />)
  else return (
    <div className="popup-wrapper loc-selector"
      disabled={fetchingData}
      id={compoId.current}
      onAnimationEnd={dispose}
      ref={popup}>
      <div className="popup">
        <div className="top-bar">
          <h2 className="highlight">Ubicación.</h2>
          <Button onClick={popupHandleOnDispose}
            borderless
            empty
            icon={CloseIcon}
            reduced
            rounded
            typeRender='error' />
        </div>
        <div className="popup-content">
          <p className="message">Ingresa la dirección de tu propiedad.</p>
          <Autocomplete onPlaceChanged={inputHandleOnPlaceChanged}>
            <input className='loc-selector-input'
              defaultValue={props.defaultValue || ''}
              type="text"
              placeholder='Ubicación'
              ref={input} />
          </Autocomplete>
          <Hintbox icon={InfoIcon}
            message={'Ingres una dirección empezando por la calle, número exterior y número interior '
              + '(si lo tiene), colonia, ciudad o provincia, estado y país. Separado por comas. Por ejemplo:'} />
          <h4 className='example'>Tomate 3, Las Huertas, Tlaquepaque, Jalisco, México</h4>
          <Hintbox icon={WarningIcon} type='warning'
            message={'Esta es la única ocasión en la que podrás modificar la ubicación. Una vez que la '
              + 'propiedad haya sido creada, no podrás modificarla.'} />
          <div className="submiter">
            <Button onClick={popupHandleOnSubmit}
              disabled={disableSubmit}
              empty
              id='save-loc'
              icon={SaveIcon}
              isWaiting={fetchingData}
              onWaitValue={'Obteniendo ubicación...'}
              reference={submitBtn}
              value='Guardar'
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LocSelector;