import '../styles/dialog.css';
import { useEffect, useRef, useState } from 'react';
import Button from '../Button';
import UIRender from '../../objects/UIRender';

/** The DialogConfirmBtnObject typedef
 * @typedef {Object} DialogBtnObject
 * @property {number} [delay] A delay in milliseconds for the button. This will disable the button until
 * the delay's end. 
 * @property {*} [icon] An icon to be displayed in the button.
 * @property {string} [onWaitValue] A value for when compo is waiting for action to be resovled or rejected.
 * @property {'complete'|'error'|'warning'} [type] A type for the button.
 * @property {string} [value] A value to be displayed in the button. 'Aceptar' is the default value.
 */

/** The DialogPropsObject typedef
 * @typedef {Object} DialogPropsObject
 * @property {() => Promise<*>} [action] A callback function that will be triggered right after user
 * pushed confirm button. Compo will enter a wait state until this promise resolves or rejects.
 * @property {string} [className] A custom class name for the compo.
 * @property {DialogBtnObject} [confirmBtn] The confirm button options.
 * @property {string} [id] A custom id. 'dialog-popup' is the default id.
 * @property {boolean} [ignoreOnResolveHideAnimation] If true, when 'action' resolves, hide animation will be
 * skiped and onHide callback will be called inmediately.
 * @property {string} message A message for the compo.
 * @property {() => void} onHide A callback function that will be triggered when como is hiden (UIRender
 * validation). Must be defined, otherwise may raise an exception.
 * @property {(err?: Error|string) => void} [onReject]  A callback function that will be triggered when 'action'
 * rejects or when user presses the reject button (in this case, 'err' will be undefined).
 * @property {(data: *) => void} [onResolve] A callback function that will be triggered when 'action' resolves
 * (or when user presses confirm button if 'action' is undefined). 'data' is any data that 'action' returned. 
 * @property {DialogBtnObject} [rejectBtn] The reject button. Won't be rendered if undefined.
 * @property {boolean} [renderButtonsBorderless] If true, buttons will be borderless.
 * @property {boolean} [renderButtonsEmpty] If true, buttons will be empty.
 * @property {boolean} [renderButtonsRounded] If true, buttons will be rounded.
 * @property {boolean} [renderButtonsSwitched] If true, buttons will switch places.
 */

/** Renders a Dialog compo.
 * @param {DialogPropsObject} props The props object.
 */
const Dialog = props => {
  // *** useRef ***
  const compoId = useRef(props.id ?? 'dialog-popup');
  /** @type {React.LegacyRef<HTMLDivElement>} */
  const popup = useRef();
  // *** useState ***
  const [disableUI, setDisableUI] = useState(false);
  const [confirmBtnDelay, setConfirmBtnDelay] = useState(false);
  const [rejectBtnDelay, setRejectBtnDelay] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);

  const confirmBtnClickHandler = async () => {
    if (props.action !== undefined) {
      setIsWaiting(true);

      await props.action()
        .then(data => {
          if (props.onResolve !== undefined) props.onResolve(data);

          if (props.ignoreOnResolveHideAnimation) props.onHide()
          else UIRender.hideElement(popup.current);

          setDisableUI(true);
        }).catch(err => {
          if (props.onReject !== undefined) props.onReject(err)
        }).finally(() => setIsWaiting(false));
    } else {
      if (props.onResolve) props.onResolve();

      if (props.ignoreOnResolveHideAnimation) props.onHide();
      else UIRender.hideElement(popup.current);

      setDisableUI(true);
    }
  }

  /** @type {React.AnimationEventHandler} */
  const dispose = e => {
    if (e.target === popup.current && UIRender.isHidden(popup.current))
      props.onHide();
  }

  const getClassName = () => {
    let className = 'popup-wrapper dialog-popup';

    if (props.className !== undefined) className += ` ${props.className}`;

    return className;
  }

  const rejectBtnClickHandler = () => {
    setDisableUI(true);

    UIRender.hideElement(popup.current);

    if (props.onReject !== undefined) props.onReject()
  }

  const renderButtons = () => {
    const buttons = [];

    buttons.push(renderConfirmBtn());

    if (props.rejectBtn !== undefined) {
      if (props.renderButtonsSwitched) buttons.unshift(renderRejectBtn());
      else buttons.push(renderRejectBtn());
    }

    return buttons;
  }

  const renderConfirmBtn = () => {
    return (<Button disabled={disableUI}
      borderless={props.renderButtonsBorderless}
      empty={props.renderButtonsEmpty}
      icon={props.confirmBtn?.icon}
      id={`${compoId.current}-confirm-btn`}
      key={`${compoId.current}-confirm-btn`}
      onWaitValue={confirmBtnDelay
        ? props.confirmBtn?.value || 'Aceptar'
        : props.confirmBtn?.onWaitValue ?? 'Solicitando...'}
      isWaiting={isWaiting || confirmBtnDelay}
      onClick={confirmBtnClickHandler}
      rounded={props.renderButtonsRounded}
      typeRender={props.confirmBtn?.type}
      value={props.confirmBtn?.value || 'Aceptar'} />);
  }

  const renderRejectBtn = () => {
    return (<Button disabled={isWaiting || disableUI}
      borderless={props.renderButtonsBorderless}
      empty={props.renderButtonsEmpty}
      icon={props.rejectImg}
      id={`${compoId.current}-reject-btn`}
      isWaiting={rejectBtnDelay}
      key={`${compoId.current}-reject-btn`}
      onClick={rejectBtnClickHandler}
      onWaitValue={props.rejectBtn.value ?? 'Cancelar'}
      rounded={props.renderButtonsRounded}
      typeRender={props.rejectBtn.type}
      value={props.rejectBtn.value ?? 'Cancelar'} />);
  }

  // First render useEffect.
  useEffect(() => {
    const id = compoId.current;
    const parent = popup.current?.parentNode;
    const options = { footer: true, navbar: true };

    UIRender.disableGlobalScroll(id);
    UIRender.disableSiblings(popup.current, options);

    return () => {
      // Enable childs.
      UIRender.enableChilds(parent, options, id);
      // Enable global scroll.
      UIRender.enableGlobalScroll(id);
    }
  }, []);

  // Disable button delay.
  useEffect(() => {
    if (props.confirmBtn?.delay > 0) {
      setConfirmBtnDelay(true);

      setTimeout(() => setConfirmBtnDelay(false), props.confirmBtn.delay);
    }

    if (props.rejectBtn?.delay > 0) {
      setRejectBtnDelay(true);

      setTimeout(() => setRejectBtnDelay(false), props.rejectBtn.delay);
    }
  }, [props.confirmBtn, props.rejectBtn]);

  return (
    <div id={compoId.current} ref={popup} className={getClassName()} onAnimationEnd={dispose}>
      <div className="popup">
        <div className="popup-content">
          <p className='dialog-message'>{props.message || 'Confirma la acción solicitada'}</p>
          {renderButtons()}
        </div>
      </div>
    </div>
  );
}

export default Dialog;