import { forwardRef, useEffect, useRef } from "react";
import Button from '../../components/Button';

/** Props Type definition.
 * @typedef {Object} Props The props object.
 * @property {*} props.image An image to be displayed.
 * @property {boolean} [props.ownerMode] The ownerMode flag.
 * @property {() => void} props.onCloseBtnClick
 * @property {() => void} props.onOpenContractBtnClick
 * @property {import('../../objects/ClientSocket').default} socket The current socket.
 */

/** Renders a Phase3 compo for SignProcess component.
 * @type {React.ForwardRefRenderFunction<?, Props>}
 */
const Phase4 = forwardRef((props, ref) => {
  const socket = useRef(props.socket)

  const getMessage = () => {
    if (props.ownerMode) {
      return 'Ya puedes revisar los detalles en los contratos de tu propiedad.'
    } else {
      return 'Ya puedes revisarlo en tus contratos. Recuerda que tú o algún otro arrendatario debe pagar'
        + ' el primer periodo pronto.'
    }
  }

  useEffect(() => {
    setTimeout(() => socket.current.close(), 3000);
  }, []);

  return (
    <div className="popup-content" id='phase-4' ref={ref}>
      <div className="phase-container">
        <div className="congrats-container">
          <div className="background-container">
            <span className="background" />
          </div>
          <div className="img-container">
            <img src={props.image} alt="props-providen" />
          </div>
        </div>
        <div className="info-content">
          <h1 className="highlight">¡Has firmado el contrato!</h1>
          <h5 className="overset">{getMessage()}</h5>
          <div className="flex-box">
            <div className="child m3">
              <Button empty
                onClick={props.onCloseBtnClick}
                rounded
                typeRender="error"
                value="Cerrar ventana" />
              <Button empty
                onClick={props.onOpenContractBtnClick}
                rounded
                value="Ver contrato" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Phase4;