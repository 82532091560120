import './styles/switch.css';
import { useEffect, useRef, useState } from 'react';

/** InputbarPlaceholderObject typedef
 * @typedef {Object} InputbarPlaceholderObject
 * @property {string} [default] Default placeholder for the compo (when it's unchecked). Assign
 * an empty string to render no placeholder (onChecked option will be ignored).
 * @property {string} [onChecked] Placeholder for the compo when is checked. If undefined or empty,
 * default will be replacing this attribute.
 */

/** Renders a Switch compo.
 * @param {Object} props The props object
 * @param {boolean} [props.disabled] used to disable compo.
 * @param {boolean} [props.defaultValue] If true, compo will be checked.
 * @param {React.MutableRefObject<(val?: boolean) => void>} [props.forceChangeRef] A ref.
 * A callback function will be assigned to its current value. Call current to force input value to
 * change (f.e. <ref_def.current(<new_value>)). If undefined, compo will be restored to its default
 * value. Doing this won't trigger any assigned callback function.
 * @param {boolean} [props.forceChangeValue] Use this attribute to force value change (use a
 * useState var for this to work). This won't trigger props.onChange. This is deprecated.
 * @param {string} [props.id] Custom id for the compo.
 * @param {(state: boolean, rollback: () => void) => void} props.onChange A callback function
 * that will be called everytime compo's value changes. Must be defined. State is the compo's
 * current value and rollback is a callback function. Use this last one to rollback compo to
 * its previous value.
 * @param {InputbarPlaceholderObject} [props.placeholder] A placeholder for the compo. If undefined, 'Switch'
 * will be the default placeholder globally.
 */
const Switch = props => {
  // *** useRef ***
  const defaultValue = useRef(Boolean(props.defaultValue));
  const switchRef = useRef(/** @type {HTMLInputElement} */(undefined));
  const toggleRef = useRef(Boolean(props.defaultValue));
  // *** useState ***
  const [toggle, setToggle] = useState(defaultValue.current);

  const getPlaceholder = () => {
    if (!toggle) return props.placeholder?.default || 'Switch';
    else return props.placeholder?.onChecked || props.placeholder?.default || 'Switch';
  }

  const rollback = val => {
    toggleRef.current = val;
    setToggle(val);
  }

  const switchHandleOnChange = () => {
    if (props.disabled) {
      switchRef.current.value = toggle;
      return;
    }

    const newVal = !toggle;
    setToggle(newVal);
    toggleRef.current = newVal;
    props.onChange(newVal, () => rollback(!newVal));
  }

  useEffect(() => {
    defaultValue.current = Boolean(props.defaultValue);
    setToggle(defaultValue.current);
  }, [props.defaultValue]);

  useEffect(() => {
    if (typeof props.forceChangeRef === 'object') {
      props.forceChangeRef.current = toggle => {

        if (toggle !== toggleRef.current) {
          toggleRef.current = Boolean(toggle !== undefined ? toggle : defaultValue.current);
          setToggle(toggleRef.current);
        }
      }
    }
  }, [props.forceChangeRef]);

  useEffect(() => {
    if (props.forceChangeValue !== undefined && props.forceChangeValue !== toggleRef.current) {
      toggleRef.current = props.forceChangeValue;
      setToggle(props.forceChangeValue);
    }
  }, [props.forceChangeValue]);

  return (
    <div className='switch' disabled={props.disabled}>
      <label className="switch" disabled={props.disabled}>
        <input id={props.id}
          checked={toggle}
          disabled={props.disabled}
          onChange={switchHandleOnChange}
          ref={switchRef}
          type="checkbox" />
        <span className="slider"></span>
      </label>
      {(props.placeholder === undefined || props.placeholder.default !== '') && <p
        className={!toggle ? 'overset' : ''}>
        {getPlaceholder()}
      </p>}
    </div>
  );
}

export default Switch;