import '../styles/splash-screen.css';
import { useContext, useEffect, useRef, useState } from 'react';
import { globalContext } from '../../context/GlobalContext';
import UIRender from '../../objects/UIRender';
import LogoImg from '../../assets/images/main_logo.png';
import DAOServ from '../../objects/DAOServ';
import User from '../../objects/User';
import ErrHandler from '../../objects/ErrHandler';
import FileChooser from '../../objects/FileChooser';

const SplashScreen = () => {
  // *** useContext ***
  const {
    pushCacheFile,
    pushMessageHint,
    setCurrSession,
    setCurrSessionPicture,
    setShowSplashScreen,
    setTimezoneOffset,
  } = useContext(globalContext);

  // *** useRef ***
  const compoId = useRef('splash-screen');
  const popup = useRef(/** @type {HTMLDivElement} */(undefined));
  // *** useState ***
  const [splashMessage, setSplashMessage] = useState('Conectando con el servidor...');

  /** @type {React.AnimationEventHandler<HTMLDivElement>} */
  const dispose = e => {
    if (e.target === popup.current && UIRender.isHidden(popup.current)) {
      setShowSplashScreen(false);
    }
  }

  useEffect(() => {
    /** Disposes splash screen */
    const dispose = () => {
      // Updating splash screen.
      popup.current.classList.remove('show');
      popup.current.classList.add('keep');
      // Time out to hide splash screen.
      setTimeout(() => UIRender.hideElement(popup.current), 1700);
    }

    const getTimezoneOffset = () => {
      setSplashMessage('Obteniendo zona horaria');

      return DAOServ.getTimezoneOffset();
    }

    /** Tests server connection */
    const start = async () => {
      try {
        await DAOServ.get('test'); // Testing server conection.
        await testBlockchain(); // Testing blockchains integrity.

        if (localStorage.getItem('tst') !== null) {
          await testSession() // Testing current session.
            .catch(err => {
              localStorage.removeItem('tst');
              setCurrSession({ sessionStatus: User.SESSION_EXP });
              pushMessageHint({ message: ErrHandler.parseError(err), type: 'warning' })
            });
        } else setCurrSession({ sessionStatus: User.SESSION_INA });

        setTimezoneOffset(await getTimezoneOffset());
        dispose();
      } catch (err) {
        setSplashMessage('Solicitud terminada. Actualiza la página');

        let message = ErrHandler.isGenericError(err)
          ? ErrHandler.parseError(err)
          : ErrHandler.getDefaultMessage(ErrHandler.CODES.SERVER_DOWN);

        pushMessageHint({ message, type: 'error' });
      }
    }

    /** Request blockchain copy function */
    const testBlockchain = () => {
      // Refresh splash message.
      setSplashMessage('Comprobando las cadenas de bloques...');

      return DAOServ.get('test-blockchain-network');
    }

    /** Test current session. */
    const testSession = async () => {
      const tst = localStorage.getItem('tst'); // Getting TST from local storage.

      if (Boolean(tst)) {
        setSplashMessage('Iniciando sesión...');

        const session = await DAOServ.post('login_with_tst', { tst }, 'JSON');

        if (!session['ok'])  // Session expired.
          return Promise.reject(ErrHandler.getError(ErrHandler.CODES.TST_FAIL));

        setCurrSession({
          creationDate: session['creationDate'],
          id: session['iduser'],
          isSubuser: session['isSubuser'],
          sessionStatus: User.SESSION_ACT,
          tst: session['tst'],
          username: session['username']
        })

        DAOServ.setSessionOpen(true); // Needed for future queries.
        const picture = (await DAOServ.post('get_user_data', { tst }, 'JSON'))['picture'];

        if (picture) { // User has picture. Request picture to server.
          DAOServ.getFileCallback(picture, async (err, file) => {
            if (err)
              pushMessageHint({ message: err, type: 'error' });
            else {
              const urlData = await FileChooser.readAsDataURL(file)
              setCurrSessionPicture(urlData);
              pushCacheFile({
                content: urlData,
                name: picture,
                size: file.size
              });
            }
          });
        }
      } else setCurrSession({ sessionStatus: User.SESSION_EXP });

      return Promise.resolve();
    }

    const id = compoId.current;
    const parent = popup.current?.parentNode;
    const options = { container: true, footer: true, navbar: true };

    UIRender.disableGlobalScroll(id);
    UIRender.disableSiblings(popup.current, options);
    popup.current.classList.add('show'); // Show splash screen.
    start(); // Tests server connection.

    return () => {
      // Enable childs.
      UIRender.enableChilds(parent, options, id);
      // Enable global scroll.
      UIRender.enableGlobalScroll(id);
    }
  }, [pushMessageHint, pushCacheFile, setCurrSession, setCurrSessionPicture, setTimezoneOffset]);

  return (
    <div
      className={'splash-screen'}
      id={compoId.current}
      onAnimationEnd={dispose}
      ref={popup}>
      <div className='logo'>
        <img src={LogoImg} alt="logo" />
        <h1 className="title">Racchome</h1>
      </div>
      <div className="info">
        <div className="load-figures">
          <span className={`animated-figure f1 mini black`}></span>
          <span className={`animated-figure f2 mini black`}></span>
          <span className={`animated-figure f3 mini black`}></span>
        </div>
        <div className="splash-info">
          <span className='splash-message'>{splashMessage}</span>
        </div>
      </div>
    </div>
  );
}

export default SplashScreen;