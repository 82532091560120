import '../styles/push-bid.css'
import { useContext, useEffect, useRef, useState } from "react";
import Button from '../Button';
import Global from "../../objects/Global";
import Hintbox from '../Hintbox';
import Inputbar from '../Inputbar';
import UIRender from "../../objects/UIRender";
import * as Icons from "../../assets/images";
import DAOServ from '../../objects/DAOServ';
import { globalContext } from '../../context/GlobalContext';
import ErrHandler from '../../objects/ErrHandler';
import Bid from '../../objects/Bid';
import User from '../../objects/User';

/** Renders a PushBid popup compo
 * @param {Object} props The props object.
 * @param {number} props.basePrice This value will be used when no bids are pushed already. This
 * value is the current estate price (the first price before offerings).
 * @param {import('../../objects/Bidding').default} props.bidding The current bidding. This
 * object must be defined, otherwhise app will crash.
 * @param {() => void} props.onHide A callback function that will be called when popup is hidden.
 * @param {(errCode: string) => void} [props.onReject] A callback function that will be called
 * when bid is rejected (only triggered when error casted is a REQUEST_REJECTED or
 * BIDDING_IS_ACTIVE type error. REQUEST_REJECTED means that offer is to low in most of the times
 * [someone made a bid with the same or higher. errCode is any of those two errors.
 * offer before you]). Other error types are casted by the compo and won't trigger this callback
 * function.
 * @param {(b: import('../../objects/Bid').default) => void} [props.onResolve] A callback function
 * that will be triggered when bid is pushed successfully.
 */
const PushBid = props => {
  // *** useContext ***
  const { currSession, pushMessageHint } = useContext(globalContext);
  // *** useRef ***
  const compId = useRef('push-bid-popup');
  const popup = useRef(/** @type {HTMLDivElement} */(undefined));
  const inputRollback = useRef(/** @type {input?: string => void} */(undefined));
  // *** useState ***
  const [disableSubmit, setDisableSubmit] = useState(/** @type {boolean} */(undefined));
  const [disableUI, setDisableUI] = useState(false);

  /** @type {React.AnimationEventHandler<Element>} */
  const dispose = e => {
    if (e.target === popup.current && UIRender.isHidden(popup.current))
      props.onHide();
  }

  const getMinimumOffer = () => {
    const bidding = props.bidding;

    const firstBid = bidding?.getBids().length > 0
      ? Math.max(...bidding.getBids().map(b => b.getAmount()))
      : props.basePrice || 0;

    return (bidding?.getMinimumBid() + firstBid) || 0;
  }

  // *** useRef ***
  const currInput = useRef(getMinimumOffer());

  /** @param {string} [input] */
  const inputOnChangeHandler = input => {
    currInput.current = (input && Number(input)) || undefined;
    requestEnableSubmit();
  }

  const minusMinOfferBtnClickHandler = () => {
    const minBid = props.bidding.getMinimumBid();
    const minOff = getMinimumOffer();
    let newVal = currInput.current - minBid;

    currInput.current = newVal < minOff ? minOff : newVal;
    inputRollback.current(currInput.current);

    requestEnableSubmit();
  }

  const plusMinOfferBtnClickHandler = () => {
    currInput.current += props.bidding.getMinimumBid();
    inputRollback.current(currInput.current);

    requestEnableSubmit();
  }

  const requestEnableSubmit = () => {
    setDisableSubmit(currInput.current === undefined || currInput.current < getMinimumOffer());
  }

  const setMinOfferBtnClickHandler = () => {
    currInput.current = getMinimumOffer();
    inputRollback.current(currInput.current);

    requestEnableSubmit();
  }

  const submitBtnOnClickHandler = () => {
    setDisableUI(true);

    const body = { tst: currSession.tst, idBidding: props.bidding.getId(), bid: currInput.current };

    DAOServ.post('push_bid', body, 'JSON')
      .then(data => {
        const bid = new Bid();

        bid.setAmount(Number(currInput.current));
        bid.setBidder(new User({ username: currSession.username }));
        bid.setCreationDate(data['creation_date']);
        bid.setId(data['idbid']);

        pushMessageHint({ message: 'Tu oferta fue enviada', type: 'complete' });
        UIRender.hideElement(popup.current);
        props.onResolve(bid);
      }).catch(err => {
        const errCode = ErrHandler.getCode(err);
        const codes = ErrHandler.CODES;

        if (errCode === codes.REQUEST_REJECTED || errCode === codes.BIDDING_IS_INACTIVE) {
          pushMessageHint({
            message: errCode === codes.REQUEST_REJECTED
              ? 'Tu oferta fue rechazada. Vuelve a intentarlo'
              : 'La subasta ya ha finalizado',
            type: 'error'
          });

          UIRender.hideElement(popup.current);
          props.onReject(errCode);
        } else {
          pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' });
          setDisableUI(false);
        }
      });
  }

  useEffect(() => {
    const id = compId.current;
    const parent = popup.current?.parentNode;
    const options = { footer: true, navbar: true };

    UIRender.disableGlobalScroll(id);
    UIRender.disableSiblings(popup.current, options);

    return () => {
      // Enable childs.
      UIRender.enableChilds(parent, options, id);
      // Enable global scroll.
      UIRender.enableGlobalScroll(id);
    }
  })

  return (
    <div className="push-bid popup-wrapper" id={compId.current} ref={popup} onAnimationEnd={dispose}>
      <div className="popup">
        <div className="top-bar">
          <h2 className="highlight">Ofertar.</h2>
          <Button onClick={() => UIRender.hideElement(popup.current)}
            borderless
            disabled={disableUI}
            empty
            icon={Icons.CloseIcon}
            reduced
            rounded
            typeRender='error' />
        </div>
        <div className="content">
          <Hintbox icon={Icons.InfoIcon}
            message={
              'Ingresa la cantidad que quieres ofertar para esta propiedad. La cantidad mínima es de '
              + `$ ${getMinimumOffer()} MXN.`} />
          <Inputbar inputMode="numeric"
            defaultValue={getMinimumOffer()}
            disabled={disableUI}
            filters={[{ regExp: Global.REGEXP_FILTER_INTEGER }]}
            forceChangeRef={inputRollback}
            isValid={input => input && Number(input) >= getMinimumOffer()}
            maxLength={10}
            onChange={inputOnChangeHandler}
            placeholder={{
              default: 'Oferta (MXN)',
              onIsValidFail: `La oferta no puede ser menor de $ ${Global.formatNumber(getMinimumOffer())} MXN`
            }} requestFocus
            required
            textCenter />
          {/* Offer buttons bar */}
          <div className="offer-buttons-bar flex-box m3">
            {/* Set min button */}
            <Button disabled={disableUI}
              empty
              onClick={setMinOfferBtnClickHandler}
              reduced
              rounded
              value="Oferta mínima" />
            {/* Plus min bid to offer */}
            <Button disabled={disableUI}
              empty
              onClick={plusMinOfferBtnClickHandler}
              reduced
              rounded
              value={`+ ${props.bidding?.getMinimumBid() ?? ''}`} />
            {/* Minus min bid to offer */}
            <Button disabled={disableUI}
              empty
              onClick={minusMinOfferBtnClickHandler}
              reduced
              rounded
              value={`- ${props.bidding?.getMinimumBid() ?? ''}`} />
          </div>
          {/* Submit button */}
          <Button disabled={disableSubmit}
            animated
            icon={Icons.BidIcon}
            isWaiting={disableUI}
            onClick={submitBtnOnClickHandler}
            onWaitValue='Solicitando...'
            value="Ofertar" />
        </div>
      </div>
    </div>
  );
}

export default PushBid;