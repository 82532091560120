import Global from "./Global"
import ErrorObject from '../assets/error_codes.json';

class ErrHandler {
  /** The code names. Each attribute is a string, not an object! */
  static get CODES() {
    const keys = Object.keys(ErrorObject);
    const obj = { ...ErrorObject };

    keys.forEach(k => obj[k] = k);

    return obj;
  }

  /** Parse an obtained error. Returns ErrHandler.getDefaultMessage( ) as result.
   * @param {string|Error} error Error given.
   */
  static parseError(error) {
    const err = `${error}`?.replace(/^Error: /, '');

    if (Global.DEV_MODE && err) console.log(err);

    return ErrHandler.getDefaultMessage(err);
  }

  /** Parse a response that its ok attribute is false. Use this function when receiving an
   * unknown error (right when fetch function fails). This will return undefined if given
   * instance is not a failed response.
   * @param {Response} res Failed response instance.
   */
  static parseUnkownResponse(res) {
    if (!res.ok) return `${ErrHandler.getCode()}${res.status} ${res.statusText}`;
  }

  /** Obtains the default message of an error code.
   * @param {Error|string|{ code: string, message: string }} error The error code (or an object
   * from ErrHandler.getError()).
   * @returns {string}
   */
  static getDefaultMessage(error) {
    const defaultMessage = 'Ocurrió un error desconocido durante la operación';

    if (typeof error === 'string' || error instanceof Error) {
      let auxError = `${error}`.replace(/^Error: ^/, '');
      const errkeys = Object.keys(ErrHandler.CODES)


      if (ErrHandler.isGenericError(auxError)) {
        auxError = auxError.match(/:(.*?);/)[1];
        return ErrorObject[auxError]?.message || defaultMessage;
      } else if (errkeys.includes(auxError)) {
        return ErrorObject[auxError]?.message
      }
    }

    return error?.message || defaultMessage;
  }

  /** Obtains the code within an error. If error code is not found, UNKNOWN will be
   * returned. Might return undefined if given error is not a generic error.
   * @param {string|Error} [error]
   */
  static getCode(error) {
    const auxErr = `${error}`.replace(/^Error: /, '');

    if (ErrHandler.isGenericError(auxErr)) {
      const err = `${auxErr}`?.match(/:(.*?);/);
      return err.length >= 2 ? err[1] : 'UNKNOWN';
    }
  }

  /** Obtains the error codes object */
  static getCodes() {
    return ErrorObject;
  }

  /** Obtains a full error code (>ERR:A_CODE; A MESSAGE).
   * @param {string|{code: string, message}} [type] An error code or object (given by
   * ErrHandler.getCodes()). If undefined or invalid, UNKNOWN will be placed.
   * @param {string} [message] A custom message that you want to be attached to the error.
   * If undefined and type is found, default message will be returned.
   */
  static getError(type, message) {
    const valid = typeof type === 'string'
      ? Object.keys(ErrorObject).find(key => key === type)
      : Object.keys(ErrorObject).find(key => ErrorObject[key].code === type);

    return `>ERR:${valid || 'UNKNOWN'}; ${message || ErrorObject[valid]?.message}`;
  }

  /** Checks if given string is an ErrHandler error.
   * @param {string|Error} err
   */
  static isGenericError = err => {
    const errStr = `${err}`?.replace(/^Error: /, '');
    return /^>ERR:[A-Z0-9_]+;.*$/.test(errStr);
  }

  /** Obtains the default error message when client cannot connect to server
   * @deprecated
   */
  static getServerErrorMessage = () => {
    return 'No es posible conectarse con el servidor ahora mismo. Inténtalo más tarde';
  }
}

export default ErrHandler;