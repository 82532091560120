import "./styles/alert-message.css";
import { useEffect, useRef, useState } from "react";

/** Renders an AlertMessage compo.
 * @param {{
 *  message: {message: string, type: string},
 *  onDispose: () => void
 * }} props
 */
const AlertMessage = props => {
  // *** useRef ***
  const pushArray = useRef([props.message]);
  // *** useState ***
  const [show, setShow] = useState(false);

  const getMessage = () => {
    if (pushArray.current.length === 0) {
      return 'AlertMessage push test.';
    } else {
      const msg = pushArray.current[0].message || 'No message provided in object.';

      return msg;
    }
  }

  const getType = () => {
    if (pushArray.current.length === 0) {
      return '';
    } else {
      const type = ` ${pushArray.current[0].type}` || '';

      return type;
    }
  }

  const alertHandleOnAnimationEnd = () => {
    pushArray.current.splice(0, 1);
    setShow(false);
  }

  useEffect(() => {
    if (!pushArray.current.find(msg => msg === props.message)) {
      pushArray.current.push(props.message);
    }
  }, [props])

  useEffect(() => {
    if (!show) {
      if (pushArray.current.length > 0) {
        setShow(true);
      } else if (props.onDispose) props.onDispose();
    }
  }, [show, props]);

  if (show) return (
    <div
      className={`alert-message${getType()}`}
      onAnimationEnd={alertHandleOnAnimationEnd}>
      <p>{getMessage()}</p>
    </div>
  );
}

export default AlertMessage