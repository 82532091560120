import { useContext, useEffect, useRef } from "react";
import { globalContext } from "../../context/GlobalContext";
import Estate from "../../objects/Estate";
import Global from "../../objects/Global";
import ImageViewer from "../../components/ImageViewer";
import Inputbar from "../../components/Inputbar";
import PetAssistant from "../../components/PetAssistant";
import Hintbox from "../../components/Hintbox";
import { InfoIcon } from "../../assets/images";

/** Renders a Step1 compo for CreateEstate.
 * @param {Object} props The props object.
 * @param {Estate} props.estate The estate object.
 * @param {(e: boolean) => void} props.setEnableNextBtn A callback function to enable or
 * disable next step button
 */
const Step1 = props => {
  // *** useContext ***
  const { pushMessageHint } = useContext(globalContext); // global context.
  // *** useRef ***
  const imgCount = useRef(props.estate.getImages().length);
  const requestCallback = useRef(props.setEnableNextBtn);
  const title = useRef(props.estate.getTitle());

  /** Function handler when Image viewer component performs an addition.
   * @param {GenericFile} file 
   */
  const imageViewerHandleOnAdd = file => {
    props.estate.getImages().push(file);
    imgCount.current++;

    if (props.estate.getImages().length === Estate.MIN_IMAGES) requestNextStep();
  }

  /** Function handler when Image viewer component catch an error.
   * @param {string} fileName
   */
  const imageViewerHandleOnError = fileName => {
    pushMessageHint({ message: `"${fileName}" ya fue cargado`, type: 'warning' });
  }

  /** Function handler when Image viewer component performs a sort.
   * @param {number} oldIndex Index of the image that has been moved.
   * @param {number} newIndex New position where the image with oldIndex has been moved.
   */
  const imageViewerHandleOnMove = (oldIndex, newIndex) => {
    const images = props.estate.getImages();
    const imageAux = images[oldIndex];
    images[oldIndex] = images[newIndex];
    images[newIndex] = imageAux;
  }

  /** Function handler when Image viewer preforms a delete.
   * @param {number} index 
   */
  const imageViewerHandleOnRemove = index => {
    props.estate.getImages().splice(index, 1);
    imgCount.current--;

    if (props.estate.getImages().length === Estate.MIN_IMAGES - 1) requestNextStep();
  }

  /** Input change handler.
   * @param {string} [input] 
   */
  const inputHandleOnChange = input => {
    props.estate.setTitle(input);
    title.current = input;
    requestNextStep();
  }

  /** Request enable next step to parent component. */
  const requestNextStep = () => {
    props.setEnableNextBtn(title.current && imgCount.current >= 3);
  }

  useEffect(() => {
    requestCallback.current(title.current && imgCount.current >= 3);
  }, []);

  return (
    <div className="popup-content create-estate-content" id="step-0">
      <PetAssistant pet="kevin" animOnDwarf
        message={"¡Empecemos! Asigna un título y sube de tres a diez fotos de tu propiedad. Todos"
          + " querrán echarle un ojo."} />
      <div className="flex-box">
        <div className="child">
          <Inputbar onBlur={input => input?.replace(/^\s+|\s+$/, '').replace(/\s+/g, ' ')}
            onChange={inputHandleOnChange}
            defaultValue={props.estate.getTitle()}
            filters={[{ regExp: Global.REGEXP_FILTER_FORBIDDEN_SYMBOLS }]}
            maxLength={Estate.MAX_TITLE_LENGTH}
            minLength={Estate.MIN_TITLE_LENGTH}
            placeholder={{ default: 'Título' }}
            required
            stopPropagation />
        </div>
      </div>
      <Hintbox message="La primera imagen será la miniatura de tu propiedad" icon={InfoIcon} />
      <ImageViewer images={props.estate.getImages()}
        max={10}
        onAdd={imageViewerHandleOnAdd}
        onError={imageViewerHandleOnError}
        onMove={imageViewerHandleOnMove}
        onRemove={imageViewerHandleOnRemove}
        showImagesList={true}
        showToolsList={true} />
    </div>
  );
}

export default Step1;