import './styles/search-item.css';
import { useContext, useEffect, useState } from "react";
import { globalContext } from '../context/GlobalContext';
import Estate from "../objects/Estate";
import DAOServ from '../objects/DAOServ';
// Icons
import { BiddIcon, EstateDefaultCover } from '../assets/images';
import FileChooser from '../objects/FileChooser';
import LoadingBlock from './LoadingBlock';
import Contract from '../objects/Contract';
import UserCard from './UserCard';

/** Renders a SearchItem compo.
 * @param {Object} props The props object
 * @param {string} [props.id] An id for the compo.
 * @param {Estate} [props.estate] The estate to be rendered. If undefined, component will be rendered
 * on wait state.
 * @param {(estate: Estate) => void} [props.onClick] An onClick event handler. Will receive the
 * estate object attached to this compo.
 * @param {number} [props.today] The current day. It's only required when compo will render as an agreement.
 * @param {boolean} [props.white] Renders compo's background in white.
 */
const SearchItem = props => {
  // *** useContext ***
  const { getCacheFile, pushCacheFile } = useContext(globalContext);
  /** @type {React.MutableRefObject<Estate>} */
  // *** useState ***
  const [cover, setCover] = useState(/** @type {string|undefined} */(undefined));

  const getClassName = () => {
    let className = 'search-item';
    if (props.white) className += ' white';
    if (props.className) className += ` ${props.className}`;
    if (props.estate === undefined) className += ' wait';

    return className;
  }

  const getCoverClassName = () => {
    return props.estate.getStatus() !== Estate.STATUS_ACT ? 'gray-scale' : '';
  }

  const getOnClickEventHandler = () => {
    if (props.onClick) return () => props.onClick(props.estate);
  }

  const getPillClassName = () => {
    if (props.estate.getContract().getAgreement()?.getHash() !== undefined) {
      return `pill status ${Contract.statusToClass(props.estate.getContract(), props.today)}`
    } else {
      return `pill ${Estate.sellMethodToClass(props.estate)}`;
    }
  }

  useEffect(() => {
    // Request estate cover.
    const fetchCover = async () => {
      const cover = props.estate.getImages()[0];
      let newCover = EstateDefaultCover;

      if (cover?.getName() !== undefined) {
        // Checking if file is on cache.
        const auxCF = getCacheFile(cover.getName());

        if (auxCF !== undefined) { // Fetching file from cache.
          newCover = typeof auxCF.content === 'string'
            ? auxCF.content
            : await FileChooser.readAsDataURL(auxCF.content);
          cover.setSize(auxCF.size);
          cover.setURLData(newCover);
          setCover(newCover);
        } else { // Fetching file from server.
          DAOServ.getFileCallback(cover.getName() || '', async (err, file) => {
            if (file) {
              newCover = await FileChooser.readAsDataURL(file);
              cover.setSize(file.size);
              cover.setURLData(newCover);
              pushCacheFile({ content: newCover, name: cover.getName(), size: file.size });
            }

            setCover(newCover);
          });
        }
      } else setCover(newCover);
    }

    if (props.estate !== undefined) {
      // Fetch cover if estate does not have an image.
      if (!props.estate.getImages().at(0)?.getURLData())
        fetchCover();
      // Loading first picture from estate.
      else
        setCover(props.estate.getImages()[0].getURLData());
    }
  }, [props.estate, getCacheFile, pushCacheFile]);

  return (
    <div className={getClassName()} id={props.id || ''} onClick={getOnClickEventHandler()}>
      {props.estate === undefined && <span className="wait-curtain" />}
      {props.estate !== undefined && <div className="image-container">
        {/* Show estate's cover */}
        {cover !== undefined && <img className={getCoverClassName()} src={cover} alt="cover" />}
        {/* Show loading block */}
        {cover === undefined && <LoadingBlock />}
      </div>}
      {props.estate !== undefined && <div className="info-container">
        {/* Title */}
        <h4 className="title">{props.estate.getTitle()}</h4>
        {/* Pill info */}
        <span className={getPillClassName()}></span>
        {/* Bidding icon */}
        {props.estate.getBidding()?.getStatus() && <img
          className='bidding'
          src={BiddIcon}
          alt="bidding"
          title='Esta propiedad está en subasta' />}
        {/* Frequency pay info */}
        {props.estate.getContract().getAgreement()?.getHash() === undefined && <h4 className="price">
          {Estate.priceToString(props.estate)}
        </h4>}
        {props.estate.getContract().getAgreement()?.getHash() !== undefined && <div className="owner-container">
          De: <UserCard user={props.estate.getOwner()}
            mini
            type='glass' />
        </div>}
        {/* Contract or bidding info */}
        {Estate.contractToString(props.estate) && <h6>
          {Estate.contractToString(props.estate)}
        </h6>}
      </div>}
    </div>
  );
}

export default SearchItem;