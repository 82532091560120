import './styles/textbox.css';
import Global from '../objects/Global';
import UIRender from '../objects/UIRender';
import { useEffect, useRef, useState } from 'react';

/** Renders an Inputbar compo.
 * @param {Object} props The props object.
 * @param {string} [props.defaultValue] Specifies the default value.
 * @param {boolean} [props.disabled] Specified if compo is disabled. When disabled, compo
 * value cannot be changed.
 * @param {string} [props.className] Adds a custom class name.
 * @param {import('./Inputbar').InputbarFilterObject[]} [props.filters] An array of FilterObjects that
 * will filter the input.
 * @param {React.MutableRefObject<(val?: string) => void>} [props.forceChangeRef] An object.
 * A callback function will be assigned to 'current attribute'. Call current to force textbox value to
 * change (f.e. <ref_def.current(<new_value>)). If undefined, compo will be restored to its default
 * value. Doing this won't trigger any assigned callback function and will clear onError and
 * onComplete hooks.
 * @param {string} [props.forceChangeValue] Use this property to force the input value to change
 * from the parent compo (with a useState hook). Doing this won't trigger any assigned callback
 * function. This callback won't be called if input is empty.
 * @param {import('./Inputbar').InputbarPlaceholderObject} [props.header] A header for the compo. 'Textbox'
 * by default.
 * @param {*} [props.icon] An icon to be displayed aside placeholder.
 * @param {(input: string) => boolean} [props.isValid] A callback function that will review the input.
 * If valid, true should be return. This callback won't be called if input is empty.
 * @param {number} [props.maxLength] Maximum length for the input.
 * @param {number} [props.minLength] Minimum length for the input. Cannot be greater than maxLength
 * (will inherit maximum length value).
 * @param {(input?: string) => string} [props.onBlur] A callback function that will be called when
 * compo loses focus. Callback function will receive current input value and must return a new
 * (or the same) value for the input. If values doesn't match or doesn't fullfill minLenght (if
 * defined), input value will be replaced by the new value and onChange callback function will be called
 * (will return undefined if minLenght is greater). onBlur returned value will not pass through isValid
 * callback nor filters.
 * @param {(input?: string) => void} props.onChange A callback function that will be called
 * when compo changes. If isValid is defined and returns false, or minLength is defined and input
 * doesn't aquire minimum length, the param of this callback function will be undefined. For date
 * inputs, it may return an empty string if no date is set.
 * @param {string} [props.placeholder] Placeholder for the compo. 'Escribe aquí' by default.
 * @param {React.MutableRefObject<HTMLDivElement>} [props.reference] A reference for the compo.
 * @param {boolean} [props.requestFocus] When compo is rendered, it will request focus to the DOM.
 * @param {boolean} [props.required] Specifies if input is required (displays a red * symbol).
 * @param {boolean} [props.showResolve] If set to true and input is valid, compo will render to green
 * when lose focus.
 * @param {boolean} [props.stopPropagation] false by default. If set to true, Event.stopPropagation( )
 * will be called when onChange occurs.
 * @param {'capitalize'|'lowercase'|'uppercase'} [props.textTransform] Transforms the input. This won't
 * have effect if type is set to 'password'.
 */
const Textbox = props => {
  // *** useRef ***
  const currInput = useRef(props.defaultValue);
  const defaultValue = useRef(props.defaultValue || '');
  const inputRef = useRef(/** @type {HTMLInputElement|HTMLSelectElement} */(undefined))
  // *** useState ***
  const [onComplete, setOnComplete] = useState(false);
  const [onError, setOnError] = useState(/** @type {string} */(undefined));

  const getClassName = () => {
    let className = 'textbox'

    if (props.textTransform) className += ` ${props.textTransform}`;
    if (props.className) className += ` ${props.className}`;

    if (onError) className += ' error';
    else if (onComplete) className += ' complete';

    return className;
  }

  const getDefaultHeader = () => {
    return props.header?.default || 'Textbox';
  }

  const getOnMinLengthFailHeader = () => {
    return props.header?.onMinLengthFail ||
      `La entrada debe tener al menos ${props.minLength} caracter${props.minLength > 1 ? 'es' : ''}`
  }

  const getOnIsValidFailHeader = () => {
    return props.header?.onIsValidFail || `La entrada no es válida`;
  }

  /** @type {React.FocusEventHandler} */
  const textareaHandleOnBlur = () => {
    if (props.onBlur) {
      const newInput = props.onBlur(currInput.current);

      if (newInput !== currInput.current) {
        if (props.minLength > 0 && newInput && newInput.length < props.minLength) { // Min lenght fail.
          setOnError(getOnMinLengthFailHeader());
          props.onChange(undefined);
        } else {
          currInput.current = newInput;
          inputRef.current.value = newInput;
          props.onChange(newInput || undefined);
        }
      }
    }
  }

  /** @type {React.ChangeEventHandler<HTMLInputElement|HTMLSelectElement>} */
  const textareaHandleOnChange = e => {
    e.preventDefault();
    if (props.stopPropagation) e.stopPropagation();

    if (props.disabled) { // Compo is disabled.
      e.target.value = currInput.current;
      return;
    }

    let input = e.target.value;
    currInput.current = input; // Set value to ref.

    if (input) {
      // Text transform.
      switch (props.textTransform) {
        case 'capitalize': {
          currInput.current = Global.capitalize(input);
          break;
        } case 'lowercase': {
          currInput.current = input.toLowerCase();
          break;
        } case 'uppercase': {
          currInput.current = input.toUpperCase();
          break
        } default: { }
      }

      // Filters the value.
      if (props.filters?.length > 0) {
        for (let i = 0; i < props.filters.length; i++) {
          const f = props.filters[i];

          if (f.regExp) {
            input = input.replace(f.regExp, f.replace || '');
            currInput.current = currInput.current.replace(f.regExp, f.replace || '');
          }
        }
      }

      // Max length review.
      if (props.maxLength >= 0 && input.length > props.maxLength) {
        input = input.substring(0, props.maxLength);
        currInput.current = currInput.current.substring(0, props.maxLength);
      }

      if (e.target.value !== input) // Set value to target.
        e.target.value = input;

      const minLengthRev = !props.minLength || currInput.current?.length >= props.minLength;
      const isValidRev = !props.isValid || props.isValid(currInput.current);

      if (!minLengthRev || !isValidRev) { // Min length review.
        input = undefined;
        setOnError(!minLengthRev ? getOnMinLengthFailHeader() : getOnIsValidFailHeader);

        if (props.showResolve) setOnComplete(false);
      } else {
        input = currInput.current;
        setOnError(undefined);

        if (props.showResolve) setOnComplete(true);
      }
    } else {
      setOnError(undefined);

      if (props.showResolve) setOnComplete(false);
    }

    props.onChange(input || undefined);
  }

  useEffect(() => {
    if (props.requestFocus) UIRender.setFocus(inputRef.current)
  }, [props.requestFocus]);

  useEffect(() => {
    if (typeof props.forceChangeRef === 'object') {
      props.forceChangeRef.current = value => {
        if (value !== currInput.current) {
          currInput.current = value !== undefined ? value : defaultValue.current;
          inputRef.current.value = currInput.current;
          setOnError();
          setOnComplete(false);
        }
      }
    }
  }, [props.forceChangeRef]);

  useEffect(() => {
    if (props.forceChangeValue !== undefined) {
      currInput.current = props.forceChangeValue;
      inputRef.current.value = currInput.current;
      setOnError(false);
    }
  }, [props.forceChangeValue]);

  useEffect(() => {
    if (props.reference) inputRef.current = props.reference.current;
  }, [props.reference]);

  return (
    <div className="textbox-container" disabled={props.disabled}>
      <textarea ref={props.reference || inputRef}
        autoComplete='off'
        className={getClassName()}
        defaultValue={defaultValue.current}
        disabled={props.disabled}
        maxLength={props.maxLength}
        minLength={props.minLength > props.maxLength
          ? props.maxLength
          : props.minLength > 0
            ? props.minLength
            : undefined}
        onBlur={textareaHandleOnBlur}
        onChange={textareaHandleOnChange}
        placeholder={props.placeholder || 'Escribe aquí'} />
      <label>
        {props.required && <span className='required' />}
        {props.icon && <img src={props.icon} alt="inputbar-icon" />}
        {onError || getDefaultHeader()}
      </label>
    </div>
  );
}

export default Textbox;