import './styles/agre-esta-result-item.css';
import { useContext, useEffect, useState } from "react";
import { globalContext } from '../context/GlobalContext.js';
import { EstateDefaultCover } from "../assets/images";
import DAOServ from "../objects/DAOServ";
import FileChooser from "../objects/FileChooser";
import GenericFile from "../objects/GenericFile";
import LoadingBlock from "./LoadingBlock";
import UserCard from "./UserCard";
import Estate from '../objects/Estate.js';

/** A compo to render an estate or an agreement. Useful to show search results.
  * @param {Object} props The props object.
  * @param {import('../objects/Estate.js').default} props.estate The estate element (used to display 
  * its title and cover. If cover dataURL is undefined but its name is defined, compo will try to fetch
  * the image. If name is undefined. Default image will be shown). If its contract has an id and contract's
  * agreement attribute is defined, compo will be render as an Agreement compo.
  * @param {React.LegacyRef<*>} [props.reference] A reference for the compo.
  */
const AgreEstaResultItem = props => {
  // *** useContext ***
  const { currSession, getCacheFile, pushCacheFile } = useContext(globalContext)
  // *** useState ***
  const [image, setImage] = useState();

  const canRenderSellMethodPill = () => {
    return props.estate.getStatus() === Estate.STATUS_ACT
      || props.estate.getSellMethod() === Estate.ON_COMPLEX;
  }

  const canRenderStatusPill = () => props.estate.getStatus() !== Estate.STATUS_ACT;

  const getStatusPillType = () => {
    if (props.estate.getStatus() === Estate.STATUS_ACT)
      return Estate.sellMethodToClass(props.estate);
    else if (props.estate.getStatus() === Estate.STATUS_INA)
      return 'inactive';
    else
      return 'suspended';
  }

  const getTitle = () => {
    if (props.estate.getContract().getAgreement()?.getId() !== undefined) {
      const agreement = props.estate.getContract().getAgreement();

      return agreement.getRemark() ?? agreement.getHash();
    } else return props.estate.getTitle();
  }

  const renderMiddleContainer = () => {
    const auxAgr = props.estate.getContract().getAgreement();

    if (auxAgr?.getId() !== undefined) { // Agreemets
      return (<div className="middle agreement" >
        <h5 className="overset" >Arrendatarios</h5>
        <div className="lessees-container" >
          {auxAgr.getLessees().map(l => <UserCard key={l.getUsername()} user={l} reduced />)}
        </div>
      </div>);
    } else { // Estates
      const creator = props.estate.getCreator();
      const renderCreator = creator !== undefined
        && creator.getUsername() !== currSession.username;

      return (<div className="middle estate">
        <p className="price" >{Estate.priceToString(props.estate)}</p>
        {renderCreator && <div className="creator">
          <p className="created-by">Creado por:</p>
          <UserCard id={creator.getUsername()}
            user={creator}
            rounded />
        </div>}
      </div>);
    }
  }

  // Image fetch.
  useEffect(() => {
    /** 
     * @param {string} fileName
     * @param {(gF: GenericFile) => void} [onObtain]
     */
    const fetchImage = async (fileName, onObtain) => {
      const auxCF = getCacheFile(fileName);
      let newImage = EstateDefaultCover, auxGF;

      // Checking if image is on cache.
      if (auxCF !== undefined) {
        newImage = typeof auxCF.content === 'string'
          ? auxCF.content
          : await FileChooser.readAsDataURL(auxCF.content);
        auxGF = new GenericFile({
          name: auxCF.name,
          size: auxCF.size,
          urlData: newImage
        });

        setImage(newImage);
        onObtain && onObtain(auxGF);
      } else {
        DAOServ.getFileCallback(fileName, async (err, file) => {
          if (file !== undefined) {
            newImage = await FileChooser.readAsDataURL(file);
            auxGF = new GenericFile({ name: fileName, size: file.size, urlData: newImage });
            pushCacheFile({ content: newImage, name: fileName, size: file.size });
          }

          setImage(newImage);
          auxGF && onObtain && onObtain(auxGF);
        });
      }
    }

    const cover = props.estate.getImages().at(0);

    if (cover !== undefined) {
      if (cover.getURLData() !== undefined)
        setImage(cover.getURLData());
      else if (cover.getName() !== undefined)
        fetchImage(cover.getName(), gF => props.estate.getImages()[0] = gF);
      else
        setImage(EstateDefaultCover);
    }
  }, [props.estate, getCacheFile, pushCacheFile]);

  return (<div className="agre-esta-result-item" ref={props.reference}>
    <div className="img-container">
      {image === undefined && <LoadingBlock />}
      {image !== undefined && <img src={image} alt="agre-esta-cover" />}
    </div>
    <div className="data-container" >
      <div className="top" >
        <h5 className="highlight title" >{getTitle()}</h5>
        {/* Sell method pill */}
        {canRenderSellMethodPill() && <span className={`pill ${Estate.sellMethodToClass(props.estate)}`} />}
        {canRenderStatusPill() && <span className={`pill ${getStatusPillType()}`} />}
      </div>
      {renderMiddleContainer()}
    </div>
  </div>);
}

export default AgreEstaResultItem;