import Generic from "./Generic";
import Global from "./Global";

/** PhoneObject typedef
 * @typedef {Object} PhoneObject
 * @property {string} [code] A 2 char length string (MX).
 * @property {string} [number] The phone number (10 chars).
 * @property {string} [prefix] The prefix (4-5 chars; +52).
 */

class Persona extends Generic {
  /** Email max length. */
  static get EMAIL_MAX_LENGTH() { return 255 }
  /** Male genre. */
  static get GENRE_MALE() { return 1 }
  /** Female genre. */
  static get GENRE_FEMALE() { return 2 }
  /** Genre unset. */
  static get GENRE_UNSET() { return 3 }
  /** The minimum age to be an over age in milliseconds */
  static get MIN_AGE_MILLI() { return 568036800000 }
  /** The minimum age to be an over age in years. */
  static get MIN_AGE_YEAR() { return 18 }
  /** Name max length. */
  static get NAME_MAX_LENGTH() { return 100 }
  /** Name min length. */
  static get NAME_MIN_LENGTH() { return 2 }
  /** RFC length */
  static get RFC_LENGTH() { return 13 }

  /** @param {Persona} [obj] */
  constructor(obj) {
    super(obj);

    const { birthdate, email, firstName, genre, lastName, phone, rfc } = obj ?? {};

    this.setBirthdate(birthdate);
    this.setEmail(email);
    this.setFirstName(firstName);
    this.setGenre(genre);
    this.setLastName(lastName);
    this.setPhone(phone);
    this.setRFC(rfc);
  }

  // ************************************************************************** METHODS ***
  /** Obtains the user's age in years. Birthdate must be defined
   * @param {number} today The current day in milli. Will return undefined
   * if today is undefined or less than user's birthdate. */
  getAge(today) {
    return this.getBirthdate() !== undefined && this.getBirthdate() <= today
      ? (today - this.getBirthdate()) / (Global.DAY_TO_MILLI * 365)
      : undefined;
  }

  /** Tests if given RFC is valid.
   * @param {string} [rfc] An RFC to test with current user data. If undefined or
   * invalid, current user's RFC will be used to perform the test. Firt name, last
   * name and birthdate must be defined, otherwise this will return false.
   */
  testRFC(rfc) {
    const isRFC = Global.REGEXP_RFC.test(rfc ?? this.getRFC());
    const basis = isRFC
      && this.getFirstName()?.length >= Persona.NAME_MIN_LENGTH
      && this.getLastName()?.length >= Persona.NAME_MIN_LENGTH
      && this.getBirthdate() !== undefined;

    if (!basis) return false;

    // Substring of RFC (ABC012345HHH TO ABC012345).
    const partialRFC = isRFC ? rfc.substring(0, 10) : this.getRFC().substring(0, 10);
    let reStart, reDate;
    // * Rebuilding RFC with user data *
    const lastName = this.getLastName().includes(' ')
      ? this.getLastName().split(' ')
      : this.getLastName();

    // Rebuilding start.
    if (Array.isArray(lastName)) { // "Two or more last names".
      if (lastName[0].length === 1) return false; // Invalid last name.

      reStart = lastName[0].substring(0, 2) + lastName[1][0];
    } else { // "Single last name"
      reStart = `${lastName.substring(0, 2)}x`;
    }

    reStart = `${reStart}${this.getFirstName()[0]}`.toUpperCase();

    // Rebuilding date.
    const pDate = Global.parseDateUTC(this.getBirthdate()).split('/');
    reDate = `${pDate[2].substring(2)}${pDate[1]}${pDate[0]}`;

    return Global.replace(`${reStart}${reDate}`, 'unsymbolized-letters') === partialRFC;
  }

  // **************************************************************** GETTERS & SETTERS ***
  /** Obtains the user's birthdate.
   * @returns {number}
   */
  getBirthdate() {
    return this.birthdate;
  }

  /**
   * Assigns a date of birth for the user.
   * @param {Date|string|number} birthdate The birthdate. If a string, be sure that date is
   * a 'YYY-MM-DD' formated date.
   */
  setBirthdate(birthdate) {
    this.birthdate = Global.formatDateUTC(birthdate);
  }

  /** Obtains user's genre. */
  getGenre() {
    return this.genre;
  }

  /** Assings user's genre.
   * @param {number} genre 
   */
  setGenre(genre) {
    this.genre = genre === Persona.GENRE_MALE
      || genre === Persona.GENRE_FEMALE
      || genre === Persona.GENRE_UNSET
      ? genre : undefined;
  }

  /** Obtains the RFC of the user.
   * @returns {string}
   */
  getRFC() {
    return this.rfc;
  }

  /** Assign a RFC of the user. Value passes through testRFC method. First name, last name and
   * birthdate must be defined.
   * @param {string} rfc Given RFC.
   */
  setRFC(rfc) {
    this.rfc = rfc !== undefined && this.testRFC(rfc) ? rfc : undefined;
  }

  /** Obtains the persona's first name. */
  getFirstName() {
    return this.firstName;
  }

  /** Assigns a first name for the persona. Internally calls Global.capitalize and removes spaces from sides
   * and extra spaces.
   * @param {string} firstName 
   */
  setFirstName(firstName) {
    this.firstName = Global.capitalize(firstName?.replace(/(^\s|\s$)/g, '').replace(/\s+/g, ' '));
  }

  /** Obtains the user's last name. */
  getLastName() {
    return this.lastName;
  }

  /** Assigns a last name for the user. Internally calls Global.capitalize() and
   * removes spaces from sides.
   * @param {string} lastName 
   */
  setLastName(lastName) {
    this.lastName = Global.capitalize(lastName?.replace(/(^\s|\s$)/g, '').replace(/\s+/g, ' '));
  }

  /** Obtains a concatenation of persona's first name and last name. Might return an empty string
   * but never undefined. */
  getName() {
    const separator = this.getFirstName() && this.getLastName() ? ' ' : '';

    return (this.getFirstName() ?? '') + separator + (this.getLastName() ?? '');
  }

  /** Obtains the user's email. */
  getEmail() {
    return this.email;
  }

  /** Assigns an email for the user.
   * @param {string} email 
   */
  setEmail(email) {
    this.email = email?.toLowerCase();
  }

  /** Obtains the user's full phone number. If phone is undefined, '_' will be returned.
   * @param {string} [splitChar] The char to separate code, prefix and number. '_' by
   * default.
   */
  getFullPhone(splitChar) {
    return [this.phone?.code, this.phone?.prefix, this.phone?.number].join(splitChar ?? '_');
  }

  /** Obtains the full prefix (code_prefix) or undefined if any of them is undefined. */
  getFullPrefix() {
    const { code, prefix } = this.getPhone();
    if (code && prefix) return `${code}_${prefix}`;
  }

  /** Obtains the user's phone.
   * @returns {PhoneObject}
   */
  getPhone() {
    return this.phone;
  }

  /** Assigns a phone for the user.
   * @param {PhoneObject} phone The phone object.
   */
  setPhone(phone) {
    if (!this.getPhone() || !phone) {
      this.phone = { code: phone?.code, number: phone?.number, prefix: phone?.prefix };
    } else {
      const keys = Object.keys(phone || {});

      this.getPhone().code = keys.includes('code') ? phone?.code : this.getPhone().code;
      this.getPhone().number = keys.includes('number') ? phone?.number : this.getPhone().number;
      this.getPhone().prefix = keys.includes('prefix') ? phone?.prefix : this.getPhone().prefix;
    }
  }
}

export default Persona;